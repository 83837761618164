import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import './updatedesigncss.css';
import { ToastContainer } from "react-toastify";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Button,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    MenuItem,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    RadioGroup,
    FormControlLabel,
    Radio,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { useState, SyntheticEvent, useEffect, ChangeEvent, FormEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { number, string } from 'prop-types';
import apiService from 'src/services/api-service';
import axios from 'axios';
import { Attachment, Login } from '@mui/icons-material';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useNavigate } from 'react-router';
import { log } from 'console';




interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



// type TabValue = 0 | 1 | 2;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const UpdateDesign: React.FC = () => {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const handleChange = (event: SyntheticEvent, newValue: number) => {

        setValue(newValue);
    }
    const handleNext = () => {

        setValue((prevValue) => Math.min(prevValue + 1, 3));
    };
    const handlePre = () => {

        setValue((prevValue) => Math.min(prevValue - 1, 3));
    };
    return (
        <>
            <Helmet>
                <title>Update Design</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Update Design"

                // docs="https://material-ui.com/components/tabs/"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg" style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "0px" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                    style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "2px" }}
                >
                    <Grid item xs={12} style={{ paddingTop: "2px" }}>
                        <Card>
                            <Divider />
                            <CardContent style={{ backgroundColor: "#f8f9fb", paddingLeft: "10px", paddingRight: "0px", paddingTop: "0px" }}>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        textColor="primary"
                                        indicatorColor="primary"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        sx={{
                                            '& .MuiTab-root': {
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'blue',
                                                    color: '#fff',
                                                },
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: 'Blue',
                                                color: '#fff',
                                            },
                                        }}
                                    >
                                        <Tab label="Customer Input Details" {...a11yProps(0)} />
                                        <Tab label="Input Form" {...a11yProps(1)} />
                                        <Tab label="Case Selection" {...a11yProps(2)} />
                                        <Tab label="Output" {...a11yProps(3)} />
                                        <Tab label="Review" {...a11yProps(4)} />
                                        <Tab label="Verification" {...a11yProps(5)} />
                                        <Tab label="Validation" {...a11yProps(6)} />
                                        <Tab label="Attachments" {...a11yProps(7)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <form>



                                            <br />
                                            <h1>safafafa</h1>
                                            <CardContent>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography>Accordion 1</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Suspendisse malesuada lacus ex, sit amet blandit leo
                                                            lobortis eget.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2a-content"
                                                        id="panel2a-header"
                                                    >
                                                        <Typography>Accordion 2</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Suspendisse malesuada lacus ex, sit amet blandit leo
                                                            lobortis eget.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion disabled>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel3a-content"
                                                        id="panel3a-header"
                                                    >
                                                        <Typography>Disabled Accordion</Typography>
                                                    </AccordionSummary>
                                                </Accordion>
                                            </CardContent>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid style={{ paddingTop: "0px" }} item xs={12}>
                                                    <Card style={{ padding: "0px" }}>
                                                        <CardHeader title=" DESIGN INPUT FORM" />
                                                        <Divider />
                                                        <CardContent style={{ padding: "0px" }}>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <div className="form-container">
                                                                        {/* <h2 className="form-title">DESIGN INPUT FORM</h2> */}
                                                                        <div className="customer-name">
                                                                            <strong>Customer Name:</strong> DEBEL, BANGALORE
                                                                        </div>
                                                                        <table className="table">
                                                                            <tbody>
                                                                                <tr className='dshead'>
                                                                                    <th style={{ width: "50%" }} colSpan={2}>From: Marketing</th>
                                                                                    <th style={{ width: "50%" }} colSpan={2}>To: Design</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        I/P No:
                                                                                    </th>
                                                                                    <td>
                                                                                        <span className="bold">840</span> DT 02.05.2024
                                                                                    </td>
                                                                                    <th>
                                                                                        No. of Eqpts. Packed/Box:
                                                                                    </th>

                                                                                    <td>
                                                                                        <div>1. One</div>
                                                                                        <div>2. Three</div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Enq. No.
                                                                                    </th>
                                                                                    <td>
                                                                                        <span className="bold">SC/DEBEL-B/24-25/1021 DT 01.05.2024</span>
                                                                                    </td>
                                                                                    <th>
                                                                                        Spl. Accs. If any
                                                                                    </th>

                                                                                    <td>
                                                                                        Standard Accessories
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Drg. Category
                                                                                    </th>
                                                                                    <td>
                                                                                        <span >Blue</span>
                                                                                    </td>
                                                                                    <th>
                                                                                        Testing Standard
                                                                                    </th>

                                                                                    <td>
                                                                                        MIL. STD.,
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Drg. Required on
                                                                                    </th>
                                                                                    <td>
                                                                                        <span className="bold">04.05.2024</span>
                                                                                    </td>
                                                                                    <th>
                                                                                        Eqpt. Type
                                                                                    </th>

                                                                                    <td>
                                                                                        Electrical & pneumatics items
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Project Name
                                                                                    </th>
                                                                                    <td>
                                                                                        <span >SES</span>
                                                                                    </td>
                                                                                    <th>
                                                                                        Eqpt. Sensitivity
                                                                                    </th>

                                                                                    <td>
                                                                                        Ultra Fragile
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Eqpt. Size (LBH)
                                                                                    </th>
                                                                                    <td>
                                                                                        <span >Refer the Attachment</span>
                                                                                    </td>
                                                                                    <th>
                                                                                        Application
                                                                                    </th>

                                                                                    <td>
                                                                                        Army/Navy
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>
                                                                                        Eqpt. Wt.
                                                                                    </th>
                                                                                    <td>
                                                                                        <div>1.175 Kgs</div>
                                                                                        <div>  2.5.5 Kgs</div>

                                                                                    </td>
                                                                                    <th>
                                                                                        Special note if any
                                                                                    </th>

                                                                                    <td>
                                                                                        <div> 1. Breathing Bag Test Facility</div>
                                                                                        <div> 2. IUWBA</div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                        </form>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <form>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                                                    <table className="table">

                                                        <tr>
                                                            <td>Enq. No. : <span className="bold" style={{ color: "#0053bb" }}>SC/24-25/BEL(EWA)-B-NIL/1219</span></td>

                                                            <td>Customer :<span className="bold" style={{ color: "#0053bb" }}>Electro Optical Instruments Research Academy Electro Optical Instruments Research Academy</span> </td>

                                                            <td>Requirement : <span className="bold" style={{ color: "#0053bb" }}>TRANSIT CASE FOR  WT500</span> </td>
                                                        </tr>
                                                    </table>
                                                    <table className="design-table">
                                                        <tr>
                                                            <td>REFERENCE. NO. : <span className="bold">QMP 7.3.2 R/A</span> </td>

                                                            <td>ISSUE. NO. : <span className="bold">07/DATE: 01.05.2024</span> </td>

                                                            <td>PAGE REV NO. : <span className="bold">06/DATE : 01.05.2024</span></td>
                                                        </tr>

                                                    </table>
                                                    <Card>
                                                        <CardHeader title="EQUIPMENT DETAILS" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <div className="form-container">

                                                                        {/* <h2 className="form-title">DESIGN INPUT FORM</h2> */}

                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr className='dshead'>
                                                                                    <th className="sl-no">SL.NO</th>
                                                                                    <th className="details">DESIGN DATA DETAILS</th>
                                                                                    <th className="given-details">GIVEN DETAILS</th>
                                                                                    <th className="remarks">REMARKS</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="sl-no">1</td>
                                                                                    <td>Project Name / Equipment Name</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <textarea placeholder='Enter Remarks'></textarea>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">2</td>
                                                                                    <td>Equipment Size & Shape (I/P by PDF, AutoCAD, IGS)</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">3</td>
                                                                                    <td>Equipment Weight in Kgs</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">4</td>
                                                                                    <td>Equipment Sensitivity (Normal/Medium/High)</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">5</td>
                                                                                    <td>Number Of Equipments to be packed per case</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">6</td>
                                                                                    <td>Equipment Position (Horizontal/Vertical)</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">7</td>
                                                                                    <td>Application (Navy/Army/Air force/General)</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">8</td>
                                                                                    <td>Type of Equipment</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">9</td>
                                                                                    <td>Mode Of Transportation</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">10</td>
                                                                                    <td>Fragility Rate Of The Equipment (In case of dust proof)</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="sl-no">11</td>
                                                                                    <td>Any other Special requirements if any</td>
                                                                                    <td></td>
                                                                                    <td>
                                                                                        <TextField
                                                                                            id="Remarks"
                                                                                            label="Compliance"
                                                                                            multiline
                                                                                            name="Remarks"
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                            sx={{ m: 1, width: '24ch' }}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                        </form>
                                    </TabPanel>

                                </Box>


                                <Button variant="outlined" style={{ float: 'right' }} onClick={handleNext} sx={{ margin: 1 }} color="primary">
                                    Next
                                </Button>
                                <Button style={{ float: 'right' }} onClick={handlePre} sx={{ margin: 1 }} color="secondary">
                                    Previous
                                </Button>
                                <Button style={{ float: 'right' }} sx={{ margin: 1 }} color="error">
                                    Cancel
                                </Button>

                                <Button sx={{ margin: 1 }} variant='contained' style={{ float: 'right' }} color="primary">
                                    Request for Clarification
                                </Button>
                            </CardContent>
                        </Card>

                    </Grid>

                </Grid>

            </Container >
            <Footer />
        </>
    );
}

export default UpdateDesign;

const tabStyles = {
    hot: {
        backgroundColor: 'red',
        color: 'white',
    },
    warm: {
        backgroundColor: 'orange',
        color: 'white',
    },
    cold: {
        backgroundColor: 'green',
        color: 'white',
    },
};


