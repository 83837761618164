import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { id, it } from "date-fns/locale";
import { ChangeEvent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
// import { Type } from "typescript";

interface Data {
  tableRows: [
    {
      id: string
      cusid: string,
      sname: string,
      name: string,
    }
  ],
  id: number;
  code: string;
  typeid: string;
  sname: string;
  name: string;
  cityid: string;
  cperson: string;
  mobile: string;
  email: string;
  gstno: string;
  pan: string;
  tinno: number;
  isactive: string;
  cby: string;
  isexe: number;
  cusid: number;
  cusref: string;
  cuspocname: string;
  cuspocno: number;
  cusdesign: string;
  ismajor: number;
  add1: string;
  add2: number;
  add3: number;
  add4: number;
  add5: string;
  cadd1: string;
  cadd2: number;
  cadd3: number;
  cadd4: number;
  cadd5: string;
  ceo: string;
  add6: string;
  add7: string;
  cadd6: string;
  cadd7: string;
  createdby: string;
}
const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

const existing = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 0,
    label: 'No'
  }
];

const major = [
  {
    value: 1,
    label: 'Yes'
  },
  {
    value: 0,
    label: 'No'
  }
];

interface Type {
  id: number;
  name: string;
}
interface City {
  id: number;
  name: string;
}

interface Dist {
  id: number;
  name: string;
  stateid: number;
}

interface State {
  id: number;
  name: string;
}

function Customer() {
  const [data, setData] = useState<Data[]>([]);
  const [type, setType] = useState<Type[]>([]);
  const [city, setCity] = useState<City[]>([]);
  const [dist, setDist] = useState<Dist[]>([]);
  const [dist1, setDist1] = useState<Dist[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [states1, setStates1] = useState<State[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [tableRowErrors, setTableRowErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<any>(
    {

      id: '',
      code: '',
      typeid: '',
      sname: '',
      name: '',
      cityid: '',
      cperson: '',
      mobile: '',
      email: '',
      gstno: '',
      pan: '',
      tinno: '',
      isactive: '1',
      cby: '',
      isexe: 1,
      cusid: '',
      cusref: '',
      cuspocname: '',
      cuspocno: '',
      cusdesign: '',
      ismajor: 1,
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      add5: '',
      cadd1: '',
      cadd2: '',
      cadd3: '',
      cadd4: '',
      cadd5: '',
      ceo: '',
      add6: '',
      add7: '',
      cadd6: '',
      cadd7: '',
      //  distid: '',
      //   stateid: ''
    }
  );

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [ispincode1valid, setIspincode1valid] = useState(true);
  const [ispincode2valid, setIspincode2valid] = useState(true);
  const [row, setRow] = useState(true);
  const [tableRows, setTableRows] = useState([{
    id: '',
    cusid: '',
    sname: '',
    name: '',
  }]);

  const [customerId, setCustomerId] = useState<number>(null)
  const [search1, setSearch1] = useState('');
  const handleSearchChange1 = (event) => {
    setSearch1(event.target.value);

  };


  useEffect(() => {
    apiService.getCustomer(rowsPerPage.toString(), (page + 1).toString(), search1).then(response => {
      setData(response.data);
      console.log("apiService.getCustomer", response.data?.tableRows1)

    }).catch((err) => {
      console.log("err", err)
    });





    // apiService.getCustomer(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
    //   setData(response.data);
    //   console.log("apiService.getCustomer",response.data?.tableRows1)

    // }).catch((err) => {
    //   console.log("err", err)
    // });

    apiService.getDropdown("crm_mas_type").then(response => {
      setType(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_city").then(response => {
      setCity(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
    apiService.getDropdown("mas_state").then(response => {
      setStates(response.data);
      setStates1(response.data);
    }).catch((err) => {
      console.log("err", err)
    });




  }, [rowsPerPage, page, formData, search1]);




  useEffect(() => {
    const caddr4ToFind = formData?.cadd4;
    const selectedOption1 = states.find(option => option.name === caddr4ToFind);
    const stateid = selectedOption1 ? selectedOption1.id : null;

    const nameToFind = formData?.add4;
    const selectedOption = states1.find(option => option.name === nameToFind);
    const stateid1 = selectedOption ? selectedOption.id : null;

    // Fetch districts only if state IDs are available
    if (stateid !== null) {
      apiService.getDropdown("mas_district")
        .then(response => {
          const filteredData = response.data.filter(item => item.stateid === stateid);
          setDist(filteredData);
        })
        .catch(err => {
          console.log("err", err);
        });
    }

    if (stateid1 !== null) {
      apiService.getDropdown("mas_district")
        .then(response => {
          const filteredData1 = response.data.filter(item => item.stateid === stateid1);
          setDist1(filteredData1);
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }, [formData, states, states1]);

  const getCustomerId = (id: number) => {
    apiService.getCustomer_id(id).then(response => {
      // setData(response.data);
      console.log("apiService.customerId", response?.data?.tableRows1)
      if (response?.data?.tableRows1?.length > 0) {
        setTableRows(response?.data?.tableRows1)
      } else {
        setTableRows([{
          id: '',
          cusid: '',
          sname: '',
          name: '',
        }])
      }

    }).catch((err) => {
      console.log("err", err)
    });
  }
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{10,11}$/;
    return mobileRegex.test(mobile);

  };

  const validatePincode1 = (add5: string) => {
    // Mobile number validation logic (example: 10 digits only)
    const pincodeRegex1 = /^[0-9]{6}$/;
    return pincodeRegex1.test(add5);
  };
  const validatePincode2 = (cadd5: string) => {
    // Mobile number validation logic (example: 10 digits only)
    const pincodeRegex2 = /^[0-9]{6}$/;
    return pincodeRegex2.test(cadd5);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'mobile') {
      setIsMobileValid(validateMobile(value));
    }



    if (name === 'add5') {
      setIspincode1valid(validatePincode1(value));
    }

    if (name == 'cadd5') {
      setIspincode2valid(validatePincode2(value));
    }


    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };
  const handleClickOpen = () => {
    setTableRows([
      {
        id: '',
        cusid: '',
        sname: '',
        name: ''
      }
    ]);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      code: '',
      typeid: '',
      sname: '',
      name: '',
      cityid: '',
      cperson: '',
      mobile: '',
      email: '',
      gstno: '',
      pan: '',
      tinno: '',
      isactive: 1,
      cby: '',
      isexe: 0,
      cusid: '',
      cusref: '',
      cuspocname: '',
      cuspocno: '',
      cusdesign: '',
      ismajor: 1,
      add1: '',
      add2: '',
      add3: '',
      add4: '',
      add5: '',
      cadd1: '',
      cadd2: '',
      cadd3: '',
      cadd4: '',
      cadd5: '',
      ceo: '',
      add6: '',
      add7: '',
      cadd6: '',
      cadd7: ''
      //  distid: '',
      //   stateid: ''
    });
    setSelectedItem(null);
    setIsMobileValid(true);
    setIsEmailValid(true);
    setIspincode1valid(true);
    setIspincode2valid(true);
    setErrors({});
  };

  const handleAddRow = () => {

    setTableRows((prevState) => [
      ...(prevState || []),
      { id: '', sname: '', cusid: '', name: 'Nill' },
    ]);
  };


  const handleMoveToFactory = () => {
    setFormData({
      ...formData,
      cadd1: formData.add1,
      cadd2: formData.add2,
      cadd3: formData.add3,
      cadd4: formData.add4,
      cadd5: formData.add5
    });
  };

  const isInsert = !formData?.id;
  const validateForm = (isInsert: boolean) => {
    const requiredFields = [
      'sname', 'name', 'typeid', 'cityid', 'cperson',
      'mobile'
    ];
    let valid = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      if (isInsert || formData[field] !== undefined) {
        console.log(field, formData[field], "validate");

        if (!formData[field]) {
          newErrors[field] = `${field} is required`;
          valid = false;
        }
      }
    });
    setErrors(newErrors);
    return valid;
  }

  const validateTableRows = (isInsert) => {
    const newTableRowErrors: { [key: string]: string } = {};
    let isValid = true;

    //Validate the name field of each row
    tableRows.forEach((row, index) => {
      if (!row.name.trim()) {
        newTableRowErrors[index] = `Row ${index + 1}: Name is required`;
        isValid = false;
      }
    });


    setTableRowErrors(newTableRowErrors); // Set the errors for tableRows
    return isValid;
  };

  // Insert and update
  let submit = (event: any) => {
    event.preventDefault();
    if (!validateForm(isInsert)) {
      return;
    }

    if (!validateTableRows(isInsert)) {
      return;
    }
    console.log("inside")
    // Insert
    if (selectedItem == null) {

      let data = {
        "tableRows1": tableRows,
        "code": formData?.code,
        "typeid": formData?.typeid.toString(),
        "sname": formData?.sname,
        "name": formData?.name,
        "cityid": formData?.cityid, //  formData?.name, id miss
        "cperson": formData?.cperson,
        "mobile": formData?.mobile,
        "email": formData?.email,
        "gstno": formData?.gstno,
        "pan": formData?.pan,
        "tinno": formData?.tinno,
        "isactive": formData?.isactive,
        "cby": localStorage.getItem('empid'),
        "isexe": formData?.isexe,
        "cusref": formData?.cusref,
        "cuspocname": formData?.cuspocname,
        "cuspocno": formData?.cuspocno, //formData?.cuspocno, missing
        "cusdesign": formData?.cusdesign,
        "ismajor": formData?.ismajor,  //formData?.ismajor, 
        "add1": formData?.add1,
        "add2": formData?.add2,
        "add3": formData?.add3,
        "add4": formData?.add4,
        "add5": formData?.add5,
        "cadd1": formData?.cadd1,
        "cadd2": formData?.cadd2,
        "cadd3": formData?.cadd3,
        "cadd4": formData?.cadd4,
        "cadd5": formData?.cadd5,
        "ceo": formData?.ceo,
        "add6": formData?.add6,
        "add7": formData?.add7,
        "cadd6": formData?.cadd6,
        "cadd7": formData?.cadd7,
        // "distid": formData?.distid,
        // "stateid": formData?.stateid
      }
      console.log("inside", data)

      apiService.insertCustomer(data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          // setSelectedItem(null);
          // setFormData({
          //   id: '',
          //   code: '',
          //   typeid: '',
          //   sname: '',
          //   name: '',
          //   cityid: '',
          //   cperson: '',
          //   mobile: '',
          //   email: '',
          //   gstno: '',
          //   pan: '',
          //   tinno: '',
          //   isactive: '',
          //   cby: '',
          //   isexe: '',
          //   cusref: '',
          //   cuspocname: '',
          //   cuspocno: '',
          //   cusdesign: '',
          //   ismajor: '',
          //   add1: '',
          //   add2: '',
          //   add3: '',
          //   add4: '',
          //   add5: '',
          //   cadd1: '',
          //   cadd2: '',
          //   cadd3: '',
          //   cadd4: '',
          //   cadd5: '',
          //   ceo: '',
          //   add6: '',
          //   add7: '',
          //   cadd6: '',
          //   cadd7: '',
          //   //      distid: '',
          //   // stateid: ''
          // });
          // handleClose();
          if (response.data !== "Already Exists!") {
            setSelectedItem(null);
            setFormData({
              id: '',
              code: '',
              typeid: '',
              sname: '',
              name: '',
              cityid: '',
              cperson: '',
              mobile: '',
              email: '',
              gstno: '',
              pan: '',
              tinno: '',
              isactive: '',
              cby: '',
              isexe: '',
              cusref: '',
              cuspocname: '',
              cuspocno: '',
              cusdesign: '',
              ismajor: '',
              add1: '',
              add2: '',
              add3: '',
              add4: '',
              add5: '',
              cadd1: '',
              cadd2: '',
              cadd3: '',
              cadd4: '',
              cadd5: '',
              ceo: '',
              add6: '',
              add7: '',
              cadd6: '',
              cadd7: '',
              // distid: '',
              // stateid: ''
            });
            handleClose(); // Only call handleClose if the value does not already exist
          }

        }
      }).catch((err) => {
        console.log("err", err);
      });

    }

    // Update
    else {
      let data = {
        tableRows1: tableRows,
        code: formData?.code,
        typeid: formData?.typeid.toString(),
        sname: formData?.sname,
        name: formData?.name,
        cityid: formData?.cityid,
        cperson: formData?.cperson,
        mobile: formData?.mobile,
        email: formData?.email,
        gstno: formData?.gstno,
        pan: formData?.pan,
        tinno: formData?.tinno,
        isactive: formData?.isactive,
        cby: localStorage.getItem('empid'),
        isexe: formData?.isexe,

        cusref: formData?.cusref,
        cuspocname: formData?.cuspocname,
        cuspocno: formData?.cuspocno,
        cusdesign: formData?.cusdesign,
        ismajor: formData?.ismajor,
        add1: formData?.add1,
        add2: formData?.add2,
        add3: formData?.add3,
        add4: formData?.add4,
        add5: formData?.add5,
        cadd1: formData?.cadd1,
        cadd2: formData?.cadd2,
        cadd3: formData?.cadd3,
        cadd4: formData?.cadd4,
        cadd5: formData?.cadd5,
        ceo: formData?.ceo,
        add6: formData?.add6,
        add7: formData?.add7,
        cadd6: formData?.cadd6,
        cadd7: formData?.cadd7,
        // distid: formData?.distid,
        // stateid: formData?.stateid
      };
      apiService.updateCustomer(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            code: '',
            typeid: '',
            sname: '',
            name: '',
            cityid: '',
            cperson: '',
            mobile: '',
            email: '',
            gstno: '',
            pan: '',
            tinno: '',
            isactive: '',
            cby: '',
            isexe: '',
            cusref: '',
            cuspocname: '',
            cuspocno: '',
            cusdesign: '',
            ismajor: '',
            add1: '',
            add2: '',
            add3: '',
            add4: '',
            add5: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            ceo: '',
            add6: '',
            add7: '',
            cadd6: '',
            cadd7: '',
            //      distid: '',
            // stateid: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  };
  const handleEditClick = (item: Data) => {
    // let isa = item?.isactive == "true"? 1 : 0;
    console.log(item.isactive);
    setFormData({
      id: item?.id.toString(),
      code: item?.code,
      typeid: item?.typeid,
      sname: item?.sname,
      name: item?.name,
      cityid: item?.cityid,
      cperson: item?.cperson,
      mobile: item?.mobile,
      email: item?.email,
      gstno: item?.gstno,
      pan: item?.pan,
      tinno: item?.tinno,
      isactive: item?.isactive,
      cby: item?.cby,
      isexe: item?.isexe,
      cusid: item?.cusid,
      cusref: item?.cusref,
      cuspocname: item?.cuspocname,
      cuspocno: item?.cuspocno,
      cusdesign: item?.cusdesign,
      ismajor: item?.ismajor,
      add1: item?.add1,
      add2: item?.add2,
      add3: item?.add3,
      add4: item?.add4,
      add5: item?.add5,
      cadd1: item?.cadd1,
      cadd2: item?.cadd2,
      cadd3: item?.cadd3,
      cadd4: item?.cadd4,
      cadd5: item?.cadd5,
      ceo: item?.ceo,
      add6: item?.add6,
      add7: item?.add7,
      cadd6: item?.cadd6,
      cadd7: item?.cadd7,
      // distid: item?.distid,
      // stateid: item?.stateid
    });
    setTableRows(() => [
      { cusid: '', name: '', sname: '', id: '' },
    ]);
    getCustomerId(item?.id)

    setSelectedItem(item);
    setOpen(true);
  };

  const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const newTableRows = [...tableRows];
    newTableRows[index][name] = value;
    console.log("newTableRows", newTableRows)

    setTableRowErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[index]) {
        delete updatedErrors[index];
      }
      return updatedErrors;
    });
    setTableRows(newTableRows);

  };

  console.log("tableRows", tableRows);
  console.log("formData", formData);


  return (
    <>
      <Helmet>
        <title>Customer Register - CRM</title>
      </Helmet>

      <PageTitleWrapper>

        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid item xs={12} md={4} style={{ paddingLeft: "10px" }}>
            <Box p={0}>
              <PageTitle heading="Customer Register" subHeading="" />
            </Box>
          </Grid>

          {localStorage.getItem("urole") === "Head of Marketing" ||
          localStorage.getItem("urole") === "Administrator" ? (
            <Grid
              item
              xs={12}
              md={8}
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Box p={0} style={{ marginTop: "-25px", display: "flex", alignItems: "center" }}>
                <TextField
                  label="Search"
                  variant="outlined"
                  value={search1}
                  onChange={handleSearchChange1}
                  margin="normal"
                />
                <Button
                  variant="contained"
                  style={{ marginTop: "22px", marginLeft: "10px" }}
                  color="primary"
                  onClick={handleClickOpen}
                >
                  + Add New
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              md={8}
              container
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Box
                p={0}
                style={{
                  marginTop: "-25px",
                  marginRight: "-25px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Search"
                  variant="outlined"
                  value={search1}
                  onChange={handleSearchChange1}
                  margin="normal"
                  sx={{ flex: 1, alignSelf: "flex-end" }}
                />
              </Box>
            </Grid>
          )}
        </Grid>



      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >

          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {/* Employee Info Section */}
                <Grid item xs={12} >
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Customer Info</Typography>
                </Grid>
                {selectedItem && (
                  <Grid item xs={12} sm={3}>
                    <TextField
                      id="code"
                      label="Customer Code"
                      name='code'
                      value={formData?.code}
                      onChange={handleChange}
                      fullWidth
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    select
                    margin="dense"
                    label="Type"
                    name='typeid'
                    fullWidth
                    value={formData?.typeid}
                    onChange={handleChange}
                    error={!!errors.typeid}
                    helperText={errors.typeid ? "Please select Type" : ''}
                  >
                    {type.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="sname"
                    label="Short Name"
                    name='sname'
                    value={formData?.sname}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!!errors.sname}
                    helperText={errors.sname ? "Please Enter Short Name" : ''}

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="name"
                    label="Name"
                    name='name'
                    value={formData?.name}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!!errors.name}
                    helperText={errors.name ? "Please Enter Name" : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    select
                    label="City"
                    name='cityid'
                    value={formData?.cityid}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!!errors.cityid}
                    helperText={errors.cityid ? "Please select City" : ''}
                  >
                    {city.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Contact Info Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Contact Info</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="cperson"
                    label="Contact Person"
                    name='cperson'
                    value={formData?.cperson}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!!errors.cperson}
                    helperText={errors.cperson ? "Please Enter Contact Person" : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    id="mobile"
                    label="Mobile No"
                    name='mobile'
                    value={formData?.mobile}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                    error={!isMobileValid || !!errors?.mobile}
                    helperText={!isMobileValid ? 'Invalid mobile number' : errors?.mobile}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/\D/g, ''); // Allow only digits
                    }}

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="email"
                    label="Email Id"
                    name='email'
                    value={formData?.email}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"


                  />
                </Grid>


                {/* Customer Details */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Customer Details</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="gstno"
                    label="GSTNO"
                    name='gstno'
                    value={formData?.gstno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="pan"
                    label="PAN NO"
                    name='pan'
                    value={formData?.pan}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="tinno"
                    label="TIN NO"
                    name='tinno'
                    value={formData?.tinno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="IS Existing"
                    name='isexe'
                    fullWidth
                    value={formData?.isexe === '' ? '' : Number(formData?.isexe)}
                    onChange={handleChange}
                    error={!!errors.isexe}
                    helperText={errors.isexe}

                  >
                    {existing.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>


                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cusref"
                    label="Reference"
                    name='cusref'
                    value={formData?.cusref}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cuspocname"
                    label="POC Name"
                    name='cuspocname'
                    value={formData?.cuspocname}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cuspocno"
                    label="POC No"
                    name='cuspocno'
                    value={formData?.cuspocno}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cusdesign"
                    label="Designation"
                    name='cusdesign'
                    value={formData?.cusdesign}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="IS Major"
                    name='ismajor'
                    fullWidth
                    value={formData?.ismajor === '' ? '' : Number(formData?.ismajor)}
                    onChange={handleChange}
                    error={!!errors.ismajor}
                    helperText={errors.ismajor}

                  >
                    {major.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>



                {/* Permanent Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>Factory Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="add1"
                    label="Plot/Street No"
                    name='add1'
                    value={formData?.add1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField

                    label="City"
                    name='add2'
                    value={formData?.add2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />

                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="State"
                    name='add4'
                    value={formData?.add4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  >
                    {states1.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="District"
                    name='add3'
                    value={formData?.add3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  >
                    {dist1.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    margin="dense"
                    id="add5"
                    label="Pin Code"
                    name='add5'
                    fullWidth
                    value={formData?.add5}
                    onChange={handleChange}
                    error={!ispincode1valid}
                    helperText={!ispincode1valid ? 'Invalid Pin Code' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    sx={{ margin: 1 }}
                    variant="contained"
                    color="primary"
                    onClick={handleMoveToFactory}
                  >
                    Same as
                  </Button>

                </Grid>
                {/* Current Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold' }}>Contact Address</Typography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="cadd1"
                    label="Plot/Street No"
                    name='cadd1'
                    value={formData?.cadd1}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="City"
                    name='cadd2'
                    value={formData?.cadd2}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  />

                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="State"
                    name='cadd4'
                    value={formData?.cadd4}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  >
                    {states.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    label="District"
                    name='cadd3'
                    value={formData?.cadd3}
                    onChange={handleChange}
                    fullWidth
                    margin="dense"
                  >
                    {dist.map((option) => (
                      <MenuItem key={option.id} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <TextField
                    required
                    margin="dense"
                    id="cadd5"
                    label="Pin Code"
                    name='cadd5'
                    fullWidth
                    value={formData?.cadd5}
                    onChange={handleChange}
                    error={!ispincode2valid}
                    helperText={!ispincode2valid ? 'Invalid Pin Code' : ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    select
                    margin="dense"
                    label="Status"
                    name='isactive'
                    fullWidth
                    value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                    onChange={handleChange}
                    error={!!errors.isactive}
                    helperText={errors.isactive}
                  >
                    {status.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>

                {/* <Grid item xs={12}>
                  <form action="#">

                    <Card>
                      <CardHeader title="Division" />
                      <Paper>

                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableRows?.map((row: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>

                                  <TableCell>
                                    <TextField
                                      id='sname'
                                      value={row.sname}
                                      name="sname"
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      rows={1}
                                      sx={{ m: 1, width: '28ch' }}
                                    />
                                  </TableCell>

                                  <TableCell>
                                    <TextField
                                      required
                                      id='name'
                                      type="string"
                                      name="name"
                                      value={row.name}
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      sx={{ m: 1, width: '28ch' }}
                                      error={!!tableRowErrors[index]}
                                      helperText={tableRowErrors[index] || ''}
                                    />
                                  </TableCell>

                                </TableRow>
                              ))}
                            </TableBody>

                          </Table>

                        </TableContainer>

                      </Paper>
                    
                    </Card>
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {Object.keys(tableRowErrors).map((index) => (
                        <div key={index}>{tableRowErrors[index]}</div>
                      ))}
                    </div>

                  </form>
                  </Grid> */}

                <Grid item xs={12}>
                  <form action="#">
                    <Card>
                      <CardHeader title="Division" />
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>S.No</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableRows?.map((row, index) => (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>
                                    <TextField
                                      id="sname"
                                      label="Division Code"
                                      value={row.sname}
                                      name="sname"
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      rows={1}
                                      sx={{ m: 1, width: '28ch' }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <TextField
                                      required
                                      id="name"
                                      label="Division Name"
                                      type="string"
                                      name="name"
                                      value={row.name}
                                      onChange={(e) => handleTableRowsChange(index, e)}
                                      sx={{ m: 1, width: '28ch' }}
                                      error={!!tableRowErrors[index]} // Shows error state if there's an error
                                      helperText={tableRowErrors[index]} // Shows error message or empty string
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <ToastContainer
                          position="top-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                          theme="light"
                        />
                      </Paper>
                    </Card>
                    {/* <div style={{ color: 'red', marginTop: '10px' }}>
                      {Object.keys(tableRowErrors).map((index) => (
                        <div key={index}>{tableRowErrors[index]}</div>
                      ))}
                    </div> */}
                  </form>
                </Grid>
                <Button sx={{ margin: 1, gap: 1 }} variant='contained' color="primary" onClick={handleAddRow} >
                  <FontAwesomeIcon icon={faPlus} />  Add More
                </Button>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !ispincode1valid || !ispincode2valid}>
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>CUSTOMER No</TableCell>
                      <TableCell>ShortName</TableCell>
                      <TableCell>Name</TableCell>

                      <TableCell>Status</TableCell>
                      <TableCell>Created By</TableCell>
                      <TableCell>Action</TableCell>
                      {/* Add more headers as necessary */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.code}</TableCell>
                        <TableCell>{row.sname}</TableCell>
                        <TableCell>{row.name}</TableCell>

                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>{row.createdby}</TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                        {/* Add more cells as necessary */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data.length === 0 ? 0 : data[0]["count"]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      /> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Footer />
    </>
  );
}

export default Customer;
function setType(data: any) {
  throw new Error("Function not implemented.");
}

