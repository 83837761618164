import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, CardHeader, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import './designdev.css'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { log } from 'console';
import { id } from 'date-fns/locale';
import { NewReleasesRounded } from '@material-ui/icons';
import { isTemplateExpression } from 'typescript';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
interface NavigationState {
  label: string;
  heading: string;
}

interface Data1 {
  id: number;
  enqno: string;
  enqdate: string;
  cusname: string;
  req: string;
  eqipposition: string;
  printcolor: string;
  isproceed: number;
  mpnno: string;
}
interface LocationState1 {
  reqtypeid: number;
  id: number;
}
interface Data {
  id: number;
  name: string;
  compliance: string;
  attach: string;
  remarks: string;
}
interface Test {
  id: number;
  type: string;
  name: string;
}
interface Model {
  id: number;
  casemodel: string;
}
interface EssData {
  essdataid: string;
  remarks: string;
  cby: string;
  name: string;
  attach: string;
  compliance: string;
}
interface TestAccess {
  type: string;
  testaccessid: string;
  name: string;
  bit: string;
  handle?: string;  // Marking as optional
  status?: boolean | string;  // Marking as optional
  desp?: string;
}

interface FormData {
  ifinsert: boolean;
  informid: number;
  partno: string;
  drawid: string | number; // Assuming state.id is either a string or number, adjust as needed
  reqtypeid: number;
  ecnid: number;
  refno: string;
  issueno: string;
  pagerevno: string;
  intype: string;
  drawdate: Date;
  casetype: string;
  casemodelid: number;
  casemodelid1: number;
  result: string;
  designby: string;
  authby: string;
  status: string;
  cby: string;
  listessdata: EssData[];
  listtest: TestAccess[];
  listaccess: TestAccess[];
  listitem: TestAccess[];
  listrequire: TestAccess[];
  attch: string;
  disablePartno?: boolean;
  testdesc: string;
  accdesc: string;
}

interface ModelData {
  id: number;
  casemodel: string;
  innerdimns: string;
  outerdimns: string;
  boxwt: string;
  casterwheel: number;
  acceswt: number;
  epe: number;
  casenetwt1: number;
  xlpe: number;
  casenetwt2: number;
  wropecode: number;
  latchtype: string;
  hingetype: string;
  stiffwt: number;
  indlen: number;
  indwidth: number;
  indbottom: number;
  indtop: number;
  isactive: number;
}

// Status options for the dropdown
const status = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'In-Active' }
];

interface SizeWt {
  id: number;
  innerdimns: string;
  outerdimns: string;
  casenetwt2: number;
  indlen: number;
  indwidth: number;
  indbottom: number;
  indtop: number;
}
interface ReviewData {
  listreqitems: ReqItems[];
  ifinsert: boolean;
  drawid: string | number;
  enqdetid: number;
  rdate: Date;
  eqipsize: string;
  eqipwt: string;
  refdrgthere: number;
  referdrgtheretext: string;
  suplocspd: number;
  eqipposition: string;
  anysplmreq: number;
  eqliftbeltreq: number;
  nameplatereq: number;
  spltestreq: number;
  senspd: number;
  senspdtext: string;
  printcolor: string;
  prv: number;
  descnt: number;
  lhook: number;
  wheel: number;
  slings: number;
  flprov: number;
  lidhandle: number;
  locks: number;
  latchlookasst: number;
  huin: number;
  foam: string;
  casetype: string;
  caseresult: string;
  rofoam: string;
  roprintcolor: string;
  cby: "1";
  revno: string;
  revpoints: string;
  note: string;
}

interface EcnHistory {
  ecnno: string;
  date: Date;
  revpoints: string;
}
interface ReqItems {
  revid: number;
  accessid: number;
  status: number
}
interface Drawing_Requirments {
  name: string;
  ipno: string | null;
  ipdate: string | null;
  drawcatgid: number | null;
  purposeid: number | null
  Drawcateg: string | null;
  Purpose: string | null;
  drawreqdate: string | null;
  proname: string | null;
  eqptsize: string | null;
  eqptwt: string | null;
  noofeqpt: string | null;
  splacc: string | null;
  teststd: string | null;
  eqpttype: string | null;
  eqptsen: string | null;
  app: string | null;
  splnote: string | null;
}
interface Drawing_Mul_Att {
  filename: string | null;
  url: string | null;

}
interface OutputData {
  ifinsert: boolean;
  drawid: string | number;
  odate: Date;
  cptdwgattachsc: Date;
  cptdwgattachhc: Date;
  casedwgattchsc: Date;
  casedwgattchhc: Date;
  foamdwgattachsc: Date;
  foamdwgattachhc: Date;
  scnprtdetattachsc: Date;
  scnprtdetattachhc: Date;
  billofmatattachsc: Date;
  billofmatattachhc: Date;
  stfdwgattachsc: Date;
  stfdwgattachhc: Date;
  prddevplnaatchsc: Date;
  prddevplnaatchhc: Date;
  dfmeaattachsc: Date;
  dfmeaattachhc: Date;
  cby: "1"
}
interface VerifyData {
  ifinsert: boolean;
  drawid: string | number;
  vdate: Date;
  cieqdimns: string;
  caseexdimns: string;
  caseinnerdims: string;
  foamoutbottom: string;
  foamouttop: string;
  formcutbottom: string;
  formcuttop: string;
  handle: string;
  wheel: string;
  latchlock: string;
  cby: "1";
  listrequirements: Requirements[];
  extraverifyrows: Extraverify[];

}
interface Requirements {
  //drawid: number;
  verid: number;
  accessid: number;
  status: string
}

interface Extraverify {
  desp: number;
  status: string
}

interface ValidData {
  id: number;
  detailstobechecked: string;
  chkwith: string;
}
interface ValidDetailsData {
  ifinsert: boolean;
  drawid: string | number;
  ecnid: number;
  drawvalid: number;
  con: Date;
  detailscheck: string[];
  casedwg: number;
  bom: number;
  foamdwg: number;
  printingdwg: number;
  remarkslistdate: number;
  eqpwt: string;
  casewt: string;
  accwt: string;
  foamwt: string;
  stiffwt: string;
  finalwt: string;
}

interface MarketDocs {
  id: 0;
  drawid: number;
  docname: string;
  path: string;
}

type Props = {}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ViewUpdatePage: React.FC = () => {
  const [tableData, setTableData] = useState<Data1[]>([]);
  const [value, setValue] = useState(0);
  const location = useLocation();
  const state = location.state as LocationState1 | undefined;
  const [data, setData] = useState<Data[]>([]);
  const [test, setTest] = useState<Test[]>([]);
  const [access, setAccess] = useState<Test[]>([]);
  const [activeItem, setActiveItem] = useState<string>("Input Form");
  const [model, setModel] = useState<ModelData[]>([]);
  const [model1, setModel1] = useState<ModelData[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [radioValues, setRadioValues] = useState({});
  const [radioValues1, setRadioValues1] = useState({});
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [radioValuesverify, setRadioValuesverify] = useState({});
  const [selectedAccessIds, setSelectedAccessIds] = useState<Set<number>>(new Set());
  const [showTextBoxreferdrg, setShowTextBoxreferdrg] = useState(false);
  const [showTextBoxsenspd, setShowTextBoxsenspd] = useState(false);
  const [showTextBoxhandle, setShowTextBoxhandle] = useState(false);
  const [insertmodelid, setinsertmodelid] = useState<number | null>(null);
  const [sizewtdata, setSizeWtData] = useState<SizeWt[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [openDesignModelPopup, setOpenDesignModelPopup] = useState(false);
  const [openFusionPopup, setOpenFusionPopup] = useState(false);
  const [isBottomEditable, setIsBottomEditable] = useState(false);
  const [isTopEditable, setIsTopEditable] = useState(false);
  const state1 = location.state as NavigationState;

  // const handleNext = () => {

  //   if(value === 0){
  //     const response =  apiService.Designinit(state.id);
  //   }

  //   setValue((prevValue) => Math.min(prevValue + 1, 7));
  //   console.log(value);

  // };
  const handleNext = () => {

    setValue((prevValue) => {
      const newValue = Math.min(prevValue + 1, 7);
      console.log('Updated value:', newValue);
      return newValue;
    });

  };

  const handleBack = () => {

    navigate('/Design/DesignFollowup');

  };

  const handlePre = () => {

    setValue((prevValue) => Math.min(prevValue - 1, 7));
  };
  const [formModelData, setFormModelData] = useState<any>({
    id: '',
    casemodel: '',
    innerdimns: '',
    outerdimns: '',
    boxwt: 0,
    casterwheel: '',
    acceswt: 0,
    epe: 0,
    casenetwt1: 0,
    xlpe: 0,
    casenetwt2: 0,
    wropecode: 0,
    latchtype: '',
    hingetype: '',
    stiffwt: 0,
    isactive: 1
  });
  const [checkboxes, setCheckboxes] = useState({
    conceptDwgAttached: false,
    casedrawingattached: false,
    foamdrawingattached: false,
    screenprintingdetailsattached: false,
    billmaterialattached: false,
    stiffnerdrawingattached: false,
    proddevplanattached: false,
    dfmeaattached: false
  });

  const [formData, setFormData] = useState<FormData>({
    ifinsert: true,
    informid: 0,
    partno: "",
    drawid: state.id,
    reqtypeid: state.reqtypeid,
    ecnid: 0,
    refno: "",
    issueno: "",
    drawdate: new Date(),
    pagerevno: "",
    intype: "",
    casetype: "",
    casemodelid: 0,
    casemodelid1: 0,
    result: "",
    designby: "1",
    authby: "1",
    status: "Initiate",
    cby: "1",
    attch: "",
    testdesc: "",
    accdesc: "",
    listessdata: [
      {
        essdataid: "",
        remarks: "",
        cby: "1",
        name: "",
        attach: "",
        compliance: ""
      }
    ],
    listtest: [
      {
        type: "test",
        testaccessid: "",
        name: "",
        bit: "",
        desp: ""
      }
    ],
    listaccess: [
      {
        type: "Accessories",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listitem: [
      {
        type: "item",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listrequire: [
      {
        type: "require",
        testaccessid: "",
        name: "",
        bit: "",
        status: "",
        desp: ""
      }
    ]
  })
  const [drawreq, setDrawreq] = useState<Drawing_Requirments>({
    name: "",
    ipno: "",
    ipdate: "",
    drawcatgid: 0,
    purposeid: 0,
    Drawcateg: "",
    Purpose: "",
    drawreqdate: "",
    proname: "",
    eqptsize: "",
    eqptwt: "",
    noofeqpt: "",
    splacc: "",
    teststd: "",
    eqpttype: "",
    eqptsen: "",
    app: "",
    splnote: "",
  });
  const [drawreqatt, setDrawreqatt] = useState<Drawing_Mul_Att[][]>([]);
  const [exverify, setExverify] = useState<any>({
    desp: "",
    status: ""
  });

  const [originalModelData, setOriginalModelData] = useState({
    casemodelid: formData.casemodelid,
    //casemodel: formData.casemodel,
  });

  const [reviewData, setReviewData] = useState<ReviewData>({
    listreqitems: [
      // {
      //   revid: state.id,
      //   accessid: null,
      //   status: null
      // }
    ],
    ifinsert: true,
    drawid: state.id,
    enqdetid: 0,
    eqipsize: "",
    eqipwt: "",
    rdate: new Date(),
    refdrgthere: null,
    referdrgtheretext: "",
    suplocspd: null,
    eqipposition: "",
    anysplmreq: null,
    eqliftbeltreq: null,
    nameplatereq: null,
    spltestreq: null,
    senspd: null,
    senspdtext: "",
    printcolor: "",
    prv: null,
    descnt: null,
    lhook: null,
    wheel: null,
    slings: null,
    flprov: null,
    lidhandle: null,
    locks: null,
    latchlookasst: null,
    huin: null,
    foam: "",
    casetype: "",
    caseresult: "",
    rofoam: "",
    roprintcolor: "",
    cby: "1",
    revno: "",
    revpoints: "",
    note: ""
  })

  const [ecnhistoryData, setecnhistoryData] = useState<EcnHistory>({
    ecnno: "",
    date: null,
    revpoints: ""
  })

  const [outputData, setoutputData] = useState<OutputData>({
    ifinsert: true,
    drawid: state.id,
    odate: new Date(),
    cptdwgattachsc: null,  // Setting to a default Date value
    cptdwgattachhc: null,
    casedwgattchsc: null,
    casedwgattchhc: null,
    foamdwgattachsc: null,
    foamdwgattachhc: null,
    scnprtdetattachsc: null,
    scnprtdetattachhc: null,
    billofmatattachsc: null,
    billofmatattachhc: null,
    stfdwgattachsc: null,
    stfdwgattachhc: null,
    prddevplnaatchsc: null,
    prddevplnaatchhc: null,
    dfmeaattachsc: null,
    dfmeaattachhc: null,
    cby: "1"
  })

  const [verifyData, setverifyData] = useState<VerifyData>({
    ifinsert: true,
    drawid: state.id,
    vdate: new Date(),
    cieqdimns: "",
    caseexdimns: "",
    caseinnerdims: "",
    foamoutbottom: "",
    foamouttop: "",
    formcutbottom: "",
    formcuttop: "",
    handle: "",
    wheel: "",
    latchlock: "",
    cby: "1",
    listrequirements: [],
    extraverifyrows: []
  })

  useEffect(() => {
    if (sizewtdata.length > 0) {
      const firstItem = sizewtdata[0]; // Assuming you want to use the first item for the data
      setverifyData((prev) => ({
        ...prev,

        caseexdimns: firstItem.outerdimns || "",
        caseinnerdims: firstItem.innerdimns || "",
        foamoutbottom: firstItem.indbottom?.toString() || "",
        foamouttop: firstItem.indtop?.toString() || "",


      }));
    }
  }, [sizewtdata]);





  const [validData, setValidData] = useState<ValidData[]>([
    {
      id: 0,
      detailstobechecked: "",
      chkwith: "",
    },
  ]);

  const [validDetailsData, setvalidDetailsData] = useState<ValidDetailsData[]>([
    // {
    //   ifinsert: true,
    //   drawid: state.id,
    //   ecnid: 0,
    //   drawvalid: 0,
    //   detailscheck: [],
    //   casedwg: 0,
    //   bom: 0,
    //   foamdwg: 0,
    //   printingdwg: 0,
    //   remarkslistdate: 0,
    //   eqpwt: "",
    //   casewt: "",
    //   accwt: "",
    //   foamwt: "",
    //   stiffwt: "",
    //   finalwt: "",
    // },
  ]);

  const [marketdocsData, setmarketdocsData] = useState<MarketDocs[]>([
    {
      id: 0,
      drawid: state.id,
      docname: "",
      path: ""
    }
  ])


  const handleChange1 = (event: any) => {
    const { name, value } = event.target;
  }

  useEffect(() => {
    if (state?.id) {
      apiService.getEssData(Number(state.id))
        .then(response => {
          //setData(response.data);
          console.log("response.data12", response?.data || '');
          setFormData(prevState => ({
            ...prevState,
            listessdata: response.data
          }));
          console.log(formData, "formData12");
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
      apiService.getDrawreq(Number(state.id))
        .then(response => {
          //setData(response.data);
          console.log("response draw req", response.data);
          setDrawreq(response.data);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
      apiService.getDrawatt(Number(state.id))
        .then(response => {
          //setData(response.data);
          console.log("response draw setDrawreatt", response.data);
          setDrawreqatt(response.data);
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      // apiService.getTestData(Number(state.id))
      //   .then(response => {
      //     console.log("response => test =>", response.data);
      //     setFormData(prevState => ({
      //       ...prevState,
      //       listtest: response.data.map((item: any) => ({
      //         type: "test", 
      //         testaccessid: item.id,
      //         desp: item.remarks || "" 
      //       }))
      //     }));
      //   })
      //   .catch(error => {
      //     console.error("Error fetching data:", error);
      //   });

      apiService.getTestData(Number(state.id))
        .then(response => {
          // Ensure the response data includes table IDs mapped to testaccessid
          const updatedData = response.data.map((item: any) => ({
            ...item,
            testaccessid: item.id, // Assign the table ID to testaccessid
            desp: item.remarks
          }));
          console.log("Updated listtest with table IDs", updatedData);

          setFormData(prevState => ({
            ...prevState,
            listtest: updatedData
          }));


        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });


      apiService.getAccessoriesData(Number(state.id))
        .then(response => {
          //setData(response.data);
          const updatedData = response.data.map((item: any) => ({
            ...item,
            testaccessid: item.id, // Assign the table ID to testaccessid
          }));

          setFormData(prevState => ({
            ...prevState,
            listaccess: updatedData
          }));


        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      apiService.getSizeWt(Number(state.id))
        .then(response => {

          setSizeWtData(response.data);
        })
        .catch(err => {
          console.log("Error fetching grid data:", err);
        });


      apiService.getDropdown("dsn_mas_testaccess")
        .then(response => {

          const filteredData2 = response.data.filter((item: Test) => item.type === 'item');

          setFormData(prevState => ({
            ...prevState,
            listitem: filteredData2
          }));

          //for requirements
          const filteredData3 = response.data.filter((item: Test) => item.type === 'require');

          setFormData(prevState => ({
            ...prevState,
            listrequire: filteredData3
          }));
        })
        .catch(error => {
          console.error("Error fetching dropdown data:", error);
        });

      apiService.getDropdown("dsn_mas_model").then(response => {
        setModel(response.data);
      }).catch((err) => {
        console.log("err", err)
      });

      apiService.getDropdown("dsn_mas_model").then(response => {
        setModel1(response.data);
      }).catch((err) => {
        console.log("err", err)
      });
    }

    apiService.getDropdown("dsn_draw_validation").then(response => {
      setValidData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getInputForm1(state.id).then(response => {

      const api = response.data
      setFormData(api as FormData);
    }).catch((err) => {
      console.log("err", err)
    });



    apiService.getEnq_Date(Number(state.id))
      .then(response => {

        setTableData(response.data);
      })
      .catch(err => {
        console.log("Error fetching grid data:", err);
      });

    apiService.getReviewForm(state.id).then(response => {

      const api = response.data
      setReviewData(api as ReviewData);

    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getEcnHistory(state.id).then((response) => {


      // Ensure the response is mapped to the rows structure
      const transformedData = Array.isArray(response.data)
        ? response.data.map((item) => ({
          ecnno: item.ecnno || "",
          date: item.date ? new Date(item.date) : null,
          revpoints: item.revpoints || "",
        }))
        : [
          {
            ecnno: response.data.ecnno || "",
            date: response.data.date ? new Date(response.data.date) : null,
            revpoints: response.data.revpoints || "",
          },
        ];

      setRows(transformedData); // Update rows state

    }).catch((err) => {
      console.log("Error fetching ECN history", err);
    });


    apiService.getOutputForm(state.id).then(response => {

      const api = response.data
      setoutputData(api as OutputData);

    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getVerificationForm(state.id).then(response => {

      const api = response.data
      setverifyData(api as VerifyData);
      if (response.data?.handle === "plastic" || response.data?.handle === "ss" || response.data?.handle === "tiny") {
        setShowTextBoxhandle(false);
      }
      else {
        setShowTextBoxhandle(true);
      }

    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getValidationForm(state.id).then(response => {

      const api = response.data
      setvalidDetailsData(api as ValidDetailsData[]);
    }).catch((err) => {
      console.log("err", err)
    });

  }, [state?.id]);
  useEffect(() => {
    console.log("test case check = > remarks", formData);

  }, [formData?.listtest])

  useEffect(() => {
    apiService.getSizeWt(Number(state.id))
      .then(response => {
        setSizeWtData(response.data);
      })
      .catch(err => {
        console.log("Error fetching grid data:", err);
      });
  }, [value])

  useEffect(() => {
    apiService.get_drawdoc(state.id).then(response => {
      setmarketdocsData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [state.id])


  useEffect(() => {
    console.log("marketdocsData ====> ", marketdocsData);

  }, [marketdocsData])

  useEffect(() => {
    if (formData.casemodelid && formData.result === 'Can Be Fusioned') {
      // Fetch the data for the existing case model
      apiService.getDesignModelid(formData.casemodelid).then((response) => {

        const modelData = response.data[0];
        setFormModelData(modelData);
      }).catch((err) => {
        console.log("Error fetching data for fusioned model:", err);
      });
    }
  }, [formData.casemodelid, formData.result]);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {

    setValue(newValue)

  };

  //FOR drawdate
  let drawdate = new Date();
  let day1 = String(drawdate.getDate()).padStart(2, '0');
  let month1 = String(drawdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year1 = drawdate.getFullYear();
  let formattedDatedrawdate = `${day1}-${month1}-${year1}`;

  //FOR rdate
  let rdate = new Date();
  let day2 = String(rdate.getDate()).padStart(2, '0');
  let month2 = String(rdate.getMonth() + 1).padStart(2, '0');
  let year2 = rdate.getFullYear();
  let formattedDaterdate = `${day2}-${month2}-${year2}`;

  //FOR odate
  let odate = new Date();
  let day3 = String(odate.getDate()).padStart(2, '0');
  let month3 = String(odate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year3 = odate.getFullYear();
  let formattedDateodate = `${day3}-${month3}-${year3}`;

  //FOR vdate
  let vdate = new Date();
  let day4 = String(vdate.getDate()).padStart(2, '0');
  let month4 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year4 = vdate.getFullYear();
  let formattedDatevdate = `${day4}-${month4}-${year4}`;

  //FOR con
  let con = new Date();
  let day5 = String(vdate.getDate()).padStart(2, '0');
  let month5 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year5 = con.getFullYear();
  let formattedDatecon = `${day5}-${month5}-${year5}`;

  const handleCheckboxChange = (field) => {
    if (field === 'indbottom') {
      setIsBottomEditable(!isBottomEditable);
      setIsTopEditable(false);  // Make the other field readonly
    } else {
      setIsTopEditable(!isTopEditable);
      setIsBottomEditable(false); // Make the other field readonly
    }
  };

  const handleRemarksChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    // Create a deep copy of the listessdata array
    const newTableRows = [...formData?.listessdata];
    // Update the specific row's remarks and essdataid
    newTableRows[index] = {
      ...newTableRows[index],
      [name]: value,
      essdataid: rowid.toString(),
    };
    // Update the state with the new list
    setFormData({ ...formData, listessdata: newTableRows });

  };

  const handleReqItemsChange = (testaccessid: any, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    // Ensure testaccessid is properly set and not an empty string
    const validAccessId = testaccessid || 0; // Default to 0 if testaccessid is falsy
    setFormData(prevData => {
      const updatedList = [...prevData.listitem];
      updatedList[index] = {
        ...updatedList[index],
        testaccessid: isChecked ? validAccessId : null // Store validAccessId if checked, otherwise set to null
      };
      return { ...prevData, listitem: updatedList };
    });
  };

  const handleBitChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Update specific row's radio button selection in local state
    setRadioValues(prevValues => ({
      ...prevValues,
      [rowid]: value,
    }));

    // Update formData with new bit value only
    setFormData(prevState => {
      const newTableRows = [...prevState.listtest];
      newTableRows[index].bit = value;
      return { ...prevState, listtest: newTableRows };
    });


  };
  const handleRemarkChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;

    // Update formData with the new remark value
    setFormData(prevState => {
      const newTableRows = [...prevState.listtest];
      newTableRows[index].desp = value; // Ensure `desp` exists in the data structure
      return { ...prevState, listtest: newTableRows };
    });


  };


  const handleBitChange1 = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Update local state for the specific row's radio button selection
    setRadioValues1(prevValues => ({
      ...prevValues,
      [rowid]: value,  // Ensure this update only applies to the current row
    }));
    // Update the global formData state
    const newTableRows = [...formData?.listaccess];
    newTableRows[index].bit = value;  // Update the corresponding bit value as string "1" or "0"
    newTableRows[index]["testaccessid"] = rowid.toString();
    setFormData({ ...formData, listaccess: newTableRows });

  };

  // const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: value
  //   }));
  //   if (name === 'casetype' && value === 'NPD Case') {
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       result: 'To Be Made',
  //     }));
  //     setOpenDesignModelPopup(true); // Open the design model popup
  //   }
  //   if (name === 'result' && value === 'Can Be Fusioned') {
  //     setFormModelData((prevState) => ({
  //       ...prevState,
  //     }));
  //     setOpenFusionPopup(true); // Open the design model popup   
  //   }
  // };

  const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;

    // Update formData state with additional logic for clearing dependent fields
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      ...(name === 'casetype' && value === 'NPD Case'
        ? { result: 'To Be Made', casemodelid: null } // Set default result and clear casemodelid
        : {}),
      ...(name === 'casetype' && value === 'Existing Case'
        ? { result: '', casemodelid: null } // Clear result and casemodelid when switching back to Existing Case
        : {}),
    }));

    // Trigger actions based on specific fields
    if (name === 'casetype') {
      if (value === 'NPD Case') {
        setOpenDesignModelPopup(true); // Open the design model popup
      } else if (value === 'Existing Case') {
        setOpenDesignModelPopup(false); // Ensure the popup is closed when switching
      }
    } else if (name === 'result' && value === 'Can Be Fusioned') {
      setOpenFusionPopup(true); // Open the fusion popup
    }
  };


  // Textbox and Select box onchage
  const handleModelChange = (event: any) => {
    const { name, value } = event.target;

    setFormModelData((prevFormModelData) => {
      const newFormData = { ...prevFormModelData, [name]: value };



      const updatedInnerdimns = `${Number(newFormData.indlen) || 0}*${Number(newFormData.indwidth) || 0}*${Number(newFormData.indbottom) || 0}+${newFormData.indtop || 0}`;

      return {
        ...newFormData,
        innerdimns: updatedInnerdimns,
      };
    });
    if (formData.result === 'Can Be Fusioned' && !originalModelData.casemodelid) {
      setOriginalModelData({
        casemodelid: formData.casemodelid,
        //casemodel: formData.casemodel,
      });
    }
  };

  useEffect(() => {
    console.log("======>", formModelData);

  }, [formModelData])

  const handleFieldChange = (e, field) => {
    const value = e.target.value;

    // Update the individual field
    setFormModelData((prevState) => {
      const newData = { ...prevState, [field]: value };

      // Recalculate the 'innerdimns' on each field change (including bottom and top)
      const updatedInnerdimns = `${Number(newData.indlen) || 0}*${Number(newData.indwidth) || 0}*${Number(newData.indbottom) || 0}+${Number(newData.indtop) || 0}`;

      return {
        ...newData,
        innerdimns: updatedInnerdimns,
      };
    });
  };

  // Insert and Update 
  let submit = (event: any) => {
    //event.preventDefault(); // Prevents default form submission behavior
    // Insert
    let data = {
      "casemodel": formModelData?.casemodel,
      "innerdimns": formModelData?.innerdimns,
      "outerdimns": formModelData?.outerdimns,
      "boxwt": formModelData?.boxwt,
      "casterwheel": formModelData?.casterwheel,
      "acceswt": formModelData?.acceswt,
      "epe": formModelData?.epe,
      "casenetwt1": formModelData?.casenetwt1,
      "xlpe": formModelData?.xlpe,
      "casenetwt2": formModelData?.casenetwt2,
      "wropecode": formModelData?.wropecode,
      "latchtype": formModelData?.latchtype,
      "hingetype": formModelData?.hingetype,
      "stiffwt": formModelData?.stiffwt,
      "indlen": formModelData?.indlen,
      "indwidth": formModelData?.indwidth,
      "indbottom": formModelData?.indbottom,
      "indtop": formModelData?.indtop,
      "isactive": formModelData?.isactive,
      "cby": "1"
    };



    apiService.insertDesignModel(data
    ).then(response => {
      const newRow: ModelData = {
        id: response.data.id,
        casemodel: data.casemodel,
        innerdimns: '',
        outerdimns: '',
        boxwt: '',
        casterwheel: null,
        acceswt: null,
        epe: null,
        casenetwt1: null,
        xlpe: null,
        casenetwt2: null,
        wropecode: null,
        latchtype: '',
        hingetype: '',
        stiffwt: null,
        indlen: null,
        indwidth: null,
        indbottom: null,
        indtop: null,
        isactive: 0,
      }

      setModel(prevModel => [...prevModel, newRow]);
      if (formData?.casetype === "NPD Case") {
        setinsertmodelid(response.data.ID);
        setFormData(prev => ({
          ...prev,
          casemodelid1: response.data[1],// Set the new case model ID here
          casemodelid: 0
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          casemodelid: formData.casemodelid  // Set the new case model ID here
        }))
      }

      if (formData?.result === "Can Be Fusioned") {
        setFormData(prev => ({
          ...prev,
          partno: formModelData?.casemodel, // Store casemodel in partno
          disablePartno: true               // Flag to disable the partno field
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          disablePartno: false // Enable partno if result is not "Can Be Fusioned"
        }));
      }
      if (formData.result === 'Can Be Fusioned') {
        // setFormData((prev) => ({
        //   ...prev,
        //   casemodelid: originalModelData.casemodelid,
        //   //casemodel: originalModelData.casemodel,
        // }));
      }

      setFormModelData({
        id: 0,
        casemodel: '',
        innerdimns: '',
        outerdimns: '',
        boxwt: 0,
        casterwheel: '',
        acceswt: 0,
        epe: 0,
        casenetwt1: 0,
        xlpe: 0,
        casenetwt2: 0,
        wropecode: 0,
        latchtype: '',
        hingetype: '',
        stiffwt: 0,
        indlen: 0,
        indwidth: 0,
        indbottom: 0,
        indtop: 0,
        isactive: 1
      });

      // Close the popup dialog
      handleClose();

      if (response?.data.key == "400") {
        errorToast(response?.data?.message)
      }
      else {
        //successToast(response.data);
        setSelectedItem(null);
        // setFormModelData({
        //   id: '',
        //   casemodel: '',
        //   innerdimns: '',
        //   outerdimns: '',
        //   boxwt: '',
        //   casterwheel: '',
        //   acceswt: '',
        //   epe: '',
        //   casenetwt1: '',
        //   xlpe: '',
        //   casenetwt2: '',
        //   wropecode: '',
        //   latchtype: '',
        //   hingetype: '',
        //   stiffwt: '',
        //   indlen: '',
        //   indwidth: '',
        //   indbottom: '',
        //   indtop: '',
        //   isactive: ''
        // });
        // handleClose();
      }
    }).catch((err) => {
      console.log("err", err)
    });

    setOpenDesignModelPopup(false);
  }

  const handleClosePopup = () => {
    setOpenDesignModelPopup(false);
    setOpenFusionPopup(false);
  };

  const handleReviewChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setReviewData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'refdrgthere') {
      if (value === '1') {
        setShowTextBoxreferdrg(true);
      } else {
        setShowTextBoxreferdrg(false);
      }
    }

    // Handle visibility for senspd
    if (name === 'senspd') {
      if (value === '1') {
        setShowTextBoxsenspd(true);
      } else {
        setShowTextBoxsenspd(false);
      }
    }
  };


  const handleCasemodelIdChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedValue = parseFloat(event.target.value); // Convert string to number

    // Update formData with the selected model ID
    setFormData((prevFormData) => ({
      ...prevFormData,
      casemodelid: selectedValue,
    }));
  };


  const handleReviewChanges1 = (
    e: React.ChangeEvent<HTMLInputElement>,
    testaccessid: string,
    index: number
  ) => {
    const { checked } = e.target;

    // Create a copy of reviewData
    const newEssentialTableRows = { ...reviewData };
    // Initialize listreqitems if it's undefined
    if (!newEssentialTableRows.listreqitems) {
      newEssentialTableRows.listreqitems = [];
    }
    // Convert testaccessid to number and validate
    const accessIdNum = Number(testaccessid);
    if (isNaN(accessIdNum) || accessIdNum <= 0) {
      console.error("Invalid testaccessid:", testaccessid);
      return;
    }
    // Create a copy of the existing listreqitems
    const updatedListReqItems = [...newEssentialTableRows.listreqitems];
    // Find the index of the item with the specified accessid
    const existingItemIndex = updatedListReqItems.findIndex(
      (item) => item.accessid === accessIdNum
    );
    // Get the correct drawid for revid
    const drawId = Number(newEssentialTableRows.drawid);
    if (isNaN(drawId)) {
      console.error("Invalid drawid:", newEssentialTableRows.drawid);
      return;
    }
    if (existingItemIndex === -1) {
      // Add a new item if it doesn't exist
      const newItem = {
        revid: drawId, // Use drawId here
        drawid: drawId,
        accessid: accessIdNum,
        status: checked ? 1 : 0,
      };
      updatedListReqItems.push(newItem);

    } else {
      // Update the existing item
      updatedListReqItems[existingItemIndex] = {
        ...updatedListReqItems[existingItemIndex],
        status: checked ? 1 : 0,
        revid: drawId // Use drawId here
      };

    }
    // Set the modified list back to newEssentialTableRows
    newEssentialTableRows.listreqitems = updatedListReqItems;
    // Update the state
    setReviewData(newEssentialTableRows);

    // Update checked items state
    setCheckedItems((prevState) => ({
      ...prevState,
      [testaccessid]: checked,
    }));
  };
  const initializeRow = (id: number): ValidDetailsData => ({
    ifinsert: true,
    drawid: state.id,
    ecnid: 0,
    drawvalid: id,
    detailscheck: [],
    casedwg: 0,
    bom: 0,
    foamdwg: 0,
    printingdwg: 0,
    remarkslistdate: 0,
    eqpwt: "",
    casewt: "",
    accwt: "",
    foamwt: "",
    stiffwt: "",
    finalwt: "",
    con: new Date(0)
  });

  const handleValidationChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;
    // Create a copy of the validDetailsData array
    const updatedValidDetailsData = [...validDetailsData];
    // Prepare the data to update with all required fields
    let res: ValidDetailsData = {
      ifinsert: true,
      drawid: state.id,
      ecnid: 0,
      drawvalid: id,  // This line ensures drawvalid is set correctly
      detailscheck: [],
      casedwg: 0,
      bom: 0,
      foamdwg: 0,
      printingdwg: 0,
      remarkslistdate: 0,
      eqpwt: "",
      casewt: "",
      accwt: "",
      foamwt: "",
      stiffwt: "",
      finalwt: "",
      con: new Date()
    };
    // If the row at the given index doesn't exist, create it with default values
    if (!updatedValidDetailsData[index]) {
      updatedValidDetailsData[index] = res;
    }
    // Update the row data based on the input type
    const currentRow = updatedValidDetailsData[index];
    if (type === 'text') {
      // Update text input fields
      updatedValidDetailsData[index] = {
        ...currentRow,
        [name]: value,
      };
    } else if (type === 'checkbox') {
      if (name === 'detailscheck') {
        // Manage detailscheck array
        let updatedDetailscheck = [...(currentRow.detailscheck || [])];
        if (checked) {
          if (!updatedDetailscheck.includes(value)) {
            updatedDetailscheck.push(value);
          }
        } else {
          updatedDetailscheck = updatedDetailscheck.filter(item => item !== value);
        }
        updatedValidDetailsData[index] = {
          ...currentRow,
          detailscheck: updatedDetailscheck,
        };
      } else {
        // Manage other checkbox fields (e.g., casedwg, bom, etc.)
        updatedValidDetailsData[index] = {
          ...currentRow,
          [name]: checked ? 1 : 0,
        };
      }
    }
    // Ensure drawvalid is not overwritten incorrectly
    updatedValidDetailsData[index] = {
      ...updatedValidDetailsData[index],
      drawvalid: id, // Explicitly set drawvalid
    };
    // Update the state with the modified array
    setvalidDetailsData(updatedValidDetailsData);

  };

  const handleStatusChanges = (e: React.ChangeEvent<HTMLInputElement>, testaccessid: number, index: number) => {
    const { name, value } = e.target;



    // Update the radioValuesverify state
    setRadioValuesverify(prevValues => ({
      ...prevValues,
      [testaccessid]: value,
    }));

    // Safely update the verifyData state
    setverifyData(prevData => {
      const newEssentialTableRows = { ...prevData };

      // Ensure the index is within bounds
      if (index >= 0 && index <= newEssentialTableRows.listrequirements.length) {
        const drawId = Number(newEssentialTableRows.drawid);
        const updatedRow = {
          ...newEssentialTableRows.listrequirements[index],
          accessid: testaccessid,
          verid: drawId,
          //drawid: drawId,
          [name]: value ? value : 0
        };

        // Use immutability to update the row
        const updatedListrequirements = [...newEssentialTableRows.listrequirements];
        updatedListrequirements[index] = updatedRow;


        return {
          ...newEssentialTableRows,
          listrequirements: updatedListrequirements,
        };
      } else {
        console.error("Index out of bounds:", index);
        return newEssentialTableRows;  // Return the current state to avoid breaking it
      }
    });

    const updatedRows = [...formData.listrequire];
    updatedRows[index].status = e.target.value; // Update status for the selected row
    setFormData((prevState) => ({
      ...prevState,
      listrequire: updatedRows,
    }));
  };

  const handleOutputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setoutputData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleVerifyChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setverifyData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'handle') {
      if (value === 'plastic' || value === 'tiny' || value === 'ss') {
        setShowTextBoxhandle(false);
      } else {
        setShowTextBoxhandle(true);
      }
    }
  };

  const handleVerifyChanges1 = (e, index, fieldName) => {
    const { value } = e.target;
    const key = `${fieldName}-${index}`; // Create a unique key for each field and index

    setverifyData((prevState) => ({
      ...prevState,
      [key]: value, // Dynamically update the field in verifyData
    }));
  };

  const handleRowChange = (index: number, field: keyof EcnHistory, value: string | Date | null) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        [field]: value,
      };
      return updatedRows;
    });
  };

  const handleDropdownChange = (e: SelectChangeEvent<unknown>, index: number) => {
    const { name, value } = e.target;
    setverifyData((prevState: any) => ({
      ...prevState,
      [name]: value,  // Update the specific field in verifyData
    }));

  };




  const [isPrintable, setIsPrintable] = useState(false);
  const [showImage, setShowImage] = useState(false)
  const [rows, setRows] = useState<EcnHistory[]>([]);// Initialize with one empty row
  const [rowsverify, setRowsverify] = useState([{}]); // Initialize with one empty row
  const [selectedItem, setSelectedItem] = useState<FormData>(null);
  const [selectedItem1, setSelectedItem1] = useState<ReviewData>(null);
  const [selectedItem2, setSelectedItem2] = useState<VerifyData>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [checkboxState, setCheckboxState] = useState<{ [key: string]: boolean }>({});
  const [error, setError] = useState(false);

  const addRow = () => {
    setRows([...rows, { ecnno: '', date: null, revpoints: '' }]);
  };



  const addRowverify = () => {
    const newRow = {
      type: "Accessories",
      testaccessid: "",
      name: "", // Initialize with an empty string for TextField
      bit: "",
      status: "",
      desp: "",
      isNew: true,
    };

    setFormData((prevState) => ({
      ...prevState, // Spread the existing state to retain other fields
      listaccess: [...(prevState.listaccess || []), newRow], // Update only listrequire
    }));
  };

  const addRowdocs = () => {


    const hasEmptyFields = marketdocsData.some(
      (row) => row.docname === "" || row.path === ""
    );

    // if (!hasEmptyFields) {
    //   setmarketdocsData((prevState) => [
    //     ...prevState,
    //     { drawid: state.id, docname: "", path: "" },
    //   ]);
    // } else {
    //   console.log("Both docname and path must be filled in before adding a new row.");
    // }
  };

  const handleClose = () => {
    // setFormData(prevState => {
    //   // Clear remarks field in listessdata
    //   const newListessdata = prevState.listessdata.map(item => ({
    //     ...item,
    //     remarks: ""
    //   }));
    //   // Clear only testaccessid and bit in listtest
    //   const newListtest = prevState.listtest.map(item => ({
    //     ...item,
    //     testaccessid: "",
    //     bit: ""
    //   }));
    //   // Clear only testaccessid and bit in listaccess
    //   const newListaccess = prevState.listaccess.map(item => ({
    //     ...item,
    //     testaccessid: "",
    //     bit: ""
    //   }));
    //   return {
    //     ...prevState,
    //     ifinsert: true,
    //     informid: 0,
    //     partno: "",
    //     drawid: state.id,
    //     ecnid: 0,
    //     refno: "",
    //     issueno: "",
    //     pagerevno: "",
    //     intype: "",
    //     //  casetype: "",
    //     // casemodelid: 0,
    //     // result: "",
    //     designby: "1",
    //     authby: "1",
    //     status: "Initiate",
    //     cby: "1",
    //     listessdata: newListessdata,  // Updated listessdata with cleared remarks
    //     listtest: newListtest,  // Updated listtest with cleared radio buttons
    //     listaccess: newListaccess  // Updated listaccess with cleared radio buttons
    //   };
    // });
    // setSelectedItem(null);
  };

  const handleClose1 = () => {
    setReviewData(prevState => ({
      ...prevState,
      listreqitems: [],
      ifinsert: true,
      drawid: state.id,
      enqdetid: 0,
      eqipsize: "",
      eqipwt: "",
      refdrgthere: null,
      referdrgtheretext: "",
      suplocspd: null,
      eqipposition: "",
      anysplmreq: null,
      eqliftbeltreq: null,
      nameplatereq: null,
      spltestreq: null,
      senspd: null,
      senspdtext: "",
      printcolor: "",
      prv: null,
      descnt: null,
      lhook: null,
      wheel: null,
      slings: null,
      flprov: null,
      lidhandle: null,
      locks: null,
      latchlookasst: null,
      huin: null,
      foam: "",
      casetype: "",
      caseresult: "",
      rofoam: "",
      roprintcolor: "",
      cby: "1",
      revno: "",
      revpoints: "",
      note: ""
    }));
    setCheckedItems({});
  };

  const handleClose2 = () => {
    setoutputData({
      ifinsert: true,
      drawid: state?.id || "",
      odate: new Date(0),
      cptdwgattachsc: new Date(0),  // Setting to a default Date value
      cptdwgattachhc: new Date(0),
      casedwgattchsc: new Date(0),
      casedwgattchhc: new Date(0),
      foamdwgattachsc: new Date(0),
      foamdwgattachhc: new Date(0),
      scnprtdetattachsc: new Date(0),
      scnprtdetattachhc: new Date(0),
      billofmatattachsc: new Date(0),
      billofmatattachhc: new Date(0),
      stfdwgattachsc: new Date(0),
      stfdwgattachhc: new Date(0),
      prddevplnaatchsc: new Date(0),
      prddevplnaatchhc: new Date(0),
      dfmeaattachsc: new Date(0),
      dfmeaattachhc: new Date(0),
      cby: "1"
    });
    setCheckboxes({
      conceptDwgAttached: false,
      casedrawingattached: false,
      foamdrawingattached: false,
      screenprintingdetailsattached: false,
      billmaterialattached: false,
      stiffnerdrawingattached: false,
      proddevplanattached: false,
      dfmeaattached: false
    });
  };

  const handleClose3 = () => {
    setverifyData(prevState => {


      const newListrequire = prevState.listrequirements.map(item => ({
        ...item,
        accessid: null,
        status: null
      }));
      const updatedState = {
        ...prevState,
        listrequirements: newListrequire,
        ifinsert: true,
        drawid: state.id,
        enqdetid: 0,
        cieqdimns: "",
        caseexdimns: "",
        caseinnerdims: "",
        foamoutbottom: "",
        foamouttop: "",
        formcutbottom: "",
        formcuttop: "",
        handle: "",  // Clear the handle field
      };


      return updatedState;
    });
    setSelectedItem2(null);
  };

  const handleClose4 = () => {
    setvalidDetailsData([
      {
        ifinsert: true,
        drawid: state.id, // Reset to initial state values
        ecnid: 0,
        drawvalid: 0,
        detailscheck: [],
        casedwg: 0,
        bom: 0,
        foamdwg: 0,
        printingdwg: 0,
        remarkslistdate: 0,
        eqpwt: "",
        casewt: "",
        accwt: "",
        foamwt: "",
        stiffwt: "",
        finalwt: "",
        con: new Date(0)
      },
    ]);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    apiService
      .uploadFiles(formData)
      .then((response) => {
        const fileUrl = response.data; // Safely access the URL from the response
        setmarketdocsData((prevState) => {
          const updatedFiles = [...prevState];
          updatedFiles[index] = { ...updatedFiles[index], path: fileUrl };
          return updatedFiles;
        });
      })
      .catch((error) => {
        console.error("File upload error:", error);
      });
  };


  const [isSaved, setIsSaved] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const [isProceedClicked, setIsProceedClicked] = useState(false);

  const handleProceedClick = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = formData;

      const response = await apiService.isproceed(dataToSubmit);
      setTableData(prevData =>
        prevData.map(data => ({ ...data, isproceed: 1 }))
      );
      setIsProceedClicked(true);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };
  //FOR INPUT FORM SUBMIT
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();


    if (!formData.casetype) {
      setError(true);
      return;
    }



    if (!formData.result) {
      setError(true);
      return;
    }

    try {
      const dataToSubmit = formData;


      const response = await apiService.postdesigndata(dataToSubmit);



      const response1 = await apiService.Designcaseselect(state.id);

      successToast(response1?.data);

      window.scrollTo(0, 0);
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + 1, 7);

        return newValue;
      });
    } catch (error: any) {
      // Log different types of errors
      if (error.response) {
        console.error("Error response:", error.response);
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const printRef = useRef(null);
  const contentToPrint: any = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => setShowImage(true),
    onBeforePrint: () => setShowImage(true),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  //FOR REVIEW SUBMIT
  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();

    // Extract rows for "Yes" and "No" options
    const yesOptions = formData?.listaccess?.filter((row: any) => row.bit === '1');
    const noOptions = formData?.listaccess?.filter((row: any) => row.bit === '0');

    // Validate all "Yes" options are ticked
    const allYesTicked = yesOptions?.every((row: any) => {
      const reviewedItem = reviewData.listreqitems?.find(
        (item: any) => item.accessid === row.id
      );
      return reviewedItem && reviewedItem.status === 1;
    });

    // Validate no "No" options are ticked
    const noExtrasTicked = noOptions?.every((row: any) => {
      const reviewedItem = reviewData.listreqitems?.find(
        (item: any) => item.accessid === row.id
      );
      return !reviewedItem || reviewedItem.status !== 1;
    });

    // if (!allYesTicked || !noExtrasTicked) {
    //   errorToast(
    //     "Validation failed! Please check Review Accessories"
    //   );
    //   return;
    // }

    try {
      // If validation passes, submit the data
      const dataToSubmit = reviewData;

      const response = await apiService.postreviewdata(dataToSubmit);
      successToast(response.data);
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + 1, 7);

        return newValue;
      });
      // setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      // Handle errors
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR OUTPUT FORM SUBMIT
  const handleSubmitOutput = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = outputData;

      const response = await apiService.postoutputdata(dataToSubmit);
      successToast(response.data);
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + 1, 7);
        return newValue;
      });

      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const handleSubmitVerification = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // if (!formData?.listrequire || !formData.listrequire[15]) {
      //   console.error('listrequire[15] is invalid or undefined:', formData.listrequire);
      //   return; // Exit if data is invalid
      // }

      const extraverifyrows = Array.isArray(formData?.listaccess[15])
        ? formData?.listaccess[15].map((item: any) => ({
          desp: item?.desp,
          status: item?.status,
        }))
        : [{ desp: formData.listaccess[15]?.desp, status: formData.listaccess[15]?.status }];

      // const dataToSubmit = {
      //   data: { ...verifyData },
      //   extraverifyrows,
      // };

      setverifyData((prevData) => ({
        ...prevData,
        extraverifyrows, // Set the mapped values to `extraverifyrows`
      }));

      const dataToSubmit = verifyData;


      const response = await apiService.postverifydata(dataToSubmit);

      successToast(response.data);
      // setIsSaved(true);
      window.scrollTo(0, 0);
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + 1, 7);

        return newValue;
      });
    } catch (error: any) {
      console.error('Error submitting verification:', error);
      if (error.response) {
        console.error('Error response:', error.response);
      }
    }
  };


  //FOR DRAWING VALIDATION FORM SUBMIT
  const handleSubmitValidation = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = validDetailsData;

      const response = await apiService.postdrawvaliddata(dataToSubmit);
      // Handle response

      successToast(response.data);
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + 1, 7);

        return newValue;
      });

      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const handleSubmitToMarket = async (e: React.FormEvent) => {
    e.preventDefault();
    try {


      const dataToSubmit = marketdocsData;

      const response = await apiService.submitmarket(dataToSubmit);
      // Handle response


      // Success toast can also be used for more detailed data
      successToast(response.data);

      // Delay navigation to give time for toast to display
      setTimeout(() => {
        navigate('/Design/DesignFollowup');
      }, 5000);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const handleSubmitToClarify = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = formData;

      const response = await apiService.req_clarify(dataToSubmit);
      setTableData(prevData =>
        prevData.map(data => ({ ...data, isproceed: 0 }))
      );
      setIsProceedClicked(false); // Optionally reset the isProceedClicked flag
      // Handle response

      // Success toast can also be used for more detailed data
      successToast(response.data);
      setTimeout(() => {
        navigate('/Design/DesignFollowup');
      }, 5000);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  function formatDate(dateString) {
    if (!dateString) {
      return ''; // Return empty string for null or undefined
    }
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2); // Get the day and ensure two digits
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Get the month and ensure two digits
    const year = date.getFullYear(); // Get the full year
    return `${day}-${month}-${year}`; // Format as dd-MM-yyyy
  }



  const handleCheckboxChangecasetype = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      casetype: checked ? value : '', // Update casetype based on the selected checkbox
      ...(value === 'NPD Case' && checked
        ? { result: 'To Be Made', casemodelid: null } // Default values for NPD Case
        : { result: '', casemodelid: null } // Clear values for Existing Case or when unchecked
      ),
    }));

    // Trigger popup actions
    if (checked) {
      if (value === 'NPD Case') {
        setOpenDesignModelPopup(true); // Open design model popup
      } else if (value === 'Existing Case') {
        setOpenDesignModelPopup(false); // Ensure the popup is closed
      }
    }
  };




  console.log('formData=====>', formData)


  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>
      <br></br>
      <Container maxWidth="lg" style={{ paddingRight: '0px' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} className='uddiv' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                {drawreq[0]?.Drawcateg === 'Green' ? (<CardHeader title={'Production Drawing '} />) :
                  (<CardHeader title={'Concept Drawing '} />)}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', color: 'white' }}>
                  <Tab
                    label="Back"
                    onClick={() => {
                      if (isSaved) {
                        // Navigate to the form page when saved
                        setValue(0); // Navigate back to the form page
                        // setIsSaved(false);
                        window.scrollTo(0, 0);
                      } else {
                        // Navigate to the DesignFollowup page when not saved
                        navigate('/Design/DesignFollowup');
                      }
                    }}
                    sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                  />
                </div>
              </div>
              <Divider />
              <CardContent>

                {tableData.map((item, index) => (
                  <div key={item.id} style={{
                    marginBottom: '20px', display: 'flex', gap: '20px', marginLeft: '20px',
                    color: "#002f76",
                  }} className="panel no-print">

                    <div style={{ width: '33%' }}><strong style={{ color: "#6f7878" }}>Enquiry No : </strong> <b style={{ color: "" }}>{item.enqno}</b> </div>
                    {/* <div><strong style={{ color: "#000" }}>Enquiry Date :</strong> <b>{item.enqdate}</b></div> */}
                    <div style={{ width: '33%' }}><strong style={{ color: "#6f7878" }}>Customer :</strong> <b>{item.cusname}</b></div>
                    <div style={{ width: '33%' }}><strong style={{ color: "#6f7878" }}>Requirement :</strong><b>{item.req}</b></div>
                    {item.mpnno ? (<div style={{ width: '33%' }}><strong style={{ color: "#6f7878" }}>MPN No :</strong><b>{item.mpnno}</b></div>) : null}
                  </div>
                ))}

                <div style={{
                  marginBottom: '20px', display: 'flex', gap: '20px', marginLeft: '20px',
                  color: "#002f76",
                }} className="panel no-print">

                  <div style={{ width: '33%', fontSize: '12.5px' }}><strong style={{ color: "#6f7878" }}>REFERENCE. NO. </strong> <b style={{ color: "" }}>: QMP 7.3.2 R/A</b> </div>
                  {/* <div><strong style={{ color: "#000" }}>Enquiry Date :</strong> <b>{item.enqdate}</b></div> */}
                  <div style={{ width: '33%', fontSize: '12.5px' }}><strong style={{ color: "#6f7878" }}>ISSUE. NO. </strong> <b>: 07/DATE: 01.05.2024</b></div>
                  <div style={{ width: '33%', fontSize: '12.5px' }}><strong style={{ color: "#6f7878" }}>PAGE REV NO.</strong><b>: 06/DATE : 01.05.2024</b></div>
                </div>



                <Box sx={{ width: '100%' }}>
                  {!isSaved && (
                    <div className="tabs-container no-print">
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Marketing Input Form" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label=" Design Input Form" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label="Case Selection" sx={{ "&:hover": { color: "white" } }} />
                        {drawreq[0]?.Drawcateg === "Green" && (
                          <Tab label="Review" sx={{ "&:hover": { color: "white" } }} />
                        )}
                        <Tab label="Output" sx={{ "&:hover": { color: "white" } }} />
                        {drawreq[0]?.Drawcateg === "Green" && (
                          <Tab label="Verification" sx={{ "&:hover": { color: "white" } }} />
                        )}
                        {drawreq[0]?.Drawcateg === "Green" && (
                          <Tab label="Drawing Validation" sx={{ "&:hover": { color: "white" } }} />
                        )}

                      </Tabs>

                    </div>
                  )}

                  {drawreq[0]?.Drawcateg === 'Green' ? (
                    <div>
                      <TabPanel value={value} index={0}>
                        <div className="main-content" >
                          <div>
                            <div className="form-container">

                              <div className="customer-name">
                                <strong>Customer Name :</strong> {drawreq[0]?.name}
                              </div>
                              <table className="table">
                                <tbody>
                                  <tr className='dshead'>
                                    <th style={{ width: "50%" }} colSpan={2}>From: Marketing</th>
                                    <th style={{ width: "50%" }} colSpan={2}>To: Design</th>
                                  </tr>
                                  <tr>
                                    <td><strong>I/P No & Input Date:</strong>

                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.ipno}</span> DT  {
                                        drawreq[0]?.ipdate
                                          ? (() => {
                                            const [year, month, day] = drawreq[0].ipdate.split('T')[0].split('-');
                                            return `${day}-${month}-${year}`;
                                          })()
                                          : ''
                                      }


                                    </th>
                                    <td>
                                      <strong>No. of Eqpts. Packed/Box:</strong>
                                    </td>

                                    <th>
                                      <div>{drawreq[0]?.noofeqpt}</div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Category</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.Drawcateg}</span>
                                    </th>
                                    <td>
                                      <strong>Spl. Accs. If any</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.splacc}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Purpose</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.Purpose}</span>
                                    </th>
                                    <td>
                                      <strong>Testing Standard</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.teststd}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Required on</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.drawreqdate ? drawreq[0]?.drawreqdate.split('T')[0] : ''}</span>
                                    </th>
                                    <td>
                                      <strong>Eqpt. Type</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.eqpttype}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Project Name</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.proname}</span>
                                    </th>
                                    <td>
                                      <strong>Eqpt. Sensitivity</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.eqptsen}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Eqpt. Size (LBH)</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.eqptsize}</span>
                                    </th>
                                    <td>
                                      <strong>Application</strong>
                                    </td>

                                    <th>
                                      <span >{drawreq[0]?.app}</span>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Eqpt. Wt.</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.eqptwt}</span>

                                    </th>
                                    <td>
                                      <strong>Special note if any</strong>
                                    </td>

                                    <th>
                                      <span >{drawreq[0]?.splnote}</span>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>


                        </div>
                        <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>ATTACHMENTS</b></Typography>
                          </AccordionSummary>
                          <AccordionDetails className='AccordionDetails'>
                            <Typography>
                              <Grid item xs={12}>
                                <Paper>
                                  <TableContainer>
                                    <Table className="">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                          <TableCell style={{ textAlign: 'center' }}><b>FILE NAME</b></TableCell>
                                          <TableCell style={{ textAlign: 'center' }}><b>PATH</b></TableCell>

                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {drawreqatt.map((row: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.filename}</TableCell>
                                            <TableCell>
                                              <div>
                                                {/* {row.url && (
                                                      <a
                                                        href={`https://docs.google.com/viewer?url=${encodeURIComponent(row.url)}&embedded=true`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          color: hoverIndex === index ? '#0056b3' : '#007bff',
                                                          textDecoration: hoverIndex === index ? 'underline' : 'none',
                                                        }}
                                                        onMouseEnter={() => setHoverIndex(index)}
                                                        onMouseLeave={() => setHoverIndex(null)}
                                                      >
                                                        View
                                                      </a>
                                                    )} */}
                                                {row.url ? (
                                                  <>
                                                    <a
                                                      href={(row.url)}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      View File
                                                    </a></>
                                                ) : null}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                              </Grid>
                              {/* {tableData.some(data => data.isproceed === null) && (
                                <DialogActions>
                                  <Button onClick={handleSubmitToClarify} color="primary" variant='contained' className="btn-print">
                                    Request for Clarification
                                  </Button>
                                  <Button onClick={handleProceedClick} variant='contained' color="primary">
                                    Proceed
                                  </Button>
                                </DialogActions>
                              )} */}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <div className="main-content" >
                          <CardContent>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>EQUIPMENT DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <Grid item xs={12}>
                                    <Paper>
                                      <TableContainer>
                                        <Table className="">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>DESIGN DATA DETAILS</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>GIVEN DETAILS & FILES</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>REMARKS</b></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>

                                            {/* {console.log(formData?.listessdata, "essdatanew")} */}
                                            {formData?.listessdata?.map((row: any, index: number) => (
                                              <TableRow key={row.essdataid}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>   <div>
                                                  {/* Always display the compliance value */}
                                                  <div>{row.compliance}</div>

                                                  {row.attach ? (
                                                    <>
                                                      <a
                                                        href={(row.attach)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        View File
                                                      </a></>
                                                  ) : null}

                                                </div></TableCell>
                                                <TableCell>
                                                  <TextField
                                                    required
                                                    name="remarks"
                                                    value={row.remarks}
                                                    // onChange={(e) => handleRemarksChange(row.essdataid, index, e)}
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ maxLength: 200 }}
                                                    fullWidth
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Paper>
                                  </Grid>
                                  {/* {tableData.some(data => data.isproceed === null) && (
                                <DialogActions>
                                  <Button onClick={handleSubmitToClarify} color="primary" variant='contained' className="btn-print">
                                    Request for Clarification
                                  </Button>
                                  {/* <Button onClick={handleProceedClick} variant='contained' color="primary">
                                    Proceed
                                  </Button> 
                                </DialogActions>
                              )} */}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>

                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>TEST & ACCESSORIES DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="display-area">
                                    <div className="panel">
                                      <Typography variant="h6" gutterBottom>
                                        <b style={{ color: "#000" }}><u>(AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                                      </Typography>
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Paper>
                                            <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                              <Table className="table-body">
                                                <TableHead>
                                                  <TableRow className="table-header">
                                                    <TableCell><b>SL. NO</b></TableCell>
                                                    <TableCell><b>NAME OF THE TEST</b></TableCell>
                                                    <TableCell><b>Yes / No</b></TableCell>
                                                    <TableCell><b>Remarks</b></TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                  {formData?.listtest?.map((row: any, index: number) => (
                                                    <TableRow key={row.testaccessid}>
                                                      <TableCell>{index + 1}</TableCell>
                                                      <TableCell>{row?.name}</TableCell>
                                                      <TableCell style={{ width: "150px" }}>
                                                        <FormControl component="fieldset">
                                                          <RadioGroup
                                                            //value={radioValues[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                            value={row.bit ? (row?.bit === '1' ? "1" : "0") : ""}
                                                            // onChange={(e) => {
                                                            //   handleBitChange(row.id, index, e);
                                                            // }}
                                                            row
                                                            className="compact-radio-group"
                                                          >
                                                            <FormControlLabel value="1" control={<Radio size="small" />} label="Y" />
                                                            <FormControlLabel value="0" control={<Radio size="small" />} label="N" />
                                                          </RadioGroup>
                                                        </FormControl>
                                                      </TableCell>
                                                      <TableCell>
                                                        <TextField multiline value={row?.desp}
                                                        // onChange={(e) => {
                                                        //   handleRemarkChange(row.id, index, e);
                                                        // }} 
                                                        />
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Grid>
                                        <Grid item xs={6}  >
                                          <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                            <Table className="table-body">
                                              <TableHead>
                                                <TableRow className="table-header">
                                                  <TableCell><b>SL. NO</b></TableCell>
                                                  <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                                  <TableCell><b>APPLICABLE Yes / No</b></TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>

                                                {formData?.listaccess?.map((row: any, index: number) => (
                                                  <TableRow key={row.testaccessid}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell style={{ width: "150px" }}>
                                                      <FormControl component="fieldset">
                                                        <RadioGroup
                                                          //value={radioValues1[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                          value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                          //  onChange={(e) => handleBitChange1(row.id, index, e)}
                                                          row
                                                          className="compact-radio-group"
                                                        >
                                                          <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Y" />
                                                          <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="N" />
                                                        </RadioGroup>
                                                      </FormControl>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                                <TableRow>
                                                  <TableCell></TableCell>
                                                  <TableCell>Others</TableCell>
                                                  <TableCell>
                                                    <TextField
                                                      id='accdesc'
                                                      name='accdesc'
                                                      value={formData?.accdesc}
                                                      // onChange={handleInputChanges}
                                                      fullWidth
                                                      multiline
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>

                          </CardContent>

                        </div>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                    
                        <div className="main-content" >
                          <Grid>
                            <div className="display-area">
                              <div className="panel">
                                <Typography variant="h6" gutterBottom>
                                  <b style={{ color: "#2801ff" }}><u>CASE SELECTION</u></b>
                                </Typography>
                                <Grid item>
                                  <Table className="casetable">
                                    <TableHead>
                                      <TableRow className="table-header">
                                        <TableCell><b>TYPE</b></TableCell>
                                        <TableCell><b>CASE MODEL</b></TableCell>
                                        <TableCell><b>RESULT</b></TableCell>
                                        {formData?.partno && (
                                          <TableCell><b>MODEL NO</b></TableCell>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>

                                        
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                id="existingCaseCheckbox"
                                                name="casetype"
                                                checked={formData.casetype === 'Existing Case'}
                                              //  onChange={(e) => handleCheckboxChangecasetype(e, 'Existing Case')}
                                              />
                                            }
                                            label={
                                              <Typography variant="body1">
                                                Existing Case
                                              </Typography>
                                            }
                                          />
                                          <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formData?.result === "Can Be Fusioned" || formData?.result === "Bottom NPD" || formData?.result === "Top NPD" && (
                                              <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                <a
                                                  href="#"
                                                  // onClick={(e) => {
                                                  //   e.preventDefault(); // Prevent default anchor behavior
                                                  //   setOpenFusionPopup(true); // Open the dialog
                                                  // }}
                                                  style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                  Modify & Create Model
                                                </a>
                                              </Box>
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                       
                                          <TextField
                                            required
                                            select
                                            id="casemodelid"
                                            name="casemodelid"
                                            fullWidth
                                         
                                            value={formData?.casemodelid || null}
                                           // onChange={handleCasemodelIdChange}
                                            error={error && !formData.casemodelid} // Show error if empty
                                            helperText={error && !formData.casemodelid ? "Please select a model" : ""}
                                          >

                                            {model.map((option) => (
                                              <MenuItem key={option.id}
                                                // value={insertmodelid !== null ? insertmodelid : option.id}>
                                                value={option.id}>
                                                {option.casemodel}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        
                                        
                                        
                                        </TableCell>
                                        <TableCell>
                                         

                                          <TextField
                                            required
                                            select
                                            id="result"
                                            name="result"
                                            fullWidth
                                            value={formData?.result || ""} // Ensure a default value
                                         //   onChange={handleInputChanges}
                                            error={error && !formData?.result}
                                            helperText={error && !formData?.result ? "Please select a result" : ""}
                                          >
                                            <MenuItem value="Can be used">Can be used</MenuItem>

                                            <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                            <MenuItem value="Bottom NPD">Bottom NPD</MenuItem>
                                            <MenuItem value="Top NPD">Top NPD</MenuItem>
                                          </TextField>
                                       
                                        </TableCell>
                                        {formData?.partno && (
                                          <TableCell>
                                            <strong>{formData?.partno}</strong>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                id="npdCaseCheckbox"
                                                name="casetype"
                                                checked={formData.casetype === 'NPD Case'}
                                            //    onChange={(e) => handleCheckboxChangecasetype(e, 'NPD Case')}
                                              />
                                            }
                                            label={
                                              <Typography variant="body1">
                                                NPD Case
                                              </Typography>
                                            }
                                          />
                                          <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formData?.casetype === "NPD Case" && (
                                              <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                <a
                                                  href="#"
                                                  // onClick={(e) => {
                                                  //   e.preventDefault(); // Prevent default anchor behavior
                                                  //   setOpenDesignModelPopup(true); // Open the dialog
                                                  // }}
                                                  style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                  Create Model
                                                </a>
                                              </Box>
                                            )}

                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          {/* {formData?.casetype === 'Existing Case' && ( */}
                                          <TextField
                                            required
                                            select
                                            id="casemodelid"
                                            name="casemodelid"
                                            fullWidth
                                            // value={formData?.casemodelid}
                                            value={formData?.casemodelid1 || null}
                                          //  onChange={handleCasemodelIdChange}
                                            error={error && !formData.casemodelid} // Show error if empty
                                            helperText={error && !formData.casemodelid ? "Please select a model" : ""}
                                          >

                                            {model1.map((option) => (
                                              <MenuItem key={option.id}
                                                // value={insertmodelid !== null ? insertmodelid : option.id}>
                                                value={option.id}>
                                                {option.casemodel}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            select
                                            id="result"
                                            name="result"
                                            fullWidth
                                            value={formData?.result || ""} // Ensure a default value
                                       //     onChange={handleInputChanges}
                                            error={error && !formData?.result}
                                            helperText={error && !formData?.result ? "Please select a result" : ""}
                                          >
                                            <MenuItem value="To Be Made">To Be Made</MenuItem>

                                          </TextField>

                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      </TabPanel>


                      <TabPanel value={value} index={3}>
                        <div>
                          <CardContent>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>INPUT DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="content-grid">
                                    <div className="column">
                                      <div>
                                        {tableData.map((item, index) => (
                                          <div key={item.id} style={{ marginBottom: "20px" }}>
                                            <TextField
                                              margin="dense"
                                              id="revno"
                                              label="I/P NO"
                                              name="revno"
                                              value={item.enqno}
                                              fullWidth
                                              // onchange={handleReviewChanges}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                          </div>
                                        ))}

                                        {sizewtdata.map((item, index) => (

                                          <div key={item.id} style={{ marginTop: '-5px' }}>

                                            <TextField
                                              margin="dense"
                                              id="eqipsize"
                                              label="EQUIPMENT SIZE"
                                              name='eqipsize'
                                              value={item.outerdimns}
                                              fullWidth
                                              // onchange={handleReviewChanges}
                                              inputProps={{ maxLength: 45 }}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                            <div style={{ marginTop: '15px' }}>
                                              <TextField
                                                margin="dense"
                                                id="eqipwt"
                                                label="EQUIPMENT WEIGHT"
                                                name='eqipwt'
                                                value={item?.casenetwt2}
                                                fullWidth
                                                // onchange={handleReviewChanges}
                                                inputProps={{ maxLength: 45 }}
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <br></br>
                                      <div>
                                        <FormControl component="fieldset" className="radioWithMargin">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>REFER DRG THERE</FormLabel>
                                            </Grid>
                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <RadioGroup
                                                value={reviewData?.refdrgthere}
                                                // onchange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="refdrgthere" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="refdrgthere" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                          {showTextBoxreferdrg && (
                                            <TextField
                                              label="Additional Information"
                                              variant="outlined"
                                              name="referdrgtheretext"
                                              value={reviewData?.referdrgtheretext || ''}
                                              fullWidth
                                              margin="normal"
                                            //   onChange={(e) =>
                                            //     setReviewData((prevState) => ({
                                            //       ...prevState,
                                            //       referdrgtheretext: e.target.value,
                                            //     }))
                                            //   }
                                            />
                                          )}
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>EQUIPMENT POSITION</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            {/* {tableData.map((item, index) => (
                                        <div key={item.id}>
                                          <Grid item >
                                            <RadioGroup
                                              name='eqipposition'
                                              value={item.eqipposition || ""}
                                              onChange={handleReviewChanges}
                                              row>
                                              <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                                              <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                                            </RadioGroup>
                                          </Grid>
                                        </div>
                                      ))} */}
                                            <Grid item >
                                              <RadioGroup
                                                value={reviewData?.eqipposition}
                                                // onchange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                                                <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 10 }}>ANY SPL MARKING REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup
                                                value={reviewData?.anysplmreq}
                                                // onchange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="anysplmreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="anysplmreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>

                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 5 }}>EQPT LIFTING BELT REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.eqliftbeltreq}
                                                // onchange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="eqliftbeltreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="eqliftbeltreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>NAME PLATE REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.nameplatereq}
                                                // onChange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="nameplatereq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="nameplatereq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>SPECIAL TEST REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.spltestreq}
                                                // onchange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="spltestreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="spltestreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 19 }}>SENSITIVITY SPECIFIED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.senspd}
                                                // onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="senspd" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="senspd" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                          {showTextBoxsenspd && (
                                            <TextField
                                              label="Additional Information"
                                              variant="outlined"
                                              name="senspdtext"
                                              value={reviewData?.senspdtext || ''}
                                              fullWidth
                                              margin="normal"
                                            //   onChange={(e) =>
                                            //     setReviewData((prevState) => ({
                                            //       ...prevState,
                                            //       senspdtext: e.target.value,
                                            //     }))
                                            //   }
                                            />
                                          )}
                                        </FormControl>

                                      </div>
                                    </div>
                                    <div className="column">

                                      <FormControl component="fieldset">
                                        <Grid container alignItems="center" className="radioWithMargin">
                                          <Grid item>
                                            <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                          </Grid>

                                        </Grid>
                                        <Grid container alignItems="center" className="radioWithMargin">

                                          {tableData.map((item, index) => (
                                            <div key={item.id}>
                                              <Grid item >
                                                <RadioGroup value={reviewData?.printcolor}
                                                  //  onChange={handleReviewChanges} 
                                                  row>
                                                  <FormControlLabel name="printcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                                  <FormControlLabel name="printcolor" value="White" control={<Radio />} label="White" />
                                                  <FormControlLabel name="printcolor" value="Black" control={<Radio />} label="Black" />
                                                </RadioGroup>
                                              </Grid>
                                            </div>
                                          ))}
                                        </Grid>
                                      </FormControl>
                                      <FormControl component="fieldset">
                                        <Grid container alignItems="center" className="radioWithMargin">
                                          <Grid item>
                                            <FormLabel component="legend" sx={{ mr: 20 }}>FOAM</FormLabel>
                                          </Grid>

                                        </Grid>
                                        <Grid container alignItems="center" className="radioWithMargin">

                                          <Grid item >
                                            <RadioGroup value={reviewData?.foam}
                                              // onChange={handleReviewChanges} 
                                              row>
                                              <FormControlLabel name="foam" value="EPE" control={<Radio />} label="EPE" />
                                              <FormControlLabel name="foam" value="XLPE" control={<Radio />} label="XLPE" />
                                              <FormControlLabel name="foam" value="PU" control={<Radio />} label="PU" />
                                            </RadioGroup>
                                          </Grid>
                                        </Grid>
                                      </FormControl>
                                      <FormControl component="fieldset" >
                                        {formData?.listaccess?.map((row: any, index: number) => (
                                          <div key={row.testaccessid}>
                                            <Grid container alignItems="center" className="radioWithMargin" >
                                              <Grid item>
                                                <FormLabel component="legend">{row.name.toUpperCase()}</FormLabel>
                                              </Grid>
                                              <Grid container alignItems="center" className="radioWithMargin" >
                                                <RadioGroup
                                                  value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                  //   onChange={(e) => handleBitChange1(row.id, index, e)}
                                                  row
                                                >
                                                  <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Yes" />
                                                  <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="No" />
                                                </RadioGroup>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        ))}
                                      </FormControl>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }} >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>REVIEW OUTPUTS & ACCESSORIES</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="content-grid">
                                    <div className="table-container">
                                      <div className="table">
                                        <div className="main-heading" style={{ color: "#000" }}>REVIEW OUTPUTS</div>
                                        <TextField
                                          required
                                          select
                                          id="casetype"
                                          name="casetype"
                                          fullWidth
                                          value={formData?.casetype}
                                          // onChange={handleReviewChanges}
                                          style={{ marginBottom: '16px' }} // Adds space below the first TextField
                                        >
                                          <MenuItem value="Existing Case">Existing Case</MenuItem>
                                          <MenuItem value="NPD Case">NPD Case</MenuItem>
                                        </TextField>
                                        <TextField
                                          required
                                          select
                                          id="caseresult"
                                          name="caseresult"
                                          fullWidth
                                          value={formData?.result}
                                          // onchange={handleReviewChanges}
                                          style={{ marginBottom: '16px' }}
                                        >
                                          <MenuItem value="Can be used">Can be used</MenuItem>
                                          <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                          {/* <MenuItem value="Top NPD">Top NPD</MenuItem>
                                    <MenuItem value="Bottom NPD">Bottom NPD</MenuItem> */}
                                          <MenuItem value="To Be Made">To Be Made</MenuItem>
                                        </TextField>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>FOAM</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.rofoam}
                                                // onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="rofoam" value="EPE" control={<Radio />} label="EPE" />
                                                <FormControlLabel name="rofoam" value="XLPE" control={<Radio />} label="XLPE" />
                                                <FormControlLabel name="rofoam" value="PU" control={<Radio />} label="PU" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>

                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.roprintcolor}
                                                // onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="roprintcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                                <FormControlLabel name="roprintcolor" value="White" control={<Radio />} label="White" />
                                                <FormControlLabel name="roprintcolor" value="Black" control={<Radio />} label="Black" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                      </div>

                                      <div className="table">
                                        <div className="main-heading" style={{ color: "#000" }}>REVIEW ACCESSORIES</div>
                                        <div className="items-container">
                                          {formData?.listaccess?.map((row: any, index: number) => {
                                            const isChecked = reviewData.listreqitems?.some(
                                              item => item.accessid === row.id && item.status === 1
                                            );
                                            return (
                                              <div className="item" key={row.id}>
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox-${row.id}`}
                                                  value={row.id}
                                                  name="accessid"
                                                  checked={isChecked}
                                                // onchange={(e) => handleReviewChanges1(e, row.id, index)}
                                                />
                                                <label htmlFor={`checkbox-${row.id}`}>{row.name}</label>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <div className="panel">
                              <div className="table-container">
                                <div className="table">
                                  <div className='reviewpoints' >
                                    <label htmlFor="revpoints">REVIEW POINTS</label>
                                    <textarea name="revpoints" onChange={handleReviewChanges} value={reviewData?.revpoints} rows="1" maxLength={500}></textarea>
                                  </div>
                                  <br></br>
                                  <div className="note-section">
                                    <label htmlFor="note">NOTE</label>
                                    <textarea name="note" value={reviewData?.note} onChange={handleReviewChanges} rows="4" maxLength={500}></textarea>
                                  </div>
                                  <br></br>
                                  {rows.length > 0 && ( // Check if there are rows before rendering the entire section
                                    <>
                                      <label className='review'>ECN HISTORY</label>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>ECN. No</TableCell>
                                            <TableCell>ECN. Date</TableCell>
                                            <TableCell>Review Points</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {rows.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell>
                                                <input
                                                  type="text"
                                                  name='ecnno'
                                                  value={row.ecnno || ""} // Ensure no undefined/null errors
                                                  onChange={(e) => handleRowChange(index, "ecnno", e.target.value)}
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <input
                                                  type="date"
                                                  name='date'
                                                  value={row.date ? new Date(row.date).toISOString().split("T")[0] : ""} // Correct date format
                                                  onChange={(e) =>
                                                    handleRowChange(
                                                      index,
                                                      "date",
                                                      e.target.value ? new Date(e.target.value) : null
                                                    )
                                                  }
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <input
                                                  type="text"
                                                  name='revpoints'
                                                  value={row.revpoints || ""} // Ensure no undefined/null errors
                                                  onChange={(e) => handleRowChange(index, "revpoints", e.target.value)}
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>

                                      <button type="button" onClick={addRow}>Add Row</button>
                                    </>
                                  )}
                                </div>
                              </div>
                              {/* <DialogActions>
                                <Button onClick={handleClose1} color="primary" variant='contained' className="btn-print" >
                                  Clear
                                </Button>
                                <Button onClick={handleSubmitReview} variant='contained' color="primary" >
                                  Save
                                </Button>
                              </DialogActions> */}
                            </div>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>REVIEW HISTORY</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="review-history">
                                    <strong>ECN HISTORY</strong>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>ECN. No</TableCell>
                                          <TableCell>ECN. Date</TableCell>
                                          <TableCell>Review Points</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {rows.map((row, index) => (
                                          <TableRow key={index}>
                                            <TableCell>{row.ecnno}</TableCell>
                                            <TableCell>{row.date}</TableCell>
                                            <TableCell>{row.revpoints}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          </CardContent>


                        </div>
                      </TabPanel>


                      <TabPanel value={value} index={4}>

                        <div>
                          <div className="panel">
                            <div className="main-heading">DESIGN OUTPUT DETAILS</div>
                            <table>
                              <thead>
                                <tr>
                                  <th rowSpan={2} className='designop'>DESIGN OUTPUT</th>
                                  <th colSpan={2} className='designop'>OUTPUT DATE</th>
                                </tr>
                                <tr>
                                  <th className='designop'>SC</th>
                                  <th className='designop' >HC</th>
                                </tr>
                              </thead>
                              <tbody>
                                {drawreq[0]?.Drawcateg === 'Blue' ? (
                                  <>

                                    <tr>
                                      <td>Concept dwg attached for finalization</td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                                        value={outputData?.cptdwgattachsc}
                                        //onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                                        value={outputData?.cptdwgattachhc}
                                        // onChange={handleOutputChanges}
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>Concept dwg attached for finalization</td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                                        value={outputData?.cptdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                                        value={outputData?.cptdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Case drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="casedwgattchsc"
                                        value={outputData?.casedwgattchsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="casedwgattchhc"
                                        value={outputData?.casedwgattchhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Foam drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="foamdwgattachsc"
                                        value={outputData?.foamdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="foamdwgattachhc"
                                        value={outputData?.foamdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Screen Printing details attached</td>
                                      <td><input type="date" className="textbox-style" name="scnprtdetattachsc"
                                        value={outputData?.scnprtdetattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="scnprtdetattachhc"
                                        value={outputData?.scnprtdetattachhc}
                                        //onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Bill of material attached</td>
                                      <td><input type="date" className="textbox-style" name="billofmatattachsc"
                                        value={outputData?.billofmatattachsc}
                                        //  onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="billofmatattachhc"
                                        value={outputData?.billofmatattachhc}
                                        //  onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Stiffner drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="stfdwgattachsc"
                                        value={outputData?.stfdwgattachsc}
                                        //onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="stfdwgattachhc"
                                        value={outputData?.stfdwgattachhc}
                                        //  onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Product development plan attached</td>
                                      <td><input type="date" className="textbox-style" name="prddevplnaatchsc"
                                        value={outputData?.prddevplnaatchsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="prddevplnaatchhc"
                                        value={outputData?.prddevplnaatchhc}
                                        //onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>DFMEA attached</td>
                                      <td><input type="date" className="textbox-style" name="dfmeaattachsc"
                                        value={outputData?.dfmeaattachsc}
                                        //onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="dfmeaattachhc"
                                        value={outputData?.dfmeaattachhc}
                                        //  onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            {/* <DialogActions>
                              <Button onClick={handleClose2} variant='contained' color="primary" >
                                Clear
                              </Button>
                              <Button onClick={handleSubmitOutput} variant='contained' color="primary" >
                                Save
                              </Button>
                            </DialogActions> */}
                          </div>
                        </div>


                      </TabPanel>


                      <TabPanel value={value} index={5}>

                        <div className="panel">
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {tableData.map((item, index) => (
                              <div key={item.id} style={{
                                marginBottom: '20px', gap: '20px',
                                color: "#a50101",
                              }} >
                                <div><strong style={{ color: "#000" }}>MPN No :</strong> <b>{item.mpnno}</b></div>
                              </div>
                            ))}
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th rowSpan={3} className="no-border"></th>
                                <th rowSpan={3} className='designop'>EQPT. DIMNS.</th> {/* Equipment Dimensions on the left */}
                                <th colSpan={6} className='designop'>OUTPUT</th> {/* Output heading spanning all columns */}
                              </tr>
                              <tr>
                                <th className='designop' rowSpan={2}>CASE EXTERIOR DIMNS.</th>
                                <th className='designop' rowSpan={2}>CASE INNER DIMNS.</th>
                                <th colSpan={2} className='designop'>FOAM OUTER DIMNS.</th> {/* Foam Outer Dimensions with partitions */}
                                <th colSpan={2} className='designop'>FOAM CUT DIMNS.</th>   {/* Foam Cut Dimensions with partitions */}
                              </tr>
                              <tr>
                                {/* Sub-headings under Foam Dimensions */}
                                <th className='designop'>BOTTOM</th>
                                <th className='designop'>TOP</th>
                                <th className='designop'>BOTTOM</th>
                                <th className='designop'>TOP</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className='verticaltext'>DIMENSIONS</td> {/* Equipment dimensions cell */}
                                <td><TextField name="cieqdimns" value={verifyData?.cieqdimns}
                                  //onChange={handleVerifyChanges}
                                  sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    width: '100px'
                                  }}
                                  multiline /></td>
                                {console.log("sizewtdata == > ", sizewtdata)
                                }
                                {sizewtdata.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <td><TextField name={`caseexdimns-${index}`}
                                      value={verifyData[`caseexdimns-${index}`] || item.outerdimns || ""}
                                      //onChange={(e) => handleVerifyChanges1(e, index, "caseexdimns")} 
                                      sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        }, width: '100px'
                                      }}
                                      multiline /></td> {/* CASE EXTERIOR DIMNS. */}
                                    <td><TextField name={`caseinnerdims-${index}`} // Unique key for each item
                                      value={verifyData[`caseinnerdims-${index}`] || item.innerdimns || ""}
                                      //onChange={(e) => handleVerifyChanges1(e, index, "caseinnerdimns")}
                                      sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        }, width: '100px'
                                      }}
                                      multiline /></td> {/* CASE INNER DIMNS. */}
                                    <td>
                                      <TextField
                                        name="foamoutbottom"
                                        value={`${item?.indlen || ''}*${item?.indwidth || ''}*${item?.indbottom || ''}`}
                                        sx={{
                                          '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                          }, width: '100px'
                                        }}
                                        multiline
                                      />
                                    </td>
                                    <td><TextField name="foamouttop" value={item?.indtop}
                                      //onChange={handleVerifyChanges}
                                      sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                          border: 'none',
                                        }, width: '100px'
                                      }}
                                      multiline /></td>
                                  </React.Fragment>
                                ))}
                                <td><TextField name="formcutbottom" value={verifyData?.formcutbottom}
                                  // onChange={handleVerifyChanges}
                                  sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    }, width: '100px'
                                  }}
                                  multiline /></td> {/* FOAM CUT DIMNS. BOTTOM */}
                                <td><TextField name="formcuttop" value={verifyData?.formcuttop}
                                  // onChange={handleVerifyChanges}
                                  sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    }, width: '100px'
                                  }}
                                  multiline /></td>
                              </tr>
                              <td rowSpan={formData.listaccess.length + 1} className='verticaltext'>ACCESSORIES</td>
                              <td colSpan={4}>
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '16px' }}>
                                  <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
                                    HANDLE
                                    <TextField
                                      select
                                      id="handle"
                                      name="handle"
                                      value={
                                        verifyData?.handle && ["tiny", "ss", "plastic"].includes(verifyData?.handle)
                                          ? verifyData?.handle
                                          : verifyData?.handle === "" || verifyData?.handle === undefined
                                            ? ""
                                            : "others"
                                      }
                                      //   onChange={(e) => {
                                      //     const selectedValue = e.target.value;
                                      //     if (selectedValue === "others") {
                                      //       setverifyData((prevState) => ({ ...prevState, handle: "" })); // Clear the value for custom input
                                      //       setShowTextBoxhandle(true); // Show custom text box
                                      //     } else {
                                      //       setverifyData((prevState) => ({ ...prevState, handle: selectedValue }));
                                      //       setShowTextBoxhandle(false); // Hide custom text box
                                      //     }
                                      //   }}
                                      style={{ padding: "2px" }}
                                    >
                                      <MenuItem value="tiny">TINY</MenuItem>
                                      <MenuItem value="ss">SS</MenuItem>
                                      <MenuItem value="plastic">PLASTIC</MenuItem>
                                      <MenuItem value="others">OTHERS</MenuItem>
                                    </TextField>
                                  </FormControl>
                                  {showTextBoxhandle && (
                                    <TextField
                                      label=""
                                      variant="outlined"
                                      name="handle"
                                      style={{ width: "180px", top: "10px" }}
                                      value={verifyData?.handle || ""}
                                      //   onChange={(e) =>
                                      //     setverifyData((prevState) => ({
                                      //       ...prevState,
                                      //       handle: e.target.value,
                                      //     }))
                                      //   }
                                      multiline
                                    />
                                  )}
                                </div>
                              </td>
                              <td colSpan={3}>
                                <FormControl component="fieldset">
                                  <RadioGroup
                                    name='status'
                                    // value = {radioValuesverify[row.id] ?? (row.status === "1" ? "1" : "0")}
                                    value={1}
                                    // Ensure this matches the expected type
                                    //onChange={(e) => handleStatusChanges(e, row.id, index)}
                                    className="compact-radio-group"
                                    row
                                  >
                                    <FormControlLabel name="status" value="1" control={<Radio size="small" />} label="Yes" />
                                    <FormControlLabel name="status" value="0" control={<Radio size="small" />} label="No" />
                                  </RadioGroup>
                                </FormControl>
                              </td>
                              {formData?.listaccess?.map((row: any, index: number) => (
                                <tr key={row.id}>

                                  <td className="designop1" colSpan={4}>
                                    {row.isNew ? (
                                      <input
                                        className='textbox-style'
                                        type='text'
                                        style={{ width: '100%', textAlign: 'left' }}
                                        value={row.desp}
                                      // onChange={(e) => {
                                      //   const updatedRows = [...formData.listaccess];
                                      //   updatedRows[index].desp = e.target.value;
                                      //   setFormData((prevState) => ({
                                      //     ...prevState,
                                      //     listaccess: updatedRows,
                                      //   }));
                                      // }}
                                      />
                                    ) : (
                                      <span>{row.name.toUpperCase()}</span>
                                    )}
                                    {index === 0 && (
                                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '16px' }}>
                                        <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
                                          <TextField
                                            select
                                            name="wheel"
                                            value={verifyData?.wheel}
                                            // onChange={handleVerifyChanges}
                                            style={{ padding: '2px' }}
                                          >
                                            <MenuItem value="casterwheel">CASTER WHEEL</MenuItem>
                                            <MenuItem value="trolleywheel">TROLLEY WHEEL</MenuItem>
                                          </TextField>
                                        </FormControl>
                                      </div>
                                    )}
                                    {index === 4 && (
                                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px', gap: '16px' }}>
                                        <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
                                          <TextField
                                            select
                                            name="latchlock"
                                            value={verifyData?.latchlock}
                                            // onChange={handleVerifyChanges}
                                            style={{ padding: '2px' }}
                                          >
                                            <MenuItem value="casterwheel">Caster Wheel</MenuItem>
                                            <MenuItem value="hasp">HASP</MenuItem>
                                          </TextField>
                                        </FormControl>
                                      </div>
                                    )}
                                  </td>
                                  <td colSpan={3}>
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        name='status'
                                        // value = {radioValuesverify[row.id] ?? (row.status === "1" ? "1" : "0")}
                                        value={verifyData?.listrequirements?.[index]?.status ?? row.status}
                                        // Ensure this matches the expected type
                                        // onChange={(e) => handleStatusChanges(e, row.id, index)}
                                        className="compact-radio-group"
                                        row
                                      >
                                        <FormControlLabel name="status" value="1" control={<Radio size="small" />} label="Yes" />
                                        <FormControlLabel name="status" value="0" control={<Radio size="small" />} label="No" />
                                      </RadioGroup>
                                    </FormControl>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <button type="button" onClick={addRowverify}>+Add</button>
                          </table>
                          {/* <DialogActions>
                            <Button onClick={handleClose3} variant='contained' color="primary" >
                              Clear
                            </Button>
                            <Button onClick={handleSubmitVerification} variant='contained' color="primary" >
                              Save
                            </Button>
                          </DialogActions> */}
                        </div>


                      </TabPanel>
                      <TabPanel value={value} index={6}>

                        <div className="panel">
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {tableData.map((item, index) => (
                              <div key={item.id} style={{
                                marginBottom: '20px', gap: '20px',
                                color: "#a50101",
                              }} >
                                <div><strong style={{ color: "#000" }}>MPN No :</strong> <b>{item.mpnno}</b></div>
                              </div>
                            ))}
                          </div>
                          <table>
                            <thead>
                              <tr>
                                <th className='designop'>DETAILS TO BE CHECKED</th>
                                <th className='designop'>CHK WITH</th>
                                <th className='designop'>CASE DWG</th>
                                <th className='designop'>BOM</th>
                                <th className='designop'>FOAM DWG</th>
                                <th className='designop'>PRINTING DWG</th>
                                <th className='designop'>REMARKS/LIST DATE</th>
                              </tr>
                            </thead>
                            <tbody>
                              {validData?.map((row, index) => (
                                <tr key={row.id} >
                                  <td className="valid" style={{ width: '150px' }} >
                                    {row.detailstobechecked}
                                    {index === 3 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('req') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> REQ </label>
                                        <label><input type="checkbox" className="checkbox-style" value='not req' checked={validDetailsData[index]?.detailscheck?.includes('not req') || false} name="detailscheck"
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '32px', marginRight: '10px' }} /> NOT REQ </label>
                                      </div>
                                    )}
                                    {index === 4 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label> <input type="checkbox" className="checkbox-style" value='latch' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('latch') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> LATCH </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='hinge' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('hinge') || false}
                                          //onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '38px', marginRight: '10px' }} /> HINGE </label>
                                        <label><input type="checkbox" className="checkbox-style" value='handle' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('handle') || false}
                                          //onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '38px', marginRight: '10px' }} /> HANDLE </label>
                                      </div>
                                    )}
                                    {index === 5 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='rivet' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('rivet') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> RIVET </label>
                                        <label><input type="checkbox" className="checkbox-style" value='bolt' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('bolt') || false}
                                          //  onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> BOLT </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='big' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('big') || false}
                                          //  onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> BIG </label>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                          SS CA <label><input type="checkbox" className="checkbox-style" value='2240' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2240') || false}
                                            // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 2240 </label>
                                          <label>  <input type="checkbox" className="checkbox-style" value='2230' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2230') || false}
                                            //  onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 2230 </label>
                                          <label><input type="checkbox" className="checkbox-style" value='2220' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2220') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2220 </label>
                                        </div>
                                      </div>
                                    )}
                                    {index === 7 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        SS CA<label><input type="checkbox" className="checkbox-style" value='2150' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2150') || false}
                                          //  onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '5px', marginRight: '10px' }} /> 2150 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='4350' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4350') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> 4350 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='4760' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4760') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> 4760 </label>
                                      </div>
                                    )}
                                    {index === 10 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label> <input type="checkbox" className="checkbox-style" value='epe' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('epe') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> EPE </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='xlpe' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('xlpe') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> XLPE </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='pu' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('pu') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> PU </label>
                                      </div>
                                    )}
                                    {index === 13 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='xlpe' checked={validDetailsData[index]?.detailscheck?.includes('xlpe') || false} name="detailscheck"
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> XLPE </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='silica gel' checked={validDetailsData[index]?.detailscheck?.includes('silica gel') || false} name="detailscheck"
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> SILICA GEL </label>
                                      </div>
                                    )}
                                    {index === 15 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='textstyle' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('textstyle') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> TEXT STYLE </label>
                                        <label><input type="checkbox" className="checkbox-style" value='hteveness' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('hteveness') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> HT EVENESS </label>
                                      </div>
                                    )}
                                    {index === 17 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label>  <input type="checkbox" className="checkbox-style" value='yellow' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('yellow') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> YELLOW </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('black') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='white' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('white') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> WHITE </label>
                                      </div>
                                    )}
                                    {index === 19 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='green' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('green') || false}
                                          //  onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> GREEN </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='grey' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('grey') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> GREY </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('black') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK </label>
                                      </div>
                                    )}
                                    {index === 20 && (
                                      <div style={{ marginTop: '10px' }}>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                          <label> <input type="checkbox" className="checkbox-style" value='plastic' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('plastic') || false}
                                            //    onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginRight: '10px' }} /> PLASTIC </label>
                                          <label> <input type="checkbox" className="checkbox-style" value='ss' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('ss') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> SS </label>
                                          <label> <input type="checkbox" className="checkbox-style" value='tiny' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('tiny') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> TINY </label>
                                        </div>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                          SS CA <label><input type="checkbox" className="checkbox-style" value='2000' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2000') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '5px', marginRight: '10px' }} /> 2000 </label>
                                          <label>  <input type="checkbox" className="checkbox-style" value='2050' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2050') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 2050 </label>
                                          <label> <input type="checkbox" className="checkbox-style" value='2110' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2110') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 2110 </label>
                                        </div>
                                      </div>
                                    )}
                                    {index === 21 && (
                                      <div style={{ marginTop: '10px' }}>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                          <label><input type="checkbox" className="checkbox-style" value='prv' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('prv') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginRight: '10px' }} /> PRV </label>
                                          <label><input type="checkbox" className="checkbox-style" value='humidity' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('humidity') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '10px', marginRight: '10px' }} /> HUMIDITY </label>
                                          <label>  <input type="checkbox" className="checkbox-style" value='doc hold' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('doc hold') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '10px', marginRight: '10px' }} /> DOC </label>
                                        </div>
                                        <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                          SS CA<label><input type="checkbox" className="checkbox-style" value='2320' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2320') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '2px', marginRight: '10px' }} />  2320 </label>
                                          <label> <input type="checkbox" className="checkbox-style" value='4000' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4000') || false}
                                            //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 4000 </label>
                                          <label> <input type="checkbox" className="checkbox-style" value='4050' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4050') || false}
                                            //    onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                            style={{ marginLeft: '20px', marginRight: '10px' }} /> 4050 </label>
                                        </div>
                                      </div>
                                    )}
                                    {index === 22 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label> <input type="checkbox" className="checkbox-style" value='screen printing' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('screen printing') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '5px', marginRight: '10px' }} /> SCREEN PRINTING </label>
                                        <label>  <input type="checkbox" className="checkbox-style" value='name plate' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('name plate') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '5px', marginRight: '10px' }} /> NAME PLATE </label>
                                      </div>
                                    )}
                                    {index === 23 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='1.5' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('1.5') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> 1.5 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='3' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('3') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> 3 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='2.5' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2.5') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> 2.5 SPECIFY </label>
                                      </div>
                                    )}
                                    {index === 24 && (
                                      <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('req') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginRight: '10px' }} /> REQ </label>
                                        <label><input type="checkbox" className="checkbox-style" value='not req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('not req') || false}
                                          // onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                          style={{ marginLeft: '20px', marginRight: '10px' }} /> NOT REQ </label>
                                      </div>
                                    )}
                                  </td>
                                  {index === 11 ? (
                                    <td className="verticaltext" rowSpan={5}>
                                      {row.chkwith}
                                    </td>
                                  ) : index > 11 && index < 16 ? null : (
                                    <td className="designop">
                                      {row.chkwith}
                                    </td>
                                  )}
                                  {index === 9 ? (
                                    <>
                                      <td className="designop">CASE WT <input type="text" className="textbox-style" value={validDetailsData[index]?.casewt || ''} name="casewt"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                        maxLength={20} /></td>
                                      <td className="designop">ACC. WT <input type="text" className="textbox-style" value={validDetailsData[index]?.accwt || ''} name="accwt"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                        maxLength={20} /></td>
                                      <td className="designop">FOAM WT <input type="text" className="textbox-style" value={validDetailsData[index]?.foamwt || ''} name="foamwt"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        maxLength={20} /></td>
                                      <td className="designop">STIFF. WT<input type="text" className="textbox-style" value={validDetailsData[index]?.stiffwt || ''} name="stiffwt"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                        maxLength={20} /></td>
                                      <td className="designop">FINAL WT <input type="text" className="textbox-style" value={validDetailsData[index]?.finalwt || ''} name="finalwt"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)} 
                                        maxLength={20} /></td>
                                    </>
                                  ) : index === 8 ? (
                                    <>
                                      <td className="designop" colSpan={4}><input type="text" className="textbox-style" value={validDetailsData[index]?.eqpwt || ''} name="eqpwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={25} /></td>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.remarkslistdate === 1}
                                          name="remarkslistdate"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.casedwg === 1}
                                          name="casedwg"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.bom === 1}
                                          name="bom"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.foamdwg === 1}
                                          name="foamdwg"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.printingdwg === 1}
                                          name="printingdwg"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                      <td className="tick">
                                        <input
                                          type="checkbox"
                                          className="styled-checkbox"
                                          checked={validDetailsData[index]?.remarkslistdate === 1}
                                          name="remarkslistdate"
                                        //   onChange={(e) => handleValidationChanges(e, row.id, index)}
                                        />
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* <DialogActions>
                            <Button onClick={handleClose4} color="primary" variant='contained' className="btn-print" >
                              Clear
                            </Button>
                            <Button onClick={handleSubmitValidation} variant='contained' color="primary" >
                              Save
                            </Button>
                          </DialogActions> */}

                          <div className="panel">
                            <div className="main-heading">SUBMIT TO MARKETING TEAM</div>
                            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={4}>
                              <Grid item xs={12}>

                                <div>

                                  <TableContainer>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>S.No</TableCell>
                                          <TableCell>File Name</TableCell>
                                          <TableCell>Upload</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {marketdocsData.map((row, index) => (
                                          <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>
                                              <TextField
                                                value={row.docname}
                                              // onChange={(e) => {
                                              //   const updatedDocname = e.target.value;
                                              //   setmarketdocsData((prevState) => {
                                              //     const updatedFiles = [...prevState];
                                              //     updatedFiles[index].docname = updatedDocname;
                                              //     return updatedFiles;
                                              //   });
                                              // }}
                                              />
                                            </TableCell>
                                            <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                              <div>  {row.path ? (
                                                <>
                                                  <a
                                                    href={(row.path)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                  >
                                                    View File
                                                  </a></>
                                              ) : null}</div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {/* <Button onClick={addRowdocs} variant="contained" color="primary" style={{ marginRight: 'auto', backgroundColor: '#FFA500' }}>
                                    Add
                                  </Button> */}

                                </div>

                              </Grid>
                            </Grid>
                            <br />
                            {/* <Button onClick={handleSubmitToMarket} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginLeft: 'auto' }} variant='contained'>
                              Send
                            </Button> */}
                            {/* <Button onClick={() => {
                              handlePrint(null, () => contentToPrint.current);
                            }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                              variant="contained"
                              color="primary"
                              startIcon={<FontAwesomeIcon icon={faPrint} />}>
                              Print
                            </Button> */}
                          </div>
                        </div>


                      </TabPanel>


                    </div>
                  ) : (
                    <div>
                      <TabPanel value={value} index={0}>
                        <div className="main-content" >
                          <div>
                            <div className="form-container">

                              <div className="customer-name">
                                <strong>Customer Name :</strong> {drawreq[0]?.name}
                              </div>
                              <table className="table">
                                <tbody>
                                  <tr className='dshead'>
                                    <th style={{ width: "50%" }} colSpan={2}>From: Marketing</th>
                                    <th style={{ width: "50%" }} colSpan={2}>To: Design</th>
                                  </tr>
                                  <tr>
                                    <td><strong>I/P No & Input Date:</strong>

                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.ipno}</span> DT  {
                                        drawreq[0]?.ipdate
                                          ? (() => {
                                            const [year, month, day] = drawreq[0].ipdate.split('T')[0].split('-');
                                            return `${day}-${month}-${year}`;
                                          })()
                                          : ''
                                      }


                                    </th>
                                    <td>
                                      <strong>No. of Eqpts. Packed/Box:</strong>
                                    </td>

                                    <th>
                                      <div>{drawreq[0]?.noofeqpt}</div>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Category</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.Drawcateg}</span>
                                    </th>
                                    <td>
                                      <strong>Spl. Accs. If any</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.splacc}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Purpose</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.Purpose}</span>
                                    </th>
                                    <td>
                                      <strong>Testing Standard</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.teststd}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Drg. Required on</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.drawreqdate ? drawreq[0]?.drawreqdate.split('T')[0] : ''}</span>
                                    </th>
                                    <td>
                                      <strong>Eqpt. Type</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.eqpttype}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Project Name</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.proname}</span>
                                    </th>
                                    <td>
                                      <strong>Eqpt. Sensitivity</strong>
                                    </td>

                                    <th>
                                      {drawreq[0]?.eqptsen}
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Eqpt. Size (LBH)</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.eqptsize}</span>
                                    </th>
                                    <td>
                                      <strong>Application</strong>
                                    </td>

                                    <th>
                                      <span >{drawreq[0]?.app}</span>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Eqpt. Wt.</strong>
                                    </td>
                                    <th>
                                      <span >{drawreq[0]?.eqptwt}</span>

                                    </th>
                                    <td>
                                      <strong>Special note if any</strong>
                                    </td>

                                    <th>
                                      <span >{drawreq[0]?.splnote}</span>
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                         

                        </div>
                        <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>ATTACHMENTS</b></Typography>
                          </AccordionSummary>
                          <AccordionDetails className='AccordionDetails'>
                            <Typography>
                              <Grid item xs={12}>
                                <Paper>
                                  <TableContainer>
                                    <Table className="">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                          <TableCell style={{ textAlign: 'center' }}><b>FILE NAME</b></TableCell>
                                          <TableCell style={{ textAlign: 'center' }}><b>PATH</b></TableCell>

                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {drawreqatt.map((row: any, index: number) => (
                                          <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.filename}</TableCell>
                                            <TableCell>
                                              <div>
                                                {/* {row.url && (
                                                      <a
                                                        href={`https://docs.google.com/viewer?url=${encodeURIComponent(row.url)}&embedded=true`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          color: hoverIndex === index ? '#0056b3' : '#007bff',
                                                          textDecoration: hoverIndex === index ? 'underline' : 'none',
                                                        }}
                                                        onMouseEnter={() => setHoverIndex(index)}
                                                        onMouseLeave={() => setHoverIndex(null)}
                                                      >
                                                        View
                                                      </a>
                                                    )} */}
                                                {row.url ? (
                                                  <>
                                                    <a
                                                      href={(row.url)}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      View File
                                                    </a></>
                                                ) : null}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                              </Grid>
                              {/* {tableData.some(data => data.isproceed === null) && (
                                <DialogActions>
                                  <Button onClick={handleSubmitToClarify} color="primary" variant='contained' className="btn-print">
                                    Request for Clarification
                                  </Button>
                                  <Button onClick={handleProceedClick} variant='contained' color="primary">
                                    Proceed
                                  </Button>
                                </DialogActions>
                              )} */}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </TabPanel>

                      <TabPanel value={value} index={1}>
                        <div className="main-content" >
                          <CardContent>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>EQUIPMENT DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <Grid item xs={12}>
                                    <Paper>
                                      <TableContainer>
                                        <Table className="">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>DESIGN DATA DETAILS</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>GIVEN DETAILS & FILES</b></TableCell>
                                              <TableCell style={{ textAlign: 'center' }}><b>REMARKS</b></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>

                                            {/* {console.log(formData?.listessdata, "essdatanew")} */}
                                            {formData?.listessdata?.map((row: any, index: number) => (
                                              <TableRow key={row.essdataid}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>   <div>
                                                  {/* Always display the compliance value */}
                                                  <div>{row.compliance}</div>

                                                  {row.attach ? (
                                                    <>
                                                      <a
                                                        href={(row.attach)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        View File
                                                      </a></>
                                                  ) : null}
                                                </div></TableCell>
                                                <TableCell>
                                                  <TextField
                                                    required
                                                    name="remarks"
                                                    value={row.remarks}
                                                    // onChange={(e) => handleRemarksChange(row.essdataid, index, e)}
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{ maxLength: 200 }}
                                                    fullWidth
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Paper>
                                  </Grid>
                                  {/* {tableData.some(data => data.isproceed === null) && (
              <DialogActions>
                <Button onClick={handleSubmitToClarify} color="primary" variant='contained' className="btn-print">
                  Request for Clarification
                </Button>
                {/* <Button onClick={handleProceedClick} variant='contained' color="primary">
                  Proceed
                </Button> 
              </DialogActions>
            )} */}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>

                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>TEST & ACCESSORIES DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="display-area">
                                    <div className="panel">
                                      <Typography variant="h6" gutterBottom>
                                        <b style={{ color: "#000" }}><u>(AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                                      </Typography>
                                      <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                          <Paper>
                                            <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                              <Table className="table-body">
                                                <TableHead>
                                                  <TableRow className="table-header">
                                                    <TableCell><b>SL. NO</b></TableCell>
                                                    <TableCell><b>NAME OF THE TEST</b></TableCell>
                                                    <TableCell><b>Yes / No</b></TableCell>
                                                    <TableCell><b>Remarks</b></TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>

                                                  {formData?.listtest?.map((row: any, index: number) => (
                                                    <TableRow key={row.testaccessid}>
                                                      <TableCell>{index + 1}</TableCell>
                                                      <TableCell>{row.name}</TableCell>
                                                      <TableCell style={{ width: "150px" }}>
                                                        <FormControl component="fieldset">
                                                          <RadioGroup
                                                            //value={radioValues[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                            value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                            // onChange={(e) => {
                                                            //   handleBitChange(row.id, index, e);
                                                            // }}
                                                            row
                                                            className="compact-radio-group"
                                                          >
                                                            <FormControlLabel value="1" control={<Radio size="small" />} label="Y" />
                                                            <FormControlLabel value="0" control={<Radio size="small" />} label="N" />
                                                          </RadioGroup>
                                                        </FormControl>
                                                      </TableCell>
                                                      <TableCell>
                                                        <TextField multiline value={row.desp} />
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Paper>
                                        </Grid>
                                        <Grid item xs={6}  >
                                          <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                            <Table className="table-body">
                                              <TableHead>
                                                <TableRow className="table-header">
                                                  <TableCell><b>SL. NO</b></TableCell>
                                                  <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                                  <TableCell><b>APPLICABLE Yes / No</b></TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>

                                                {formData?.listaccess?.map((row: any, index: number) => (
                                                  <TableRow key={row.testaccessid}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell style={{ width: "150px" }}>
                                                      <FormControl component="fieldset">
                                                        <RadioGroup
                                                          //value={radioValues1[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                          value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                          //onChange={(e) => handleBitChange1(row.id, index, e)}
                                                          row
                                                          className="compact-radio-group"
                                                        >
                                                          <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Y" />
                                                          <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="N" />
                                                        </RadioGroup>
                                                      </FormControl>
                                                    </TableCell>
                                                  </TableRow>
                                                ))}
                                                <TableRow>
                                                  <TableCell></TableCell>
                                                  <TableCell>Others</TableCell>
                                                  <TableCell>
                                                    <TextField
                                                      id='accdesc'
                                                      name='accdesc'
                                                      value={formData?.accdesc}
                                                      //   onChange={handleInputChanges}
                                                      fullWidth
                                                      multiline
                                                    />
                                                  </TableCell>
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>

                          </CardContent>

                        </div>
                      </TabPanel>

                      <TabPanel value={value} index={2}>
                      <div className="main-content" >
                          <Grid>
                            <div className="display-area">
                              <div className="panel">
                                <Typography variant="h6" gutterBottom>
                                  <b style={{ color: "#2801ff" }}><u>CASE SELECTION</u></b>
                                </Typography>
                                <Grid item>
                                  <Table className="casetable">
                                    <TableHead>
                                      <TableRow className="table-header">
                                        <TableCell><b>TYPE</b></TableCell>
                                        <TableCell><b>CASE MODEL</b></TableCell>
                                        <TableCell><b>RESULT</b></TableCell>
                                        {formData?.partno && (
                                          <TableCell><b>MODEL NO</b></TableCell>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell>

                                        
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                id="existingCaseCheckbox"
                                                name="casetype"
                                                checked={formData.casetype === 'Existing Case'}
                                              //  onChange={(e) => handleCheckboxChangecasetype(e, 'Existing Case')}
                                              />
                                            }
                                            label={
                                              <Typography variant="body1">
                                                Existing Case
                                              </Typography>
                                            }
                                          />
                                          <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formData?.result === "Can Be Fusioned" || formData?.result === "Bottom NPD" || formData?.result === "Top NPD" && (
                                              <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                <a
                                                  href="#"
                                                  // onClick={(e) => {
                                                  //   e.preventDefault(); // Prevent default anchor behavior
                                                  //   setOpenFusionPopup(true); // Open the dialog
                                                  // }}
                                                  style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                  Modify & Create Model
                                                </a>
                                              </Box>
                                            )}
                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                       
                                          <TextField
                                            required
                                            select
                                            id="casemodelid"
                                            name="casemodelid"
                                            fullWidth
                                         
                                            value={formData?.casemodelid || null}
                                           // onChange={handleCasemodelIdChange}
                                            error={error && !formData.casemodelid} // Show error if empty
                                            helperText={error && !formData.casemodelid ? "Please select a model" : ""}
                                          >

                                            {model.map((option) => (
                                              <MenuItem key={option.id}
                                                // value={insertmodelid !== null ? insertmodelid : option.id}>
                                                value={option.id}>
                                                {option.casemodel}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        
                                        
                                        
                                        </TableCell>
                                        <TableCell>
                                         

                                          <TextField
                                            required
                                            select
                                            id="result"
                                            name="result"
                                            fullWidth
                                            value={formData?.result || ""} // Ensure a default value
                                         //   onChange={handleInputChanges}
                                            error={error && !formData?.result}
                                            helperText={error && !formData?.result ? "Please select a result" : ""}
                                          >
                                            <MenuItem value="Can be used">Can be used</MenuItem>

                                            <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                            <MenuItem value="Bottom NPD">Bottom NPD</MenuItem>
                                            <MenuItem value="Top NPD">Top NPD</MenuItem>
                                          </TextField>
                                       
                                        </TableCell>
                                        {formData?.partno && (
                                          <TableCell>
                                            <strong>{formData?.partno}</strong>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                id="npdCaseCheckbox"
                                                name="casetype"
                                                checked={formData.casetype === 'NPD Case'}
                                            //    onChange={(e) => handleCheckboxChangecasetype(e, 'NPD Case')}
                                              />
                                            }
                                            label={
                                              <Typography variant="body1">
                                                NPD Case
                                              </Typography>
                                            }
                                          />
                                          <Box display="flex" flexDirection="column" alignItems="flex-start">
                                            {formData?.casetype === "NPD Case" && (
                                              <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                <a
                                                  href="#"
                                                  // onClick={(e) => {
                                                  //   e.preventDefault(); // Prevent default anchor behavior
                                                  //   setOpenDesignModelPopup(true); // Open the dialog
                                                  // }}
                                                  style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                >
                                                  Create Model
                                                </a>
                                              </Box>
                                            )}

                                          </Box>
                                        </TableCell>
                                        <TableCell>
                                          {/* {formData?.casetype === 'Existing Case' && ( */}
                                          <TextField
                                            required
                                            select
                                            id="casemodelid"
                                            name="casemodelid"
                                            fullWidth
                                            // value={formData?.casemodelid}
                                            value={formData?.casemodelid1 || null}
                                          //  onChange={handleCasemodelIdChange}
                                            error={error && !formData.casemodelid} // Show error if empty
                                            helperText={error && !formData.casemodelid ? "Please select a model" : ""}
                                          >

                                            {model1.map((option) => (
                                              <MenuItem key={option.id}
                                                // value={insertmodelid !== null ? insertmodelid : option.id}>
                                                value={option.id}>
                                                {option.casemodel}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </TableCell>
                                        <TableCell>
                                          <TextField
                                            required
                                            select
                                            id="result"
                                            name="result"
                                            fullWidth
                                            value={formData?.result || ""} // Ensure a default value
                                       //     onChange={handleInputChanges}
                                            error={error && !formData?.result}
                                            helperText={error && !formData?.result ? "Please select a result" : ""}
                                          >
                                            <MenuItem value="To Be Made">To Be Made</MenuItem>

                                          </TextField>

                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Grid>
                              </div>
                            </div>
                          </Grid>
                        </div>
                      </TabPanel>

                      {/* <TabPanel value={value} index={3}>
                        <div>
                          <CardContent>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>INPUT DETAILS</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="content-grid">
                                    <div className="column">
                                      <div>
                                        {tableData.map((item, index) => (
                                          <div key={item.id} style={{ marginBottom: "20px" }}>
                                            <TextField
                                              margin="dense"
                                              id="revno"
                                              label="I/P NO"
                                              name="revno"
                                              value={item.enqno}
                                              fullWidth
                                              //       onChange={handleReviewChanges}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                          </div>
                                        ))}

                                        {sizewtdata.map((item, index) => (

                                          <div key={item.id} style={{ marginTop: '-5px' }}>

                                            <TextField
                                              margin="dense"
                                              id="eqipsize"
                                              label="EQUIPMENT SIZE"
                                              name='eqipsize'
                                              value={item.outerdimns}
                                              fullWidth
                                              //       onChange={handleReviewChanges}
                                              inputProps={{ maxLength: 45 }}
                                              InputProps={{
                                                readOnly: true,
                                              }}
                                            />
                                            <div style={{ marginTop: '15px' }}>
                                              <TextField
                                                margin="dense"
                                                id="eqipwt"
                                                label="EQUIPMENT WEIGHT"
                                                name='eqipwt'
                                                value={item?.casenetwt2}
                                                fullWidth
                                                //         onChange={handleReviewChanges}
                                                inputProps={{ maxLength: 45 }}
                                                InputProps={{
                                                  readOnly: true,
                                                }}
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <br></br>
                                      <div>
                                        <FormControl component="fieldset" className="radioWithMargin">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>REFER DRG THERE</FormLabel>
                                            </Grid>
                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <RadioGroup
                                                value={reviewData?.refdrgthere}
                                                onChange={handleReviewChanges} row>
                                                <FormControlLabel name="refdrgthere" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="refdrgthere" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                          {showTextBoxreferdrg && (
                                            <TextField
                                              label="Additional Information"
                                              variant="outlined"
                                              name="referdrgtheretext"
                                              value={reviewData?.referdrgtheretext || ''}
                                              fullWidth
                                              margin="normal"
                                            //   onChange={(e) =>
                                            //     setReviewData((prevState) => ({
                                            //       ...prevState,
                                            //       referdrgtheretext: e.target.value,
                                            //     }))
                                            //   }
                                            />
                                          )}
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>EQUIPMENT POSITION</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            {/* {tableData.map((item, index) => (
                      <div key={item.id}>
                        <Grid item >
                          <RadioGroup
                            name='eqipposition'
                            value={item.eqipposition || ""}
                            onChange={handleReviewChanges}
                            row>
                            <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                            <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                          </RadioGroup>
                        </Grid>
                      </div>
                    ))} 
                                            <Grid item >
                                              <RadioGroup
                                                value={reviewData?.eqipposition}
                                                //   onChange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                                                <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 10 }}>ANY SPL MARKING REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup
                                                value={reviewData?.anysplmreq}
                                                //     onChange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="anysplmreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="anysplmreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>

                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 5 }}>EQPT LIFTING BELT REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.eqliftbeltreq}
                                                onChange={handleReviewChanges} row>
                                                <FormControlLabel name="eqliftbeltreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="eqliftbeltreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>NAME PLATE REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.nameplatereq}
                                                //</Grid>  onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="nameplatereq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="nameplatereq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>SPECIAL TEST REQUIRED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.spltestreq}
                                                // onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="spltestreq" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="spltestreq" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 19 }}>SENSITIVITY SPECIFIED</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.senspd}
                                                // onChange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="senspd" value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel name="senspd" value="0" control={<Radio />} label="No" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                          {showTextBoxsenspd && (
                                            <TextField
                                              label="Additional Information"
                                              variant="outlined"
                                              name="senspdtext"
                                              value={reviewData?.senspdtext || ''}
                                              fullWidth
                                              margin="normal"
                                            //   onChange={(e) =>
                                            //     setReviewData((prevState) => ({
                                            //       ...prevState,
                                            //       senspdtext: e.target.value,
                                            //     }))
                                            //   }
                                            />
                                          )}
                                        </FormControl>

                                      </div>
                                    </div>
                                    <div className="column">

                                      <FormControl component="fieldset">
                                        <Grid container alignItems="center" className="radioWithMargin">
                                          <Grid item>
                                            <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                          </Grid>

                                        </Grid>
                                        <Grid container alignItems="center" className="radioWithMargin">

                                          {tableData.map((item, index) => (
                                            <div key={item.id}>
                                              <Grid item >
                                                <RadioGroup value={reviewData?.printcolor}
                                                  //   onChange={handleReviewChanges} 
                                                  row>
                                                  <FormControlLabel name="printcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                                  <FormControlLabel name="printcolor" value="White" control={<Radio />} label="White" />
                                                  <FormControlLabel name="printcolor" value="Black" control={<Radio />} label="Black" />
                                                </RadioGroup>
                                              </Grid>
                                            </div>
                                          ))}
                                        </Grid>
                                      </FormControl>
                                      <FormControl component="fieldset">
                                        <Grid container alignItems="center" className="radioWithMargin">
                                          <Grid item>
                                            <FormLabel component="legend" sx={{ mr: 20 }}>FOAM</FormLabel>
                                          </Grid>

                                        </Grid>
                                        <Grid container alignItems="center" className="radioWithMargin">

                                          <Grid item >
                                            <RadioGroup value={reviewData?.foam}
                                              //   onChange={handleReviewChanges}
                                              row>
                                              <FormControlLabel name="foam" value="EPE" control={<Radio />} label="EPE" />
                                              <FormControlLabel name="foam" value="XLPE" control={<Radio />} label="XLPE" />
                                              <FormControlLabel name="foam" value="PU" control={<Radio />} label="PU" />
                                            </RadioGroup>
                                          </Grid>
                                        </Grid>
                                      </FormControl>
                                      <FormControl component="fieldset" >
                                        {formData?.listaccess?.map((row: any, index: number) => (
                                          <div key={row.testaccessid}>
                                            <Grid container alignItems="center" className="radioWithMargin" >
                                              <Grid item>
                                                <FormLabel component="legend">{row.name.toUpperCase()}</FormLabel>
                                              </Grid>
                                              <Grid container alignItems="center" className="radioWithMargin" >
                                                <RadioGroup
                                                  value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                  //   onChange={(e) => handleBitChange1(row.id, index, e)}
                                                  row
                                                >
                                                  <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Yes" />
                                                  <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="No" />
                                                </RadioGroup>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        ))}
                                      </FormControl>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion style={{ backgroundColor: '#f3f3f3' }} >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                              >
                                <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>REVIEW OUTPUTS & ACCESSORIES</b></Typography>
                              </AccordionSummary>
                              <AccordionDetails className='AccordionDetails'>
                                <Typography>
                                  <div className="content-grid">
                                    <div className="table-container">
                                      <div className="table">
                                        <div className="main-heading" style={{ color: "#000" }}>REVIEW OUTPUTS</div>
                                        <TextField
                                          required
                                          select
                                          id="casetype"
                                          name="casetype"
                                          fullWidth
                                          value={formData?.casetype}
                                          //   onChange={handleReviewChanges}
                                          style={{ marginBottom: '16px' }} // Adds space below the first TextField
                                        >
                                          <MenuItem value="Existing Case">Existing Case</MenuItem>
                                          <MenuItem value="NPD Case">NPD Case</MenuItem>
                                        </TextField>
                                        <TextField
                                          required
                                          select
                                          id="caseresult"
                                          name="caseresult"
                                          fullWidth
                                          value={formData?.result}
                                          onChange={handleReviewChanges}
                                          style={{ marginBottom: '16px' }}
                                        >
                                          <MenuItem value="Can be used">Can be used</MenuItem>
                                          <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                          {/* <MenuItem value="Top NPD">Top NPD</MenuItem>
                  <MenuItem value="Bottom NPD">Bottom NPD</MenuItem> 
                                          <MenuItem value="To Be Made">To Be Made</MenuItem>
                                        </TextField>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin">
                                            <Grid item>
                                              <FormLabel component="legend" sx={{ mr: 15 }}>FOAM</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin">

                                            <Grid item >
                                              <RadioGroup value={reviewData?.rofoam}
                                                // onChange={handleReviewChanges} 
                                                row>
                                                <FormControlLabel name="rofoam" value="EPE" control={<Radio />} label="EPE" />
                                                <FormControlLabel name="rofoam" value="XLPE" control={<Radio />} label="XLPE" />
                                                <FormControlLabel name="rofoam" value="PU" control={<Radio />} label="PU" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>

                                        </FormControl>
                                        <FormControl component="fieldset">
                                          <Grid container alignItems="center" className="radioWithMargin" >
                                            <Grid item>
                                              <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                            </Grid>

                                          </Grid>
                                          <Grid container alignItems="center" className="radioWithMargin" >

                                            <Grid item >
                                              <RadioGroup value={reviewData?.roprintcolor}
                                                // onChange={handleReviewChanges}
                                                row>
                                                <FormControlLabel name="roprintcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                                <FormControlLabel name="roprintcolor" value="White" control={<Radio />} label="White" />
                                                <FormControlLabel name="roprintcolor" value="Black" control={<Radio />} label="Black" />
                                              </RadioGroup>
                                            </Grid>
                                          </Grid>
                                        </FormControl>
                                      </div>

                                      <div className="table">
                                        <div className="main-heading" style={{ color: "#000" }}>REVIEW ACCESSORIES</div>
                                        <div className="items-container">
                                          {formData?.listaccess?.map((row: any, index: number) => {
                                            const isChecked = reviewData.listreqitems?.some(
                                              item => item.accessid === row.id && item.status === 1
                                            );
                                            return (
                                              <div className="item" key={row.id}>
                                                <input
                                                  type="checkbox"
                                                  id={`checkbox-${row.id}`}
                                                  value={row.id}
                                                  name="accessid"
                                                  checked={isChecked}
                                                //   onChange={(e) => handleReviewChanges1(e, row.id, index)}
                                                />
                                                <label htmlFor={`checkbox-${row.id}`}>{row.name}</label>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <div className="panel">
                              <div className="table-container">
                                <div className="table">
                                  <div className='reviewpoints' >
                                    <label htmlFor="revpoints">REVIEW POINTS</label>
                                    <textarea name="revpoints" onChange={handleReviewChanges} value={reviewData?.revpoints} rows="1" maxLength={500}></textarea>
                                  </div>
                                  <br></br>
                                  <div className="note-section">
                                    <label htmlFor="note">NOTE</label>
                                    <textarea name="note" value={reviewData?.note} onChange={handleReviewChanges} rows="4" maxLength={500}></textarea>
                                  </div>
                                  <br></br>
                                  {/* {rows.length > 0 && ( // Check if there are rows before rendering the entire section
                                    <>
                                      <label className='review'>ECN HISTORY</label>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>ECN. No</TableCell>
                                            <TableCell>ECN. Date</TableCell>
                                            <TableCell>Review Points</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {rows.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell>
                                                <input
                                                  type="text"
                                                  name='ecnno'
                                                  value={row.ecnno || ""} // Ensure no undefined/null errors
                                                  onChange={(e) => handleRowChange(index, "ecnno", e.target.value)}
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <input
                                                  type="date"
                                                  name='date'
                                                  value={row.date ? new Date(row.date).toISOString().split("T")[0] : ""} // Correct date format
                                                  onChange={(e) =>
                                                    handleRowChange(
                                                      index,
                                                      "date",
                                                      e.target.value ? new Date(e.target.value) : null
                                                    )
                                                  }
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                              <TableCell>
                                                <input
                                                  type="text"
                                                  name='revpoints'
                                                  value={row.revpoints || ""} // Ensure no undefined/null errors
                                                  onChange={(e) => handleRowChange(index, "revpoints", e.target.value)}
                                                  className='textbox-style'
                                                />
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>

                                      <button type="button" onClick={addRow}>Add Row</button>
                                    </>
                                  )} 
                                </div>
                              </div>
                              {/* <DialogActions>
                                <Button onClick={handleClose1} color="primary" variant='contained' className="btn-print" >
                                  Clear
                                </Button>
                                <Button onClick={handleSubmitReview} variant='contained' color="primary" >
                                  Save
                                </Button>
                              </DialogActions> 
                            </div>
                            {rows?.length > 0 && (
                              <>
                                <Accordion style={{ backgroundColor: '#f3f3f3' }}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                  >
                                    <Typography><b style={{ color: 'rgb(40, 1, 255)' }}>REVIEW HISTORY</b></Typography>
                                  </AccordionSummary>
                                  <AccordionDetails className='AccordionDetails'>

                                    <Typography>
                                      <div className="review-history">
                                        <strong>ECN HISTORY</strong>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>ECN. No</TableCell>
                                              <TableCell>ECN. Date</TableCell>
                                              <TableCell>Review Points</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>

                                            {rows?.map((row, index) => (
                                              <TableRow key={index}>
                                                <TableCell>{row.ecnno}</TableCell>
                                                <TableCell>{row.date}</TableCell>
                                                <TableCell>{row.revpoints}</TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </div>
                                    </Typography>

                                  </AccordionDetails>
                                </Accordion>
                              </>
                            )}
                          </CardContent>


                        </div>
                      </TabPanel> */}


                      <TabPanel value={value} index={3}>

                        <div>
                          <div className="panel">
                            <div className="main-heading">DESIGN OUTPUT DETAILS</div>
                            <table>
                              <thead>
                                <tr>
                                  <th rowSpan={2} className='designop'>DESIGN OUTPUT</th>
                                  <th colSpan={2} className='designop'>OUTPUT DATE</th>
                                </tr>
                                <tr>
                                  <th className='designop'>SC</th>
                                  <th className='designop' >HC</th>
                                </tr>
                              </thead>
                              <tbody>
                                {drawreq[0]?.Drawcateg === 'Blue' ? (
                                  <>

                                    <tr>
                                      <td>Concept dwg attached for finalization</td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                                        value={outputData?.cptdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                                        value={outputData?.cptdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>Concept dwg attached for finalization</td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                                        value={outputData?.cptdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                                        value={outputData?.cptdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Case drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="casedwgattchsc"
                                        value={outputData?.casedwgattchsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="casedwgattchhc"
                                        value={outputData?.casedwgattchhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Foam drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="foamdwgattachsc"
                                        value={outputData?.foamdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="foamdwgattachhc"
                                        value={outputData?.foamdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Screen Printing details attached</td>
                                      <td><input type="date" className="textbox-style" name="scnprtdetattachsc"
                                        value={outputData?.scnprtdetattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="scnprtdetattachhc"
                                        value={outputData?.scnprtdetattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Bill of material attached</td>
                                      <td><input type="date" className="textbox-style" name="billofmatattachsc"
                                        value={outputData?.billofmatattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="billofmatattachhc"
                                        value={outputData?.billofmatattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Stiffner drawing attached</td>
                                      <td><input type="date" className="textbox-style" name="stfdwgattachsc"
                                        value={outputData?.stfdwgattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="stfdwgattachhc"
                                        value={outputData?.stfdwgattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>Product development plan attached</td>
                                      <td><input type="date" className="textbox-style" name="prddevplnaatchsc"
                                        value={outputData?.prddevplnaatchsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="prddevplnaatchhc"
                                        value={outputData?.prddevplnaatchhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                    <tr>
                                      <td>DFMEA attached</td>
                                      <td><input type="date" className="textbox-style" name="dfmeaattachsc"
                                        value={outputData?.dfmeaattachsc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                      <td><input type="date" className="textbox-style" name="dfmeaattachhc"
                                        value={outputData?.dfmeaattachhc}
                                        // onChange={handleOutputChanges} 
                                        style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <div className="panel">
                              <div className="main-heading">SUBMIT TO MARKETING TEAM</div>
                              <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={4}>
                                <Grid item xs={12}>

                                  <div>

                                    <TableContainer>
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>File Name</TableCell>
                                            <TableCell>Upload</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {marketdocsData?.map((row, index) => (
                                            <TableRow key={index}>
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>
                                                <TextField
                                                  value={row.docname}
                                                // onChange={(e) => {
                                                //   const updatedDocname = e.target.value;
                                                //   setmarketdocsData((prevState) => {
                                                //     const updatedFiles = [...prevState];
                                                //     updatedFiles[index].docname = updatedDocname;
                                                //     return updatedFiles;
                                                //   });
                                                // }}
                                                />
                                              </TableCell>
                                              <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <TableCell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                  <div> {row.path ? (
                                                    <>
                                                      <a
                                                        href={(row.path)}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                      >
                                                        View File
                                                      </a></>
                                                  ) : null}</div>
                                                </TableCell>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>

                                    {/* <Button onClick={addRowdocs} variant="contained" color="primary" style={{ marginRight: 'auto', backgroundColor: '#FFA500' }}>
                                    Add
                                  </Button> */}

                                  </div>

                                </Grid>
                              </Grid>
                              <br />
                              {/* <Button onClick={handleSubmitToMarket} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', marginLeft: 'auto' }} variant='contained'>
                              Send
                            </Button> */}
                              {/* <Button onClick={() => {
                              handlePrint(null, () => contentToPrint.current);
                            }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                              variant="contained"
                              color="primary"
                              startIcon={<FontAwesomeIcon icon={faPrint} />}>
                              Print
                            </Button> */}
                            </div>
                            {/* <DialogActions>
                              <Button onClick={handleClose2} variant='contained' color="primary" >
                                Clear
                              </Button>
                              <Button onClick={handleSubmitOutput} variant='contained' color="primary" >
                                Save
                              </Button>
                            </DialogActions> */}
                          </div>
                        </div>


                      </TabPanel>

                    </div>
                  )}

                  {drawreq[0]?.Drawcateg === 'Blue' ? (
                    <>

                      {
                        value === 3 ? (
                          <>
                            <Button
                              variant="outlined"
                              style={{ float: 'right' }}
                              onClick={handleBack}
                              sx={{ margin: 1 }}
                              color="primary"
                            >
                              Back
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              style={{ float: 'right' }}
                              onClick={handleNext}
                              sx={{ margin: 1 }}
                              color="primary"
                            >
                              Next
                            </Button>
                          </>
                        )
                      }
                    </>
                  ) : (
                    <>

                      {
                        value === 6 ? (
                          <>
                            <Button
                              variant="outlined"
                              style={{ float: 'right' }}
                              onClick={handleBack}
                              sx={{ margin: 1 }}
                              color="primary"
                            >
                              Back
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              style={{ float: 'right' }}
                              onClick={handleNext}
                              sx={{ margin: 1 }}
                              color="primary"
                            >
                              Next
                            </Button>
                          </>
                        )
                      }
                    </>
                  )}


                  {value === 0 ? null : (<><Button style={{ float: 'right' }} onClick={handlePre} sx={{ margin: 1 }} color="secondary">
                    Previous
                  </Button></>)}

                  {/* <Button style={{ float: 'right' }} sx={{ margin: 1 }} color="error">
                    Cancel
                  </Button> */}

                  {/* <Button sx={{ margin: 1 }} onClick={handleSubmitToClarify} variant='contained' style={{ float: 'right' }} color="primary">
                    Request for Clarification
                  </Button> */}
                </Box>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

      </Container >
      <Footer />
    </>
  )
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default ViewUpdatePage; 
