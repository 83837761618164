import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, MenuItem, Grid, useMediaQuery, useTheme, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import apiService from 'src/services/api-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

interface FollowDataItem {

    id: number;
    fdate: Date; // Change from string to Date
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: Date; // Change from string to Date
    statusid: number;
    ftypename: string;
    fbyname: string;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EnqQuote {
    id: number;
    qdate: Date;          // Date object for date values
    qdate_s: string;      // String representation of date, if needed
    qno: string;          // Quote number
    rno: number;          // Reference number
    qval: number;         // Quote value
    cusref: string;       // Customer reference
    cuspocname: string;  // Customer Point of Contact name
    cuspocno: string;    // Customer Point of Contact number
    cusdesign: string;   // Customer design
    subject: string;     // Subject of the quote
}

interface EssentialTableRow {
    enqdetid: number;
    essdataid: number;
    compliance: string;
    attach: string;
}

interface TableRowData {
    id: number;
    enqdetid: number | null;  // Null or number depending on the data
    desp: string;
    mpnno: number | null;     // Null or number depending on the data
    qty: number;
    essentialTableRows: EssentialTableRow[];
    unitPrice?: number; // Add this if it is part of your data
    taxableAmount?: number; // Add this if it is part of your data
    igst?: number;
}

interface FormData {
    enquiry: Array<{
        id: number,
        enqid: number,
        qdate: string,
        qno: number,
        Rno: number,
        qval: number,
        cusref: string,
        cuspocname: string,
        cuspocno: string,
        cusdesign: string,
        status: string,
        lastqid: number,
        subject: string,
        cby: string,
        con: string,
        mby: string,
        mon: string,
        taxid: number,
        taxamt: number,
        total: number,
        appby: null,
        appdate: null,
        comments: null,
        finyearid: number,
        empid: number,
        enqdate: string,
        enqno: string,
        newex: string,
        typeid: number,
        modeid: number,
        cusid: number,
        divid: number,
        rfxenqno: string,
        dueon: string,
        desp: string,
        basicvalue: number,
        ccperson: string,
        cdesign: string,
        cmobile: string,
        cmail: string,
        tcperson: string,
        tdesign: string,
        tmobile: string,
        tmail: string,
        bankname: string,
        branchname: string,
        accno: string,
        ifsc: string,
        statusid: number,
        code: string,
        sname: string,
        name: string,
        cityid: number,
        cperson: string,
        mobile: string,
        email: string,
        gstno: string,
        pan: string,
        tinno: string,
        isactive: number,
        isexe: null,
        ismajor: null,
        add1: string,
        add2: string,
        add3: string,
        add4: string,
        add5: string,
        cadd1: string,
        cadd2: string,
        cadd3: string,
        cadd4: string,
        cadd5: string,
        ceo: string,
        add6: string,
        add7: string,
        cadd6: string,
        cadd7: string,
        qid: number,
        mpnno: string,
        qty: number,
        unitprice: number,
        Taxableamt: number,
        Taxid: number,
        enqdetid: number,
        packchange: number,
        freight: number,
        docchargeper: number,
        doccharge: number,
        designcostqty: number,
        designcostup: number,
        designcostamt: number,
        testchargeqty: number,
        testchargeup: number,
        testchargeamt: number,
        dummycostqty: number,
        dummycostup: number,
        dummycostamt: number,
        jigsfixqty: number,
        jigsfixup: number,
        jigsfixamt: number,
        subtotal: number,
        packchangeper: number,
        freightper: number
    }>

    ,
    follow: null,
    enqdet: Array<{
        id: number;
        qid: number;
        desp: string;
        mpnno: string;
        qty: number;
        unitprice: number;
        Taxableamt: number;
        Taxid: number;
        taxamt: number;
        total: number;
        enqdetid: number;
    }>;
    enqdet_essdata: null,
    quote: Array<{
        id: number;
        type: string;
        sname: string;
        name: string;

    }>;
    quotedet: null


}
interface GST {
    id: number;
    name: string;
    sname: string;
    per: number;
}

interface LocationState1 {
    id: number;
    qno: string;
    enqid: number;
}

interface Dist {
    id: number;
    name: string;
    stateid: number;
}

interface State {
    id: number;
    name: string;
}
const ViewQuotation: React.FC = () => {

    const location = useLocation();
    const state = location.state as LocationState1;
    const navigate = useNavigate();
    const [companyData, setCompanyData] = useState<any[]>([]); // Assuming companyData will be an array of objects
    const [selectedCompany, setSelectedCompany] = useState<any>(null);
    const [term, setTerm] = useState([]);
    const [isTermFilter, setIsTermFilter] = useState(false);
    const [retrive, setRetrive] = useState([]);
    const [isSaved, setIsSaved] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const defaultTextValues = [
        "Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.",
        "Looking forward to your valuable purchase order to serve you on time."
    ];
    const [open, setOpen] = useState(false);
    const [dist1, setDist1] = useState<Dist[]>([]);

    const [states1, setStates1] = useState<State[]>([]);

    const [formData, setFormData] = useState<FormData>(
        {
            enquiry: [
                {
                    id: 0,
                    enqid: 0,
                    qdate: "",
                    qno: 0,
                    Rno: 0,
                    qval: 0,
                    cusref: '',
                    cuspocname: '',
                    cuspocno: '',
                    cusdesign: '',
                    status: "",
                    lastqid: 0,
                    subject: "",
                    cby: "",
                    con: "7",
                    mby: "",
                    mon: "",
                    taxid: 0,
                    taxamt: 0,
                    total: 0,
                    appby: null,
                    appdate: null,
                    comments: null,
                    finyearid: 0,
                    empid: 0,
                    enqdate: "",
                    enqno: "",
                    newex: "",
                    typeid: 0,
                    modeid: 0,
                    cusid: 0,
                    divid: 0,
                    rfxenqno: "",
                    dueon: "",
                    desp: "",
                    basicvalue: 0,
                    ccperson: "",
                    cdesign: "",
                    cmobile: "",
                    cmail: "",
                    tcperson: "",
                    tdesign: "",
                    tmobile: "",
                    tmail: "",
                    bankname: "",
                    branchname: "",
                    accno: "",
                    ifsc: "",
                    statusid: 0,
                    code: "",
                    sname: "",
                    name: "",
                    cityid: 0,
                    cperson: "",
                    mobile: "",
                    email: "",
                    gstno: "",
                    pan: "",
                    tinno: "",
                    isactive: 0,
                    isexe: null,
                    ismajor: null,
                    add1: "",
                    add2: "",
                    add3: "",
                    add4: "",
                    add5: "",
                    cadd1: "",
                    cadd2: "",
                    cadd3: "",
                    cadd4: "",
                    cadd5: "",
                    ceo: "",
                    add6: '',
                    add7: '',
                    cadd6: '',
                    cadd7: '',
                    qid: 0,
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    Taxableamt: 0,
                    Taxid: 0,
                    enqdetid: 0,
                    packchange: 0,
                    freight: 0,
                    docchargeper: 0,
                    doccharge: 0,
                    designcostqty: 0,
                    designcostup: 0,
                    designcostamt: 0,
                    testchargeqty: 0,
                    testchargeup: 0,
                    testchargeamt: 0,
                    dummycostqty: 0,
                    dummycostup: 0,
                    dummycostamt: 0,
                    jigsfixqty: 0,
                    jigsfixup: 0,
                    jigsfixamt: 0,
                    subtotal: 0,
                    packchangeper: 0,
                    freightper: 0
                }

            ],
            follow: null,
            enqdet: [
                {
                    id: 0,
                    qid: 0,
                    desp: "",
                    mpnno: "",
                    qty: 0,
                    unitprice: 0,
                    Taxableamt: 0,
                    Taxid: 0,
                    taxamt: 0,
                    total: 0,
                    enqdetid: 0
                }
            ],
            enqdet_essdata: null,
            quote: [{
                id: 0,
                type: '',
                sname: '',
                name: ''
            }
            ],

            quotedet: null
        });


    const [term1, setTerm1] = useState([])
    const [dataToUpdate1, setDataToUpdate1] = useState([]);
    const [DocPercentage, setDocPercentage] = useState(0);
    const [taxname, setTaxName] = useState<string>('');
    const [quoteData, setQuoteData] = useState({
        enqquotedet: formData.enqdet.map(row => ({
            enqdetid: row.id,
            desp: row.desp,
            mpnno: row.mpnno,
            qty: Number(row.qty), // Ensure qty is a number
            unitprice: row.unitprice,
            taxableamt: row.Taxableamt,
            taxamt: row.taxamt,
            taxid: row.Taxid,
            total: row.total
        })),
        enqquoteTerms: [],
        quote: formData.quote.map(q => ({
            id: q.id,
            type: q.type,
            sname: q.sname,
            name: q.name
        })),
        id: state.id,
        query: '',
        qdate: formData.enquiry[0].qdate,
        enqid: state.enqid, // Initialize enqid
        qval: formData.enquiry[0].qval,
        cusref: formData.enquiry[0].cusref,
        cuspocname: formData.enquiry[0].cuspocname,
        cuspocno: formData.enquiry[0].cuspocno,
        cusdesign: formData.enquiry[0].cusdesign,
        enqno: formData.enquiry[0].enqno,
        status: 'Approval pending',
        qno: state.qno,
        lastqid: 0,
        subject: formData.enquiry[0].subject,
        cby: "1",
        taxid: formData.enquiry[0].taxid,
        taxamt: formData.enquiry[0].taxamt,
        total: formData.enquiry[0].total,
        packchange: formData.enquiry[0].packchange,
        freight: formData.enquiry[0].freight,
        docchargeper: formData.enquiry[0].docchargeper,
        doccharge: formData.enquiry[0].doccharge,
        designcostqty: formData.enquiry[0].designcostqty,
        designcostup: formData.enquiry[0].designcostup,
        designcostamt: formData.enquiry[0].designcostamt,
        testchargeqty: formData.enquiry[0].testchargeqty,
        testchargeup: formData.enquiry[0].testchargeup,
        testchargeamt: formData.enquiry[0].testchargeamt,
        dummycostqty: formData.enquiry[0].dummycostqty,
        dummycostup: formData.enquiry[0].dummycostup,
        dummycostamt: formData.enquiry[0].dummycostamt,
        jigsfixqty: formData.enquiry[0].jigsfixqty,
        jigsfixup: formData.enquiry[0].jigsfixup,
        jigsfixamt: formData.enquiry[0].jigsfixamt,
        subtotal: formData.enquiry[0].subtotal,
        packchangeper: formData.enquiry[0].packchangeper,
        freightper: formData.enquiry[0].freightper,
        faddr1: formData.enquiry[0]?.add1,
        faddr2: formData.enquiry[0]?.add2,
        faddr3: formData.enquiry[0]?.add3,
        faddr4: formData.enquiry[0]?.add4,
        faddr5: formData.enquiry[0]?.add5,
        cusid: formData.enquiry[0]?.cusid
    });




    const [igst, setIgst] = useState<number>(0);
    const [freightPercentage, setFreightPercentage] = useState(0);
    const [packPercentage, setPackPercentage] = useState(0);

    useEffect(() => {
        const totalAmount = formData.enqdet.reduce((sum, row) => sum + (row.Taxableamt || 0), 0);

        setQuoteData(prevQuoteData => ({
            ...prevQuoteData,
            enqquotedet: formData.enqdet.map(row => ({
                id: row.id,
                enqdetid: row.enqdetid,
                desp: row.desp,
                mpnno: row.mpnno,
                qty: row.qty,
                unitprice: row.unitprice,
                taxableamt: row.Taxableamt,
                taxamt: 0, // Assuming IGST is 18%
                taxid: 0,
                total: 0
            })),

            enqquoteTerms: [],
            quote: formData.quote.map(q => ({
                id: q.id,
                type: q.type,
                sname: q.sname,
                name: q.name
            })),
            id: state.id,
            query: '',
            qdate: formData.enquiry[0].qdate,
            enqid: state.enqid,
            cusref: formData.enquiry[0].cusref,
            cuspocname: formData.enquiry[0].cuspocname,
            cuspocno: formData.enquiry[0].cuspocno,
            cusdesign: formData.enquiry[0].cusdesign,
            enqno: formData.enquiry[0].enqno,
            qno: state.qno,
            lastqid: 0,
            subject: formData.enquiry[0].subject,
            cby: "1",
            qval: totalAmount,
            taxid: formData.enquiry[0].taxid,
            taxamt: formData.enquiry[0].taxamt,
            total: formData.enquiry[0].total,
            packchange: formData.enquiry[0].packchange,
            freight: formData.enquiry[0].freight,
            docchargeper: formData.enquiry[0].docchargeper,
            doccharge: formData.enquiry[0].doccharge,
            designcostqty: formData.enquiry[0].designcostqty,
            designcostup: formData.enquiry[0].designcostup,
            designcostamt: formData.enquiry[0].designcostamt,
            testchargeqty: formData.enquiry[0].testchargeqty,
            testchargeup: formData.enquiry[0].testchargeup,
            testchargeamt: formData.enquiry[0].testchargeamt,
            dummycostqty: formData.enquiry[0].dummycostqty,
            dummycostup: formData.enquiry[0].dummycostup,
            dummycostamt: formData.enquiry[0].dummycostamt,
            jigsfixqty: formData.enquiry[0].jigsfixqty,
            jigsfixup: formData.enquiry[0].jigsfixup,
            jigsfixamt: formData.enquiry[0].jigsfixamt,
            subtotal: formData.enquiry[0].subtotal,
            packchangeper: formData.enquiry[0].packchangeper,
            freightper: formData.enquiry[0].freightper,
            faddr1: formData.enquiry[0]?.add1,
            faddr2: formData.enquiry[0]?.add2,
            faddr3: formData.enquiry[0]?.add3,
            faddr4: formData.enquiry[0]?.add4,
            faddr5: formData.enquiry[0]?.add5,
            cusid: formData.enquiry[0]?.cusid
        }));
    }, [formData]);

    const validate = () => {
        // Check if each row in enqquotedet has valid values for desp, qty, and unitprice
        const isValid = quoteData.enqquotedet.every(row =>
            row.desp &&
            row.qty &&
            row.unitprice
        );
        return isValid;
    };

    const handleApprovalClick = async () => {

        if (!validate()) {
            const toastmsg = "Quotation is invalid. Please fill all Description ,Quantity and Unitprice fields."
            errorToast(toastmsg)
            return;
        }
        try {


            const dataToUpdate = quoteData;
            console.log("Quotation Data", dataToUpdate);
            const response = await apiService.EditQuote(dataToUpdate);
            console.log('Update Quotation', response.data);
            // navigate('/crm/budgetquotationpending')

            setShouldFetchData(true);

            const success = "Quotation Update Successfully";
            successToast(success);


        } catch (error) {
            console.error('Error saving quote:', error);
        }
    };
    useEffect(() => {
        if (shouldFetchData) {
            // Assuming quoteData is fetched or already available
            const fetchedData1 = quoteData.enqquoteTerms;
            setDataToUpdate1(fetchedData1);
            setShouldFetchData(false);
        }
    }, [shouldFetchData]);

    const handleBack = () => {
        navigate(-1); // Goes to the previous screen without refreshing
    };




    const handleItemChange = (event) => {
        setQuoteData(prevData => ({
            ...prevData,
            item: event.target.value
        }));
    };






    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [currentDate] = useState(getCurrentDate());
    const [gst, setGst] = useState<GST[]>([]);


    useEffect(() => {
        apiService.getDropdown("mas_company").then(response => {
            setCompanyData(response.data?.map((data: any) => ({
                id: data?.id,
                name: data?.name,
                sname: data?.sname,
                mobile: data?.mobile,
                phone: data?.phone,
                email: data?.email,
                pan: data?.pan,
                gstno: data?.gstno,
                tinno: data?.tinno,
                hsncode: data?.hsncode,
                gemsellerid: data?.gemsellerid,
                add1: data?.add1,
                add2: data?.add2,
                add3: data?.add3,
                add4: data?.add4,
                add5: data?.add5,
                add6: data?.add6,
                mon: data?.mon
            })));
        }).catch(err => {
            console.log("err", err);
        });
    }, []);




    useEffect(() => {
        apiService.getDropdown("crm_mas_quoteterms")
            .then(response => {
                setTerm(response.data);
                console.log("setTerm", response.data);
                setIsTermFilter(true)
            })
            .catch(err => {
                console.log("err", err);
            });
        apiService.getDropdown("crm_mas_tax").then(response => {
            setGst(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_ent_enqquote")
            .then(response => {
                setRetrive(response.data);
                console.log("setTerm", response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
    }, []);



    useEffect(() => {

        if (state.id) {
            // Fetch data if id is provided (for updating an enquiry)
            apiService.getUpdateQuote(Number(state.id))
                .then(response => {
                    const data = response.data;
                    console.log("response.Quotation == >>>>", data);
                    setFormData(data as FormData);

                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [state.id]);

    useEffect(() => {
        if (companyData.length > 0) {
            setSelectedCompany(companyData[0]);
        }
    }, [companyData]);




    const calculateTotal = () => {
        return formData.enqdet.reduce((sum, row) => sum + (row.Taxableamt || 0), 0);
    };

    const calculateUnitprice = () => {
        return formData.enqdet.reduce((sum, row) => sum + (row.unitprice || 0), 0);
    };

    const unittotal = calculateUnitprice()
    const totalAmount = calculateTotal();
    // const igstAmount = calculateIGST(totalAmount+unittotal);
    const finalTotal = totalAmount + igst + unittotal;


    const handleQuoteInputChange = (field, value) => {
        setQuoteData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };


    const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

    const contentToPrint = useRef(null);
    const handlePrint1 = useReactToPrint({
        onBeforePrint: () => setIsPrintMode(true),
        onAfterPrint: () => setIsPrintMode(false),
        removeAfterPrint: true,


    });


    useEffect(() => {
        calculateGST();
    }, [quoteData.qval, quoteData.taxid]);



    const calculateGST = () => {
        const gstRate = quoteData.taxid === 1 ? 0.18 : 0.12;
        const gstAmount = quoteData.qval * gstRate;
        setIgst(gstAmount);
    };



    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));



    const handleInputChange = (index, field, value) => {
        const updatedData = [...quoteData.enqquotedet];
        updatedData[index][field] = value;

        setQuoteData((prevData) => ({
            ...prevData,
            enqquotedet: updatedData,
        }));

    };
    useEffect(() => {
        console.log("formData ==- >", formData);
        console.log("quoteData ==- >", quoteData);

    }, [formData, quoteData]);


    const handleInputChange2 = (field, value) => {
        setQuoteData(prevData => ({
            ...prevData,
            [field]: Number(value) // Convert to number if needed
        }));
    };

    // Function to handle select changes (like tax id change)
    const handleSelectChange = (e, index) => {
        const updatedData = [...quoteData.enqquotedet];
        updatedData[index].taxid = e.target.value;
        setQuoteData((prevData) => ({
            ...prevData,
            enqquotedet: updatedData,
        }));
    };

    // Calculate the tax name by its id
    const getTaxNameById = (taxid) => {
        const selectedTax = gst.find((g) => g.id === taxid);
        return selectedTax ? selectedTax.name : '';
    };

    // Handle packing change
    // const handlePackingChange = (value) => {
    //     const packingCharge = quoteData.qval * (parseFloat(value) / 100);
    //     setPackPercentage(packingCharge);
    //     setQuoteData((prevData) => ({
    //         ...prevData,
    //         packchange: packingCharge,
    //         packchangeper: value
    //     }));
    // };

    const handlePackingChange = (value) => {
        const parsedValue = parseFloat(value);
        const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

        // Update only the packchangeper based on user input
        setQuoteData((prevData) => ({
            ...prevData,
            packchangeper: validPercentage,
        }));
    };

    useEffect(() => {
        // Recalculate packchange whenever qval or packchangeper changes
        const packpercentage = quoteData.packchangeper / 100;
        const packingCharge = quoteData.qval * packpercentage;

        setQuoteData((prevData) => ({
            ...prevData,
            packchange: packingCharge,
        }));
    }, [quoteData.qval, quoteData.packchangeper]);

    // Handle freight change
    // const handleFreightChange = (value) => {
    //     const freightCharge = quoteData.qval * (parseFloat(value) / 100);
    //     setFreightPercentage(freightCharge);
    //     let fc = freightCharge;

    //     setQuoteData((prevData) => ({
    //         ...prevData,
    //         freight: Number(fc),
    //         freightper: value
    //     }));

    // };
    const handleFreightChange = (value) => {
        // Parse the input value and default to 0 if invalid
        const parsedValue = parseFloat(value);
        const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

        // Update only the freight percentage
        setQuoteData((prevData) => ({
            ...prevData,
            freightper: validPercentage,
        }));
    };

    useEffect(() => {
        // Recalculate freight whenever qval or freightper changes
        const freightPercentage = quoteData.freightper / 100;
        const freightCharge = quoteData.qval * freightPercentage;

        setQuoteData((prevData) => ({
            ...prevData,
            freight: freightCharge,
        }));
    }, [quoteData.qval, quoteData.freightper]);

    // const handleDocChanrge = (value) => {
    //     const docprecentage = (parseFloat(value) / 100);
    //     const docCharge = quoteData.qval * docprecentage;
    //     setDocPercentage(docprecentage);
    //     let fc = docCharge;

    //     setQuoteData((prevData) => ({
    //         ...prevData,
    //         docchargeper: value,
    //         doccharge: fc,
    //     }));
    //     console.log('quoteData', quoteData);
    // };
    const handleDocChanrge = (value) => {
        // Parse input value and handle NaN cases by defaulting to 0
        const parsedValue = parseFloat(value);
        const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

        // Update only docchargeper based on the user input
        setQuoteData((prevData) => ({
            ...prevData,
            docchargeper: validPercentage,
        }));
    };

    useEffect(() => {
        // Recalculate doccharge whenever qval or docchargeper changes
        const docPercentage = quoteData.docchargeper / 100;
        const docCharge = quoteData.qval * docPercentage;

        setQuoteData((prevData) => ({
            ...prevData,
            doccharge: docCharge,
        }));
    }, [quoteData.qval, quoteData.docchargeper]);


    const handleTermChange = (e, index, type, id) => {
        const { checked, value } = e.target;

        setQuoteData((prevQuoteData) => {
            // Create a copy of the enqquoteTerms array
            const updatedTerms = [...prevQuoteData.enqquoteTerms];

            // Handle checkbox change
            if (type === 'checkbox') {
                updatedTerms[index] = {
                    ...updatedTerms[index],
                    checked: checked, // Update the checked property
                    termid: checked ? id : 0, // If checked, set termId to the passed id; if unchecked, set it to 0
                };

                // Optionally filter terms based on the checked value (for debugging/logging purposes)
                const filteredTerms = updatedTerms.filter(term => term.checked);
                console.log("filteredTerms", filteredTerms);

                return {
                    ...prevQuoteData,
                    enqquoteTerms: updatedTerms, // Store the updated terms array
                };
            }

            // Handle text field (name) change
            if (type === 'name') {
                if (updatedTerms[index]?.checked) { // Only update the name if the checkbox is checked
                    updatedTerms[index] = {
                        ...updatedTerms[index],
                        name: value, // Update the name property with the new value
                    };
                }
                return {
                    ...prevQuoteData,
                    enqquoteTerms: updatedTerms, // Store the updated terms array with the new name
                };
            }

            return prevQuoteData; // If no valid type, return the state as is
        });
    };

    const handleTermChange1 = (e, index, type, id, name) => {
        const { checked, value } = e.target;

        setQuoteData((prevQuoteData) => {

            const updatedTerms = [...prevQuoteData.enqquoteTerms];

            // Handle checkbox change
            if (type === 'checkbox') {
                updatedTerms[id] = {
                    ...updatedTerms[id],
                    checked: checked,
                    termid: checked ? id : 0,
                };

                // Optionally filter terms based on the checked value (if needed)
                const filteredTerms = updatedTerms.filter(term => term.checked);
                console.log("filteredTerms", filteredTerms);

                return {
                    ...prevQuoteData,
                    enqquoteTerms: updatedTerms, // Store the updated terms
                };
            }

            // Handle text field (name) change
            if (type === 'name') {
                if (updatedTerms[id]?.checked) {  // Only update name if checkbox is checked
                    updatedTerms[id].name = value; // Update the name property with the new value
                }

                return {
                    ...prevQuoteData,
                    enqquoteTerms: updatedTerms, // Store the updated terms with the new name
                };
            }

            return prevQuoteData; // If no valid type, return the state as is
        });
    };

    useEffect(() => {

        const filteredTerms = term
            .filter(item => item.type === "0" || item.type === "1")
            .map(item => {
                const isMatched = formData.quote.some(qt => qt.id === item.id);
                return {
                    type: item.type,
                    termid: item.id,
                    name: item.name,
                    checked: isMatched,  // Set checked based on whether the id matches
                };
            });
        setQuoteData(prevQuoteData => ({
            ...prevQuoteData,
            enqquoteTerms: filteredTerms,
        }));

    }, [term]);


    useEffect(() => {
        let qval = 0;
        let taxamt = 0;

        quoteData.enqquotedet.forEach((item) => {
            const taxableamt = (item.qty || 0) * (item.unitprice || 0);

            qval += taxableamt;

        });
        const selectedTax = gst.find((g) => g.id === quoteData.taxid);
        const taxRate = selectedTax ? selectedTax.per / 100 : 0;
        const taxName = selectedTax ? selectedTax.sname : '';
        const tax = quoteData.subtotal * taxRate;
        const tot = quoteData.subtotal + tax;
        console.log("taxName => ", taxName);

        setTaxName(taxName)
        setQuoteData((prevData) => ({
            ...prevData,
            qval,
            taxamt: tax,
            total: tot

        }));

    }, [quoteData.enqquotedet, quoteData.packchange, quoteData.freight, gst, setQuoteData, setTaxName]);


    const [type0, setType0] = useState([]);
    const [type1, setType1] = useState([]);
    const [sname, setSname] = useState([]);


    useEffect(() => {

        const filteredData0 = dataToUpdate1.filter(item => item.type === '0' && item.checked === true);

        setType0(filteredData0)
        const filteredData1 = dataToUpdate1.filter(item => item.type === '1' && item.checked === true);

        setType1(filteredData1)

        const filteredSnameData = filteredData0.map(filteredItem => {
            const matchedTerm = term.find(termItem => termItem.id === filteredItem.id); // Match by termId
            return matchedTerm ? matchedTerm.sname : null; // Return sname if found, else null
        }).filter(Boolean); // Filter out null values


        setSname(filteredSnameData);  // Store the filtered sname values

    }, [dataToUpdate1, term]);

    const [masterChecked, setMasterChecked] = useState(false);
    const [masterChecked1, setMasterChecked1] = useState(false);

    // Function to handle the main checkbox click
    const handleMasterCheckboxChange = (e) => {
        const checked = e.target.checked;
        setMasterChecked(checked);

        // Update all checkboxes
        term.forEach((item, index) => {
            if (item.type === "0") {
                handleTermChange({ target: { checked } }, index, 'checkbox', item.id);
            }
        });
    };

    const handleMasterCheckboxChange1 = (e) => {
        const checked = e.target.checked;
        setMasterChecked1(checked);

        // Update all checkboxes for items where type === "1"
        term.forEach((item, index) => {
            if (item.type === "1") {
                handleTermChange1(
                    { target: { checked } },  // Event object with the checked status
                    index,                    // Index of the current item
                    'checkbox',               // Input type
                    item.id,                  // ID of the current term
                    item.name                 // Name of the current term
                );
            }
        });
    };

    useEffect(() => {
        if (isTermFilter) {

            const updatedTerms = term.map(item => {
                const isChecked = formData.quote.some(quoteItem => quoteItem.id === item.id);
                return {
                    ...item,
                    checked: isChecked // Set checked: true if condition matches, otherwise false
                };
            });



            // Set the updatedTerms to enqquoteTerms in quoteData
            setQuoteData(prevData => ({
                ...prevData,
                enqquoteTerms: updatedTerms
            }));


            setIsTermFilter(false);
        }
    }, [formData, term]);


    const handleFaddrInputChange = (field, value) => {
        setFormData(prevData => ({
            ...prevData,
            enquiry: [
                {
                    ...prevData.enquiry[0],
                    [field]: value,
                },
                ...prevData.enquiry.slice(1),
            ],
        }));
    };

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        apiService.getDropdown("mas_state")
            .then(response => {

                setStates1(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });
    }, [])



    useEffect(() => {

        const nameToFind = formData?.enquiry[0].add4;
        const selectedOption = states1.find(option => option.name === nameToFind);
        const stateid1 = selectedOption ? selectedOption.id : null;

        // Fetch districts only if state IDs are available


        if (stateid1 !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData1 = response.data.filter(item => item.stateid === stateid1);
                    setDist1(filteredData1);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    }, [formData, states1]);

    const handleClickOpen = () => {
        setOpen(true)
    }


    return (
        <>   <Helmet>
            <title>Quotation</title>
        </Helmet>

        <Box ref={contentToPrint}>
                    <Box className="print-container viewquote">
                        <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={12} mb={-2}>
                                    <Grid container alignItems="center" spacing={1} direction="row">
                                        <Grid item>
                                            <img
                                                src={require('src/assets/logosaarc.png')}
                                                alt="Company Logo"
                                                style={{ width: 40, height: 40 }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: 'red',
                                                    fontSize: 23,
                                                    fontFamily: 'Arial, sans-serif',
                                                    display: 'inline',
                                                }}
                                            >
                                                {selectedCompany?.name?.split(' ')[0]}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    color: '#000',
                                                    fontSize: 22,
                                                    fontFamily: 'Arial, sans-serif',
                                                    display: 'inline',
                                                }}
                                            >
                                                &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={7} sm={7}>

                                    <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                                        <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: "10" }}>
                                            PE Molded Advanced CEMILAC certified Mil Std. Containers
                                        </Typography>
                                        <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                                            {selectedCompany?.add1}, {selectedCompany?.add2}
                                        </Typography>
                                        <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                                            {selectedCompany?.add3} - {selectedCompany?.add4}, {selectedCompany?.add5},  India {selectedCompany?.add6}.
                                        </Typography>
                                        <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                                            Phone: {selectedCompany?.mobile}
                                        </Typography>
                                        <Typography variant="body2" sx={{ fontFamily: 'Arial, sans-serif', fontSize: 10 }}>
                                            <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                                        </Typography>
                                        <Typography variant="body2" fontSize="85%" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                                            <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {/* <br /> */}
                                        <Grid item xs={12} sm={7} md={7} >
                                            <Box sx={{ p: 1, borderRadius: '5px' }}>
                                                <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                                                    To
                                                </Typography>
                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 12 }} variant="body2" paragraph>
                                                    <strong> {formData?.enquiry[0].name} </ strong ><br />
                                                    
                                                    {formData?.enquiry[0].add1} , {formData?.enquiry[0].add2} ,{formData?.enquiry[0].add3} , <br />
                                                    {formData?.enquiry[0].add4} - {formData?.enquiry[0].add5}.<br />
                                                    {formData?.enquiry[0].cmail}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Box>

                                </Grid>

                                <Grid item xs={5}>

                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                                        BUDGETARY QUOTATION
                                    </Typography>
                                    <Grid container direction="row" spacing={1} >

                                        <Grid item xs={12} sm={6} md={6} mt={2}>
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>SAARC Enquiry No:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} mt={2} >
                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData?.enqno}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Quotation No:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.qno}</Typography>
                                        </Grid><br />
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Quotation Date:</Typography></Grid>
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography variant="body2" sx={{ fontSize: 12 }}>
                                                {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                                            </Typography>


                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12, padding: '2px 0' }}>
                                                Quotation Validity:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} mt={0.4}>

                                            <Typography variant="body2" sx={{ fontSize: 12 }}>30 Days</Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer Ref:</Typography></Grid>
                                        <Grid item xs={12} sm={6} md={6} >

                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cusref}</Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer POC Name:</Typography></Grid>
                                        <Grid item xs={12} sm={6} md={6} >

                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cuspocname}</Typography>

                                        </Grid>

                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Designation:</Typography></Grid>
                                        <Grid item xs={12} sm={6} md={6} >

                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cusdesign}</Typography>

                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} >
                                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer POC No:</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>

                                            <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cuspocno}</Typography>

                                        </Grid>

                                    </Grid>
                                </Grid>



                                <Grid item xs={12}  >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            marginTop: '-6%',
                                            width: '100%',
                                            padding: isSmallScreen ? '0px' : '5px',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            paragraph
                                            fontFamily='Arial, sans-serif'
                                            color='black'
                                            sx={{
                                                width: '100%',
                                                padding: '10px',
                                                boxSizing: 'border-box',
                                                fontSize: 15
                                            }}
                                        >
                                            Dear Sir/Madam,
                                            <br />

                                            <div className="print-value" style={{
                                                width: '100%',
                                                maxWidth: '1130px',
                                                boxSizing: 'border-box',
                                                fontSize: 12
                                            }}>
                                                {quoteData.subject} {/* Display subject value in print mode */}
                                            </div>


                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <div
                                className="print-table"
                                style={{
                                    width: '100%',
                                    padding: isSmallScreen ? '0px' : '5px',
                                    boxSizing: 'border-box',
                                    overflowX: 'hidden',
                                    overflowY: 'hidden',
                                    marginTop: '-3.5%'
                                }}
                            >
                                <table style={{ tableLayout: 'fixed', width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sl. No.</th>
                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '30%' }}>Description</th>
                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>SAARC MPN No</th>
                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '24%' }}>Quantity</th>
                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Unit Price</th>

                                            <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Taxable Amount</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {quoteData.enqquotedet.map((item, index) => (
                                            <tr key={index} style={{ height: '30px' }}>
                                                <td align="center" style={{ fontSize: 11 }}>{index + 1}</td>
                                                <td align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 11 }}>
                                                    <div className="print-desp">{item.desp}</div>
                                                </td>

                                                <td align="center" style={{ fontSize: 11 }}>
                                                    <div className="print-mpnno" >{item.mpnno}</div>
                                                </td>
                                                <td align="center" style={{ fontSize: 11 }}>
                                                    <div className="print-qty">{item.qty}</div>
                                                </td>
                                                <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                    <div className="print-unitprice"style={{textAlign: 'right'}} >₹ {item.unitprice}</div>
                                                </td>
                                                <td align="center" style={{ fontSize: 11,textAlign: 'right'  }}>
                                                   <span style={{textAlign: 'right'}} >₹ {item.taxableamt.toFixed(2)}</span> 
                                                    </td>

                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={5} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>SUB-TOTAL AMOUNT:</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}><b>₹ {quoteData.qval.toFixed(2)}</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Packing Charges (from Basic Price)</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.packchangeper}{"%"}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                ₹ {quoteData.packchange.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Freight Charges (from Basic Price)</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.freightper}{"%"}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.freight.toFixed(2)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Documentation Charges (from Basic Price)</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.docchargeper}{"%"}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.doccharge.toFixed(2)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Design & Development Cost</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.designcostqty.toFixed(2)}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.designcostup.toFixed(2)}

                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.designcostamt.toFixed(2)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Testing Charges</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.testchargeqty.toFixed(2)}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.testchargeup.toFixed(2)}

                                            </td>
                                            <td align="center" style={{ fontSize: 11 ,textAlign: 'right'}}>

                                                ₹ {quoteData.testchargeamt.toFixed(2)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Dummmy Equipment Cost</td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>
                                                {quoteData.dummycostqty.toFixed(2)}
                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.dummycostup.toFixed(2)}

                                            </td>
                                            <td align="center" style={{ fontSize: 11 ,textAlign: 'right'}}>

                                                ₹ {quoteData.dummycostamt.toFixed(2)}

                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Mfg. of Jigs & Fixtures</td>
                                            <td align="center" style={{ fontSize: 11 ,textAlign: 'right'}}>
                                                {quoteData.jigsfixqty.toFixed(2)}
                                            </td>
                                            <td align="center" style={{ fontSize: 11 ,textAlign: 'right'}}>

                                                ₹ {quoteData.jigsfixup.toFixed(2)}

                                            </td>
                                            <td align="center" style={{ fontSize: 11,textAlign: 'right' }}>

                                                ₹ {quoteData.jigsfixamt.toFixed(2)}

                                            </td>
                                        </tr>

                                        <tr>
                                            <td colSpan={4} rowSpan={3} style={{ fontSize: 11 }}>
                                                <h4 >Note:</h4>
                                                <ul>
                                                    <li>Actual Deliverables based on mutual discussion</li>
                                                </ul>
                                            </td>
                                            <td style={{ fontSize: 11 }}>
                                              <b>TOTAL</b>  
                                            </td>
                                            <td align="right" style={{ fontSize: 11,textAlign: 'right' }}><b>₹ {quoteData.subtotal.toFixed(2)}</b></td>
                                        </tr>


                                        <tr>
                                            <td align="right" style={{ fontSize: 11 }}>
                                                {taxname}
                                            </td>
                                            <td align="right" style={{ fontSize: 11,textAlign: 'right' }}>₹ {quoteData.taxamt.toFixed(2)}</td>
                                        </tr>

                                        <tr>
                                            <td align="right" style={{ fontSize: 11 }}>
                                              <b>TOTAL</b>  
                                            </td>
                                            <td align="right" style={{ fontSize: 11,textAlign: 'right' }}><b>₹ {quoteData.total.toFixed(2)}</b></td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>


                        <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                                SPECIAL TERMS & CONDITIONS:
                            </Typography>

                        </Box>

                        <div
                            className='print-terms'
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'initial',
                                alignItems: 'flex-start',
                                textAlign: 'left',
                                position: 'relative',
                                left: '52px'
                            }}
                        >
                            {/* First Box: Displaying sname values */}
                            <div>
                            {quoteData.quote
                                    .filter((item) => item.type === '0') 
                                    .map((item, index) => (
                                        <div key={index} style={{ marginBottom: '1rem' }}>
                                            <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                                                <strong>{item?.sname}</strong> 
                                            </p>
                                        </div>
                                    ))}
                            </div>

                            {/* Second Box: Displaying name values where id > 0 and type === 0 */}
                            <div style={{ position: 'relative', left: '40px' }}>
                               
                                {quoteData.quote
                                    .filter((item) => item.type === '0') 
                                    .map((item, index) => (
                                        <div key={index} style={{ marginBottom: '1rem' }}>
                                            <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                                                : {item?.name}
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>


                        <Box
                            mt={2}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}
                                >
                                    <strong>STANDARD TERMS & CONDITIONS:</strong>
                                </Typography>


                            </Box>
                            <div>
                            {quoteData.quote
                                    .filter((item) => item.type === '1') 
                                    .map((item, index) => (
                                        <div key={index} style={{ marginBottom: '1rem' }}>
                                            <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                                               {index+1}. {item?.name}
                                            </p>
                                        </div>
                                    ))}
                            </div>

                            <br />
                            <Box
                                sx={{
                                    width: '250px',
                                    height: '45px',
                                    bgcolor: '#e0e0e0',
                                    p: 1,
                                    borderRadius: '5px',
                                }}
                            >
                                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                                    Queries to be Addressed to:
                                </Typography>
                            </Box>
                            <br />
                            <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                                Mr/s. V. Suganthy, Mob: 8807060127 | Email: marketing@saarctecs.com<br />
                                Mr/s. C. Srinivita, Mob: 8807060125 | Email: marketing@saarctecs.com<br />
                                Mr/M. Sridevi, Mob: 8807042319 | Email: applicationengg@saarctecs.com
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="45px"
                            >
                                <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                                    THANK YOU FOR YOUR BUSINESS SUPPORT
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Box>
                                    <Typography variant="caption"><strong>Prepared By</strong></Typography>
                                </Box>
                                <Box>
                                    <Typography variant="caption">Checked By</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box>
                    <Button sx={{ position: 'absolute', left: 10, bottom: 25, margin: 1 }} onClick={handleBack} variant="contained" color="primary" className="no-print" >
                        Back
                    </Button>

                    <Box display="flex" justifyContent="flex-end" p={2}>

                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ position: 'absolute', right: 40, bottom: 25, margin: 1 }}
                            className="no-print"
                            onClick={async () => {
                                // await handleSubmit(); // Save the quotation first

                                handlePrint1(null, () => contentToPrint.current); // Trigger the print after successful save
                            }}
                        >
                            Print
                        </Button>
                    </Box>
                </Box>

            </Box>

            <style>
  {`
    @media print {
      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        text-align: left !important;
        bottom: 0 !important;
        right: 0 !important;
        top: -5px !important;
        left: 0 !important;
        margin: 0 !important;
        padding: 0 8px !important;
        pointer-events: none !important;
        border-radius: inherit !important;
        border-style: solid !important;
        border-width: 0px !important;
        overflow: hidden !important;
        min-width: 0% !important;
        border-color: rgba(0, 0, 0, 0.23) !important;
      }

      .print-table {
        width: 100% !important;
        padding: 5px !important;
        box-sizing: border-box !important;
        font-size: 9pt !important;
        overflow: hidden !important; /* Ensure no scroll bars appear */
      }

      .no-print {
        display: none !important;
      }

      .print-desp,
      .print-mpnno,
      .print-qty,
      .print-unitPrice,
      .print-tax,
      .print-igst {
        font-size: 9pt !important;
      }

      @page {
        size: A4 !important;
        margin: 5.2mm !important;
      }
    }

    @media screen {
      .printable-content {
        display: none !important;
      }
    }
  `}
</style>


        </>
    );
};


export default ViewQuotation;
