import { Box, Button, CardContent, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import apiService from "src/services/api-service";
import SearchIcon from '@mui/icons-material/Search';
import { log } from "console";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { number } from "prop-types";
import React from "react";

interface Data {
    id: number;
    empid: number;
    ltypeid: number;
    reqdays: number;
    fdate: Date;
    todate: Date;
    fnanfull: string;
    remarks: string;
    attach: string;
    status: string;
    year: number;
    month: number;
    empno: number;
    name: string;
    deptname: string;
    designname: string;
    leavetype: string;
    img: string;
    maxleavereq: number;
}

interface LeaveType {
    id: number;
    name: string;
}

type FetchedErrors = {
    fdate?: string;
    todate?: string;
    // Add any other fields that could have fetched errors
};

const LeaveReuest: React.FC = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [leavetype, setLeaveType] = useState<LeaveType[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    const [data, setData] = useState<Data[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isftodatevalid, setIsftodatevalid] = useState(true);
    const [isDisabledForReqdays, setIsDisabledForReqdays] = React.useState(false);

    const [formData, setFormData] = useState<any>({
        id: 0,
        empid: localStorage.getItem('empid') || '',
        ltypeid: 0,
        reqdays: '',
        fdate: '',
        todate: '',
        fnanfull: '',
        remarks: '',
        attach: '',
        status: '',
        year: new Date().getFullYear(),
        month: 0,
        empnno: 0,
        name: '',
        deptname: '',
        designname: '',
        leavetype: '',
        img: '',
        maxleavereq: 0
    })

    useEffect(() => {
        getleavereqlist()
    }, [rowsPerPage, page, search])

    let getleavereqlist = () => {
        apiService.getleavereqlist(rowsPerPage.toString(), (page + 1).toString(), search, localStorage.getItem('empid')).then(response => {
            setData(response.data);
            console.log(response.data)
        }).catch((err) => {
            console.log("err", err);
        });
    }

    { console.log("statussssssssss", formData.status) }

    useEffect(() => {
        apiService.getDropdown("hrms_mas_leavetype").then(response => {
            const activeLeaveType = response.data.filter(item => item.isactive === 1);
            setLeaveType(activeLeaveType);
            console.log("leavetype>>>>>>>>>>>", activeLeaveType)
        }).catch((err) => {
            console.log("err", err)
        });
    }, [])

    const formatDateForInput = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`; // Format expected by type="date"
    };

    const getCurrentDate = () => {
        const date = new Date();
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleAddNewClick = () => {
        setFormData({
            id: 0,
            empid: localStorage.getItem('empid') || '',
            ltypeid: 0,
            reqdays: '',
            fdate: '',
            todate: '',
            fnanfull: '',
            remarks: '',
            attach: '',
            status: '',
            year: new Date().getFullYear(),
            month: 0,
            empnno: 0,
            name: '',
            deptname: '',
            designname: '',
            leavetype: '',
            img: '',
            maxleavereq: 0
        }); // Reset the form for new employee
        setIsFormVisible(true); // Show the form
    };

    const handleBackToList = () => {
        setIsFormVisible(false);
        setErrors(null);
        setIsftodatevalid(true);
        setErrorMessage(null);
    }

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => {
            const updatedFormData = { ...prevFormData, [name]: value };

            // Validate Leave Days
            if (name === "reqdays") {
                const reqDays = parseFloat(value) || 0;
                const balDays = parseFloat(prevFormData?.baldays) || 0;
                const maxleavereq = parseFloat(prevFormData?.maxleavereq) || 0;

                if (reqDays > balDays) {
                    setErrorMessage(`Leave Days cannot exceed Balance Days`);
                } else {
                    setErrorMessage(""); // Clear error message if valid
                }

                if (reqDays > maxleavereq) {
                    setErrorMessage(`Max allowed days per request is ${parseFloat(prevFormData?.maxleavereq)}`);
                } else {
                    setErrorMessage(""); // Clear error message if valid
                }
            }

            // Calculate To Date if reqdays or fdate is updated
            if (name === "reqdays" || name === "fdate") {
                const reqDays = parseFloat(updatedFormData.reqdays) || 0;
                const fromDate = updatedFormData.fdate ? new Date(updatedFormData.fdate) : null;

                // Only recalculate 'todate' if 'reqdays' and 'fdate' are both valid and updated
                if (fromDate && reqDays > 1) {
                    const calculatedDate = new Date(fromDate);
                    if (name === "reqdays") {
                        // Update 'todate' based on new 'reqdays'
                        console.log(reqDays, "reqDays")
                        if (formData.id === 0) {
                            calculatedDate.setDate(fromDate.getDate() + reqDays - 1);
                        }
                        else {
                            calculatedDate.setDate(fromDate.getDate() + reqDays);
                        }
                    } else {
                        // If only fdate is changed, calculate todate accordingly
                        calculatedDate.setDate(fromDate.getDate() + reqDays - 1); // Adjust based on fdate and reqdays
                    }
                    updatedFormData.todate = calculatedDate.toISOString().split("T")[0]; // Format to yyyy-mm-dd
                } else {
                    updatedFormData.todate = null; // Reset todate if invalid input or reqdays is 0
                }
            }
            return updatedFormData;

        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
            });
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "attach" && event.target.files) {
            const file = event.target.files[0];
            const formdata = new FormData();
            formdata.append('file', file);

            apiService.uploadFiles(formdata)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL

                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        attach: url,
                    }));
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    useEffect(() => {
        console.log(formData.empid, ">>>>>>>>>")
        if (formData.ltypeid && formData.empid && formData.year) {
            fetchBaldaysData();
        }
    }, [formData.ltypeid, formData.empid, formData.year]);

    const fetchBaldaysData = () => {
        apiService.getBalDays(formData.ltypeid, formData.empid, formData.year)
            .then(response => {
                const fetchedData = response.data;
                console.log("fetched leave data", fetchedData)
                if (fetchedData.length > 0) {
                    // Extract desp and attach from the first row
                    const { baldays,maxleavereq } = fetchedData[0];

                    // Update formData
                    const updatedFormData = {
                        ...formData, // Preserve existing values
                        baldays: baldays || 0,
                        maxleavereq: maxleavereq || 0
                    };
                    setFormData(updatedFormData);
                }
            }) 
            .catch(err => {
                console.log("Error fetching Baldays data:", err);
            });
    };

    useEffect(() => {
        console.log(formData.empid, ">>>>>>>>>")
        if (formData.fdate || formData.todate && formData.empid && formData.year) {
            fetchftodateData();
        }
    }, [formData.fdate, formData.todate, formData.empid, formData.year]);

    const fetchftodateData = () => {
        apiService.getftodate(formData.empid, formData.year, formData.fdate, formData.todate)
            .then(response => {
                const fetchedData = response.data;
                console.log("fetched from to date data", fetchedData)
                if (formData.id == 0) {
                    if (fetchedData.length > 0) {
                        setIsftodatevalid(false)
                    } else {
                        setIsftodatevalid(true)
                    }
                }
                else {
                    if (fetchedData.length > 1) {
                        setIsftodatevalid(false)
                    } else {
                        setIsftodatevalid(true)
                    }
                }

            })
            .catch(err => {
                console.log("Error fetching From To date:", err);
            });
    };

    const handleEditClick = (formData) => {
        setFormData(formData);
        setErrors(prevErrors => ({ ...prevErrors, fdate: "" }));
        setIsFormVisible(true); // Show the form

    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm(isInsert)) {
            console.log('Form is invalid. Please fill all required fields.');
            return;
        }

        console.log("Save button clicked");
        try {
            const dataToSubmit = {
                ...formData,
                reqdays: formData.reqdays ? parseFloat(formData.reqdays) : 0, // Convert to float, or default to 0
            };
            console.log("dataToSubmit", dataToSubmit);
            const response = await apiService.postleaverequest(dataToSubmit);
            getleavereqlist()
            toast.success("Leave Requested Successfully");
            setIsFormVisible(false);
        } catch (error: any) {
            // Log different types of errors
            if (error.response) {
                console.error("Error response:", error.response);
                console.error("Error data:", error.response.data);
                console.error("Error status:", error.response.status);
                console.error("Error headers:", error.response.headers);
            } else if (error.request) {
                console.error("Error request:", error.request);
            } else {
                console.error("Error message:", error.message);
            }
            console.error("Error config:", error.config);
        }
    };

    const calculateToDate = (fdate, reqdays) => {
        if (!fdate || !reqdays) return "";
        const fromDate = new Date(fdate); // Convert fdate to a Date object
        const daysToAdd = parseInt(reqdays, 10); // Ensure reqdays is a number
        fromDate.setDate(fromDate.getDate() + daysToAdd - 1); // Add days to the date
        return fromDate.toISOString().split("T")[0]; // Format as yyyy-mm-dd
    };

    const isInsert = !formData?.id;
    const validateForm = (isInsert: boolean) => {
        const requiredFields = [
            'ltypeid', 'reqdays', 'fdate', 'remarks'
        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};

        requiredFields.forEach((field) => {
            // Only validate all required fields during insert, or validate only if the field is being updated
            if (isInsert || formData[field] !== undefined) {
                console.log(field, formData[field], "validate");
                if (field === "isactive") {
                    if (formData[field] === '') {
                        newErrors[field] = `${field} is isactive`;
                        valid = false;
                    }
                }
                else {
                    if (!formData[field]) {
                        newErrors[field] = `${field} is required`;
                        valid = false;
                    }
                }

            }
        });
        setErrors(newErrors);
        return valid;
    }

    //Sort
    const [sortConfig, setSortConfig] = useState<{
        primaryKey: keyof Data | null;
        secondaryKey: keyof Data | null;
        direction: "asc" | "desc";
    }>({ primaryKey: null, secondaryKey: null, direction: "asc" });

    const sortedData = [...data].sort((a, b) => {
        if (!sortConfig.primaryKey) return 0;

        const primaryA = a[sortConfig.primaryKey];
        const primaryB = b[sortConfig.primaryKey];

        if (primaryA < primaryB) return sortConfig.direction === "asc" ? -1 : 1;
        if (primaryA > primaryB) return sortConfig.direction === "asc" ? 1 : -1;

        // If primary keys are equal, sort by the secondary key
        if (sortConfig.secondaryKey) {
            const secondaryA = a[sortConfig.secondaryKey];
            const secondaryB = b[sortConfig.secondaryKey];

            if (secondaryA < secondaryB) return sortConfig.direction === "asc" ? -1 : 1;
            if (secondaryA > secondaryB) return sortConfig.direction === "asc" ? 1 : -1;
        }

        return 0;
    });

    const handleSort = (primaryKey: keyof Data, secondaryKey: keyof Data | null = null) => {
        setSortConfig((prevConfig) => ({
            primaryKey,
            secondaryKey,
            direction:
                prevConfig.primaryKey === primaryKey && prevConfig.direction === "asc"
                    ? "desc"
                    : "asc",
        }));
    };

    const renderSortIcon = (key: keyof Data) => {
        if (sortConfig.primaryKey !== key) return null;
        return sortConfig.direction === "asc" ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />;
    };

    const isDisabled = formData.status === "HR Pending" || formData.status === "HOD Rejected" || formData.status === "HR Rejected" || formData.status === "HR Approved";

    return (
        <>
            <Helmet>
                <title>Leave or OD Request</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Leave or OD Request"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Dialog open={isFormVisible} onClose={handleBackToList} fullWidth maxWidth="sm">
                    <DialogTitle>Leave/OD Request</DialogTitle>
                    <DialogContent>
                        <form>
                            <Grid container spacing={2} style={{ marginTop: '-7px' }}>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        id="ltypeid"
                                        name="ltypeid"
                                        label="Leave Type"
                                        value={formData?.ltypeid}
                                        // onChange={(event) => {
                                        //     const selectedId = parseInt(event.target.value, 10); // Convert string to number
                                        //     const selectedLeaveType = leavetype.find((option) => option.id === selectedId);

                                        //     setFormData((prevData) => ({
                                        //         ...prevData,
                                        //         ltypeid: selectedId,
                                        //         reqdays: selectedLeaveType?.name === "Casual Leave" ? 1 : prevData.reqdays, // Set reqdays to 1 if Casual Leave is selected
                                        //     }));

                                        //     setIsDisabledForReqdays(selectedLeaveType?.name === "Casual Leave"); // Disable reqdays if Casual Leave is selected
                                        // }}
                                         onChange={handleChange}
                                        fullWidth
                                        error={!!errors?.ltypeid} // Highlight the field if there's an error
                                        helperText={errors?.ltypeid ? "Leave Type is required" : ""}
                                        disabled={isDisabled}
                                    >
                                        {leavetype.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="baldays"
                                        name="baldays"
                                        label="Balance Days"
                                        value={formData?.baldays}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="reqdays"
                                        name="reqdays"
                                        label="Leave Days"
                                        value={formData?.reqdays || ""}
                                        onChange={handleChange}
                                        fullWidth
                                        onInput={(e) => {
                                            const input = e.target as HTMLInputElement;
                                            input.value = input.value.replace(/[^0-9.]/g, '') // Remove non-numeric and non-period characters
                                                .replace(/(\..*?)\..*/g, '$1') // Prevent more than one decimal point
                                                .replace(/(\.\d).*/g, '$1'); // Allow only one digit after the decimal point
                                        }}
                                        error={!!errors?.reqdays || !!errorMessage} // Highlight the field if there's an error
                                        helperText={errors?.reqdays ? "Leave Days is/are required" : errorMessage || ""}
                                        disabled={isDisabled || isDisabledForReqdays}
                                    />
                                </Grid>
                                {console.log("REQUIRE DAYS>>>", formData.reqdays)}

                                <Grid item xs={12}>
                                    <TextField
                                        type="date"
                                        id="fdate"
                                        name="fdate"
                                        value={formData?.fdate ? formatDateForInput(formData.fdate) : ""}
                                        label="From Date"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!isftodatevalid || !!errors?.fdate}
                                        helperText={!isftodatevalid ? "Leave Request already exits on this date" : errors?.fdate ? "From Date is required" : ""}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                {console.log("todate>>>>>>>>", formData.todate)}
                                {formData.reqdays > 1 && (
                                    <Grid item xs={12}>
                                        <TextField
                                            type="date"
                                            id="todate"
                                            name="todate"
                                            value={formData?.todate ? formatDateForInput(formData.todate) : ""}
                                            label="To Date"
                                            InputLabelProps={{ shrink: true }}
                                            onChange={handleChange}
                                            fullWidth
                                            disabled
                                        // error={!isftodatevalid}
                                        // helperText={!isftodatevalid ? 'Leave Request already exits on this date' : ""}
                                        />
                                    </Grid>
                                )}

                                {formData.reqdays < 1 && formData.reqdays > 0 && (
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1">Shift</Typography>
                                            <RadioGroup
                                                id="fnanfull"
                                                name="fnanfull"
                                                value={formData?.fnanfull || ""}
                                                onChange={handleChange}
                                                row
                                            >
                                                <FormControlLabel name="fnanfull" value="FN" control={<Radio />} label="FN" />
                                                <FormControlLabel name="fnanfull" value="AN" control={<Radio />} label="AN" />
                                            </RadioGroup>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12}>
                                    <hr style={{ border: '1px solid #ccc', width: '100%' }} />
                                </Grid>
                                {/* Remarks and File Upload */}
                                <Grid item xs={12}>
                                    <TextField
                                        id="remarks"
                                        name="remarks"
                                        label="Reason"
                                        value={formData?.remarks || ""}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        error={!!errors?.remarks} // Highlight the field if there's an error
                                        helperText={errors?.remarks ? "Reason is required" : ""}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Attach File
                                    </Typography>
                                    <input
                                        type="file"
                                        name="attach"
                                        id="attach"
                                        onChange={handleFileChange}
                                        style={{ display: 'block', marginBottom: '10px' }}
                                    />
                                    {formData.attach && (
                                        <Typography variant="body2">
                                            <a href={formData.attach} target="_blank" rel="noopener noreferrer">
                                                View File
                                            </a>
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent="flex-end" style={{ marginTop: 25 }}>
                                <DialogActions>
                                    <Button onClick={handleBackToList} color="secondary">
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} variant="contained" color="primary" disabled={!!errorMessage || !isftodatevalid}>
                                        Save
                                    </Button>
                                </DialogActions>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center', // Align vertically in case of height differences
                        gap: 2, // Add space between TextField and Button
                        marginTop: '-15px', // Retain your margin adjustments
                        marginBottom: '5px',
                    }}
                >
                    <TextField
                        id="search"
                        name="search"
                        value={search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                        margin="dense"
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: 'auto' }}
                    />
                    <Button variant="contained" color="primary" onClick={handleAddNewClick}>
                        + Leave/OD Request
                    </Button>
                </Box>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell> <IconButton onClick={() => handleSort("id")} size="small">
                                        S.No {renderSortIcon("id")}
                                    </IconButton></TableCell>
                                    <TableCell> <IconButton onClick={() => handleSort("empno", "name")} size="small">
                                        Employee {renderSortIcon("empno")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("deptname")} size="small">
                                        Department {renderSortIcon("deptname")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("leavetype")} size="small">
                                        Leave Type {renderSortIcon("leavetype")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("reqdays")} size="small">
                                        Leave Days {renderSortIcon("reqdays")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("fdate")} size="small">
                                        From Date {renderSortIcon("fdate")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("todate")} size="small">
                                        To Date{renderSortIcon("todate")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("remarks")} size="small">
                                        Reason {renderSortIcon("remarks")}
                                    </IconButton></TableCell>
                                    <TableCell><IconButton onClick={() => handleSort("status")} size="small">
                                        Status {renderSortIcon("status")}
                                    </IconButton></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.slice().map((row, index) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                            <TableCell>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <div style={{ marginRight: "10px" }}>
                                                        <img
                                                            src={row.img || 'https://image.shutterstock.com/image-vector/vector-flat-illustration-avatar-user-260nw-2503308093.jpg'}
                                                            alt="Employee"
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                                objectFit: 'cover',
                                                                borderRadius: '50%',
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex", alignItems: "center" }}>
                                                            <b
                                                                style={{ color: "#0017c3", cursor: "pointer" }}
                                                                onClick={() => handleEditClick(row)}
                                                            >
                                                                {row.name} ({row.empno})
                                                            </b>
                                                        </div>
                                                        <div>
                                                            <p>{row.designname}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{row.deptname}</TableCell>
                                            <TableCell>{row.leavetype}</TableCell>
                                            <TableCell>{row.reqdays}</TableCell>
                                            <TableCell>{formatDateForInput(row.fdate)}</TableCell>
                                            <TableCell style={{ textAlign: 'center' }}>{row.todate ? formatDateForInput(row.todate) : "-"}</TableCell>
                                            <TableCell>{row.remarks}</TableCell>
                                            <TableCell>{row.status}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <TablePagination
                    component="div"
                    count={data.length === 0 ? 0 : data[0]["count"]}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 100, 200, 500]}
                />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container >
            <Footer />
        </>
    )
}

export default LeaveReuest;
