import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';


import {
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    TextField,
    Button,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    IconButton,
    MenuItem,
    DialogActions,
    Dialog,
    DialogContent,
    DialogTitle,
    TablePagination,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';
import { useState, SyntheticEvent, useEffect, ChangeEvent, FormEvent, useRef, memo , useMemo} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPrint, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import { number, string } from 'prop-types';
import apiService from 'src/services/api-service';
import axios from 'axios';
import { Attachment, Login } from '@mui/icons-material';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { NumberFormat } from 'xlsx';
import { toast, ToastContainer } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface Data {
    tableRows: [
        {
            desp: string,
            qty: number,
            idenqdet: number,
            essentialTableRows: [
                {
                    essid: number,
                    requirements: string,
                    essdatid: number,
                    compliance: string,
                    attach: File
                }
            ],
            multiattach: [
                {
                    mid: number | null,
                    enqdetid: number,
                    filename: string,
                    url: string
                }
            ],
        }
    ],
    updcheck: boolean;
    finYearId: number;
    lenessrow: number;
    empId: number;
    enqID: number;
    typeID: number;
    newEx: string;
    modeId: number;
    divId: number;
    rfxEnqNo: string;
    dueOn: Date;
    statusId: string;
    desp: string;
    basicValue: number;
    ccperson: string;
    cdesign: string;
    cmobile: string;
    cmail: string;
    tcperson: string;
    tdesign: string;
    tmobile: string;
    tmail: string;
    bankName: string;
    branchname: string;
    accNo: string;
    ifsc: string;
    isExcus: boolean;
    excusid: number;
    sname: string;
    code: string;
    name: string;
    city: string;
    ceo: string;
    md: string;
    mp: string;
    caddr1: string;
    caddr2: string;
    caddr3: string;
    caddr4: string;
    caddr5: string;
    caddr6: string;
    caddr7: string;
    faddr1: string;
    faddr2: string;
    faddr3: string;
    faddr4: string;
    faddr5: string;
    faddr6: string;
    faddr7: string;
    panno: string;
    tinno: number;
    email: string;
    // omobile: string;
    pmobile: string;
    gstno: string;
    // nbus: string;
    // ncomp: string;
    // datestart: Date;
    // cpname: string;
    // cpdesign: string;
    // cpmobile: string;
    // cpmail: string;
    // annualturnover: string;
    // buildOR: string;
    followtype: number;
    followdesc: string;
    isqul: boolean;
    nextfollow: Date;
    status: string;
    priority: string;
    // nfteamid: string;
    // nfempid: string;
    attach: string;
    divname: string;

}

interface Type {
    id: number;
    name: string;
}
interface Mode {
    id: number;
    name: string;
}
interface Division {
    id: number;
    name: string;
}
interface City {
    id: number;
    sname: string;
    name: string;
}

interface Dist {
    id: number;
    name: string;
    stateid: number;
}

interface State {
    id: number;
    name: string;
}
interface Status {
    id: number;
    name: string;
    islead: number;
}
interface Followtype {
    id: number;
    name: string;
}
interface EssDta {
    id: number;
    name: string;
    compliance: String,
    isattach: 1;
    reqtypeid: number;
}

interface RowData {
    id: number;
    code: string;
    name: string;
    place: string;
    mobile: string;
    mail: string;
    contactPerson: string;
    isMajor: boolean;
}
interface FollowDataItem {

    id: number;
    fdate: Date; // Change from string to Date
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: Date; // Change from string to Date
    statusid: number;
    ftypename: string;
    fbyname: string;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EngineeringChangeRequest {
    enqid: number;
    enqdetid: number;
    ecrno: number;
    date: string;
    casemodel: string;
    changedet: string;
    reasonchange: string;
    spltestcon: string;
    authempid: number;
    status: string;
    cby: string;
}

interface EnqQuote {
    id: number;
    qdate: Date;          // Date object for date values
    qdate_s: string;      // String representation of date, if needed
    qno: string;          // Quote number
    rno: number;          // Reference number
    qval: number;         // Quote value
    cusref: string;       // Customer reference
    cuspocname: string;  // Customer Point of Contact name
    cuspocno: string;    // Customer Point of Contact number
    cusdesign: string;   // Customer design
    subject: string;     // Subject of the quote
}

interface TestAccess {

    testaccessid: number;
    remarks: string;
}

interface EssentialTableRow {
    essid: number;
    enqdetid: number;
    essdatid: number;
    compliance: string;
    attach: string;
}

interface TableRows {
    id: number;
    idenqdet: number;
    desp: string;
    mpnno: string | null;
    qty: number;
    changedet: string;
    reasonchange: string;
    spltestcon: string;
    casemodel: string;
    essentialTableRows: EssentialTableRow[];
    //enqecr: EngineeringChangeRequest[]; // Adjust type based on your actual data structure
    enqecr: [];
}

interface EnqFollow {
    id: number;
    fdate: string;
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: string;
    statusid: number;
    ftypename: string;
    fbyname: string | null;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;
}


interface Enquiry {
    tableRows: TableRows[];
    enqfollow: EnqFollow[];
    enqquote: EnqQuote[]; // Adjust type based on your actual data structure
    testaccess: TestAccess[];
    enqNo: string;
    enqdate: string;
    updcheck: boolean;
    finYearId: number;
    lenessrow: null; // Check the type for lenessrow if needed
    empId: number;
    enqID: number;
    typeID: number;
    newEx: string;
    modeId: number;
    divId: number;
    rfxEnqNo: string;
    dueOn: string; // Can use Date type if needed
    statusId: number;
    desp: string | null;
    basicValue: number;
    ccperson: string;
    cdesign: string;
    cmobile: string;
    cmail: string;
    tcperson: string;
    tdesign: string;
    tmobile: string;
    tmail: string;
    bankName: string;
    branchname: string;
    accNo: string;
    ifsc: string;
    isExcus: boolean;
    excusid: number;
    sname: string;
    code: string;
    name: string;
    city: number;
    ceo: string;
    md: string | null;
    mp: string | null;
    caddr1: string;
    caddr2: string;
    caddr3: string;
    caddr4: string | null;
    caddr5: string | null;
    caddr6: string | null;
    caddr7: string | null;
    faddr1: string;
    faddr2: string;
    faddr3: string;
    faddr4: string;
    faddr5: string;
    faddr6: string;
    faddr7: string;
    panno: string;
    tinno: string;
    email: string;
    omobile: string | null;
    pmobile: string;
    gstno: string;
    nbus: string | null;
    ncomp: string | null;
    datestart: string | null; // Can be Date type if needed
    cpname: string | null;
    cpdesign: string | null;
    cpmobile: string | null;
    cpmail: string | null;
    annualturnover: number | null;
    buildOR: string | null;
    followtype: string | null;
    followdesc: string | null;
    isqul: string | null;
    nextfollow: string | null; // Can be Date type if needed
    priority: string | null;
    nfteamid: number | null;
    nfempid: number | null;
    attch: string | null;
    divname: string;
}

interface DrawingCategory {
    id: number;
    name: string;
    isactive: number;
}
interface TEST {
    id: number;
    name: string;
    type: string;
    reqtypeid: number;
    isactive: number;
}

interface FormData {
    enquiry: Enquiry[];

}
interface Follow {
    id: number;
    fdate: string;
    ftypeid: number;
    priority: string;
    fby: number;
    desp: string;
    nfdate: string;
    statusid: number;
    ftypename: string;
    fbyname: string | null;
    statusname: string;
    fdate_s: string;
    nfdate_s: string;
}
interface DropdownData {
    name: string;
    id: number;
}

interface LocationState {
    message?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

interface LocationState1 {
    id: number;
    detid: number;
    iid: number;
}

interface Priority {
    id: number;
    name: string;
    isactive: number;
}
interface ReqType {
    id: number;
    name: string;
}
interface Type {
    id: number;
    name: string;
  }

const UpdateReqClarify: React.FC = () => {

    const location = useLocation();
    const state = location.state as LocationState1;



    // const id = state?.id ? Number(state.id) : undefined;
    const [value, setValue] = useState(2);
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [customerType, setCustomerType] = useState<string>('');
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [data, setData] = useState<Data[]>([]);
    const [type, setType] = useState<Type[]>([]);
    const [mode, setMode] = useState<Mode[]>([]);
    const [city, setCity] = useState<City[]>([]);
    const [dist, setDist] = useState<Dist[]>([]);
    const [dist1, setDist1] = useState<Dist[]>([]);

    const [states1, setStates1] = useState<State[]>([]);
    const [states, setStates] = useState<State[]>([]);
    const [status, setStatus] = useState<Status[]>([]);
    const [follow, setFollow] = useState<Followtype[]>([]);
    const [division, setDivision] = useState<Division[]>([]);
    const [rowdata, setRowdata] = useState<RowData[]>([]);
    const [essdata, setEssdata] = useState<EssDta[]>([]);
    // const [essDataValue, setEssDataValue] = useState<EssDta[]>([]);
    const [followData, setFollowData] = useState<FollowData>([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRowdet, setSelectedRowdet] = useState(null);
    // const [data, setData] = useState<RowData[]>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [essDataValue, setEssDataValue] = useState<DropdownData[]>([]);
    const [isPMobileValid, setIsPMobileValid] = useState(true);
    // const [isCMobileValid, setIsCMobileValid] = useState(true);
    const [isTMobileValid, setIsTMobileValid] = useState(true);
    const [isCAMobileValid, setIsCAMobileValid] = useState(true);
    const [isFAMobileValid, setIsFAMobileValid] = useState(true);
    const [priority, setPriority] = useState<Priority[]>([]);
    // const [isEmailValid, setIsEmailValid] = useState(true);
    const [isCEmailValid, setIsCEmailValid] = useState(true);
    const [isTEmailValid, setIsTEmailValid] = useState(true);
    const [isCAEmailValid, setIsCAEmailValid] = useState(true);
    const [isFAEmailValid, setIsFAEmailValid] = useState(true);
    const [quote, setQuote] = useState([]);
    const [reqType, setReqtype] = useState<ReqType[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTab1, setSelectedTab1] = useState(0);
    const [drawing, setDrawing] = useState<DrawingCategory[]>([]);
    const [drawingPurpose, setDrawingPurpose] = useState<DrawingCategory[]>([]);
    const [test, setTest] = useState<TEST[]>([]);
    const [type1, setType1] = useState<Type[]>([]);
    const [formData, setFormData] = useState<any>({
        enquiry: [
            {
                tableRows: [
                    {
                        id: 0,
                        idenqdet: 0,
                        desp: "",
                        mpnno: '',
                        qty: 0,
                        changedet: '',
                        reasonchange: '',
                        spltestcon: '',
                        casemodel: '',
                        eqipposition: "",
                        printcolor: "",
                        asreqfoam: "",
                        foam: "",
                        inputno: "",
                        drawcatgid: 1,
                        drawreqon: "",
                        reqdesp:"",
                        essentialTableRows: [
                            {
                                essid: 0,
                                enqdetid: 0,
                                essdatid: 0,
                                compliance: "",
                                attach: "",
                                remarks: ""
                            }

                        ],
                        multiattach: [
                            {
                                mid: null,
                                enqdetid: 0,
                                filename: "",
                                url: ""
                            }
                        ],
                        enqecr: []
                    },

                ],
                enqfollow: [
                    {
                        id: 0,
                        fdate: "",
                        ftypeid: 0,
                        priority: "",
                        fby: 0,
                        desp: "",
                        nfdate: "",
                        statusid: 0,
                        ftypename: "",
                        fbyname: '',
                        statusname: "",
                        fdate_s: "",
                        nfdate_s: ""
                    }
                ],
                enqquote: [],
                testaccess: [
                    {
                        testaccessid: 0,
                        remarks: ""
                    }
                ],
                enqNo: "",
                enqdate: "",
                updcheck: false,
                finYearId: 1,
                lenessrow: null,
                empId: localStorage.getItem('empid'),
                enqID: 0,
                typeID: 0,
                newEx: "",
                modeId: 0,
                divId: 0,
                rfxEnqNo: "",
                dueOn: "",
                statusId: 0,
                desp: '',
                basicValue: 0,
                ccperson: "",
                cdesign: "",
                cmobile: "",
                cmail: "",
                tcperson: "",
                tdesign: "",
                tmobile: "",
                tmail: "",
                bankName: "",
                branchname: "",
                accNo: "",
                ifsc: "",
                excusid: 0,
                sname: "",
                code: "",
                name: "",
                city: 0,
                ceo: "",
                md: '',
                mp: '',
                caddr1: "",
                caddr2: "",
                caddr3: "",
                caddr4: '',
                caddr5: '',
                caddr6: '',
                caddr7: '',
                faddr1: "",
                faddr2: "",
                faddr3: "",
                faddr4: "",
                faddr5: "",
                faddr6: "",
                faddr7: "",
                panno: "",
                tinno: "",
                email: "",
                omobile: '',
                pmobile: "",
                gstno: "",
                nbus: '',
                ncomp: '',
                datestart: '',
                cpname: '',
                cpdesign: '',
                cpmobile: '',
                cpmail: '',
                annualturnover: '',
                buildOR: '',
                followtype: '',
                followdesc: '',
                isqul: '',
                nextfollow: '',
                priority: '',
                nfteamid: '',
                nfempid: '',
                // attch: '',
                divname: "",
                reqtypeid: null,
                ipno: '',
                ipdate: null,
                drawcatgid: 0,
                purposeid: 0,
                drawreqdate: null,
                proname: '',
                eqptsize: '',
                eqptwt: '',
                noofeqpt: '',
                splacc: '',
                teststd: '',
                eqpttype: '',
                eqptsen: '',
                app: '',
                splnote: '',
                reqdesp: ''
            }
        ],
        follow: [
            {
                id: 0,
                fdate: "",
                ftypeid: 0,
                priority: "",
                fby: 0,
                desp: "",
                nfdate: "",
                statusid: 0,
                ftypename: "",
                fbyname: '',
                statusname: "",
                fdate_s: "",
                nfdate_s: ""
            }
        ],
        enqdet: null,
        enqdet_essdata: null,
        quote: [],
        quotedet: []
    });


    const [enquiry, setEnquiry] = useState({
        tableRows: [
            {
                id: 0,
                idenqdet: 0,
                desp: " ",
                mpnno: " ",
                qty: 0,
                changedet: " ",
                reasonchange: " ",
                spltestcon: " ",
                casemodel: " ",
                eqipposition: "",
                printcolor: "",
                asreqfoam: "",
                foam: "",
                inputno: "",
                drawcatgid: 1,
                drawreqon: "",
                essentialTableRows: [
                    {
                        essid: 0,
                        enqdetid: 0,
                        essdatid: 0,
                        compliance: " ",
                        attach: " "
                    }
                ],
                multiattach: [
                    {
                        mid: null,
                        enqdetid: 0,
                        filename: "",
                        url: ""
                    }
                ],
                enqecr: [
                    {
                        enqid: 0,
                        enqdetid: 0,
                        ecrno: " ",
                        date: " ",
                        casemodel: " ",
                        changedet: " ",
                        reasonchange: " ",
                        spltestcon: " ",
                        authempid: " ",
                        status: " ",
                        cby: " "
                    }
                ]
            }
        ],
        enqfollow: [
            {
                id: 0,
                fdate: " ",
                ftypeid: 0,
                priority: " ",
                fby: 0,
                desp: " ",
                nfdate: " ",
                statusid: 0,
                ftypename: " ",
                fbyname: " ",
                statusname: " ",
                fdate_s: " ",
                nfdate_s: " "
            }
        ],
        enqquote: [
            {
                id: 0,
                qdate: " ",
                qdate_s: " ",
                qno: " ",
                rno: " ",
                qval: " ",
                cusref: " ",
                cuspocname: " ",
                cuspocno: " ",
                cusdesign: " ",
                subject: " "
            }
        ],
        testaccess: [
            {
                testaccessid: 0,
                remarks: ""
            }
        ],
        enqNo: " ",
        enqdate: " ",
        updcheck: true,
        finYearId: 0,
        lenessrow: 0,
        empId: localStorage.getItem('empid'),
        enqID: 0,
        typeID: 0,
        newEx: " ",
        modeId: 0,
        divId: 0,
        rfxEnqNo: " ",
        dueOn: "",
        statusId: 0,
        desp: " ",
        basicValue: 0,
        ccperson: " ",
        cdesign: " ",
        cmobile: " ",
        cmail: " ",
        tcperson: " ",
        tdesign: " ",
        tmobile: " ",
        tmail: " ",
        bankName: " ",
        branchname: " ",
        accNo: " ",
        ifsc: " ",
        isExcus: true,
        excusid: 0,
        sname: " ",
        code: " ",
        name: " ",
        city: 0,
        ceo: " ",
        md: " ",
        mp: " ",
        caddr1: " ",
        caddr2: " ",
        caddr3: " ",
        caddr4: " ",
        caddr5: " ",
        caddr6: " ",
        caddr7: " ",
        faddr1: " ",
        faddr2: " ",
        faddr3: " ",
        faddr4: " ",
        faddr5: " ",
        faddr6: " ",
        faddr7: " ",
        panno: " ",
        tinno: " ",
        email: " ",
        omobile: " ",
        pmobile: " ",
        gstno: " ",
        nbus: " ",
        ncomp: " ",
        datestart: " ",
        cpname: " ",
        cpdesign: " ",
        cpmobile: " ",
        cpmail: " ",
        annualturnover: " ",
        buildOR: " ",
        followtype: 0,
        followdesc: " ",
        isqul: true,
        nextfollow: "",
        priority: " ",
        nfteamid: " ",
        nfempid: " ",
        //  attch: "",
        divname: " ",
        reqtypeid: null,
        ipno: '',
        ipdate: null,
        drawcatgid: 0,
        purposeid: 0,
        drawreqdate: null,
        proname: '',
        eqptsize: '',
        eqptwt: '',
        noofeqpt: '',
        splacc: '',
        teststd: '',
        eqpttype: '',
        eqptsen: '',
        app: '',
        splnote: ''
    });

    const [currentIndex, setCurrentIndex] = useState<number>(0);


    useEffect(() => {
      
        if (state.id) {

            apiService.getreqId(Number(state.id), Number(state.detid), Number(state.iid))
                .then(response => {
                    const data = response.data;

                    // Update the formData state with the fetched data
                    setFormData(data as FormData);

                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });
        }
    }, [state.id]);



    useEffect(() => {
      
        if (state.id) {
            // Fetch data if id is provided (for updating an enquiry)
            apiService.quotelist(Number(state.id))
                .then(response => {
                    const data = response.data;
                    setQuote(data);
                   
                })
                .catch(error => {
                    console.error("Error fetching data: ", error);
                });

        }
    }, [state.id]);

    useEffect(() => {
        apiService.getDropdown("crm_mas_essdata").then(response => {
            setEssdata(response.data);

            setEssDataValue(response.data?.map((data) => {
                return {
                    requirements: data?.name,
                    essdatid: data?.id,
                    compliance: ''
                }
            }))
        }).catch((err) => {
            console.log("err", err)
        });
    }, []);


    useEffect(() => {


        apiService.getDropdown("crm_mas_type").then(response => {
            setType(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_mode").then(response => {
            setMode(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_division").then(response => {
            setDivision(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_status").then(response => {
            setStatus(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_followtype").then(response => {
            setFollow(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_priority").then(response => {
            setPriority(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_dsncatg").then(response => {
            setDrawing(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_purpose").then(response => {
            setDrawingPurpose(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("dsn_mas_testaccess").then(response => {
            setTest(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_reqtype").then(response => {
            setReqtype(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("crm_mas_essdata").then(response => {
            setEssdata(response.data);
            setEssDataValue(response.data?.map((data) => {
                return {
                    requirements: data?.name,
                    essdatid: data?.id,
                    compliance: ''
                }
            }))
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_city").then(response => {
            setCity(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
        apiService.getDropdown("mas_state")
            .then(response => {
                setStates(response.data);
                setStates1(response.data);
            })
            .catch(err => {
                console.log("err", err);
            });

    }, [formData, page, rowsPerPage]);



    const handleQuotation = (id: number) => {
        console.log("id", id);

        navigate('/crm/quotation', { state: { id } });

    }



    const handleEssential = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }



    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const validateForm = () => {
        let typename = type.find(x => x.id == formData.enquiry[0].typeID)?.name;
        const requiredFields = [
            'typeID','gstno', 'panno', 'email', 'pmobile',
            'ccperson', 'cdesign', 'cmobile', 'cmail', 'tcperson', 'tdesign', 'tmobile', 'tmail',

        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};

        // requiredFields.forEach((field) => {
        //     if (!formData.enquiry[0][field]) {
        //         newErrors[field] = `${field} is required`;
        //         valid = false;
        //     }
        // });

        requiredFields.forEach((field) => {
            if(typename == "DRDO" && (field == "gstno" || field == "panno")){
      
            }
            else{
                if (!formData.enquiry[0][field]) {
                    newErrors[field] = `${field} is required`;
                    valid = false;
                }
            }
           
          });




        // Email specific validation
        if (formData.pmobile && !isCMobileValid(formData.enquiry[0].pmobile)) {
            newErrors.pmobile = 'Please enter valid Mobile Number';
            valid = false;
        }
        if (formData.email && !isEmailValid(formData.enquiry[0].email)) {
            newErrors.email = 'Invalid email id';
            valid = false;
        }
        if (formData.cmobile && !isCMobileValid(formData.enquiry[0].cmobile)) {
            newErrors.cmobile = 'Please enter valid Mobile Number';
            valid = false;
        }
        if (formData.cmail && !isEmailValid(formData.enquiry[0].cmail)) {
            newErrors.cmail = 'Invalid email id';
            valid = false;
        }
        if (formData.tmobile && !isCMobileValid(formData.enquiry[0].tmobile)) {
            newErrors.tmobile = 'Invalid mobile number';
            valid = false;
        }
        if (formData.tmail && !isEmailValid(formData.enquiry[0].cmail)) {
            newErrors.cmail = 'Invalid email id';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };
    const validateForm1 = () => {
        const requiredFields = [
            'statusId', 'nextfollow', 'followdesc', 'followtype'
        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};

        requiredFields.forEach((field) => {
            if (!formData.enquiry[0][field]) {
                newErrors[field] = `${field} is required`;
                valid = false;
            }
        });

        if (formData.enquiry[0].nextfollow) {
            const currentDate = new Date().toISOString().split('T')[0]; // Format as YYYY-MM-DD
            if (formData.enquiry[0].nextfollow < currentDate || !formData.enquiry[0].nextfollow) {
                newErrors.nextfollow = 'Next Follow Date cannot be in the past';
                valid = false;
            }
        }

        setErrors(newErrors);
        return valid;
    };
    const isEmailValid = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const isCMobileValid = (mobile: string) => {
        const mobileRegex = /^[0-9]{10,11}$/;
        return mobileRegex.test(mobile);
    };

    const handleRadioChange = (index, rowId, value) => {
        setFormData((prevFormData) => {
            let updatedTestAccess = [...prevFormData.enquiry[0].testaccess];

            // Find the entry for this rowId
            const existingEntryIndex = updatedTestAccess.findIndex(entry => entry.testaccessid === rowId);

            if (value === 'Yes') {
                if (existingEntryIndex !== -1) {
                    // Update the existing entry if found
                    updatedTestAccess[existingEntryIndex] = { testaccessid: rowId };
                } else {
                    // Add a new entry if not found
                    updatedTestAccess.push({ testaccessid: rowId });
                }
            } else if (value === 'No') {
                // Remove the entry for this rowId if it exists
                updatedTestAccess = updatedTestAccess.filter(entry => entry.testaccessid !== rowId);
            }

            // Correct the structure of formData by spreading prevFormData.enquiry[0] properly
            return {
                ...prevFormData,
                enquiry: [
                    {
                        ...prevFormData.enquiry[0], // Correctly spread the first enquiry object
                        testaccess: updatedTestAccess, // Update the testaccess array
                    },
                ],
            };
        });
    };




    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validateForm1()) {

            const toastmsg = "Form is invalid. Please fill all required fields."
            errorToast(toastmsg)
            return;
        }
     
        try {


            const updatedLeadData = {
                ...enquiry, // Spread existing leadData.

                tableRows: formData.enquiry[0].tableRows,
                testaccess: formData.enquiry[0].testaccess,
                enqNo: formData.enquiry[0].enqNo,
                enqdate: formData.enquiry[0].enqdate,
                updcheck: formData.enquiry[0].updcheck,
                finYearId: formData.enquiry[0].finYearId,
                lenessrow: formData.enquiry[0].lenessrow,
                empId: localStorage.getItem('empid'),
                enqID: formData.enquiry[0].enqID,
                typeID: formData.enquiry[0].typeID,
                modeId: formData.enquiry[0].modeId,
                divId: formData.enquiry[0].divId,
                rfxEnqNo: formData.enquiry[0].rfxEnqNo,
                dueOn: formData.enquiry[0].dueOn,
                statusId: formData.enquiry[0].statusId,
                desp: formData.enquiry[0].desp,
                basicValue: formData.enquiry[0].basicValue,
                ccperson: formData.enquiry[0].ccperson,
                cdesign: formData.enquiry[0].cdesign,
                cmobile: formData.enquiry[0].cmobile,
                cmail: formData.enquiry[0].cmail,
                tcperson: formData.enquiry[0].tcperson,
                tdesign: formData.enquiry[0].tdesign,
                tmobile: formData.enquiry[0].tmobile,
                tmail: formData.enquiry[0].tmail,
                bankName: formData.enquiry[0].bankName,
                branchname: formData.enquiry[0].branchname,
                accNo: formData.enquiry[0].accNo,
                ifsc: formData.enquiry[0].ifsc,
                isExcus: formData.enquiry[0].isExcus,
                excusid: formData.enquiry[0].excusid,
                sname: formData.enquiry[0].sname,
                code: formData.enquiry[0].code,
                name: formData.enquiry[0].name,
                city: formData.enquiry[0].city,
                ceo: formData.enquiry[0].ceo,
                caddr1: formData.enquiry[0].caddr1,
                caddr2: formData.enquiry[0].caddr2,
                caddr3: formData.enquiry[0].caddr3,
                caddr4: formData.enquiry[0].caddr4,
                caddr5: formData.enquiry[0].caddr5,
                caddr6: formData.enquiry[0].caddr6,
                caddr7: formData.enquiry[0].caddr7,
                faddr1: formData.enquiry[0].faddr1,
                faddr2: formData.enquiry[0].faddr2,
                faddr3: formData.enquiry[0].faddr3,
                faddr4: formData.enquiry[0].faddr4,
                faddr5: formData.enquiry[0].faddr5,
                faddr6: formData.enquiry[0].faddr6,
                faddr7: formData.enquiry[0].faddr7,
                panno: formData.enquiry[0].panno,
                tinno: formData.enquiry[0].tinno,
                email: formData.enquiry[0].email,
                omobile: formData.enquiry[0].omobile,
                pmobile: formData.enquiry[0].pmobile,
                gstno: formData.enquiry[0].gstno,
                nbus: formData.enquiry[0].nbus,
                ncomp: formData.enquiry[0].ncomp,
                datestart: formData.enquiry[0].datestart,
                cpname: formData.enquiry[0].cpname,
                cpdesign: formData.enquiry[0].cpdesign,
                cpmobile: formData.enquiry[0].cpmobile,
                cpmail: formData.enquiry[0].cpmail,
                annualturnover: formData.enquiry[0].annualturnover,
                buildOR: formData.enquiry[0].buildOR,
                followtype: formData.enquiry[0].followtype,
                followdesc: formData.enquiry[0].followdesc,
                isqul: formData.enquiry[0].isqul,
                nextfollow: formData.enquiry[0].nextfollow,
                priority: formData.enquiry[0].priority,
                divname: formData.enquiry[0].divname,
                reqtypeid: formData.enquiry[0].reqtypid,
                detid: state.detid,
                ipno: formData.enquiry[0].ipno,
                ipdate: formData.enquiry[0].ipdate,
                drawcatgid: formData.enquiry[0].drawcatgid,
                purposeid: formData.enquiry[0].purposeid,
                drawreqdate: formData.enquiry[0].drawreqdate,
                proname: formData.enquiry[0].proname,
                eqptsize: formData.enquiry[0].eqptsize,
                eqptwt: formData.enquiry[0].eqptwt,
                noofeqpt: formData.enquiry[0].noofeqpt,
                splacc: formData.enquiry[0].splacc,
                teststd: formData.enquiry[0].teststd,
                eqpttype: formData.enquiry[0].eqpttype,
                eqptsen: formData.enquiry[0].eqptsen,
                app: formData.enquiry[0].app,
                splnote: formData.enquiry[0].splnote
            };

            const dataToUpdate = updatedLeadData;
         

            const response = await apiService.updateReqclarify(dataToUpdate);
            // navigate('/crm/enquiry?status=success');
            navigate('/MarketingCRM/requesttoclarify', { state: { message: 'Request Clarified successfully!' } });
            toast.success(response.data);


        } catch (error: any) {
            if (error.response) {
                console.error('Error response:', error.response);
                errorToast(`Error: ${error.response.data.message || error.response.status}`);
            } else if (error.request) {
                console.error('Error request:', error.request);
                errorToast('Error: No response received from server');
            } else {
                console.error('Error message:', error.message);
                errorToast(`Error: ${error.message}`);
            }
            console.error('Error config:', error.config);
        }
    };
    const validateMobile = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only) 
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);

    };
    const validateMobile1 = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only) 
        const mobileRegex = /^[0-9]{9}$/;
        return mobileRegex.test(mobile);

    };
    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[a-zA-Z]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
        return emailRegex.test(email);
    };

    const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === 'pmobile') {
            setIsPMobileValid(validateMobile(value));
        }
        if (name === 'caddr6') {
            setIsCAMobileValid(validateMobile(value));
        }
        if (name === 'faddr6') {
            setIsFAMobileValid(validateMobile(value));
        }
        if (name == 'caddr7') {
            setIsCAEmailValid(validateEmail(value));
        }
        if (name == 'faddr') {
            setIsFAEmailValid(validateEmail(value));
        }

        setFormData((prevFormData) => ({
            ...prevFormData, // Keep the previous state
            enquiry: [
                {
                    ...prevFormData.enquiry[0], // Keep the existing properties of enquiry[0]
                    [name]: value, // Update the specific field
                },
            ],
        }));


      
    };

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const handleNext = () => {
        if (!validateForm()) {
            return;
        } else {
            setValue((prevValue) => Math.min(prevValue + 1, 3));
        }

    };


    const handleAddRow = () => {
        setFormData(prevState => {
            const currentTableRows = prevState.enquiry[0]?.tableRows || [];

            // Determine the new id and idenqdet for the new row
            const newId = currentTableRows.length + 1;
            const newIdenqdet = newId;  // Assuming idenqdet follows the same numbering as id

            return {
                ...prevState,
                enquiry: [
                    {
                        ...prevState.enquiry[0],
                        tableRows: [
                            ...currentTableRows,
                            {
                                id: newId, // New row id
                                idenqdet: newIdenqdet, // New row idenqdet
                                desp: '',
                                mpnno: null,
                                qty: 0,
                                changedet: '',
                                reasonchange: '',
                                spltestcon: '',
                                casemodel: '',
                                enqecr: [],
                                multiattach: [
                                    {
                                        mid: null,
                                        enqdetid: newIdenqdet,
                                        filename: "",
                                        url: ""
                                    }
                                ],
                                essentialTableRows: [{

                                    enqdetid: newIdenqdet,  // Set enqdetid to match idenqdet of the new row
                                    essdatid: 1,
                                    compliance: '',
                                    attach: '',
                                }]
                            }
                        ]
                    }
                ]
            };
        });
    };

    const [values, setValues] = useState(0);

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowUp') {
            setValues(values + 1);
        } else if (event.key === 'ArrowDown') {
            setValues(values - 1);
        }
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setFormData(prevState => ({
            ...prevState, // Keep the existing state
            enquiry: [
                {
                    ...prevState.enquiry[0], // Spread the existing enquiry object
                    priority: newValue, // Update only the priority field
                },
            ],
        }));
    }

    const handleTabChange1 = (event, newValue) => {
        setSelectedTab(newValue);

    };


    // const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //     const { name, value } = event.target;
    //     const newTableRows = [...formData?.enquiry[0]?.tableRows];
    //     newTableRows[index][name] = value;
    //     setFormData({ ...formData, tableRows: newTableRows });
    //     console.log(formData?.tableRows, "formData.tableRows");
    // };

    const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const updatedFormData = { ...formData };

        // Copy the tableRows from formData
        const newTableRows = [...updatedFormData.enquiry[0]?.tableRows];

        // Update the specific row with new value
        newTableRows[index] = {
            ...newTableRows[index],
            [name]: value
        };

        // If this is a new row, set id and enqdetid based on previous row
        if (index > 0) {
            const previousRow = newTableRows[index - 1];
            newTableRows[index].id = previousRow.id + 1;
            newTableRows[index].idenqdet = previousRow.idenqdet + 1;
        }

        // Update the formData with modified tableRows
        updatedFormData.enquiry[0].tableRows = newTableRows;
        setFormData(updatedFormData);

     
    };



    const handleEssentialTableRowsChange = (
        rowid: number,
        index: number,
        tableRowindex: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        // Deep clone formData to ensure immutability
        const newEssentialTableRows = JSON.parse(JSON.stringify(formData));
        const { name, value } = event.target;

        // Get idenqdet from the parent table row (tableRowindex)
        const parentTableRow = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex];
        const enqdetid = parentTableRow?.idenqdet; // Get the idenqdet value from parent row
       


        if (name === "attach") {
            // Handle file upload
            if (event.target instanceof HTMLInputElement && event.target.files) {
                const file = event.target.files[0];
                const formdata = new FormData();
                formdata.append('file', file);

                apiService.uploadFiles(formdata)
                    .then(response => {
                        const url = response.data; // Assuming response.data contains the file URL

                        const res = {

                            enqdetid: enqdetid, // Set enqdetid from parent row
                            essdatid: rowid,
                            compliance: "",
                            attach: url,
                        };

                        const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

                        if (rowIndex === -1) {
                            // If the row doesn't exist, add it
                            newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
                        } else {
                            // If the row exists, update it
                            newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
                                ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
                                attach: url,
                            };
                        }

                        setFormData(newEssentialTableRows); // Update the state
                    })
                    .catch(err => {
                        console.log("File upload error", err);
                    });
            }
        } else {
            // Handle other input types (e.g., text)
            const rowIndex = newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.findIndex(t => t.essdatid === rowid);

            const res = {
                enqdetid: enqdetid, // Set enqdetid from parent row
                essdatid: rowid,
                compliance: "",     // You can set a default value if needed
                attach: "",         // This field will be updated when needed
                [name]: value,      // Dynamically setting the field value based on the input name
            };

            if (rowIndex === -1) {
                // If the row doesn't exist, add it
                newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows.push(res);
            } else {
                // If the row exists, update it
                newEssentialTableRows.enquiry[0].tableRows[tableRowindex].essentialTableRows[rowIndex] = {
                    ...newEssentialTableRows.enquiry[0]?.tableRows[tableRowindex].essentialTableRows[rowIndex],
                    [name]: value,
                };
            }

            setFormData(newEssentialTableRows); // Update the state
        }

      
    };


    const handleInputChangesEcr = (field: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;

        setFormData(prevState => {
            const updatedTableRows = [...prevState.enquiry[0]?.tableRows];

            // Update the selected row's field directly
            if (selectedRowdet !== null && selectedRowdet !== undefined) {
                updatedTableRows[selectedRowdet] = {
                    ...updatedTableRows[selectedRowdet],
                    [field]: value,
                };
            }

            // Return updated form data including the updated `enquiry` object
            return {
                ...prevState,
                enquiry: [
                    {
                        ...prevState.enquiry[0],
                        tableRows: updatedTableRows,
                    },
                ],
            };
        });
    };



    const navigate = useNavigate();

    const handleCreateQuotation = () => {
        navigate('/marketingcrm/Quotation', { state: { id: state.id } });
    };



    const [rows1, setRows1] = useState<{ fileName: string }[]>([]);


    const handleFileUpload1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // Loop through all selected files and add them to rows
            const newRows = Array.from(e.target.files).map(file => ({ fileName: file.name }));
            setRows1(prevRows => [...prevRows, ...newRows]);
        }
    };


    const handleDeleteRow = (index: number) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            enquiry: prevFormData.enquiry.map((enq, i) =>
                i === 0
                    ? { ...enq, tableRows: enq.tableRows.filter((_, rowIndex) => rowIndex !== index) }
                    : enq
            ),
        }));
    };


    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClickOpen1 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen1(true); // Open the dialog
    };

    const handleClickOpen2 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen2(true); // Open the dialog
    };

    const handleClickOpen3 = (index: number) => {
        setSelectedRowdet(index); // Set the index of the selected row
        setOpen3(true); // Open the dialog
    };
    const handleClickOpen4 = () => {
        setOpen4(true);
    };
    const handleClickOpen5 = () => {
        setOpen5(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpen1(false);
        setOpen2(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
        setSelectedItem(null);
    };
    const handleClose1 = () => {

        setOpen3(false);
        setSelectedItem(null);
    };
    const handlecancel = () => {
        navigate('/marketingcrm/leadenquiry');
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };


    const handleCustomerTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomerType(event.target.value);
        const { name, value } = event.target;
        if (selectedRow) {
            setSelectedRow(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleCustomerClick = () => {
        if (customerType === 'Ex-Customer') {
            setOpen(true);
        }
    };

    const handleDialogClose = () => {
        setOpen(false);
    };
    const handleRowClick = (row) => {
        setSelectedRow(row);
        setOpen(false);
    };



    const handleMoveToFactory = () => {
        setFormData(prevFormData => ({
            ...prevFormData, // Spread the entire previous formData
            enquiry: prevFormData.enquiry.map((item, index) =>
                index === 0 // Target the first item in the enquiry array
                    ? {
                        ...item, // Spread the rest of the item's data
                        faddr1: item.caddr1,
                        faddr2: item.caddr2,
                        faddr3: item.caddr3,
                        faddr4: item.caddr4,
                        faddr5: item.caddr5,
                        faddr6: item.caddr6,
                        faddr7: item.caddr7
                    }
                    : item
            )
        }));
    };

    const toaste = useLocation();

    useEffect(() => {
        // Type assertion to specify the expected shape of location.state
        const state = toaste.state as LocationState;

        if (state?.message) {
            toast.success(state.message);
        }
    }, [location.state]);


    const printRef = useRef(null);
    const [showImage, setShowImage] = useState(false);

    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Report",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => {
            setShowImage(false);
         
        },
        removeAfterPrint: true,
    });

    const updateQuotation = (id: number, qno: string, enqid: number) => {
 


        navigate('/MarketingCRM/EditQuotation', { state: { id, qno, enqid } });
    }
    const handleTabChange2 = (event) => {
        const newValue = event.target.value; // Get the selected index from the dropdown
        setSelectedTab1(newValue); // Update the selected tab state

        const selectedRowId = reqType[newValue].id; // Get the selected row's id
    

        // Update formData.enquiry[0].reqtypeid with the selected row's id
        setFormData((prevState) => ({
            ...prevState,
            enquiry: prevState.enquiry.map((enquiryItem, index) =>
                index === 0 ? { ...enquiryItem, reqtypeid: selectedRowId } : enquiryItem
            ),
        }));
    };

    // Automatically set the dropdown value based on formData.enquiry[0].reqtypeid
    useEffect(() => {
        if (formData.enquiry[0].reqtypeid) {
            const selectedIndex = reqType.findIndex((row) => row.id === formData.enquiry[0].reqtypeid);
            if (selectedIndex !== -1) {
                setSelectedTab1(selectedIndex); // Automatically select the correct dropdown value
            }
        }
    }, [formData.enquiry[0].reqtypeid, reqType]);




    useEffect(() => {
        const caddr4ToFind = formData?.enquiry[0]?.caddr4;
        const selectedOption1 = states.find(option => option.name === caddr4ToFind);
        const stateid = selectedOption1 ? selectedOption1.id : null;

        const nameToFind = formData?.enquiry[0]?.faddr4;
        const selectedOption = states1.find(option => option.name === nameToFind);
        const stateid1 = selectedOption ? selectedOption.id : null;

        // Fetch districts only if state IDs are available
        if (stateid !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData = response.data.filter(item => item.stateid === stateid);
                    setDist(filteredData);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }

        if (stateid1 !== null) {
            apiService.getDropdown("mas_district")
                .then(response => {
                    const filteredData1 = response.data.filter(item => item.stateid === stateid1);
                    setDist1(filteredData1);
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    }, [formData, states, states1]);

    const handleInputMultiattach = (index, field, selectedRowdet, value) => {

        if (selectedRowdet < 0 || selectedRowdet >= formData.enquiry[0].tableRows.length) {
            console.error("Invalid selectedRowdet index");
            return;
        }


        if (field === "url" && value instanceof File) {
            const formData = new FormData();
            formData.append("file", value);

            apiService
                .uploadFiles(formData)
                .then((response) => {
                    const url = response.data;


                    setFormData((prevState) => {
                        const updatedTableRows = [...prevState.enquiry[0].tableRows];
                        updatedTableRows[selectedRowdet].multiattach[index] = {
                            ...updatedTableRows[selectedRowdet].multiattach[index],
                            url,
                        };

                        return {
                            ...prevState,
                            enquiry: [
                                {
                                    ...prevState.enquiry[0],
                                    tableRows: updatedTableRows,
                                },
                            ],
                        };
                    });
                })
                .catch((err) => {
                    console.error("File upload failed:", err);
                });
        } else {

            setFormData((prevState) => {
                const updatedTableRows = [...prevState.enquiry[0].tableRows];
                updatedTableRows[selectedRowdet].multiattach[index] = {
                    ...updatedTableRows[selectedRowdet].multiattach[index],
                    [field]: value,
                };

                return {
                    ...prevState,
                    enquiry: [
                        {
                            ...prevState.enquiry[0],
                            tableRows: updatedTableRows,
                        },
                    ],
                };
            });
        }
    };

    const handleAddAttach = (selectedRowdet) => {

        const currentRow = formData.enquiry[0].tableRows[selectedRowdet].multiattach;

        const lastRow = currentRow[currentRow.length - 1];
        if (currentRow.length === 0) {

            const newRow = { filename: '', url: '' };

            setFormData((prevFormData) => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.enquiry[0].tableRows[selectedRowdet].multiattach.push(newRow);
                return updatedFormData;
            });
        }

        if (lastRow.filename && lastRow.url) {

            const newRow = { filename: '', url: '' };

            setFormData((prevFormData) => {
                const updatedFormData = { ...prevFormData };
                updatedFormData.enquiry[0].tableRows[selectedRowdet].multiattach.push(newRow);
                return updatedFormData;
            });
        } else {

            console.log("Please fill in both filename and URL before adding a new row.");
        }
    };



    return (
        <>
            <Helmet>
                <title>Request to Clarify Update</title>
            </Helmet>
            <PageTitleWrapper>
                <PageTitle
                    heading="Request to Clarify Update"

                    docs="https://material-ui.com/components/tabs/"
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Card>
                            <Divider />
                            <CardContent>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        variant="scrollable"
                                        value={value}
                                        onChange={handleChange}
                                        aria-label="basic tabs example"
                                        sx={{
                                            '& .MuiTab-root': {
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: 'blue', // Changes background color on hover
                                                    color: '#fff',
                                                },
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: 'Blue', // Background color for selected tab
                                                color: '#fff', // Keep the font color unchanged
                                            },
                                        }}
                                    >
                                        <Tab label="Basic Information" {...a11yProps(0)} />
                                        <Tab label="Address Details" {...a11yProps(1)} />
                                        <Tab label="Essential Data's" {...a11yProps(2)} />
                                        <Tab label="Follow Up" {...a11yProps(3)} />
                                    </Tabs>
                                    <TabPanel value={value} index={0}>
                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        required
                                                                        id="enqNo"
                                                                        label="Enq No"
                                                                        name='enqNo'
                                                                        onClick={handleClickOpen}
                                                                        value={selectedRow ? selectedRow?.enqNo : formData?.enquiry[0].enqNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="enqdate"
                                                                        label="Enq Date"
                                                                        name='enqdate'
                                                                        onClick={handleClickOpen}
                                                                        value={formData?.enquiry[0].enqdate ? new Date(formData.enquiry[0].enqdate).toLocaleDateString('en-GB') : ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="typeID"
                                                                        name="typeID"
                                                                        label="Type"
                                                                        InputProps={{ readOnly: true }}
                                                                        value={formData?.enquiry[0].typeID || ''}
                                                                        onChange={handleInputChanges}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    >
                                                                        {type.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        required
                                                                        id="name"
                                                                        label="Customer"
                                                                        name='name'
                                                                        onClick={handleClickOpen}
                                                                        value={formData?.enquiry[0].name}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                        sx={{
                                                                            '.MuiInputBase-input': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                            '.MuiFormLabel-root': {
                                                                                fontWeight: 'bold',
                                                                            },
                                                                        }}
                                                                    />


                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="modeId"
                                                                        label="Mode of Enquiry"
                                                                        name="modeId"
                                                                        value={formData?.enquiry[0].modeId}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}

                                                                    >
                                                                        {mode.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        required
                                                                        // select
                                                                        id="divname"
                                                                        label="Division"
                                                                        name="divname"
                                                                        value={formData?.enquiry[0].divname || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}

                                                                    >
                                                                        {/* {division.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))} */}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="code"
                                                                        label="Code"
                                                                        name="code"
                                                                        autoFocus
                                                                        value={formData?.enquiry[0].code || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                    />
                                                                    <TextField
                                                                        id="sname"
                                                                        label="Short Name"
                                                                        // autoFocus
                                                                        name="sname"
                                                                        value={formData?.enquiry[0].sname || ''}
                                                                        onChange={handleInputChanges}
                                                                        InputProps={{ readOnly: true }}
                                                                    >

                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="city"
                                                                        label="Place"
                                                                        name="city"
                                                                        value={formData?.enquiry[0].city || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 11 }}
                                                                    >
                                                                        {city.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.sname}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        id="ceo"
                                                                        label="Managing Director"
                                                                        name="ceo"
                                                                        value={formData?.enquiry[0].ceo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />

                                                                    <TextField
                                                                        required
                                                                        id="email"
                                                                        label="Email ID"
                                                                        name="email"
                                                                        value={formData?.enquiry[0].email || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                        error={!!errors.email}
                                                                        helperText={errors.email}
                                                                    />

                                                                    <TextField
                                                                        required
                                                                        id="pmobile"
                                                                        label=" Mobile Number"
                                                                        name="pmobile"
                                                                        value={formData?.enquiry[0].pmobile || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isPMobileValid}
                                                                        helperText={!isPMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="gstno"
                                                                        label="GST No."
                                                                        name="gstno"
                                                                        value={formData?.enquiry[0].gstno || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.gstno}
                                                                        helperText={errors.gstno}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />


                                                                    <TextField
                                                                        required
                                                                        id="panno"
                                                                        label="PAN No"
                                                                        name="panno"
                                                                        value={formData?.enquiry[0].panno || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.panno}
                                                                        helperText={errors.panno}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />
                                                                    <TextField
                                                                        id="tinno"
                                                                        label="TIN No"
                                                                        name="tinno"
                                                                        value={formData?.enquiry[0].tinno || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />

                                                                    <TextField
                                                                        id="rfxEnqNo"
                                                                        label="Customer Ref.No"
                                                                        name="rfxEnqNo"
                                                                        value={formData?.enquiry[0].rfxEnqNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}
                                                                    />
                                                                    <TextField
                                                                        id="dueon"
                                                                        label="Due On"
                                                                        type="date"
                                                                        InputLabelProps={{ shrink: true }}
                                                                        name="dueOn"
                                                                        value={formData?.enquiry[0].dueOn.split('T')[0] || ''}
                                                                        onChange={handleInputChanges}

                                                                    />

                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Contact Person For Commercial" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        required
                                                                        id="ccperson"
                                                                        label="Contact Person"
                                                                        name="ccperson"
                                                                        value={formData?.enquiry[0].ccperson || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.ccperson}
                                                                        helperText={errors.ccperson}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="cdesign"
                                                                        label="Designation"
                                                                        name="cdesign"
                                                                        value={formData?.enquiry[0].cdesign || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.cdesign}
                                                                        helperText={errors.cdesign}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="cmobile"
                                                                        label="Mobile No"
                                                                        name="cmobile"
                                                                        value={formData?.enquiry[0].cmobile || ''}
                                                                        onChange={handleInputChanges}

                                                                        error={!!errors.cmobile}
                                                                        helperText={errors.cmobile}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="cmail"
                                                                        label="Email ID"
                                                                        name="cmail"
                                                                        value={formData?.enquiry[0].cmail || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.cmail}
                                                                        helperText={errors.cmail}
                                                                        inputProps={{ maxLength: 45 }}

                                                                    />


                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />

                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title=" Contact Person For Technical" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    <TextField
                                                                        required
                                                                        id="tcperson"
                                                                        label="Contact Person"
                                                                        name="tcperson"
                                                                        value={formData?.enquiry[0].tcperson || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tcperson}
                                                                        helperText={errors.tcperson}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="tdesign"
                                                                        label="Designation"
                                                                        name="tdesign"
                                                                        value={formData?.enquiry[0].tdesign || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tdesign}
                                                                        helperText={errors.tdesign}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="tmobile"
                                                                        label="Mobile No"
                                                                        name="tmobile"
                                                                        value={formData?.enquiry[0].tmobile || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tmobile}
                                                                        helperText={errors.tmobile}
                                                                        inputProps={{ maxLength: 45 }}

                                                                    />
                                                                    <TextField
                                                                        required
                                                                        id="tmail"
                                                                        label="Email ID"
                                                                        name="tmail"
                                                                        value={formData?.enquiry[0].tmail || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!!errors.tmail}
                                                                        helperText={errors.tmail}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    />
                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Bank Information" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="bankName"
                                                                        label="Bank Name"
                                                                        name="bankName"
                                                                        value={formData?.enquiry[0].bankName || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}

                                                                    />
                                                                    <TextField
                                                                        id="branchname"
                                                                        label="Branch Name"
                                                                        name="branchname"
                                                                        value={formData?.enquiry[0].branchname || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 100 }}

                                                                    />
                                                                    <TextField
                                                                        id="accNo"
                                                                        label="Account No"
                                                                        name="accNo"
                                                                        value={formData?.enquiry[0].accNo || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="ifsc"
                                                                        label="IFSC Code"
                                                                        sx={{ height: '10ch' }}
                                                                        name="ifsc"
                                                                        value={formData?.enquiry[0].ifsc || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />

                                                                </div>


                                                            </Box>

                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box>  </CardContent>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <form>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Contact Address" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="caddr1"
                                                                        label="Plot No/Street"
                                                                        name="caddr1"
                                                                        value={formData?.enquiry[0].caddr1 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    />
                                                                    <TextField

                                                                        id="caddr2"
                                                                        label="City"
                                                                        name="caddr2"
                                                                        value={formData?.enquiry[0].caddr2 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    >
                                                                        {/* {city.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.name}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))} */}
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="caddr4"
                                                                        label="State"
                                                                        name="caddr4"
                                                                        value={formData?.enquiry[0].caddr4 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    >
                                                                        {states.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.name}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        select
                                                                        id="caddr3"
                                                                        label="District"
                                                                        name="caddr3"
                                                                        value={formData?.enquiry[0]?.caddr3 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    >
                                                                        {dist.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.name}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>

                                                                    <TextField
                                                                        id="caddr5"
                                                                        label="Pincode"
                                                                        name="caddr5"
                                                                        value={formData?.enquiry[0].caddr5 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="caddr6"
                                                                        label="Landline"
                                                                        name="caddr6"
                                                                        value={formData?.enquiry[0].caddr6 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isCAMobileValid}
                                                                        helperText={!isCAMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    />

                                                                    <Button
                                                                        sx={{ margin: 1 }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleMoveToFactory}
                                                                    >
                                                                        Copy as Factory Address
                                                                    </Button>

                                                                </div>


                                                            </Box>
                                                        </CardContent>
                                                    </Card>
                                                </Grid></Grid>
                                            <br />
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="stretch"
                                                spacing={3}
                                            >
                                                <Grid item xs={12}>
                                                    <Card>
                                                        <CardHeader title="Factory Address" />
                                                        <Divider />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' }
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>

                                                                    <TextField
                                                                        id="faddr1"
                                                                        label="Plot No/Street"
                                                                        name="faddr1"
                                                                        value={formData?.enquiry[0].faddr1 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField

                                                                        id="faddr2"
                                                                        label="City"
                                                                        name="faddr2"
                                                                        value={formData?.enquiry[0].faddr2 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}

                                                                    >

                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="faddr4"
                                                                        label="State"
                                                                        name="faddr4"
                                                                        value={formData?.enquiry[0].faddr4 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    >
                                                                        {states1.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.name}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                    <TextField
                                                                        select
                                                                        id="faddr3"
                                                                        label="District"
                                                                        name="faddr3"
                                                                        value={formData?.enquiry[0]?.faddr3 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    >
                                                                        {dist1.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.name}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>


                                                                    <TextField
                                                                        id="faddr5"
                                                                        label="Pincode"
                                                                        name="faddr5"
                                                                        value={formData?.enquiry[0].faddr5 || ''}
                                                                        onChange={handleInputChanges}
                                                                        inputProps={{ maxLength: 50 }}
                                                                    />
                                                                    <TextField
                                                                        id="faddr6"
                                                                        label="Landline"
                                                                        name="faddr6"
                                                                        value={formData?.enquiry[0].faddr6 || ''}
                                                                        onChange={handleInputChanges}
                                                                        error={!isFAMobileValid}
                                                                        helperText={!isFAMobileValid ? 'Invalid mobile number' : ''}
                                                                        inputProps={{ maxLength: 45 }}
                                                                    />


                                                                </div>
                                                            </Box>
                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box></CardContent>
                                                    </Card>
                                                </Grid>

                                            </Grid>

                                        </form>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>
                                                        <CardContent>
                                                            <TextField
                                                                select
                                                                label="Select Requirement Type"
                                                                value={selectedTab1}
                                                                onChange={handleTabChange2}
                                                                sx={{
                                                                    mb: '10px',
                                                                    '& .MuiSelect-root': {
                                                                        cursor: 'pointer',
                                                                        '&:hover': {
                                                                            backgroundColor: 'blue', // Changes background color on hover
                                                                            color: '#fff',
                                                                        },
                                                                    },
                                                                    '& .Mui-selected': {
                                                                        backgroundColor: 'blue', // Background color for selected option
                                                                        color: '#fff', // Keep the font color unchanged
                                                                    },
                                                                }}
                                                                InputProps={{ readOnly: true }}
                                                            >
                                                                {reqType.map((row, index) => (
                                                                    <MenuItem key={row.id || index} value={index}>
                                                                        {row.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </CardContent>

                                                    </Card>

                                                </form></Grid>




                                        </Grid>
                                        <br />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>
                                                        <CardHeader title="Customer Requirements" />
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>S.No</TableCell>
                                                                            <TableCell>MPN No</TableCell>
                                                                            <TableCell>Description</TableCell>
                                                                            <TableCell>Qty</TableCell>
                                                                            <TableCell> Essential's </TableCell>

                                                                            {/* <TableCell>Delete</TableCell> */}
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {formData.enquiry[0].tableRows.length > 0 ? (
                                                                            formData.enquiry[0].tableRows.map((detail: any, index: number) => (
                                                                                <TableRow key={detail.id}>
                                                                                    <TableCell>{index + 1}</TableCell>
                                                                                    <TableCell>{detail.mpnno}</TableCell>
                                                                                    <TableCell>
                                                                                        <TextField
                                                                                            id='desp'
                                                                                            multiline
                                                                                            name="desp"
                                                                                            value={detail.desp}
                                                                                            onChange={(e) => handleTableRowsChange(index, e)}
                                                                                            inputProps={{ maxLength: 250 }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <TextField
                                                                                            id='qty'
                                                                                            type="number"
                                                                                            name="qty"
                                                                                            value={detail.qty}
                                                                                            onChange={(e) => handleTableRowsChange(index, e)}
                                                                                            inputProps={{ maxLength: 11 }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Button onClick={() => handleClickOpen1(index)}>Essential's Data</Button>
                                                                                    </TableCell>
                                                                                    {/* <TableCell>
                                                                                        <IconButton
                                                                                            color="secondary"
                                                                                            onClick={() => handleDeleteRow(index)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                                                        </IconButton>
                                                                                    </TableCell> */}
                                                                                </TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <TableRow>
                                                                                <TableCell colSpan={6} align="center">
                                                                                    No records found
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>


                                                                </Table>

                                                            </TableContainer>
                                                            <Box display="flex" justifyContent="flex-end" p={2}><Button
                                                                sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }}
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={handleNext}
                                                            >
                                                                Next
                                                            </Button></Box>

                                                        </Paper>
                                                        {/* <Button   variant="contained" color="primary">Add Row</Button> */}

                                                    </Card>

                                                </form></Grid>


                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                justifyContent="flex-end"
                                                p={2}
                                            >

                                                <Box sx={{  maxWidth: 400, maxHeight: 150, overflowY: 'auto' ,alignItems:'flex-start'}} p={2}>
                                                
                                                    
                                                    {formData.enquiry[0].tableRows[0].reqdesp.length > 0 ? (
                                                        <>  <Typography>
                                                        <strong>Request Reason :</strong>
                                                    </Typography>
                                                       <Typography>
                                                           {formData.enquiry[0].tableRows[0].reqdesp}
                                                       </Typography></>
                                                       
                                                    ) : null}
                                                </Box>
                                                <Button sx={{ marginRight: 2 }} onClick={(e) => handleClickOpen4()} >Test Details</Button>

                                                {formData.enquiry[0].tableRows[0].reqdesp.length > 0 ? (
                                                    <Button sx={{
                                                        marginRight: 2,
                                                        color: 'red'
                                                    }} onClick={(e) => handleClickOpen5()} >Drawing Requirements</Button>
                                                ) : <Button sx={{
                                                    marginRight: 2
                                                    // color: 'red'
                                                }} onClick={(e) => handleClickOpen5()} >Drawing Requirements</Button>}

                                                {/* <Button
                                                    variant='contained'
                                                    color="primary"
                                                    onClick={handleAddRow}
                                                    sx={{ marginRight: -6 }}
                                                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                                                >
                                                    Add More
                                                </Button> */}
                                            </Box>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value={value} index={3}>
                                        <Grid
                                            container
                                            direction="row" // Stack items vertically
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            spacing={4}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">
                                                    <Card>
                                                        <CardHeader title="Next Step" />
                                                        <CardContent>
                                                            <Box
                                                                component="form"
                                                                sx={{
                                                                    '& .MuiTextField-root': { m: 1, width: '24ch' },
                                                                }}
                                                                noValidate
                                                                autoComplete="off"
                                                            >
                                                                <div>
                                                                    {/* <Grid item>
                                                                      
                                                                            <Card> */}


                                                                    <Tabs
                                                                        value={formData?.enquiry[0].priority}
                                                                        onChange={handleTabChange}
                                                                        aria-label="priority tabs"
                                                                        variant="fullWidth"
                                                                        sx={{
                                                                            m: 1, width: '24ch',
                                                                            '& .MuiTab-root': {
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    backgroundColor: 'blue', // Changes background color on hover
                                                                                    color: '#fff',
                                                                                },
                                                                            },
                                                                            '& .Mui-selected': {
                                                                                backgroundColor: 'Blue', // Background color for selected tab
                                                                                color: '#fff', // Keep the font color unchanged
                                                                            },
                                                                        }}
                                                                    >
                                                                        {priority.filter(item => item.isactive == 1).map((item) => (
                                                                            <Tab
                                                                                key={item.id} // Assuming there's an `id` field in your priority data
                                                                                label={item.name} // Display the priority name
                                                                                value={item.name} // Set the tab value based on the name
                                                                                style={formData?.enquiry[0].priority === item.name ? tabStyles[item.name.toLowerCase()] : undefined} // Use dynamic styling based on the name
                                                                            />
                                                                        ))}
                                                                    </Tabs>


                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="followtype"
                                                                        label="Follow Type"
                                                                        name="followtype"
                                                                        value={formData?.enquiry[0].followtype}
                                                                        onChange={handleInputChanges}
                                                                        sx={{ m: 1, width: '24ch' }}
                                                                        error={!!errors.followtype}
                                                                        helperText={errors.followtype}
                                                                        inputProps={{ maxLength: 11 }}
                                                                    >
                                                                        {follow.map((option) => (
                                                                            <MenuItem key={option?.id} value={option?.id}>
                                                                                {option?.name}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>


                                                                    <TextField
                                                                        required
                                                                        id="followdesc"
                                                                        label="Description"
                                                                        multiline
                                                                        name="followdesc"
                                                                        value={formData.enquiry[0].followdesc}
                                                                        onChange={handleInputChanges}
                                                                        sx={{ m: 1, width: '24ch' }}
                                                                        error={!!errors.followdesc}
                                                                        helperText={errors.followdesc}
                                                                        inputProps={{ maxLength: 250 }}
                                                                    />

                                                                    <TextField
                                                                        required
                                                                        id="nextfollow"
                                                                        label="Next Follow Date"
                                                                        type="date"
                                                                        name="nextfollow"
                                                                        value={formData.enquiry[0].nextfollow}
                                                                        onChange={handleInputChanges}
                                                                        sx={{ m: 1, width: '24ch' }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        error={!!errors.nextfollow}
                                                                        helperText={errors.nextfollow}

                                                                    />

                                                                    <TextField
                                                                        required
                                                                        select
                                                                        id="status"
                                                                        label="Status"
                                                                        name="statusId"
                                                                        value={formData?.enquiry[0].statusId}
                                                                        onChange={handleInputChanges}
                                                                        sx={{ m: 1, width: '24ch' }}
                                                                        error={!!errors.statusId}
                                                                        helperText={errors.statusId}
                                                                        inputProps={{ maxLength: 11 }}
                                                                    >

                                                                        {status
                                                                            .filter(option => option.islead != 1)
                                                                            .map(option => (
                                                                                <MenuItem key={option.id} value={option.id}>
                                                                                    {option.name}
                                                                                </MenuItem>
                                                                            ))}
                                                                    </TextField>



                                                                    {/* </Card>
                                                                        </Grid> */}






                                                                </div>  </Box>
                                                        </CardContent>
                                                    </Card>


                                                </form>

                                            </Grid>

                                        </Grid>

                                        <br />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">

                                                    <Card>
                                                        <CardHeader title="Follow History" />
                                                        <Paper>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>S.No</TableCell>
                                                                            <TableCell>Follow Type</TableCell>
                                                                            <TableCell>Follow Date</TableCell>
                                                                            <TableCell>Description</TableCell>
                                                                            <TableCell>Priority</TableCell>
                                                                            <TableCell>Next Follow</TableCell>
                                                                            <TableCell>Status</TableCell>
                                                                            <TableCell>Follow By</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {formData.enquiry[0].enqfollow.slice().reverse().map((data: any, index: number) => (
                                                                            <TableRow key={data.id}>
                                                                                <TableCell>{index + 1}</TableCell>
                                                                                <TableCell>{data.ftypename}</TableCell>
                                                                                <TableCell>{data.fdate_s}</TableCell>
                                                                                <TableCell>{data.desp}</TableCell>
                                                                                <TableCell>{data.priority}</TableCell>
                                                                                <TableCell>{data.nfdate_s}</TableCell>
                                                                                <TableCell>{data.statusname}</TableCell>
                                                                                <TableCell>{data.fbyname}</TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>

                                                                </Table>
                                                            </TableContainer>
                                                        </Paper>
                                                    </Card>

                                                </form></Grid>

                                        </Grid>
                                        <br />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="stretch"
                                            spacing={3}
                                        >
                                            <Grid item xs={12}>
                                                <form action="#">

                                                    <Card>
                                                        <CardHeader title="Quotation History" />
                                                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <CardHeader title="Quotation History" />
                                                            {quote.length === 0 || quote[quote.length - 1].status !== "Approval pending" ? (
                                                                <button onClick={handleCreateQuotation}>
                                                                    Create Quotation
                                                                </button>
                                                            ) : null}
                                                        </Box> */}
                                                        <Paper>
                                                            <TableContainer>

                                                                <Table>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>S.No</TableCell>
                                                                            <TableCell>Rivision No.</TableCell>
                                                                            <TableCell>Quote No</TableCell>
                                                                            <TableCell>Quote Date</TableCell>
                                                                            <TableCell> Status </TableCell>
                                                                            {/* <TableCell> Action </TableCell> */}


                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {quote.map((quote: any, index: number) => (
                                                                            <TableRow key={quote.id}>
                                                                                <TableCell>{index + 1}</TableCell>
                                                                                <TableCell>{quote.Rno}</TableCell>
                                                                                <TableCell>{quote.qno}</TableCell>
                                                                                <TableCell>  {new Date(quote.qdate).toLocaleDateString('en-GB')}</TableCell>
                                                                                <TableCell>{quote.status}</TableCell>
                                                                                {/* <TableCell align="center">
                                                                                    {quote.status !== "Successfully Approved" ? (
                                                                                        <FontAwesomeIcon
                                                                                            icon={faEdit}
                                                                                            onClick={() => updateQuotation(quote.id, quote.qno, quote.enqid)}
                                                                                            style={{ cursor: 'pointer' }}
                                                                                        />
                                                                                    ) : (
                                                                                        null // Leaves the cell empty if status is "Successfully Approved"
                                                                                    )}
                                                                                </TableCell> */}
                                                                            </TableRow>
                                                                        ))}

                                                                    </TableBody>

                                                                </Table>

                                                            </TableContainer>
                                                            <ToastContainer
                                                                position="top-right"
                                                                autoClose={5000}
                                                                hideProgressBar={false}
                                                                newestOnTop={false}
                                                                closeOnClick
                                                                rtl={false}
                                                                pauseOnFocusLoss
                                                                draggable
                                                                pauseOnHover
                                                                theme="light"
                                                            />
                                                            <Box display="flex" justifyContent="flex-end" p={2}>
                                                                <Button sx={{ position: 'absolute', right: 40, bottom: 120, margin: 1 }} onClick={handleSubmit} variant="contained" color="primary">
                                                                    Update
                                                                </Button>
                                                            </Box>

                                                        </Paper>

                                                    </Card>

                                                </form></Grid>

                                        </Grid>
                                        {/* <Button sx={{ margin: 1 }} variant='contained' color="primary" onClick={() => handleQuotation()}> */}
                                        {/* Add More
                                        </Button> */}
                                        <br />

                                    </TabPanel>
                                </Box>
                                {/* <Dialog className="dialog" open={open} onClose={
                                }
                                    maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                                    fullWidth
                                    sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                                    <form>
                                        <DialogTitle>Ex - Customer</DialogTitle>
                                        <DialogContent>
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className="tableHead" >S.No</TableCell>
                                                                <TableCell className="tableHead"> Code</TableCell>
                                                                <TableCell className="tableHead">Name</TableCell>
                                                                <TableCell className="tableHead">Place</TableCell>
                                                                <TableCell className="tableHead">Mobile	</TableCell>
                                                                <TableCell className="tableHead">Mail</TableCell>
                                                                <TableCell className="tableHead">Contact Person</TableCell>
                                                                <TableCell className="tableHead">Is Major</TableCell>

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody >
                                                            {rowdata.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                                <TableRow key={row.id} onClick={() => handleRowClick(row)}>
                                                                    <TableCell className="tableBody">{page * rowsPerPage + index + 1}</TableCell>
                                                                    <TableCell className="tableBody">{row.code}</TableCell>
                                                                    <TableCell className="tableBody">{row.name}</TableCell>
                                                                    <TableCell className="tableBody">{row.place}</TableCell>
                                                                    <TableCell className="tableBody">{row.mobile}</TableCell>
                                                                    <TableCell className="tableBody">{row.mail}</TableCell>
                                                                    <TableCell className="tableBody">{row.contactPerson}</TableCell>
                                                                    <TableCell className="tableBody">{row.isMajor ? 'Yes' : 'No'}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    component="div"
                                                    count={data.length === 0 ? 0 : data[0]["count"]}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                />
                                            </Paper>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Cancel
                                            </Button>
                                            <Button color="primary" >
                                                {selectedItem ? 'Update' : 'Save'}
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Dialog> */}
                                <Dialog
                                    className="dialog"
                                    open={open1}
                                    onClose={handleClose}
                                    maxWidth="lg"
                                    fullWidth
                                    sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}
                                >
                                    <form>
                                        <br />
                                        <Tabs
                                            value={selectedTab}
                                            onChange={handleTabChange1}
                                            aria-label="essential and dns tabs"
                                            sx={{
                                                ml: '23px',
                                                '& .MuiTab-root': {
                                                    cursor: 'pointer',
                                                    '&:hover': {
                                                        backgroundColor: '#f24646', // Changes background color on hover
                                                        color: '#fff',
                                                    },
                                                },
                                                '& .Mui-selected': {
                                                    backgroundColor: '#f24646', // Background color for selected tab
                                                    color: '#fff', // Keep the font color unchanged
                                                },
                                            }}
                                        >
                                            <Tab label="Customer Requirements" />
                                            {/* <Tab label="Drawing Requirements" /> */}
                                            <Tab label="Multi Attach" />
                                        </Tabs>

                                        <DialogContent>
                                            {/* Content for Customer Requirements tab */}
                                            {selectedTab === 0 && (
                                                <>

                                                    {/* Small and Light Cases Table */}
                                                    {selectedTab1 === 0 && (
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>S.No</TableCell>
                                                                        <TableCell>SAARC Requirements</TableCell>
                                                                        <TableCell>Customer Compliance</TableCell>
                                                                        <TableCell>Remarks</TableCell>
                                                                        <TableCell>Attachments</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {essdata
                                                                        .filter((row) => row.reqtypeid === 1)
                                                                        .map((row, index) => (
                                                                            <TableRow key={row.id}>
                                                                                <TableCell ><Typography>{index + 1}</Typography></TableCell>
                                                                                <TableCell>{row.name}</TableCell>
                                                                                <TableCell>
                                                                                    <TextField
                                                                                        id="compliance"
                                                                                        label="Compliance"
                                                                                        multiline
                                                                                        name="compliance"
                                                                                        value={formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows.find(t => t.essdatid === row.id)?.compliance || ""}
                                                                                        inputProps={{ maxLength: 250 }}
                                                                                        onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows.find(t => t.essdatid === row.id)?.remarks || ""}

                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row.isattach === 1 ? (
                                                                                        <div>
                                                                                            <input
                                                                                                type="file"
                                                                                                name="attach"
                                                                                                id="attach"

                                                                                                onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                                                style={{ margin: '10px' }}
                                                                                            />
                                                                                            {/* Display the URL as a clickable link */}
                                                                                            {/* <a
                                                                                                href={formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows[index]?.attach}
                                                                                                target="_blank"   // Opens the link in a new tab
                                                                                                rel="noopener noreferrer"  // Prevents the new page from having access to the original window object
                                                                                            >
                                                                                                View File
                                                                                            </a> */}

                                                                                            {formData.enquiry[0]?.tableRows[selectedRowdet]?.essentialTableRows?.find(
                                                                                                (t) => t.essdatid === row.id
                                                                                            )?.attach && (
                                                                                                    <a
                                                                                                        href={
                                                                                                            formData.enquiry[0]?.tableRows[selectedRowdet]?.essentialTableRows?.find(
                                                                                                                (t) => t.essdatid === row.id
                                                                                                            )?.attach
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        View File
                                                                                                    </a>
                                                                                                )}

                                                                                        </div>
                                                                                    ) : (
                                                                                        <TableCell>
                                                                                            {/* <TextField disabled /> */}
                                                                                        </TableCell>
                                                                                    )}
                                                                                </TableCell>

                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    )}

                                                    {/* Heavy Applications Table */}
                                                    {selectedTab1 === 1 && (
                                                        <TableContainer>
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>S.No</TableCell>
                                                                        <TableCell>SAARC Requirements</TableCell>
                                                                        <TableCell>Customer Compliance</TableCell>
                                                                        <TableCell>Remarks</TableCell>
                                                                        <TableCell>Attachments</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {essdata
                                                                        .filter((row) => row.reqtypeid === 2) // Filter rows with reqtypeid = 2
                                                                        .map((row, index) => (
                                                                            <TableRow key={row.id}>
                                                                                <TableCell ><Typography>{index + 1}</Typography></TableCell>
                                                                                <TableCell>{row.name}</TableCell>
                                                                                <TableCell>
                                                                                    <TextField
                                                                                        id="compliance"
                                                                                        label="Compliance"
                                                                                        multiline
                                                                                        name="compliance"
                                                                                        value={formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows.find(t => t.essdatid === row.id)?.compliance || ""}
                                                                                        
                                                                                        onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                                        sx={{ m: 1, width: '24ch' }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {formData.enquiry[0].tableRows[selectedRowdet]?.essentialTableRows.find(t => t.essdatid === row.id)?.remarks || ""}

                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    {row.isattach === 1 ? (
                                                                                        <div>
                                                                                            <input
                                                                                                type="file"
                                                                                                name="attach"
                                                                                                id="attach"

                                                                                                onChange={(e) => handleEssentialTableRowsChange(row.id, index, selectedRowdet, e)}
                                                                                                style={{ margin: '10px' }}
                                                                                            />

                                                                                            {formData.enquiry[0]?.tableRows[selectedRowdet]?.essentialTableRows?.find(
                                                                                                (t) => t.essdatid === row.id
                                                                                            )?.attach && (
                                                                                                    <a
                                                                                                        href={
                                                                                                            formData.enquiry[0]?.tableRows[selectedRowdet]?.essentialTableRows?.find(
                                                                                                                (t) => t.essdatid === row.id
                                                                                                            )?.attach
                                                                                                        }
                                                                                                        target="_blank"
                                                                                                        rel="noopener noreferrer"
                                                                                                    >
                                                                                                        View File
                                                                                                    </a>
                                                                                                )}

                                                                                        </div>
                                                                                    ) : (
                                                                                        <TableCell>
                                                                                            {/* <TextField disabled /> */}
                                                                                        </TableCell>
                                                                                    )}
                                                                                </TableCell>

                                                                            </TableRow>
                                                                        ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    )}
                                                </>
                                            )}

                                            {/* Content for Drawing Requirements tab */}
                                            {/* {selectedTab === 1 && (
                                                <>
                                                    <Box flexDirection="row" display="flex" gap={4}>
                                                       
                                                        <Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 9.5 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Input Number:</strong>
                                                                </Typography>
                                                                <TextField
                                                                    id='inputno'
                                                                    name="inputno"
                                                                    variant="outlined"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.inputno || ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ width: '200px' }}
                                                                />
                                                            </Box>
                                                            <br />
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 6.5 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Drawing Category:</strong>
                                                                </Typography>
                                                                <TextField
                                                                    select
                                                                    id='drawcatgid'
                                                                    name="drawcatgid"
                                                                    label="Select"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.drawcatgid || ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ width: '200px' }}>
                                                                    {drawing.map((option) => (
                                                                        <MenuItem key={option?.id} value={option?.id}>
                                                                            {option?.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                            </Box>
                                                            <br />
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Drawing Required On:</strong>
                                                                </Typography>
                                                                <TextField
                                                                    id='drawreqon'
                                                                    name="drawreqon"
                                                                    type="date"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.drawreqon
                                                                        ? formData.enquiry[0].tableRows[selectedRowdet].drawreqon.split('T')[0]
                                                                        : ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ width: '200px' }}
                                                                />

                                                            </Box>
                                                        </Box>

                                                      
                                                        <Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Equipment position:</strong>
                                                                </Typography>
                                                                <TextField
                                                                    select
                                                                    id='eqipposition'
                                                                    name="eqipposition"
                                                                    label="Select"
                                                                    variant="outlined"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.eqipposition || ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ width: '200px' }}
                                                                >
                                                                    <MenuItem value="H">Horizondal</MenuItem>
                                                                    <MenuItem value="V">Vertical</MenuItem>

                                                                </TextField>

                                                            </Box>
                                                            <br />
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Printing Color:</strong>
                                                                </Typography>
                                                                <RadioGroup
                                                                    name="printcolor"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.printcolor || ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ display: 'flex', flexDirection: 'row', gap: -5 }} // Adjust spacing and layout
                                                                >
                                                                    <FormControlLabel value="Yellow" control={<Radio />} label="Yellow" />
                                                                    <FormControlLabel value="White" control={<Radio />} label="White" />
                                                                    <FormControlLabel value="Black" control={<Radio />} label="Black" />
                                                                </RadioGroup>
                                                            </Box>

                                                            <br />
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>As Req.foam:</strong>
                                                                </Typography>
                                                                <Tabs
                                                                    value={formData?.tableRows?.[selectedRowdet]?.asreqfoam || 'EPE'} // Bind to asreqfoam
                                                                    onChange={(event, newValue) => {
                                                                        const newTableRows = [...formData.enquiry[0].tableRows];
                                                                        newTableRows[selectedRowdet].asreqfoam = newValue; // Update asreqfoam for the selected row
                                                                        setFormData({ ...formData, tableRows: newTableRows }); // Update the state
                                                                    }}
                                                                    aria-label="priority tabs"
                                                                    variant="fullWidth"
                                                                    sx={{
                                                                        m: 1,
                                                                        width: '24ch',
                                                                        '& .MuiTab-root': {
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                backgroundColor: 'blue',
                                                                                color: '#fff',
                                                                            },
                                                                        },
                                                                    }}
                                                                >
                                                                    <Tab label="EPE" value="EPE" />
                                                                    <Tab label="XLPE" value="XLPE" />
                                                                    <Tab label="PU" value="PU" />
                                                                </Tabs>
                                                            </Box>
                                                            <br />
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                                    <strong>Foam:</strong>
                                                                </Typography>
                                                                <TextField
                                                                    id='foam'
                                                                    name="foam"
                                                                    value={formData?.enquiry[0].tableRows?.[selectedRowdet]?.foam || ''}
                                                                    onChange={(e) => handleTableRowsChange(selectedRowdet, e)}
                                                                    sx={{ width: '200px' }} />
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                </>

                                            )} */}
                                            {selectedTab === 1 && (
                                                <>


                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                        }}
                                                    >
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => handleAddAttach(selectedRowdet)}

                                                        >
                                                            Add Row
                                                        </Button>
                                                    </Box>

                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>S.No</TableCell>
                                                                    <TableCell>File Name</TableCell>
                                                                    <TableCell>Attachments</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {formData.enquiry[0].tableRows[selectedRowdet].multiattach.length > 0 ? (
                                                                    formData.enquiry[0].tableRows[selectedRowdet].multiattach.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell>{index + 1}</TableCell>
                                                                            <TableCell>
                                                                                <TextField
                                                                                    value={row.filename} // Bind the value
                                                                                    onChange={(e) =>
                                                                                        handleInputMultiattach(index, 'filename', selectedRowdet, e.target.value) // Update filename
                                                                                    }
                                                                                    placeholder="Enter File Name"
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <div>
                                                                                    <input
                                                                                        type="file"
                                                                                        name="url"
                                                                                        style={{ margin: '10px' }}
                                                                                        onChange={(e) =>
                                                                                            handleInputMultiattach(index, 'url', selectedRowdet, e.target.files[0]?.name) // Update file URL
                                                                                        }
                                                                                    />
                                                                                    {row.url && (
                                                                                        <a href={row.url} target="_blank" rel="noopener noreferrer">
                                                                                            View File
                                                                                        </a>
                                                                                    )}
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                ) : (
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} align="center">
                                                                            No records found
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>



                                                        </Table>
                                                    </TableContainer>


                                                </>
                                            )}
                                        </DialogContent>


                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Cancel
                                            </Button>

                                        </DialogActions>
                                    </form>
                                </Dialog>
                                <Dialog
                                    className="dialog"
                                    open={open2}
                                    onClose={handleClose}
                                    maxWidth="lg"
                                    fullWidth
                                    sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}
                                >
                                    <form>
                                        <DialogTitle sx={{
                                            fontSize: '1.3rem', // Adjust the font size as needed
                                        }}>
                                            <strong>Engineering Change Request</strong>
                                        </DialogTitle>
                                        <DialogContent>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>S.No</TableCell>
                                                            <TableCell>Enq No</TableCell>
                                                            <TableCell>Date</TableCell>
                                                            <TableCell>Case Model</TableCell>
                                                            <TableCell>Change Detail</TableCell>
                                                            <TableCell>Reason of Changes</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {/* <TableBody>
                                                        {formData.enquiry[0].tableRows[0].enqecr.map((item, index) => (
                                                            <TableRow key={item.enqid} onClick={() => handleClickOpen3(index)}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{item.ecrno}</TableCell>
                                                                <TableCell>{item.date}</TableCell>
                                                                <TableCell>{item.casemodel}</TableCell>
                                                                <TableCell>{item.changedet}</TableCell>
                                                                <TableCell>{item.reasonchange}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody> */}
                                                </Table>
                                            </TableContainer>

                                            <br />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 18, // Optional: Adds spacing between Typography and TextField
                                                }}
                                            >
                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                    <strong> Change Detail : </strong>
                                                </Typography>

                                                <TextField
                                                    id='changedet'
                                                    name="changedet"
                                                    variant="outlined"
                                                    multiline
                                                    value={formData?.enquiry[0].tableRows[selectedRowdet]?.changedet || ''}
                                                    onChange={handleInputChangesEcr('changedet')}
                                                    sx={{ width: '300px' }}
                                                />
                                                {/* <textarea
                                                    style={{
                                                        width: '300px',
                                                        height: '100px',
                                                        borderColor: 'GrayText',
                                                        padding: '8px', // Adjust padding
                                                        resize: 'none', // Prevent resizing if needed
                                                    }}
                                                /> */}




                                            </Box>
                                            <br />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 15, // Optional: Adds spacing between Typography and TextField
                                                }}
                                            >
                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                    <strong> Reason of change : </strong>
                                                </Typography>

                                                <TextField
                                                    id='reasonchange'
                                                    name="reasonchange"
                                                    variant="outlined"
                                                    multiline
                                                    value={formData?.enquiry[0].tableRows[selectedRowdet]?.reasonchange || ''}
                                                    onChange={handleInputChangesEcr('reasonchange')}
                                                    sx={{ width: '300px' }}
                                                />

                                            </Box>
                                            <br />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 4, // Optional: Adds spacing between Typography and TextField
                                                }}
                                            >
                                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontSize: 14 }}>
                                                    <strong>Specify any test to be conducted : </strong>
                                                </Typography>

                                                <TextField
                                                    id='spltestcon'
                                                    name="spltestcon"
                                                    variant="outlined"
                                                    multiline
                                                    value={formData?.enquiry[0].tableRows[selectedRowdet]?.spltestcon || ''}
                                                    onChange={handleInputChangesEcr('spltestcon')}
                                                    sx={{ width: '300px' }}
                                                />

                                            </Box>
                                            <br />



                                        </DialogContent><br />
                                        <DialogActions>
                                            <Button onClick={handleClose} color="primary">
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Dialog>

                                {/* <Button sx={{ margin: 1 }} onClick={handleSubmit} variant='contained' color="primary">
                                    Save
                                </Button> */}
                                <Button sx={{ margin: 1 }} variant='contained' color="warning" onClick={handlecancel}>
                                    Cancel
                                </Button>


                            </CardContent>
                        </Card>

                    </Grid>

                </Grid>
                <Dialog open={open3} onClose={handleClose1} fullWidth maxWidth="md">
                    <DialogTitle>ECR Form</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} ref={printRef} style={{ padding: '20px' }}>

                            <Grid item xs={12}>
                                <div className="printable-content" >
                                    <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '40px', height: '40px', marginRight: '20px' }}
                                        />
                                        <Typography variant="h3" style={{ margin: 0 }}>Engineering Change Request</Typography>
                                    </header>
                                </div>
                            </Grid>

                            {/* Enq No and I/P No */}
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Enq No :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="Enq No." fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>I/P No :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="I/P No." fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* ECR Number and Date */}
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>ECR Number :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="ECR Number" fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Date :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            label="Date"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Customer Name */}
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Customer Name :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="Customer Name" fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Case Model & DRG No. */}
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Case Model :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="Case Model" fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>DRG No. :</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField label="DRG No." fullWidth variant="outlined" />
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Change Detail */}
                            <Grid item xs={12}>
                                <TextField label="Change Detail" fullWidth multiline rows={4} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="REASON OF CHANGE :" fullWidth multiline rows={4} variant="outlined" />
                            </Grid>
                        </Grid>

                        {/* Submit and Cancel Buttons */}
                        <Grid item xs={12} direction="row" style={{ textAlign: "right" }}>
                            <Button onClick={handleClose1} color="primary" variant="contained">
                                Cancel
                            </Button>

                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<FontAwesomeIcon icon={faPrint} />}
                                onClick={handlePrint1}
                                sx={{
                                    m: 1,
                                    width: '10ch',
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                            >
                                Print
                            </Button>
                        </Grid>


                    </DialogContent>
                </Dialog>
                <Dialog className="dialog" open={open4} onClose={handleClose} maxWidth="lg" fullWidth>
                    <form>
                        <DialogTitle>
                            <strong> Test Accessories</strong>
                        </DialogTitle>
                        <DialogContent>
                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead"><strong>S.No</strong></TableCell>
                                                <TableCell className="tableHead"><strong>Name</strong></TableCell>
                                                <TableCell className="tableHead"><strong>Remarks</strong></TableCell>
                                                <TableCell className="tableHead" width="25%"><strong>Yes / No</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {test
                                                .filter((row) => row.type === "test" && row.reqtypeid === 1 && row.isactive === 1)
                                                .map((row, index) => {

                                                    const testAccessEntry = formData?.enquiry[0]?.testaccess?.find(
                                                        entry => entry.testaccessid === row.id

                                                    );
                                                    const selectedValue = testAccessEntry ? (testAccessEntry.testaccessid !== null ? "Yes" : "No") : "No";
                                                    const remarksValue = testAccessEntry?.remarks || ""; // Safely handle undefined
                                                  
                                                   

                                                    return (
                                                        <TableRow key={row.id}>
                                                            <TableCell className="tableBody">{index + 1}</TableCell>
                                                            <TableCell className="tableBody">{row.name}</TableCell>
                                                            <TableCell className="tableBody">
                                                                <TextField
                                                                    value={remarksValue}
                                                                    onChange={(e) =>
                                                                        setFormData(prevFormData => {
                                                                            const updatedTestAccess = prevFormData.enquiry[0].testaccess.map(entry =>
                                                                                entry.testaccessid === row.id
                                                                                    ? { ...entry, remarks: e.target.value }
                                                                                    : entry
                                                                            );
                                                                            return {
                                                                                ...prevFormData,
                                                                                enquiry: [
                                                                                    {
                                                                                        ...prevFormData.enquiry[0],
                                                                                        testaccess: updatedTestAccess
                                                                                    }
                                                                                ]
                                                                            };
                                                                        })
                                                                    }
                                                                />
                                                            </TableCell>
                                                            <TableCell className="tableBody">
                                                                <RadioGroup
                                                                    value={selectedValue} // This should be "Yes" or "No" based on whether testaccessid exists for the current row
                                                                    onChange={(e) => handleRadioChange(index, row.id, e.target.value)} // Handle changes
                                                                    sx={{ flexDirection: "row" }}
                                                                >
                                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                                </RadioGroup>
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Cancel</Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Dialog className="dialog" open={open5} onClose={handleClose} maxWidth="md" >
                    <form>
                        <DialogTitle>
                            <strong> Drawing Requirement</strong>
                        </DialogTitle>
                        <DialogContent>
                            <Paper>
                                <TableContainer>
                                    <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
                                        <TableRow><TableCell colSpan={4}><b><strong>Drawing Requirement</strong></b></TableCell></TableRow>
                                        <TableRow>
                                            <TableCell >Customer Name : </TableCell>
                                            <TableCell><strong>{formData?.enquiry[0].name}</strong></TableCell>
                                            <TableCell >Input Date </TableCell>
                                            <TableCell> <TextField
                                                name='ipdate'
                                                type="date"
                                                value={formData?.enquiry?.[0]?.ipdate ? formData.enquiry[0].ipdate.split('T')[0] : ''}
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true, // Ensures the label stays above the input field
                                                }}
                                            /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2}>From : Marketing</TableCell>
                                            <TableCell colSpan={2}>To : Design</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>I/P No</TableCell>
                                            <TableCell>
                                                <TextField
                                                    name='ipno'
                                                    placeholder="Input number"
                                                    value={formData?.enquiry[0].ipno}
                                                    onChange={handleInputChanges}
                                                    multiline
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none', // Removes border
                                                        },
                                                        width: '150px',
                                                    }}
                                                />

                                            </TableCell>
                                            <TableCell>No. of Eqpts. Packed/Box</TableCell>
                                            <TableCell><TextField
                                                value={formData?.enquiry[0].noofeqpt}
                                                onChange={handleInputChanges}
                                                name='noofeqpt'
                                                placeholder='No. of Eqpts. Packed/Box'
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '180px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Drg. Category</TableCell>
                                            <TableCell><TextField
                                                select
                                                value={formData?.enquiry[0].drawcatgid}
                                                onChange={handleInputChanges}
                                                name='drawcatgid'
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    // width: '150px'

                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value={0}>
                                                    Select Categery
                                                </MenuItem>
                                                {
                                                    drawing.filter(t => t.isactive === 1).map((option) => (
                                                        <MenuItem key={option?.id} value={option?.id}>
                                                            {option?.name}
                                                        </MenuItem>
                                                    ))}</TextField>
                                            </TableCell>
                                            <TableCell>Spl. Accs. (If any)</TableCell>
                                            <TableCell><TextField
                                                value={formData?.enquiry[0].splacc}
                                                onChange={handleInputChanges}
                                                placeholder='Spl. Accs.'
                                                name='splacc'
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Drg. Purpose</TableCell>
                                            <TableCell><TextField
                                                select
                                                name='purposeid'
                                                value={formData?.enquiry[0].purposeid}
                                                onChange={handleInputChanges}

                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    // width: '150px'
                                                }}
                                                fullWidth
                                            >
                                                <MenuItem value={0}>
                                                    Select Purpose
                                                </MenuItem>
                                                {
                                                    drawingPurpose.filter(t => t.isactive === 1).map((option) => (
                                                        <MenuItem key={option?.id} value={option?.id}>
                                                            {option?.name}
                                                        </MenuItem>
                                                    ))}

                                            </TextField></TableCell>
                                            <TableCell>Testing Standard</TableCell>
                                            <TableCell><TextField
                                                name='teststd'
                                                placeholder='Testing Standard'
                                                value={formData?.enquiry[0].teststd}
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Drg. Required on</TableCell>
                                            <TableCell><TextField
                                                type='date'
                                                name='drawreqdate'
                                                value={formData?.enquiry[0]?.drawreqdate ? formData?.enquiry[0].drawreqdate.split('T')[0] : ''}
                                                // value={formData?.enquiry?.[0]?.ipdate ? formData.enquiry[0].ipdate.split('T')[0] : ''}
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                InputLabelProps={{
                                                    shrink: true, // Ensures the label stays above the input field
                                                }}
                                            /></TableCell>

                                            <TableCell>Eqpt. Type</TableCell>
                                            <TableCell><TextField
                                                name='eqpttype'
                                                value={formData?.enquiry[0].eqpttype}
                                                placeholder='Eqpt. Type'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Project Name</TableCell>
                                            <TableCell><TextField
                                                name='proname'
                                                value={formData?.enquiry[0].proname}
                                                placeholder='Project Name'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                            <TableCell>Eqpt. Sensitivity</TableCell>
                                            <TableCell><TextField
                                                name='eqptsen'
                                                value={formData?.enquiry[0].eqptsen}
                                                placeholder='Eqpt. Sensitivity'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Eqpt. Size (LBH)</TableCell>
                                            <TableCell><TextField
                                                name='eqptsize'
                                                value={formData?.enquiry[0].eqptsize}
                                                placeholder='Eqpt. Size (LBH)'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                            <TableCell>Application</TableCell>
                                            <TableCell><TextField
                                                name='app'
                                                value={formData?.enquiry[0].app}
                                                placeholder='Application'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Eqpt. Wt.</TableCell>
                                            <TableCell><TextField
                                                name='eqptwt'
                                                value={formData?.enquiry[0].eqptwt}
                                                placeholder='Eqpt. Wt.'
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>

                                            <TableCell>Special note if any</TableCell>
                                            <TableCell><TextField
                                                name='splnote'
                                                placeholder='Input number'
                                                value={formData?.enquiry[0].splnote}
                                                onChange={handleInputChanges}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: 'none',
                                                    },
                                                    width: '150px'
                                                }}
                                                multiline /></TableCell>
                                        </TableRow>
                                    </Table>

                                </TableContainer>
                            </Paper>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">Close</Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </Container >
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </>
    );
}

export default memo(UpdateReqClarify);

const tabStyles = {
    hot: {
        backgroundColor: 'red',
        color: 'white',
    },
    warm: {
        backgroundColor: 'orange',
        color: 'white',
    },
    cold: {
        backgroundColor: 'green',
        color: 'white',
    },
};


