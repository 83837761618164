import React, { useState, useEffect, useRef } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CardContent,
    Card,
    TextField,
    Grid,
} from '@mui/material';
import apiService from 'src/services/api-service';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';


interface DataItem {
    id: number;
    code: string;
    name: string;
    mobile: string;
    email: string;
    gstno: string;
    pan: string;
    tinno: string;
    enqcount: number;
    pocount: number;
    poamt: number;
    cusid: number;
}

interface RowData {
    id: number,
    enqno: string,
    enqdate: string,
    mode: string,
    status: string,
    lastfdate: string,
    lastfdesc: string,
    nextfdate: string
}



const EnquiryTable: React.FC = () => {
    const [data, setData] = useState<DataItem[]>([]);
    const [rowdata, setRowdata] = useState<RowData[]>([]);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [state, setState] = useState<{ id: number | null, name: string | null }>({ id: null, name: null });
    const [showImage, setShowImage] = useState(false);


    useEffect(() => {
        apiService.getCustomerDetail('')
            .then(response => {
                setData(response.data);
                console.log('Data fetched roww:', response.data); // Logging fetched data
            })
            .catch(err => console.error('Error fetching enquiry data:', err)); // Corrected error message
    }, [search]);

    useEffect(() => {
        console.log('State ID:', state.id);
        if (state.id) {
            apiService.getCusDetail(Number(state.id))
                .then(response => {
                    const data = response.data;
                    console.log('Fetched data:', data);
                    setRowdata(data)

                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [state.id]);

    const handleRowClick = (id: number, name: string) => {
        console.log('row', id);
        setState(prevState => ({ ...prevState, id, name }));
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);

    };

    const [isPrinting, setIsPrinting] = useState(false);

    const printRef = useRef(null);
    const contentToPrint: any = useRef(null);
    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Reort",
        // onBeforeGetContent: () => setShowImage(true),
        onBeforeGetContent: () => {
            setIsPrinting(true);
            setShowImage(true);
            return Promise.resolve();  // Ensures proper timing
        },
        onAfterPrint: () => {
            setIsPrinting(false);
            console.log("after printing...");
        },
        removeAfterPrint: true,

    });

    const handleExportToExcel = () => {
        const title = ['Customer Detail Report'];
        const blankRow = [];
        const headers = [
            'S.No', 'Code', 'Customer Name', 'Mobile', 'Email', 'Gst', 'Pan', 'Tin',
            'Total Enquiry Count', 'Total Po Count', 'Total Po Amount'
        ];

        // Prepare data for Excel
        const dataRows = data.map((row, index) => ({
            'S.No': index + 1,
            'Code': row.code,
            'Customer Name': row.name,
            'Mobile': row.mobile,
            'Email': row.email,
            'Gst': row.gstno,
            'Pan': row.pan,
            'Tin': row.tinno,
            'Total Enquiry Count': row.enqcount,
            'Total Po Count': row.pocount,
            'Total Po Amount': row.poamt
        }));

        // Create a new worksheet and add the title, blank row, and headers
        const ws = XLSX.utils.json_to_sheet([]);

        // Adding title to the worksheet
        XLSX.utils.sheet_add_aoa(ws, [title], { origin: 'A1' });

        // Adding a blank row
        XLSX.utils.sheet_add_aoa(ws, [blankRow], { origin: 'A2' });

        // Adding headers
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A3' });

        // Adding data rows starting from row 4
        XLSX.utils.sheet_add_json(ws, dataRows, { header: headers, skipHeader: true, origin: 'A4' });

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Customer Data');

        // Save the file
        XLSX.writeFile(wb, 'CustomerDetailReport.xlsx');
    };

    const handleSearch = () => {
        apiService.getCustomerDetail(search)
            .then(response => {
                setData(response.data);
                console.log('Data fetched roww:', response.data); // Logging fetched data
            })
            .catch(err => console.error('Error fetching enquiry data:', err));
    };

    return (
        <>
            <PageTitleWrapper>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <PageTitle
                            heading="Customer Detail Report"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={9}
                        container
                        spacing={1}
                        justifyContent="space-between"  // Distribute space between search and buttons
                        alignItems="center"             // Vertically align the content
                    >


                        <Grid
                            item
                            // xs={6}
                            container
                            justifyContent="flex-end"   // Align buttons to the right
                            gap={1}                     // Add spacing between buttons
                        >
                            <TextField
                                label="Search"
                                variant="outlined"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            // fullWidth


                            />
                            <Button
                                variant="contained"
                                color="primary"
                                // startIcon={<FontAwesomeIcon icon={faPrint} />}
                                onClick={handleSearch}
                                sx={{
                                    m: 1,
                                    width: '10ch',
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                            >
                                Search
                            </Button>
                            {localStorage.getItem("urole") === "Head of Marketing" ||
                                localStorage.getItem("urole") === "Administrator" ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<FontAwesomeIcon icon={faPrint} />}
                                        onClick={handlePrint1}
                                        sx={{
                                            m: 1,
                                            width: '10ch',
                                            '& .MuiInputBase-root': {
                                                height: '5%'  // Adjust this value as needed
                                            }
                                        }}
                                    >
                                        Print
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="success"
                                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                                        onClick={handleExportToExcel}
                                        sx={{
                                            m: 1,
                                            width: '10ch',
                                            '& .MuiInputBase-root': {
                                                height: '5%'  // Adjust this value as needed
                                            }
                                        }}
                                    >
                                        Excel
                                    </Button>
                                </>
                            ) : (<></>)}
                        </Grid>
                    </Grid>

                </Grid>
            </PageTitleWrapper>
            <Card ref={printRef}>
                <CardContent>
                    <div className="printable-content" style={{ padding: '20px' }}>
                        <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                            <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '50px', height: '50px', marginRight: '20px' }}
                            />
                            <h1 style={{ margin: 0 }}>Customer Detail Report</h1>
                        </header>
                    </div>
                    <TableContainer
                        // component={Paper}
                        sx={{
                            width: '100%',
                            overflowX: 'auto',
                            '@media print': {
                                width: '100%',  // Ensure the table uses full width in print
                                overflow: 'visible', // Allow the content to be visible during printing
                            },
                        }}
                    >
                        <Table
                            sx={{
                                width: '100%',
                                '@media print': {
                                    width: '100%',  // Table uses full width on print
                                    tableLayout: 'fixed',  // Ensure fixed table layout for print
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '5%', '@media print': { width: '10%' } }}>S.No</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Code</TableCell>
                                    <TableCell sx={{ width: '20%', '@media print': { width: '20%' } }}>Customer Name</TableCell>
                                    <TableCell sx={{ width: '15%', '@media print': { width: '15%' } }}>Mobile</TableCell>
                                    <TableCell sx={{ width: '15%', '@media print': { width: '15%' } }}>Email</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Gst</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Pan</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Tin</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Total Enquiry Count</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Total Po Count</TableCell>
                                    <TableCell sx={{ width: '10%', '@media print': { width: '10%' } }}>Total Po Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell><strong>{row.code}</strong></TableCell>
                                        <TableCell><strong>{row.name}</strong></TableCell>
                                        <TableCell>{row.mobile}</TableCell>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.gstno}</TableCell>
                                        <TableCell>{row.pan}</TableCell>
                                        <TableCell>{row.tinno}</TableCell>
                                        <TableCell>
                                            {isPrinting ? (
                                                row.enqcount
                                            ) : (
                                                <Button onClick={() => handleRowClick(row.id, row.name)}>
                                                    {row.enqcount}
                                                </Button>
                                            )}
                                        </TableCell>
                                        <TableCell>{row.pocount}</TableCell>
                                        <TableCell>{row.poamt}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>



                </CardContent></Card>
            <Dialog className="dialog" open={open} onClose={handleClose}
                maxWidth="lg" // You can set 'sm', 'md', 'lg', 'xl', or false
                fullWidth
                sx={{ '& .MuiDialog-paper': { width: '1000px', maxWidth: 'none' } }}>
                <form>
                    <DialogTitle>Enquiry of <strong>{state.name}</strong></DialogTitle>
                    <DialogContent>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableHead" >S.No</TableCell>
                                            <TableCell className="tableHead"> Enquiry No</TableCell>
                                            <TableCell className="tableHead">Enquiry Date</TableCell>
                                            <TableCell className="tableHead">Mode</TableCell>
                                            <TableCell className="tableHead">Status	</TableCell>
                                            <TableCell className="tableHead">Last Follow Date</TableCell>
                                            <TableCell className="tableHead">Last Follow Description</TableCell>
                                            <TableCell className="tableHead">Next Follow Date</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {rowdata.map((row, index) => (
                                            <TableRow key={row.id} >
                                                <TableCell className="tableBody">{index + 1}</TableCell>
                                                <TableCell className="tableBody">{row.enqno}</TableCell>
                                                <TableCell className="tableBody">{row.enqdate}</TableCell>
                                                <TableCell className="tableBody">{row.mode}</TableCell>
                                                <TableCell className="tableBody">{row.status}</TableCell>
                                                <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                                <TableCell className="tableBody">{row.lastfdesc}</TableCell>
                                                <TableCell className="tableBody">{row.nextfdate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>

                    </DialogActions>
                </form>
            </Dialog>

            <style>
                {`
                    @media print {
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
            </style>

        </>
    );
};



export default EnquiryTable;
