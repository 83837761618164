import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { formatDistance, subDays } from 'date-fns';
import apiService from 'src/services/api-service';
import { CenterFocusStrong } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [countData, setCountData] = useState([]);

  const handleOpen = (): void => {
    apiService.getNotify({})
      .then(response => setCountData(response.data))
      .catch(err => console.error('Error fetching count data:', err));
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  useEffect(() => {
    apiService.getNotify({})
      .then(response => {
        setCountData(response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));
  }, []);

  const totalCount = countData.reduce((total, item) => total + item.count, 0);
  const navigate = useNavigate();

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={totalCount}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{ p: 2 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5"> Notifications </Typography>
          <Typography marginTop={1} > <NotificationsActiveTwoToneIcon /></Typography>

        </Box>
        <Divider />
        <List sx={{ p: 0 }}>
          <ListItem
            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
          >
            {/* <Box flex="1"> */}

            <Box>
              <Typography
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{ fontWeight: 'bold' }}
              >
                {countData.map((item) => (

                  <div
                    key={item.name}
                    onClick={() => {
                      if (item.name === 'Budget Quotation') {
                        handleClose();
                        navigate('/MarketingCRM/BudgetQuotationPending');
                      }
                      if (item.name === 'Concept Drawing') {
                        handleClose();
                        navigate('/Design/DesignFollowup');
                      }
                      if (item.name === 'Request To Clarify') {
                        handleClose();
                        navigate('/MarketingCRM/requesttoclarify');
                      }
                      if (item.name === 'ECR') {
                        handleClose();
                        navigate('/Design/DesignFollowup');
                      }
                      if (item.name === 'Design Approve') {
                        handleClose();
                        navigate('/Design/DrawingApprovalPendinglist');
                      } else {
                        handleClose();
                      }
                      if (item.name === 'HOD') {
                        handleClose();
                        navigate('/hrms/hodapproval');
                      } else {
                        handleClose();
                      }
                      if (item.name === 'HR') {
                        handleClose();
                        navigate('/hrms/hrapproval');
                      } else {
                        handleClose();
                      }
                    }}
                    style={{
                      cursor:
                        item.name === 'Budget Quotation' ||
                          item.name === 'Concept Drawing' ||
                          item.name === 'Request To Clarify' ||
                          item.name === 'ECR' ||
                          item.name === 'Design Approve' ||
                          item.name === 'HOD' ||
                          item.name === 'HR' 
                          ? 'pointer'
                          : 'default',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between', // Use space-between for left and right alignment
                        marginLeft: '35px',
                        marginRight: '35px',
                        marginBottom: '10px',
                        marginTop: '10px',
                        width:250
                      }}
                    >
                      <div >{`${item.name} Pending`}</div>
                    
                      <span
                        style={{
                          backgroundColor: '#1b34b3',
                          color: '#fff',
                          borderRadius: '12px',
                          padding: '2px 8px',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          justifyContent: 'flex-start',
                        }}
                      >
                        {item.count}
                      </span>
                    </div>
                    <hr
                      style={{
                        width: '150%',
                        margin: '10px 0',
                        borderColor: '#eee',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                      }}
                    />
                  </div>
                ))}
              </Typography>
            </Box>

            {/* </Box> */}
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
