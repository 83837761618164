// import axios from 'axios';

//   const API_URL = 'https://localhost:7202/api/';
//  // const API_URL = 'https://saarcapi.netkampus.in/api/';

// export const login = async (username: string, password: string) => {
//   const response = await axios.post(API_URL + 'login?username=' +username +'&password='+ password);
//   if (response.data.token) {
//     localStorage.setItem('token', response.data.token);
//     console.log(response.data.token,"response.data.token");
//   }
//   console.log(response.data,"response.data");
//   return response.data;
// };

import api from './api-jwt';  // Assuming this is the axios instance
import apilogin from './api_jwtlogin';

export const login = async (username: string, password: string) => {
  try {
    const response = await apilogin.post('login?username=' +username +'&password='+ password +'&machineName='+window.location.hostname);
    if (response.data.token) {
      // Store the token in localStorage
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('uid', response.data.res[0]?.id);
      localStorage.setItem('uname', response.data.res[0]?.displayname);
      localStorage.setItem('urole', response.data.res[0]?.role);
      localStorage.setItem('empid', response.data.res[0]?.empid);
      console.log(response.data, "response.data.token");
    }
    
    console.log(response.data, "response.data");
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;  // Handle error appropriately in the calling function
  }
};


export const vpass = async (id: string) => {
  try {
    const response = await apilogin.get('/visitorpass/get_visitor/'+id);
    console.log(response.data, "response.data");
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    throw error;  // Handle error appropriately in the calling function
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const getCurrentUserToken = () => {
  return localStorage.getItem('token');
};