import React, { useEffect, useRef, useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, TextField, Button, MenuItem, Grid, useMediaQuery, useTheme, Checkbox, DialogActions, Dialog, DialogContent, DialogTitle, Card, CardContent } from '@mui/material';
import apiService from 'src/services/api-service';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';
import { format } from 'date-fns';
import { ToastContainer } from 'react-toastify';
import '../saleorder/ProformaInvoice.css';

const formatDateToYYYYMMDD = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const day = String(d.getDate()).padStart(2, '0'); // Ensure 2 digits
  return `${year}-${month}-${day}`;
};

interface FollowDataItem {

  id: number;
  fdate: Date; // Change from string to Date
  ftypeid: number;
  priority: string;
  fby: number;
  desp: string;
  nfdate: Date; // Change from string to Date
  statusid: number;
  ftypename: string;
  fbyname: string;
  statusname: string;
  fdate_s: string;
  nfdate_s: string;


}
type FollowData = FollowDataItem[];

interface EnqQuote {
  id: number;
  qdate: Date;          // Date object for date values
  qdate_s: string;      // String representation of date, if needed
  qno: string;          // Quote number
  rno: number;          // Reference number
  qval: number;         // Quote value
  cusref: string;       // Customer reference
  cuspocname: string;  // Customer Point of Contact name
  cuspocno: string;    // Customer Point of Contact number
  cusdesign: string;   // Customer design
  subject: string;     // Subject of the quote
}

interface EssentialTableRow {
  enqdetid: number;
  essdataid: number;
  compliance: string;
  attach: string;
}

interface TableRowData {
  id: number;
  enqdetid: number | null;  // Null or number depending on the data
  desp: string;
  mpnno: string;     // Null or number depending on the data
  qty: number;
  essentialTableRows: EssentialTableRow[];
  unitPrice?: number; // Add this if it is part of your data
  taxableAmount?: number; // Add this if it is part of your data
  igst?: number;
}

interface FormData {
  tableRows: TableRowData[];
  enqfollow: FollowDataItem[];
  enqquote: EnqQuote[];
  enqNo: string,
  enqdate: string,
  updcheck: boolean;
  finYearId: number;
  lenessrow: number | null;
  empId: number;
  enqID: number | null;
  typeID: number;
  newEx: string;
  modeId: number;
  divId: number;
  rfxEnqNo: number | null;
  dueOn: Date;
  statusId: number;
  desp: string;
  basicValue: number;
  ccperson: string;
  cdesign: string;
  cmobile: string;
  cmail: string;
  tcperson: string;
  tdesign: string;
  tmobile: string;
  tmail: string;
  bankName: string;
  branchname: string;
  accNo: string;
  ifsc: string;
  isExcus: boolean;
  excusid: number;
  sname: string;
  code: string;
  name: string;
  city: string;
  ceo: string;
  md: string;
  mp: string;
  caddr1: string;
  caddr2: string;
  caddr3: string;
  caddr4: string;
  caddr5: string;
  caddr6: string;
  caddr7: string;
  faddr1: string;
  faddr2: string;
  faddr3: string;
  faddr4: string;
  faddr5: string;
  faddr6: string;
  faddr7: string;
  panno: string;
  tinno: string;
  email: string;
  omobile: string;
  pmobile: string;
  gstno: string;
  nbus: string;
  ncomp: string;
  datestart: Date | null;
  cpname: string;
  cpdesign: string;
  cpmobile: string;
  cpmail: string;
  annualturnover: number;
  buildOR: string;
  followtype: string;
  followdesc: string;
  isqul: boolean | null;
  nextfollow: string;
  priority: string;
  nfteamid: number | null;
  nfempid: number | null;
  attch: string;
}
interface GST {
  id: number;
  name: string;
  sname: string;
  per: number;
}

interface LocationState1 {
  id: string;
}

interface Dist {
  id: number;
  name: string;
  stateid: number;
}

interface State {
  id: number;
  name: string;
}

const Quotation: React.FC = () => {

  const location = useLocation();
  const state = location.state as LocationState1;

  const [companyData, setCompanyData] = useState<any[]>([]); // Assuming companyData will be an array of objects
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [term, setTerm] = useState([])
  const [term1, setTerm1] = useState([])
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const defaultTextValues = [
    "Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.",
    "Looking forward to your valuable purchase order to serve you on time."
  ];
  const [freightPercentage, setFreightPercentage] = useState(0);
  const [packPercentage, setPackPercentage] = useState(0);
  const [DocPercentage, setDocPercentage] = useState(0);
  const [filteredQuotes, setFilteredQuotes] = useState([]);
  const [dataToUpdate1, setDataToUpdate1] = useState([]);
  const [taxname, setTaxName] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [dist, setDist] = useState<Dist[]>([]);
  const [dist1, setDist1] = useState<Dist[]>([]);

  const [states1, setStates1] = useState<State[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const [formData, setFormData] = useState<FormData>({
    tableRows: [
      {
        id: 0,
        enqdetid: null,
        desp: "",
        mpnno: '',
        qty: 0,
        unitPrice: 0,
        taxableAmount: 0,
        essentialTableRows: [
          {
            enqdetid: 0,
            essdataid: 0,
            compliance: "",
            attach: ""
          }
        ]
      },
    ],
    enqfollow: [
      {
        id: 0,
        fdate: new Date(),
        ftypeid: 0,
        priority: "",
        fby: 0,
        desp: "",
        nfdate: new Date(),
        statusid: 0,
        ftypename: "",
        fbyname: "",
        statusname: "",
        fdate_s: "",
        nfdate_s: ""
      }
    ],
    enqquote: [
      {
        id: 0,
        qdate: new Date(),
        qdate_s: "",
        qno: "0",
        rno: 0,
        qval: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        subject: ""
      }
    ],
    updcheck: false,
    finYearId: 0,
    lenessrow: null,
    enqNo: '',
    enqdate: '',
    empId: 0,
    enqID: 0,
    typeID: 0,
    newEx: "",
    modeId: 0,
    divId: 0,
    rfxEnqNo: null,
    dueOn: new Date(),
    statusId: 0,
    desp: "",
    basicValue: 0,
    ccperson: "",
    cdesign: "",
    cmobile: "",
    cmail: "",
    tcperson: "",
    tdesign: "",
    tmobile: "",
    tmail: "",
    bankName: "",
    branchname: "",
    accNo: "",
    ifsc: "",
    isExcus: false,
    excusid: 0,
    sname: "",
    code: "",
    name: "",
    city: "",
    ceo: "",
    md: "",
    mp: "",
    caddr1: "",
    caddr2: "",
    caddr3: "",
    caddr4: "",
    caddr5: "",
    caddr6: "",
    caddr7: "",
    faddr1: "",
    faddr2: "",
    faddr3: "",
    faddr4: "",
    faddr5: "",
    faddr6: "",
    faddr7: "",
    panno: "",
    tinno: "",
    email: "",
    omobile: "",
    pmobile: "",
    gstno: "",
    nbus: "",
    ncomp: "",
    datestart: null,
    cpname: "",
    cpdesign: "",
    cpmobile: "",
    cpmail: "",
    annualturnover: 0,
    buildOR: "",
    followtype: "",
    followdesc: "",
    isqul: null,
    nextfollow: "",
    priority: "",
    nfteamid: null,
    nfempid: null,
    attch: ""
  });


  const [quoteData, setQuoteData] = useState({
    enqquotedet: formData.tableRows.map(row => ({
      enqdetid: row.id,
      desp: row.desp,
      mpnno: row.mpnno,
      qty: Number(row.qty), // Ensure qty is a number
      unitprice: row.unitPrice,
      taxableamt: row.taxableAmount,
      taxid: 0,
      taxamt: 0,
      total: 0
    })),
    enqquoteTerms: [],
    query: '',
    qdate: formData.enqquote && formData.enqquote.length > 0
      ? formData.enqquote[0].qdate
      : new Date(), // Provide a default value
    enqid: state.id,

    cusref: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusref : '',
    cuspocname: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocname : '',
    cuspocno: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cuspocno : '',
    cusdesign: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].cusdesign : '',
    qno: formData.enqquote && formData.enqquote.length > 0 ? formData.enqquote[0].qno : '0',
    status: 'Approval pending',
    qval: 0,
    lastqid: 0,
    subject: formData.enqquote && formData.enqquote.length > 0 && formData.enqquote[0].subject
      ? formData.enqquote[0].subject
      : `Many thanks for enquiry. We are pleased to offer our best Quotation for the supply of Transit Cases.
Looking forward to your valuable purchase order to serve you on time.`,
    cby: "1",
    taxid: 1,
    taxamt: 0,
    packchange: 0,
    freight: 0,
    total: 0,
    docchargeper: 0,
    doccharge: 0,
    designcostqty: 0,
    designcostup: 0,
    designcostamt: 0,
    testchargeqty: 0,
    testchargeup: 0,
    testchargeamt: 0,
    dummycostqty: 0,
    dummycostup: 0,
    dummycostamt: 0,
    jigsfixqty: 0,
    jigsfixup: 0,
    jigsfixamt: 0,
    subtotal: 0,
    packchangeper: 0,
    freightper: 0,
    faddr1: formData.faddr1,
    faddr2: formData.faddr2,
    faddr3: formData.faddr3,
    faddr4: formData.faddr4,
    faddr5: formData.faddr5,
    cusid: formData.excusid
  });



  useEffect(() => {
    if (term && term.length > 0) {
      const filteredTerms = term
        .filter(item => item.type === "0" || item.type === "1")
        .map(item => ({
          id: 0,       // Set id appropriately
          qid: 0,      // Set qid appropriately
          type: item.type,
          termId: item.id,
          name: item.name,
          checked: true
        }));

      const checkTerms = filteredTerms.filter(item => item.checked);

      setQuoteData(prevQuoteData => ({
        ...prevQuoteData,
        enqquoteTerms: checkTerms, // Ensuring it's a flat array
      }));
    }
  }, [term]);




  // Ensure you have a way to set the item field
  const handleItemChange = (event) => {
    setQuoteData(prevData => ({
      ...prevData,
      item: event.target.value
    }));
  };



  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [currentDate] = useState(getCurrentDate());
  const [gst, setGst] = useState<GST[]>([]);


  useEffect(() => {
    apiService.getDropdown("mas_company").then(response => {
      setCompanyData(response.data?.map((data: any) => ({
        id: data?.id,
        name: data?.name,
        sname: data?.sname,
        mobile: data?.mobile,
        phone: data?.phone,
        email: data?.email,
        pan: data?.pan,
        gstno: data?.gstno,
        tinno: data?.tinno,
        hsncode: data?.hsncode,
        gemsellerid: data?.gemsellerid,
        add1: data?.add1,
        add2: data?.add2,
        add3: data?.add3,
        add4: data?.add4,
        add5: data?.add5,
        add6: data?.add6,
        mon: data?.mon
      })));
    }).catch(err => {
      console.log("err", err);
    });
  }, []);

  useEffect(() => {
    apiService.getDropdown("crm_mas_quoteterms")
      .then(response => {
        const filteredData = response.data.filter(item => item.isactive === 1);
        setTerm(filteredData); // Set the filtered data
      })
      .catch(err => {
        console.log("err", err);
      });

    apiService.getDropdown("crm_mas_tax").then(response => {
      setGst(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, []);



  useEffect(() => {
    console.log(state.id, "Enqid");
    if (state.id) {
      // Fetch data if id is provided (for updating an enquiry)
      apiService.getquoteId(Number(state.id))
        .then(response => {
          const data = response.data;
          console.log("response => cus id =>", data);

          // Update the formData state with the fetched data
          setFormData(data as FormData);

        })
        .catch(error => {
          console.error("Error fetching data: ", error);
        });
    }
  }, [state.id]);

  useEffect(() => {
    if (companyData.length > 0) {
      setSelectedCompany(companyData[0]);
    }
  }, [companyData]);

  const [igst, setIgst] = useState<number>(0);


  useEffect(() => {
    const totalAmount = formData.tableRows.reduce((sum, row) => sum + (row.taxableAmount || 0), 0);
    const finalTotal = totalAmount + igst;

    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      enqquotedet: formData.tableRows.map(row => ({
        enqdetid: row.id,
        desp: row.desp,
        mpnno: row.mpnno,
        qty: row.qty,
        unitprice: row.unitPrice,
        taxableamt: row.taxableAmount,
        taxid: 0,
        taxamt: igst,
        total: 0
      })),
      qval: totalAmount,
      taxamt: 0,
      packchange: 0,
      freight: 0,
      total: finalTotal,
      faddr1: formData.faddr1,
      faddr2: formData.faddr2,
      faddr3: formData.faddr3,
      faddr4: formData.faddr4,
      faddr5: formData.faddr5,
      cusid: formData.excusid
    }));
  }, [formData, igst]);


  const calculateTotals = () => {
    let qval = 0;
    let taxamt = 0;

    // Calculate qval (sum of taxable amounts)
    quoteData.enqquotedet.forEach((item) => {
      qval += item.taxableamt || 0;
      taxamt += item.taxamt || 0;
    });

    // Calculate taxamt, packchange, and freight percentages based on qval
    const packchange = (qval * (quoteData.packchange / 100)) || 0;
    const freight = (qval * (quoteData.freight / 100)) || 0;

    // Calculate total
    const total = qval + packchange + freight + taxamt;

    setQuoteData({ ...quoteData, qval, packchange, freight, taxamt, total });
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Goes to the previous screen without refreshing
  };


  const validate = () => {
    // Check if each row in enqquotedet has valid values for desp, qty, and unitprice
    const hasValidEnqquotedet = quoteData.enqquotedet.every(row =>
      row.desp &&
      row.qty &&
      row.unitprice
    );



    // Combine both conditions
    return hasValidEnqquotedet;
  };
  const validate1 = () => {



    // Check if enqquoteTerms has at least one item
    const isEnqquoteTermsValid = quoteData.enqquoteTerms.some(term => term.checked === true);
    // Combine both conditions
    return isEnqquoteTermsValid;
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = async () => {
    setIsDisabled(true); // Disable the button
    try {
      await handleSubmit(); // Call the async function
    } catch (error) {
      console.error("Error submitting:", error);
      setIsDisabled(false); // Re-enable the button if an error occurs
    }
  };


  const handleSubmit = async () => {
    if (!validate()) {
      const toastmsg = "Quotation is invalid. Please fill all Description ,Quantity and Unitprice fields."
      errorToast(toastmsg)
      setIsDisabled(false);
      return;
    }
    if (!validate1()) {
      const toastmsg = "Quotation is invalid. Please select atleast one Term."
      errorToast(toastmsg)
      setIsDisabled(false);
      return;
    }
    try {

      const dataToUpdate = quoteData;
      console.log("Quotation quoteData", dataToUpdate);
      const response = await apiService.getQuote(dataToUpdate);

      setShouldFetchData(true);

      const success = "Quotation Created Successfully";
      successToast(success);
      setTimeout(() => {
        setIsSaved(true);
      }, 2000);
    } catch (error) {
      setIsDisabled(false);
      console.error('Error saving quote:', error);
    }
  };



  useEffect(() => {
    if (shouldFetchData) {
      // Assuming quoteData is fetched or already available
      const fetchedData1 = quoteData.enqquoteTerms; // Accessing the array inside quoteData

      console.log("fetchedData1 ===> ", fetchedData1);

      setDataToUpdate1(fetchedData1);
      setShouldFetchData(false);
    }
  }, [shouldFetchData]);

  useEffect(() => {
    console.log("dataToUpdate1 updated ===> ", dataToUpdate1); // Log after the update
  }, [dataToUpdate1]);


  const handleQuoteInputChange = (field, value) => {
    setQuoteData(prevQuoteData => ({
      ...prevQuoteData,
      [field]: value,
    }));
  };

  const handleFaddrInputChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleQdateChange = (name, value) => {
    setQuoteData({
      ...quoteData,
      [name]: value, // Update the qdate field
    });
  };

  const handleTermChange = (e, index, type, id) => {
    const { checked, value } = e.target;

    setQuoteData((prevQuoteData) => {
      // Create a copy of the enqquoteTerms array
      const updatedTerms = [...prevQuoteData.enqquoteTerms];

      // Handle checkbox change
      if (type === 'checkbox') {
        updatedTerms[index] = {
          ...updatedTerms[index],
          checked: checked, // Update the checked property
          termId: checked ? id : 0, // If checked, set termId to the passed id; if unchecked, set it to 0
        };

        // Optionally filter terms based on the checked value (for debugging/logging purposes)
        const filteredTerms = updatedTerms.filter(term => term.checked);
        console.log("filteredTerms", filteredTerms);

        return {
          ...prevQuoteData,
          enqquoteTerms: updatedTerms, // Store the updated terms array
        };
      }

      // Handle text field (name) change
      if (type === 'name') {
        if (updatedTerms[index]?.checked) { // Only update the name if the checkbox is checked
          updatedTerms[index] = {
            ...updatedTerms[index],
            name: value, // Update the name property with the new value
          };
        }
        return {
          ...prevQuoteData,
          enqquoteTerms: updatedTerms, // Store the updated terms array with the new name
        };
      }

      return prevQuoteData; // If no valid type, return the state as is
    });
  };

  const handleTermChange1 = (e, index, type, id, name) => {
    const { checked, value } = e.target;

    setQuoteData((prevQuoteData) => {
      // Create a copy of the existing enqquoteTerms object
      const updatedTerms = { ...prevQuoteData.enqquoteTerms };

      // Handle checkbox change
      if (type === 'checkbox') {
        // Update the specific term's checked status
        updatedTerms[id] = {
          ...updatedTerms[id],
          checked: checked,
          termId: checked ? id : 0, // Set termId if checked, otherwise set to 0
        };

        // Log the filtered terms (for debugging purposes)
        const filteredTerms = Object.values(updatedTerms).filter(term => term.checked);
        console.log("filteredTerms", filteredTerms);

        return {
          ...prevQuoteData,
          enqquoteTerms: Object.values(updatedTerms), // Convert object to array for storage
        };
      }

      // Handle text field (name) change
      if (type === 'name') {
        if (updatedTerms[id]?.checked) { // Only update name if the checkbox is checked
          updatedTerms[id] = {
            ...updatedTerms[id],
            name: value, // Update the name property with the new value
          };
        }

        return {
          ...prevQuoteData,
          enqquoteTerms: Object.values(updatedTerms), // Convert object to array for storage
        };
      }

      return prevQuoteData; // If no valid type, return the state as is
    });
  };

  useEffect(() => {
    // Assuming you are fetching or setting quoteData at some point
    setQuoteData((prevData) => {
      const updatedEnqquoteTerms = prevData.enqquoteTerms.map(term => ({
        ...term,
        checked: true // Set checked to true by default
      }));

      // Filter to only include checked terms
      const filteredTerms = updatedEnqquoteTerms.filter(term => term.checked);

      return {
        ...prevData,
        enqquoteTerms: filteredTerms, // Store only the checked terms
      };


    });
  }, []);


  const [isPrintMode, setIsPrintMode] = useState<boolean>(false);

  const contentToPrint = useRef(null);
  const handlePrint1 = useReactToPrint({
    onBeforePrint: () =>
      setIsPrintMode(true), onAfterPrint: () => setIsPrintMode(false),
    removeAfterPrint: true,


  });


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [masterChecked, setMasterChecked] = useState(false);
  const [masterChecked1, setMasterChecked1] = useState(false);

  // Function to handle the main checkbox click
  const handleMasterCheckboxChange = (e) => {
    const checked = e.target.checked;
    setMasterChecked(checked);

    // Update all checkboxes
    term.forEach((item, index) => {
      if (item.type === "0") {
        handleTermChange({ target: { checked } }, index, 'checkbox', item.id);
      }
    });
  };





  const handleMasterCheckboxChange1 = (e) => {
    const checked = e.target.checked;
    setMasterChecked1(checked);

    // Update all checkboxes
    term.forEach((item, index) => {
      if (item.type === "1") {
        handleTermChange1({ target: { checked } }, index, 'checkbox', item.id, item.name);
      }
    });
  };

  useEffect(() => {
    const allChecked = term.filter(item => item.type === "0").every((item, index) =>
      quoteData.enqquoteTerms[index]?.checked
    );
    setMasterChecked(allChecked);
  }, [quoteData.enqquoteTerms, term]);

  useEffect(() => {
    const allChecked1 = term.filter(item => item.type === "1").every((item, index) =>
      quoteData.enqquoteTerms[index]?.checked
    );
    setMasterChecked1(allChecked1);
  }, [quoteData.enqquoteTerms, term]);


  const handleInputChange = (index, field, value) => {
    if(field == "qty" || field == "unitprice"){
      if (!/^\d*$/.test(value)) return;
    }
   
    const updatedData = [...quoteData.enqquotedet];
    updatedData[index][field] = value;
    setQuoteData((prevData) => ({
      ...prevData,
      enqquotedet: updatedData,
    }));
    console.log('quoteData', quoteData);
  };

  const handleInputChange2 = (field, value) => {
    setQuoteData(prevData => ({
      ...prevData,
      [field]: Number(value) // Convert to number if needed
    }));
  };

  // Function to handle select changes (like tax id change)
  const handleSelectChange = (e, index) => {
    const updatedData = [...quoteData.enqquotedet];
    updatedData[index].taxid = e.target.value;
    setQuoteData((prevData) => ({
      ...prevData,
      enqquotedet: updatedData,
    }));
  };


  // Handle packing change
  const handlePackingChange = (value) => {
    const parsedValue = parseFloat(value);
    const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

    // Update only the packchangeper based on user input
    setQuoteData((prevData) => ({
      ...prevData,
      packchangeper: validPercentage,
    }));
  };

  useEffect(() => {
    // Recalculate packchange whenever qval or packchangeper changes
    const packpercentage = quoteData.packchangeper / 100;
    const packingCharge = quoteData.qval * packpercentage;

    setQuoteData((prevData) => ({
      ...prevData,
      packchange: packingCharge,
    }));
  }, [quoteData.qval, quoteData.packchangeper]);

  const handleFreightChange = (value) => {
    // Parse input to float and handle NaN by defaulting to 0
    const parsedValue = parseFloat(value);
    const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

    // Update only the freightper based on user input
    setQuoteData((prevData) => ({
      ...prevData,
      freightper: validPercentage,
    }));
  };

  useEffect(() => {
    // Recalculate freight whenever qval or freightper changes
    const freightPercentage = quoteData.freightper / 100;
    const freightCharge = quoteData.qval * freightPercentage;

    setQuoteData((prevData) => ({
      ...prevData,
      freight: freightCharge,
    }));
  }, [quoteData.qval, quoteData.freightper]);

  const handleDocChanrge = (value) => {
    // Parse input value and handle NaN cases by defaulting to 0
    const parsedValue = parseFloat(value);
    const validPercentage = isNaN(parsedValue) ? 0 : parsedValue;

    // Update only docchargeper based on the user input
    setQuoteData((prevData) => ({
      ...prevData,
      docchargeper: validPercentage,
    }));
  };

  useEffect(() => {
    // Recalculate doccharge whenever qval or docchargeper changes
    const docPercentage = quoteData.docchargeper / 100;
    const docCharge = quoteData.qval * docPercentage;

    setQuoteData((prevData) => ({
      ...prevData,
      doccharge: docCharge,
    }));
  }, [quoteData.qval, quoteData.docchargeper]); // Dependencies: qval and docchargeper


  // Calculate totals
  useEffect(() => {
    let qval = 0;
    let taxamt = 0;

    quoteData.enqquotedet.forEach((item) => {
      const taxableamt = (item.qty || 0) * (item.unitprice || 0);

      qval += taxableamt;

    });
    const selectedTax = gst.find((g) => g.id === quoteData.taxid);
    const taxRate = selectedTax ? selectedTax.per / 100 : 0;
    const taxName = selectedTax ? selectedTax.sname : '';
    const tax = quoteData.subtotal * taxRate;
    const tot = quoteData.subtotal + tax;

    setTaxName(taxName)
    setQuoteData((prevData) => ({
      ...prevData,
      qval,
      taxamt: tax,
      total: tot

    }));

  }, [quoteData.enqquotedet, quoteData.packchange, quoteData.freight, gst, setQuoteData, setTaxName]);

  useEffect(() => {
    console.log("check =>", formData);
    console.log("check =>", quoteData);

  }, [quoteData, formData]);

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleInputChange1 = (event) => {
    setQuoteData({
      ...quoteData,
      subject: event.target.value // Update subject value
    });
  };

  const [isSaved, setIsSaved] = useState(false);

  const [type0, setType0] = useState([]);
  const [type1, setType1] = useState([]);
  const [sname, setSname] = useState([]);

  useEffect(() => {

    const filteredData0 = dataToUpdate1.filter(item => item.type === '0' && item.checked === true);

    setType0(filteredData0)
    const filteredData1 = dataToUpdate1.filter(item => item.type === '1' && item.checked === true);

    setType1(filteredData1)

    const filteredSnameData = filteredData0.map(filteredItem => {
      const matchedTerm = term.find(termItem => termItem.id === filteredItem.termId); // Match by termId
      return matchedTerm ? matchedTerm.sname : null; // Return sname if found, else null
    }).filter(Boolean); // Filter out null values


    setSname(filteredSnameData); // Store the filtered sname values

  }, [dataToUpdate1, term]);

  useEffect(() => {
    apiService.getDropdown("mas_state")
      .then(response => {
        setStates(response.data);
        setStates1(response.data);
      })
      .catch(err => {
        console.log("err", err);
      });
  }, [])



  useEffect(() => {

    const nameToFind = formData?.faddr4;
    const selectedOption = states1.find(option => option.name === nameToFind);
    const stateid1 = selectedOption ? selectedOption.id : null;

    // Fetch districts only if state IDs are available


    if (stateid1 !== null) {
      apiService.getDropdown("mas_district")
        .then(response => {
          const filteredData1 = response.data.filter(item => item.stateid === stateid1);
          setDist1(filteredData1);
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  }, [formData, states1]);

  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only) 
    const mobileRegex = /^[0-9]{10,11}$/;
    return mobileRegex.test(mobile);

  };
  return (
    <>   <Helmet>
      <title>Quotation</title>
    </Helmet>

      {!isSaved ? (
        <>
          <div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end', // Aligns the button to the right
                width: '95%', // Ensures the container spans the full width
              }}
            >
              <Button
                sx={{ alignSelf: 'flex-end', mt: 1, mb: 1 }} // Optional if you want vertical alignment control
                onClick={handleBack}
                variant="contained"
                color="primary"
                className="no-print"
              >
                Back
              </Button>
            </Box>
          </div>
          <div className="quotation">

            <Box >
              <Box className="print-container">
                <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
                  <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} mb={-2}>
                      <Grid container alignItems="center" spacing={1} direction="row">
                        <Grid item>
                          <img
                            src={require('src/assets/logosaarc.png')}
                            alt="Company Logo"
                            style={{ width: 40, height: 40 }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              color: 'red',
                              fontSize: 23,
                              fontFamily: 'Arial, sans-serif',
                              display: 'inline',
                            }}
                          >
                            {selectedCompany?.name?.split(' ')[0]}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 'bold',
                              color: '#000',
                              fontSize: 22,
                              fontFamily: 'Arial, sans-serif',
                              display: 'inline',
                            }}
                          >
                            &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={7} sm={7}>

                      <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                        <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: 18 }}>
                          PE Molded Advanced CEMILAC certified Mil Std. Containers
                        </Typography>
                        <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                          {selectedCompany?.add1}, {selectedCompany?.add2}
                        </Typography>
                        <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                          {selectedCompany?.add3} -  {selectedCompany?.add4}, {selectedCompany?.add5}, India {selectedCompany?.add6}.
                        </Typography>
                        <Typography variant="body2" fontFamily='Arial, sans-serif' fontSize="18">
                          Phone: {selectedCompany?.mobile}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, fontFamily: 'Arial, sans-serif', fontSize: 18 }}>
                          <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                        </Typography>
                        <Typography variant="body2" fontSize="18" fontFamily='Arial, sans-serif'>
                          <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                        </Typography>
                      </Box>
                      <Box>
                        {/* <br /> */}
                        <Grid item xs={12} sm={7} md={7} >
                          <Box sx={{ p: 1, borderRadius: '5px' }}>
                            <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 17, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                              To
                            </Typography>
                            <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 14 }} variant="body2" paragraph onClick={handleClickOpen}>
                              <strong> {formData?.name} </ strong ><br />
                              {formData?.faddr1},{formData?.faddr2},{formData?.faddr3},<br />
                              {formData?.faddr4} - {formData?.faddr5}.<br />
                              {formData?.cmail}.

                            </Typography>
                          </Box>
                        </Grid>
                      </Box>

                    </Grid>

                    <Grid item xs={5}>

                      <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                        BUDGETARY QUOTATION
                      </Typography>
                      <Grid container direction="row" spacing={1} mt={1}>

                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>SAARC Enquiry No:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-6px' }}>
                          <Typography variant="body2">{formData?.enqNo}</Typography>
                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation No:</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                          <TextField
                            onChange={(e) => handleQuoteInputChange('qno', e.target.value)}
                            variant="outlined"
                            value={formData?.enqquote?.[0]?.qno}
                            placeholder="Enter Quotation no"
                            // fullWidth
                            sx={{
                              width: '93%',
                              height: '30px',
                              '& .MuiInputBase-root': {
                                height: '100%',
                              },
                              '& .MuiOutlinedInput-root': {
                                height: '100%',
                              },
                            }}
                          />
                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation Date:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography variant="body2">
                            {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                          </Typography>


                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Quotation Validity:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography variant="body2">
                            30 Days
                          </Typography>


                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer Ref:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={6} >
                          {!isPrintMode ? (
                            <TextField
                              onChange={(e) => handleQuoteInputChange('cusref', e.target.value)}
                              variant="outlined"
                              value={formData?.enqquote?.[0]?.cusref}
                              placeholder="Enter Customer Ref"
                              // fullWidth
                              sx={{
                                width: '93%',
                                height: '30px',
                                '& .MuiInputBase-root': {
                                  height: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: '100%',
                                },
                              }}
                            />
                          ) : (
                            <Typography variant="body2">{quoteData.cusref}</Typography>
                          )}
                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC Name:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={6} >
                          {!isPrintMode ? (
                            <TextField
                              onChange={(e) => handleQuoteInputChange('cuspocname', e.target.value)}
                              variant="outlined"
                              value={formData?.enqquote?.[0]?.cuspocname}
                              placeholder="Enter Customer POC Name"
                              // fullWidth
                              // label="cuspocname"
                              // autoFocus
                              sx={{
                                width: '93%',
                                height: '30px',
                                '& .MuiInputBase-root': {
                                  height: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: '100%',
                                },
                              }}

                            />
                          ) : (
                            <Typography variant="body2">{quoteData.cuspocname}</Typography>
                          )}
                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Customer POC No:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {!isPrintMode ? (
                            <TextField
                              onChange={(e) => handleQuoteInputChange('cuspocno', e.target.value)}
                              variant="outlined"
                              value={formData?.enqquote?.[0]?.cuspocno}
                              // fullWidth
                              // label="cusdesign"
                              // autoFocus
                              placeholder="Enter Customer POC No"
                              sx={{
                                width: '142%',
                                height: '30px',
                                '& .MuiInputBase-root': {
                                  height: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: '100%',
                                },
                              }}

                            />
                          ) : (
                            <Typography variant="body2">{quoteData.cuspocno}</Typography>
                          )}
                        </Grid><br />
                        <Grid item xs={12} sm={6} md={6} >
                          <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14 }}>Designation:</Typography></Grid>
                        <Grid item xs={12} sm={6} md={6} >
                          {!isPrintMode ? (
                            <TextField
                              onChange={(e) => handleQuoteInputChange('cusdesign', e.target.value)}
                              variant="outlined"
                              placeholder="Enter Designation"
                              // label="cusdesign"
                              // autoFocus
                              value={formData?.enqquote?.[0]?.cusdesign}
                              // fullWidth
                              sx={{
                                width: '93%',
                                height: '30px',
                                '& .MuiInputBase-root': {
                                  height: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: '100%',
                                },
                              }}
                            />
                          ) : (
                            <Typography variant="body2">{quoteData.cusdesign}</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}  >
                      <Box
                        sx={{
                          position: 'relative',
                          marginTop: '-5%',
                          width: '100%',
                          padding: isSmallScreen ? '0px' : '5px',
                          boxSizing: 'border-box',
                        }}
                      >
                        <Typography
                          variant="body2"
                          paragraph
                          fontFamily='Arial, sans-serif'
                          color='black'
                          sx={{
                            width: '100%',
                            padding: '10px',
                            boxSizing: 'border-box',
                          }}
                        >
                          Dear Sir/Madam,

                          {!isPrintMode ? (
                            <TextField
                              variant="outlined"
                              value={quoteData.subject} // Bind subject to TextField
                              onChange={handleInputChange1} // Handle input change
                              multiline
                              rows={isSmallScreen ? 4 : 2}
                              sx={{
                                width: '100%',
                                maxWidth: '1130px',
                                '& .MuiInputBase-root': {
                                  height: 'auto',
                                  padding: '10px',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: 'auto',
                                },
                              }}
                            />
                          ) : (
                            <div className="print-value" style={{
                              width: '100%',
                              maxWidth: '1130px',
                              padding: '10px',
                              boxSizing: 'border-box',
                            }}>
                              {quoteData.subject} {/* Display subject value in print mode */}
                            </div>
                          )}

                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <TableContainer
                    // component={Paper}
                    className="print-table"
                    sx={{
                      width: '100%',
                      padding: isSmallScreen ? '0px' : '5px',
                      boxSizing: 'border-box',
                      overflowX: isPrintMode ? 'hidden' : 'auto',
                      overflowY: isPrintMode ? 'hidden' : 'auto',
                      marginTop: '-2%'
                    }}
                  >
                    <Table sx={{ tableLayout: 'fixed' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '10%' }}>Sl. No.</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '26%' }}>Description</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>SAARC MPN No</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '14%' }}>Quantity</TableCell>
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Unit Price</TableCell>
                          {/* <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sub-Total </TableCell>
                      <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Tax</TableCell> */}
                          <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Taxable Amount</TableCell>

                          {/* <TableCell align="center" sx={{ fontWeight: 'bold', borderBottom: '2px solid #000', color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Total Amount</TableCell> */}

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quoteData.enqquotedet.map((item, index) => {
                          const taxableamt = (item.qty || 0) * (item.unitprice || 0);

                          const designamt = (quoteData.designcostqty || 0) * (quoteData.designcostup);
                          const testamt = (quoteData.testchargeqty || 0) * (quoteData.testchargeup);
                          const dummyamt = (quoteData.dummycostqty || 0) * (quoteData.dummycostup);
                          const jigsamt = (quoteData.jigsfixqty || 0) * (quoteData.jigsfixup);
                          item.taxableamt = taxableamt;


                          quoteData.designcostamt = designamt;
                          quoteData.testchargeamt = testamt;
                          quoteData.dummycostamt = dummyamt;
                          quoteData.jigsfixamt = jigsamt;

                          const subtotal = (quoteData.qval) + (quoteData.packchange) + (quoteData.freight)
                            + (quoteData.doccharge) + (quoteData.designcostamt) + (quoteData.dummycostamt) + (quoteData.testchargeamt)
                            + (quoteData.jigsfixamt);

                          quoteData.subtotal = subtotal;

                          const selectedTax = gst.find((g) => g.id === quoteData.taxid);
                          const taxRate = selectedTax ? selectedTax.per / 100 : 0;
                          const taxamt = subtotal * taxRate;


                          quoteData.taxamt = taxamt;
                          const total = subtotal + taxamt;
                          quoteData.total = total;



                          return (
                            <TableRow key={index} sx={{ height: '30px' }}>
                              <TableCell align="center">{index + 1}</TableCell>

                              <TableCell align="left" sx={{ display: 'flex', justifyContent: 'center' }}>
                                <TextField
                                  value={item.desp}
                                  multiline
                                  placeholder="Enter Description"
                                  onChange={(e) => handleInputChange(index, 'desp', e.target.value)}
                                  sx={{
                                    width: '100%',
                                    //  height: '35px',
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                    },
                                  }}
                                />

                              </TableCell>

                              <TableCell align="center">

                                <TextField
                                  value={item.mpnno}
                                  onChange={(e) => handleInputChange(index, 'mpnno', e.target.value)}
                                  placeholder="Enter MPN no"
                                  sx={{
                                    width: '100%',
                                    //    height: '35px',
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                    },
                                  }}
                                />

                              </TableCell>

                              <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                <TextField
                                  type="text"
                                  value={item.qty || ''}
                                  placeholder="Enter Quantity"
                                  onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                                  sx={{
                                    textAlign: 'center',
                                    justifyContent: 'center', alignItems: 'center',
                                    width: '100%',
                                    //   height: '35px',
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                    },
                                  }}
                                />

                              </TableCell>

                              <TableCell align="center">
                                <TextField
                                  type="text"
                                  value={item.unitprice || ''}
                                  onChange={(e) => handleInputChange(index, 'unitprice', e.target.value)}
                                  placeholder="Enter Unit Price"
                                  sx={{
                                    width: '100%',
                                    textAlign: 'center',
                                    //      height: '35px',
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        border: 'none',
                                      },
                                    },
                                  }}
                                />


                              </TableCell>

                              <TableCell align="center">{taxableamt.toFixed(2)}</TableCell>


                            </TableRow>
                          );
                        })}

                        <TableRow>
                          <TableCell colSpan={5} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            SUB-TOTAL AMOUNT:
                          </TableCell>
                          <TableCell align="center">₹ {quoteData.qval.toFixed(2)}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={4} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            Packing Charges (from Basic Price)
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="%"
                              type="text" // Ensure only numeric input
                              value={quoteData.packchangeper}
                              onChange={(e) => handlePackingChange(e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={quoteData.packchange.toFixed(2)} // Bind current packing charge value
                              onChange={(e) => {
                                const newValue = parseFloat(e.target.value); // Parse input value
                                const packpercentage = isNaN(newValue) ? 0 : (newValue / quoteData.qval) * 100; // Calculate percentage from charge
                                setQuoteData((prevData) => ({
                                  ...prevData,
                                  packchange: isNaN(newValue) ? 0 : newValue, // Ensure it's a number
                                  packchangeper: packpercentage // Set packchangeper as a number
                                }));
                              }}
                              label="Packing Charge" // Label for the field
                              type="text" // Use a number input for numeric entries
                              InputProps={{
                                startAdornment: <span>₹</span>, // Add ₹ symbol before the input
                              }}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={4} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            Freight Charges (from Basic Price)
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="%"
                              value={quoteData.freightper}
                              onChange={(e) => handleFreightChange(e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={quoteData.freight.toFixed(2)} // Bind the current freight value
                              onChange={(e) => {
                                const newValue = parseFloat(e.target.value);
                                const freightprecentage = isNaN(newValue) ? 0 : (newValue / quoteData.qval) * 100; // Calculate percentage from charge
                                setQuoteData((prevData) => ({
                                  ...prevData,
                                  freight: isNaN(newValue) ? 0 : newValue, // Ensure the value is a number
                                  freightper: freightprecentage
                                }));
                              }}
                              label="Freight" // Optionally label the field
                              type="text" // Use a number input to prevent non-numeric entries
                              InputProps={{
                                startAdornment: <span>₹</span>, // Add ₹ symbol before the input
                              }}
                            />
                          </TableCell>

                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={4} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            Documentation Charges (from Basic Price)
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              label="%"
                              value={quoteData.docchargeper}
                              onChange={(e) => handleDocChanrge(e.target.value)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <TextField
                              value={quoteData.doccharge.toFixed(2)} // Bind the current freight value
                              onChange={(e) => {
                                const newValue = parseFloat(e.target.value);
                                const docper = isNaN(newValue) ? 0 : (newValue / quoteData.qval) * 100;
                                setQuoteData((prevData) => ({
                                  ...prevData,
                                  doccharge: isNaN(newValue) ? 0 : newValue, // Ensure the value is a number
                                  docchargeper: docper
                                }));
                              }}
                              label="Doc Charge" // Optionally label the field
                              type="text" // Use a number input to prevent non-numeric entries
                              InputProps={{
                                startAdornment: <span>₹</span>, // Add ₹ symbol before the input
                              }}
                            />
                          </TableCell>

                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            Design & Development Cost
                          </TableCell>
                          <TableCell align="center"> <TextField
                            type="text"
                            value={quoteData.designcostqty || ''}
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange2('designcostqty', e.target.value)}
                            sx={{
                              justifyContent: 'center', alignItems: 'center',
                              width: '100%',
                              //   height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center"> <TextField
                            type="text"
                            value={quoteData.designcostup || ''}
                            placeholder="Enter Unit Price"
                            onChange={(e) => handleInputChange2('designcostup', e.target.value)}
                            sx={{
                              width: '100%',
                              //      height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center">₹ {quoteData.designcostamt.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} align="left" sx={{ fontWeight: 'bold' }}>
                            Testing Charges
                          </TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.testchargeqty || ''}
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange2('testchargeqty', e.target.value)}
                            sx={{
                              justifyContent: 'center', alignItems: 'center',
                              width: '100%',
                              //   height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.testchargeup || ''}
                            placeholder="Enter Unit Price"
                            onChange={(e) => handleInputChange2('testchargeup', e.target.value)}
                            sx={{
                              width: '100%',
                              //      height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center">₹ {quoteData.testchargeamt.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} align="left" sx={{ fontWeight: 'bold',textAlign:'left' }}>
                            Dummy Equipment Cost
                          </TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.dummycostqty || ''}
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange2('dummycostqty', e.target.value)}
                            sx={{
                              justifyContent: 'center', alignItems: 'center',
                              width: '100%',
                              //   height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          />
                          </TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.dummycostup || ''}
                            placeholder="Enter Unit Price"
                            onChange={(e) => handleInputChange2('dummycostup', e.target.value)}
                            sx={{
                              width: '100%',
                              //      height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center">₹ {quoteData.dummycostamt.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={3} align="left" sx={{ fontWeight: 'bold' }}>
                            Mfg. of Jigs & Fixtures
                          </TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.jigsfixqty || ''}
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange2('jigsfixqty', e.target.value)}
                            sx={{
                              justifyContent: 'center', alignItems: 'center',
                              width: '100%',
                              //   height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>
                          <TableCell align="center"><TextField
                            type="text"
                            value={quoteData.jigsfixup || ''}
                            placeholder="Enter Unit Price"
                            onChange={(e) => handleInputChange2('jigsfixup', e.target.value)}
                            sx={{
                              width: '100%',
                              //      height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              },
                            }}
                          /></TableCell>

                          <TableCell align="center">₹ {quoteData.jigsfixamt.toFixed(2)}</TableCell>
                        </TableRow>

                        <TableRow>

                          <TableCell rowSpan={3} colSpan={4}>
                            <Typography variant="subtitle1" fontWeight="bold">Note:</Typography>
                            <ul style={{ margin: 0, padding: '10px 0 0 15px' }}>
                              <li>Actual Deliverables based on mutual discussion</li>
                            </ul>
                          </TableCell>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right">₹ {quoteData.subtotal.toFixed(2)}</TableCell>
                        </TableRow>

                        {/* Second Row */}
                        <TableRow>
                          <TableCell align="right"> <TextField
                            select
                            value={quoteData.taxid}
                            onChange={(e) => handleInputChange2('taxid', e.target.value)}
                            sx={{
                              width: '100%', height: '35px',
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  border: 'none',
                                },
                              }
                            }}
                          >
                            {gst.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.sname}
                              </MenuItem>
                            ))}
                          </TextField></TableCell>
                          <TableCell align="right">₹ {quoteData.taxamt.toFixed(2)}</TableCell>
                        </TableRow>

                        {/* Third Row */}
                        <TableRow>
                          <TableCell align="right" style={{ fontWeight: 'bold' }}>
                            TOTAL
                          </TableCell>
                          <TableCell align="right">₹ {quoteData.total.toFixed(2)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                      SPECIAL TERMS & CONDITIONS:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' no-print'>
                      <Typography>Select All</Typography>
                      <Checkbox
                        checked={masterChecked}
                        onChange={handleMasterCheckboxChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </Box>
                  </Box>

                  <br />

                  <TableContainer className='no-print'>
                    <Table aria-label="simple table">
                      <TableBody>
                        {term.filter(item => item.type === "0").map((item, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              height: { xs: 'auto', sm: '60px', md: '80px' }, // Responsive row height
                            }}
                          >
                            {/* First Column: sname */}
                            <TableCell align="left">
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  color: 'GrayText',
                                  fontSize: { xs: 12, sm: 14 }, // Responsive font size
                                  fontFamily: 'Arial, sans-serif',
                                }}
                                variant="body2"
                              >

                                {item?.sname}:
                              </Typography>
                            </TableCell>

                            {/* Second Column: Editable TextField */}
                            <TableCell align="left">
                              <TextField
                                value={quoteData.enqquoteTerms[index]?.name || ''} // Ensure it's sourced from the state
                                onChange={(e) => handleTermChange(e, index, 'name', item.id)} // Use 'name' type for text field change
                                variant="outlined"
                                sx={{
                                  width: "100%",
                                  fontSize: { xs: 12, sm: 14 }, // Responsive font size
                                  color: 'black',
                                  fontFamily: 'Arial, sans-serif',
                                  '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                      border: 'none', // Hides the border of the TextField
                                    },
                                  },
                                }}
                              />
                            </TableCell>

                            {/* Third Column: Checkbox */}
                            <TableCell align="center">
                              <Checkbox
                                checked={quoteData.enqquoteTerms[index]?.checked ?? false} // Ensure checkbox state is synced
                                onChange={(e) => handleTermChange(e, index, 'checkbox', item.id)} // Use 'checkbox' type for checkbox change
                                inputProps={{ 'aria-label': 'controlled' }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>


                  <Box
                    mt={2}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}
                      >
                        <strong>STANDARD TERMS & CONDITIONS:</strong>
                      </Typography>

                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className=' no-print'>
                        <Typography>Select All</Typography>
                        <Checkbox
                          checked={masterChecked1}
                          onChange={handleMasterCheckboxChange1}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Box>
                    </Box>

                    <br />
                    <TableContainer className=' no-print'>
                      <Table aria-label="simple table">
                        <TableBody>
                          {term
                            .filter(item => item.type === "1") // Filter terms with type === 1
                            .map((item, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{index + 1}</TableCell>

                                  {/* First Column: name */}
                                  <TableCell align="left">
                                    <Typography
                                      sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }}
                                      variant="body2"
                                    >
                                      {item.name}
                                    </Typography>
                                  </TableCell>

                                  {/* Additional logic (Checkbox or other columns) */}
                                  <TableCell align="center">
                                    <Checkbox
                                      checked={quoteData.enqquoteTerms[item.id]?.checked ?? false} // Ensure checkbox state is synced
                                      onChange={(e) => handleTermChange1(e, index, 'checkbox', item.id, item.name)}
                                      inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>

                      </Table>
                    </TableContainer>
                    <br />
                    <Box
                      sx={{
                        width: '250px',
                        height: '45px',
                        bgcolor: '#e0e0e0',
                        p: 1,
                        borderRadius: '5px',
                      }}
                    >
                      <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                        Queries to be Addressed to:
                      </Typography>
                    </Box>
                    <br />
                    <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                      Mr/s. V. Suganthi, Mob: 8807060127 | Email: hodmarketing@saarccases.com<br />
                      Mr/s. N. Sasikala. , Mob: 8807060129 | Email: marketing@saarccases.com<br />
                      Mr/M. Sridevi, Mob: 8807023419 | Email: applicationengg@saarccases.com
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="45px"
                    >
                      <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                        THANK YOU FOR YOUR BUSINESS SUPPORT
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                      <Box>
                        <Typography variant="caption"><strong>Prepared By</strong></Typography>
                      </Box>
                      <Box>
                        <Typography variant="caption">Checked By</Typography>
                      </Box>
                    </Box>
                  </Box>
                  <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </Box>
              </Box>
              {/* <Box>
            <Button sx={{ left: 10}} onClick={handleBack} variant="contained" color="primary" className="no-print" >
              Back
            </Button>

            <Box display="flex" justifyContent="flex-end" p={2}>

              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                sx={{ left: 10}} 
                className="no-print"
                disabled={isDisabled} 
              >
                Save
              </Button>
            </Box>
          </Box> */}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center" // Optional, to vertically align the children
                p={2} // Optional padding
              >
                <Button
                  sx={{ left: 10 }}
                  onClick={handleBack}
                  variant="contained"
                  color="primary"
                  className="no-print"
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                  sx={{ left: 10 }}
                  className="no-print"
                  disabled={isDisabled}
                >
                  Save
                </Button>
              </Box>

              <Dialog open={open} onClose={handleClose}>
                <form>
                  <DialogTitle> Edit Address</DialogTitle>
                  <DialogContent>

                    <TextField

                      margin="dense"
                      id="name"
                      label="Name"
                      name='name'
                      fullWidth
                      value={formData?.name}
                      InputProps={{ readOnly: true }}
                      sx={{
                        '.MuiInputBase-input': {
                          fontWeight: 'bold',
                        },
                        '.MuiFormLabel-root': {
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <TextField

                      margin="dense"
                      id="faddr1"
                      label="Plot No/Street"
                      name='faddr1'
                      fullWidth
                      value={formData?.faddr1}
                      onChange={(e) => handleFaddrInputChange('faddr1', e.target.value)}
                    />

                    <TextField
                      id="faddr2"
                      margin="dense"
                      label="City"
                      name='faddr2'
                      fullWidth
                      value={formData?.faddr2}
                      onChange={(e) => handleFaddrInputChange('faddr2', e.target.value)}
                    />
                    <TextField
                      select
                      id='="faddr4'
                      margin="dense"
                      label="State"
                      name='faddr4'
                      fullWidth
                      value={formData?.faddr4}
                      onChange={(e) => handleFaddrInputChange('faddr4', e.target.value)}
                    >
                      {states1.map((option) => (
                        <MenuItem key={option?.id} value={option?.name}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      select
                      margin="dense"
                      id="faddr3"
                      label="District"
                      name='faddr3'
                      fullWidth
                      value={formData?.faddr3}
                      onChange={(e) => handleFaddrInputChange('faddr3', e.target.value)}

                    >
                      {dist1.map((option) => (
                        <MenuItem key={option?.id} value={option?.name}>
                          {option?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      type='number'
                      id="faddr5"
                      margin="dense"
                      label="Pin Code"
                      name='faddr5'
                      fullWidth
                      value={formData?.faddr5}
                      onChange={(e) => handleFaddrInputChange('faddr5', e.target.value)}
                    />
                    <TextField
                      required
                      margin="dense"
                      id="cmail"
                      label="Email"
                      name='cmail'
                      fullWidth
                      value={formData?.cmail}
                      onChange={(e) => handleFaddrInputChange('cmail', e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Cancel
                    </Button>

                  </DialogActions>
                </form>
              </Dialog>

            </Box>
          </div>
        </>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Card>
            <CardContent>
              <div className="proforma-invoice">
                <Box ref={contentToPrint}>
                  <Box className="print-container">
                    <Box p={4} sx={{ fontFamily: 'Arial, sans-serif', bgcolor: '#fff' }}>
                      <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12} mb={-2}>
                          <Grid container alignItems="center" spacing={1} direction="row">
                            <Grid item>
                              <img
                                src={require('src/assets/logosaarc.png')}
                                alt="Company Logo"
                                style={{ width: 40, height: 40 }}
                              />
                            </Grid>
                            <Grid item xs>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 'bold',
                                  color: 'red',
                                  fontSize: 23,
                                  fontFamily: 'Arial, sans-serif',
                                  display: 'inline',
                                }}
                              >
                                {selectedCompany?.name?.split(' ')[0]}
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  fontWeight: 'bold',
                                  color: '#000',
                                  fontSize: 22,
                                  fontFamily: 'Arial, sans-serif',
                                  display: 'inline',
                                }}
                              >
                                &nbsp;{selectedCompany?.name?.split(' ').slice(1).join(' ')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={7} sm={7}>

                          <Box sx={{ bgcolor: '#e0e0e0', p: 2, borderRadius: '5px' }}>
                            <Typography variant="subtitle2" sx={{ fontFamily: 'Arial, sans-serif', color: 'red', mb: 1, fontSize: "10" }}>
                              PE Molded Advanced CEMILAC certified Mil Std. Containers
                            </Typography>
                            <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                              {selectedCompany?.add1}, {selectedCompany?.add2}
                            </Typography>
                            <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                              {selectedCompany?.add3} - {selectedCompany?.add4}, {selectedCompany?.add5},  India {selectedCompany?.add6}.
                            </Typography>
                            <Typography variant="body2" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                              Phone: {selectedCompany?.mobile}
                            </Typography>
                            <Typography variant="body2" sx={{ fontFamily: 'Arial, sans-serif', fontSize: 10 }}>
                              <strong>GST No:</strong> {selectedCompany?.gstno} &nbsp;&nbsp; <strong>HSN Code:</strong> {selectedCompany?.hsncode}
                            </Typography>
                            <Typography variant="body2" fontSize="85%" fontFamily='Arial, sans-serif' sx={{ fontSize: 10 }}>
                              <strong>GEM SELLER ID:</strong> {selectedCompany?.gemsellerid}
                            </Typography>
                          </Box>
                          <Box>
                            {/* <br /> */}
                            <Grid item xs={12} sm={7} md={7} >
                              <Box sx={{ p: 1, borderRadius: '5px' }}>
                                <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 14, fontFamily: 'Arial, sans-serif' }} variant="body2" >
                                  To
                                </Typography>
                                <Typography sx={{ fontWeight: 'bold', color: 'Black', fontFamily: 'Arial, sans-serif', fontSize: 12 }} variant="body2" paragraph>
                                  <strong> {formData?.name} </ strong ><br />
                                  {formData?.faddr1},{formData?.faddr2},{formData?.faddr3},<br />
                                  {formData?.faddr4} - {formData?.faddr5}.<br />
                                  {formData?.cmail}.
                                </Typography>
                              </Box>
                            </Grid>
                          </Box>

                        </Grid>

                        <Grid item xs={5}>

                          <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'Black', fontSize: 20 }}>
                            BUDGETARY QUOTATION
                          </Typography>
                          <Grid container direction="row" spacing={1} >

                            <Grid item xs={12} sm={6} md={6} mt={2}>
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>SAARC Enquiry No:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} mt={2} >
                              <Typography variant="body2" sx={{ fontSize: 12 }}>{formData?.enqNo}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Quotation No:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} sx={{ marginTop: '-4px' }}>
                              <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.qno}</Typography>
                            </Grid><br />
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Quotation Date:</Typography></Grid>
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography variant="body2" sx={{ fontSize: 12 }}>
                                {new Date(quoteData.qdate).toLocaleDateString('en-GB')} {/* Adjust locale as needed */}
                              </Typography>


                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12, padding: '2px 0' }}>
                                Quotation Validity:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} mt={0.4}>

                              <Typography variant="body2" sx={{ fontSize: 12 }}>30 Days</Typography>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer Ref:</Typography></Grid>
                            <Grid item xs={12} sm={6} md={6} >

                              <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cusref}</Typography>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer POC Name:</Typography></Grid>
                            <Grid item xs={12} sm={6} md={6} >

                              <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cuspocname}</Typography>

                            </Grid>

                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Designation:</Typography></Grid>
                            <Grid item xs={12} sm={6} md={6} >

                              <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cusdesign}</Typography>

                            </Grid>
                            <Grid item xs={12} sm={6} md={6} >
                              <Typography sx={{ fontWeight: 'bold', color: 'GrayText', fontSize: 12 }}>Customer POC No:</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>

                              <Typography variant="body2" sx={{ fontSize: 12 }}>{quoteData.cuspocno}</Typography>

                            </Grid>

                          </Grid>
                        </Grid>



                        <Grid item xs={12}  >
                          <Box
                            sx={{
                              position: 'relative',
                              marginTop: '-8%',
                              width: '100%',
                              padding: isSmallScreen ? '0px' : '5px',
                              boxSizing: 'border-box',
                            }}
                          >
                            <Typography
                              variant="body2"
                              paragraph
                              fontFamily='Arial, sans-serif'
                              color='black'
                              sx={{
                                width: '100%',
                                padding: '10px',
                                boxSizing: 'border-box',
                                fontSize: 15
                              }}
                            >
                              Dear Sir/Madam,
                              <br />

                              <div className="print-value" style={{
                                width: '100%',
                                maxWidth: '1130px',
                                boxSizing: 'border-box',
                                fontSize: 12
                              }}>
                                {quoteData.subject} {/* Display subject value in print mode */}
                              </div>


                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      <div
                        className="print-table"
                        style={{
                          width: '100%',
                          padding: isSmallScreen ? '0px' : '5px',
                          boxSizing: 'border-box',
                          overflowX: 'hidden',
                          overflowY: 'hidden',
                          marginTop: '-4%'
                        }}
                      >
                        <table style={{ tableLayout: 'fixed', width: '100%' }}>
                          <thead>
                            <tr>
                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '15%' }}>Sl. No.</th>
                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '30%' }}>Description</th>
                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>SAARC MPN No</th>
                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '24%' }}>Quantity</th>
                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Unit Price</th>

                              <th style={{ fontWeight: 'bold', fontSize: 12, color: 'Black', fontFamily: 'Arial, sans-serif', width: '25%' }}>Taxable Amount</th>

                            </tr>
                          </thead>
                          <tbody>
                            {quoteData.enqquotedet.map((item, index) => (
                              <tr key={index} style={{ height: '30px' }}>
                                <td align="center" style={{ fontSize: 11 }}>{index + 1}</td>
                                <td align="left" style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word', fontSize: 11 }}>
                                  <div className="print-desp">{item.desp}</div>
                                </td>

                                <td align="center" style={{ fontSize: 11 }}>
                                  <div className="print-mpnno" >{item.mpnno}</div>
                                </td>
                                <td align="center" style={{ fontSize: 11 }}>
                                  <div className="print-qty">{item.qty}</div>
                                </td>
                                <td align="center" style={{ fontSize: 11 }}>
                                  <div className="print-unitprice">₹ {item.unitprice}</div>
                                </td>
                                <td align="center" style={{ fontSize: 11 }}>₹ {item.taxableamt.toFixed(2)}</td>

                              </tr>
                            ))}
                            <tr>
                              <td colSpan={5} align="left" style={{ fontWeight: 'bold', fontSize: 11 ,textAlign: 'left'}}>SUB-TOTAL AMOUNT:</td>
                              <td align="center" style={{ fontSize: 11 }}>₹ {quoteData.qval.toFixed(2)}</td>
                            </tr>
                            <tr>
                              <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Packing Charges (from Basic Price)</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.packchangeper}{"%"}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>
                                ₹ {quoteData.packchange.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Freight Charges (from Basic Price)</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.freightper}{"%"}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.freight.toFixed(2)}

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={4} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Documentation Charges (from Basic Price)</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.docchargeper}{"%"}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.doccharge.toFixed(2)}

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Design & Development Cost</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.designcostqty.toFixed(2)}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.designcostup.toFixed(2)}

                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.designcostamt.toFixed(2)}

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Testing Charges</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.testchargeqty.toFixed(2)}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.testchargeup.toFixed(2)}

                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.testchargeamt.toFixed(2)}

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Dummmy Equipment Cost</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.dummycostqty.toFixed(2)}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.dummycostup.toFixed(2)}

                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.dummycostamt.toFixed(2)}

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} align="right" style={{ fontWeight: 'bold', fontSize: 11 }}>Mfg. of Jigs & Fixtures</td>
                              <td align="center" style={{ fontSize: 11 }}>
                                {quoteData.jigsfixqty.toFixed(2)}
                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.jigsfixup.toFixed(2)}

                              </td>
                              <td align="center" style={{ fontSize: 11 }}>

                                ₹ {quoteData.jigsfixamt.toFixed(2)}

                              </td>
                            </tr>

                            <tr>
                              <td colSpan={4} rowSpan={3} style={{ fontSize: 11 }}>
                                <h4 >Note:</h4>
                                <ul>
                                  <li>Actual Deliverables based on mutual discussion</li>
                                </ul>
                              </td>
                              <td style={{ fontSize: 11 }}>
                                TOTAL
                              </td>
                              <td align="right" style={{ fontSize: 11 }}>₹ {quoteData.subtotal.toFixed(2)}</td>
                            </tr>


                            <tr>
                              <td align="right" style={{ fontSize: 11 }}>
                                {taxname}
                              </td>
                              <td align="right" style={{ fontSize: 11 }}>₹ {quoteData.taxamt.toFixed(2)}</td>
                            </tr>

                            <tr>
                              <td align="right" style={{ fontSize: 11 }}>
                                TOTAL
                              </td>
                              <td align="right" style={{ fontSize: 11 }}>₹ {quoteData.total.toFixed(2)}</td>
                            </tr>


                          </tbody>
                        </table>
                      </div>


                      <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6" fontWeight="bold" sx={{ fontSize: 18 }}>
                          SPECIAL TERMS & CONDITIONS:
                        </Typography>

                      </Box>

                      <div
                        className='print-terms'
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'initial',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          position: 'relative',
                          left: '52px'
                        }}
                      >
                        {/* First Box: Displaying sname values */}
                        <div>
                          {sname.length > 0 ? (
                            sname.map((snameItem, index) => (
                              <div key={index} >
                                <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                                  {snameItem}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p>No terms found</p>
                          )}
                        </div>

                        {/* Second Box: Displaying name values where termId > 0 is changed to id */}
                        <div style={{ position: 'relative', left: '40px' }}>
                          {type0// Ensures id > 0 and type '0'
                            .map((item, index) => (
                              <div key={index} >
                                <p style={{ fontSize: '14px', color: 'black', fontFamily: 'Arial, sans-serif' }}>
                                  : {item?.name}
                                </p>
                              </div>
                            ))}
                        </div>
                      </div>

                      <Box
                        mt={2}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            sx={{ fontSize: 18, color: 'black', fontFamily: 'Arial, sans-serif' }}
                          >
                            <strong>STANDARD TERMS & CONDITIONS:</strong>
                          </Typography>


                        </Box>
                        <div>
                          {type1.map((item, index) => (
                            <p key={index}>
                              {index + 1}. {item.name}
                            </p>
                          ))}
                        </div>

                        <br />
                        <Box
                          sx={{
                            width: '250px',
                            height: '45px',
                            bgcolor: '#e0e0e0',
                            p: 1,
                            borderRadius: '5px',
                          }}
                        >
                          <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                            Queries to be Addressed to:
                          </Typography>
                        </Box>
                        <br />
                        <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black' fontSize={14}>
                          Mr/s. V. Suganthi, Mob: 8807060127 | Email: hodmarketing@saarccases.com<br />
                          Mr/s. N. Sasikala. , Mob: 8807060129 | Email: marketing@saarccases.com<br />
                          Mr/M. Sridevi, Mob: 8807023419 | Email: applicationengg@saarccases.com
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="45px"
                        >
                          <Typography variant="body2" paragraph fontFamily='Arial, sans-serif' color='Black'>
                            THANK YOU FOR YOUR BUSINESS SUPPORT
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <Typography variant="caption"><strong>Prepared By</strong></Typography>
                          </Box>
                          <Box>
                            <Typography variant="caption">Checked By</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center" // Optional for vertical alignment
                    p={2} // Optional padding for spacing
                  >
                    <Button
                      onClick={handleBack}
                      variant="contained"
                      color="primary"
                      className="no-print"
                    >
                      Back
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      className="no-print"
                      onClick={async () => {
                        handlePrint1(null, () => contentToPrint.current);
                      }}
                    >
                      Print
                    </Button>
                  </Box>


                </Box>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
    <style>
  {`
    @media print {
      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        text-align: left !important;
        bottom: 0 !important;
        right: 0 !important;
        top: -5px !important;
        left: 0 !important;
        margin: 0 !important;
        padding: 0 8px !important;
        pointer-events: none !important;
        border-radius: inherit !important;
        border-style: solid !important;
        border-width: 0px !important;
        overflow: hidden !important;
        min-width: 0% !important;
        border-color: rgba(0, 0, 0, 0.23) !important;
      }

      .print-table {
        width: 100% !important;
        padding: 5px !important;
        box-sizing: border-box !important;
        font-size: 9pt !important;
      }

      .no-print {
        display: none !important;
      }

      .print-desp,
      .print-mpnno,
      .print-qty,
      .print-unitPrice,
      .print-tax,
      .print-terms,
      .print-igst {
        font-size: 9pt !important;
      }

      @page {
        size: A4 !important;
        margin: 5.2mm !important;
      }
    }

    @media screen {
      .printable-content {
        display: none !important;
      }

      .print-terms {
        display: none !important; /* Hides the Typography on screen */
      }

      .table-container {
        display: block !important; /* Shows the TableContainer on screen */
      }
       
    }

    @media print {
      .no-print {
        display: none !important;
      }
    }
  `}
</style>


    </>
  );
};


export default Quotation;
