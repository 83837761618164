import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { it } from "date-fns/locale";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";

import { format } from "date-fns";
import { useReactToPrint } from 'react-to-print';// import { Type } from "typescript";
import * as XLSX from 'xlsx';
import React from "react";
import { faPrint, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


interface Data {

    id: number;
    EnquiryNo: number;
    EnquiryDate: Date;
    Customer: string;
    Division: string;
    Status: string;
    Priority: string;
    FollowBy: string;
    FollowDate: Date;
    FollowDescription: string;
    NextFollowDate: Date;


}
const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const getFirstDayOfMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
};


function EnqStsReport() {
    const [data, setData] = useState<any[]>([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(500);
    const [formData, setFormData] = useState<any>({
        id: '',
        EnquiryNo: '',
        EnquiryDate: '',
        Customer: '',
        Division: '',
        Status: '',
        Priority: '',
        FollowBy: '',
        FollowDate: '',
        FollowDescription: '',
        NextFollowDate: ''
    });

    const [status, setStatus] = useState<string>('');
    const [search, setSearch] = useState('');


    const [showImage, setShowImage] = useState(false)


    useEffect(() => {
        apiService.getdailyfollowupreport(rowsPerPage.toString(), (page + 1).toString(), "", fromDate, toDate, status)
            .then(response => {
                console.log(response.data);

                setData(response.data);
            })
            .catch(err => console.error('Error fetching list data:', err));
    }, [rowsPerPage, page, search, status]);


    const handleSearch = () => {
        apiService.getdailyfollowupreport(rowsPerPage.toString(), (page + 1).toString(), search, fromDate, toDate, status).then((response) => {
            setData(response.data);

        }).catch((err) => {
            console.log("err", err);

        })
        setFormData({
            id: '',
            EnquiryNo: '',
            EnquiryDate: '',
            Customer: '',
            Division: '',
            Status: '',
            Priority: '',
            FollowBy: '',
            FollowDate: '',
            FollowDescription: '',
            NextFollowDate: ''


        });
    }




    const printRef = useRef(null);
    const contentToPrint: any = useRef(null);
    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Reort",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,

    });
    const PrintComponent = React.forwardRef((props, ref) => (
        <div style={{ padding: '20px' }}>
            <header style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={require('../assets/logosaarc.png')} alt="Logo" style={{ width: '100px', display: 'block', margin: '0 auto', height: '100%' }} />
                <h1>Daily Followup Report</h1>
            </header>

        </div>
    ));

    const handleConvert = () => {
        const data = [];

        data.push([
            'Enquiry Status Wise Report',

        ]);
        data.push([
            ' ',

        ]);

        data.push([
            "S.No",
            "Enquiry No",
            "Enquiry Date",
            "Customer",
            "Division",
            "Status",
            "Priority",
            "Follow By",
            "Follow Date",
            "Follow Description",
            "Next Follow Date"
        ]);

        Object.keys(groupedData).forEach(status => {
            data.push([`Status : ${status}`]); // Adding status as a header row
            groupedData[status].forEach((row, index) => {
                data.push([
                    index + 1,
                    row.EnquiryNo,
                    format(new Date(row.EnquiryDate), 'dd-MM-yyyy'),
                    row.Customer,
                    row.Division,
                    row.Status,
                    row.Priority,
                    row.FollowBy,
                    format(new Date(row.FollowDate), 'dd-MM-yyyy'),
                    row.FollowDescription,
                    format(new Date(row.NextFollowDate), 'dd-MM-yyyy')
                ]);
            });
        });

        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Enquiries");

        XLSX.writeFile(workbook, "Enquiries.xlsx");
    };

    const [stat, setStat] = useState([]);

    useEffect(() => {
        // Fetch the dropdown data when the component mounts
        apiService.getDropdown("crm_mas_status")
            .then(response => {
                setStat(response.data);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, []);




    const formatToInput = (date: string) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };

    const formatToISO = (date: string) => {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
    };

    const [fromDate, setFromDate] = useState(formatToISO(getFirstDayOfMonth()));
    const [toDate, setToDate] = useState(formatToISO(getCurrentDate()));
    const [error, setError] = useState('');

    const handleDateChange = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        setFromDate(formattedDate);
        setError("");

    };
    const handleDateChange1 = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        if (new Date(formattedDate) < new Date(fromDate)) {
            setError("To Date cannot be earlier than From Date.");
        } else {
            setToDate(formattedDate);
            setError(""); // Clear error if validation passes
        }
    };

    const groupedData = data.reduce((acc, row) => {
        const status = row.Status;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(row);
        return acc;
    }, {});

    return (
        <>
            <Helmet>
                <title>Enquiry Status Wise Report - CRM</title>
            </Helmet>
            <PageTitleWrapper>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={2}>
                        <PageTitle
                            heading="Enquiry Statuswise Report"
                            subHeading=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={10} container spacing={1} justifyContent="flex-end">
                        <Grid item>
                            <TextField
                                required
                                select
                                label="Status"
                                value={status}
                                onChange={(e: any) => {
                                    setStatus(e.target.value);
                                }}
                                sx={{
                                    m: 1,
                                    width: '20ch',
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                inputProps={{ maxLength: 11 }}
                            >
                                <MenuItem value="">
                                    All
                                </MenuItem>
                                {stat.map((option) => (
                                    <MenuItem key={option?.id} value={option?.name}>
                                        {option?.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                id="fdate"
                                label="From Date"
                                name='fdate'
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={handleDateChange}
                                value={formatToInput(fromDate)}  // Display initial value
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="tdate"
                                label="To Date"
                                name="tdate"
                                type="date"
                                value={formatToInput(toDate)}  // Display initial value
                                onChange={handleDateChange1}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                                error={!!error}
                                helperText={error}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="search"
                                label="Search"
                                name='search'
                                onChange={(e: any) => {
                                    setSearch(e.target.value);
                                }}
                                sx={{
                                    m: 1,
                                    width: '20ch',
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: '8px' }}
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </PageTitleWrapper>



            <Container maxWidth="lg">
                <Grid
                    item
                    xs={12}

                    container
                    alignItems="center"
                    justifyContent="flex-end"  // Aligns items to the right side
                    style={{ display: 'flex', flexDirection: 'row' }}  // Reverses the row direction so buttons are on the right
                    gap={2}
                >
                    <Button
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                        variant="contained"
                        color="secondary"
                        startIcon={<FontAwesomeIcon icon={faPrint} />}  // Add the Print icon
                        onClick={() => {
                            handlePrint1(null, () => contentToPrint.current);
                        }}
                    >
                        Print
                    </Button>

                    <Button
                        style={{ marginTop: '10px' }}
                        variant="contained"
                        color="success"
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}  // Add the Excel icon
                        onClick={handleConvert}
                    >
                        Excel
                    </Button>
                </Grid>
                <br />

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                // spacing={3}
                >



                    <Grid item xs={12}>
                        <Paper
                            ref={contentToPrint}
                        >


                            <div className="printable-content" style={{ padding: '20px' }}>
                                <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '50px', height: '50px', marginRight: '20px' }}
                                    />
                                    <h1 style={{ margin: 0 }}>Enquiry Statuswise Report</h1>
                                </header>
                            </div>


                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Enquiry No</TableCell>
                                            <TableCell>Enquiry Date</TableCell>
                                            <TableCell>Customer</TableCell>
                                            <TableCell>Division</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Priority</TableCell>
                                            <TableCell>Follow By</TableCell>
                                            <TableCell>Follow Date</TableCell>
                                            <TableCell>Follow Description</TableCell>
                                            <TableCell>Next Follow Date</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(groupedData).length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={11} style={{ textAlign: 'center', color: '#888' }}>
                                                    No records or Provide Valid Filters 
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            Object.keys(groupedData).map(status => (
                                                <React.Fragment key={status}>
                                                    <TableRow>
                                                        <TableCell colSpan={11} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' }}>
                                                            {status} {/* Display status name */}
                                                        </TableCell>
                                                    </TableRow>
                                                    {groupedData[status].map((row, index) => (
                                                        <TableRow key={row.EnquiryNo}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{row.EnquiryNo}</TableCell>
                                                            <TableCell>{format(new Date(row.EnquiryDate), 'dd-MM-yyyy')}</TableCell>
                                                            <TableCell>{row.Customer}</TableCell>
                                                            <TableCell>{row.Division}</TableCell>
                                                            <TableCell>{row.Status}</TableCell>
                                                            <TableCell>{row.Priority}</TableCell>
                                                            <TableCell>{row.FollowBy}</TableCell>
                                                            <TableCell>{format(new Date(row.FollowDate), 'dd-MM-yyyy')}</TableCell>
                                                            <TableCell>{row.FollowDescription}</TableCell>
                                                            <TableCell>{format(new Date(row.NextFollowDate), 'dd-MM-yyyy')}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>
                        {/* <TablePagination
                            component="div"
                            count={data.length === 0 ? 0 : data[0]["count"]}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                        /> */}

                    </Grid>
                </Grid>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />



            <style>
                {`
                    @media print {
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default EnqStsReport;


