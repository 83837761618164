import React, { useEffect, useRef, useState } from "react";
import "./SalarySheet.css";
import { Box, Button, Card, CardContent, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import apiService from "src/services/api-service";
import { FaPencil } from "react-icons/fa6";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';

import { useReactToPrint } from 'react-to-print';
import './printcss.css';
interface Salary {
    id: number;
    name: string;
    isactive: number;
}


const SalarySheet: React.FC = () => {

    const [formData, setFormData] = useState([]);
    const [salary, setSalary] = useState<Salary[]>([]);
    const [configid, setConfigid] = useState(1);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [data, setData] = useState<string>('enqdate');
    const tableRef = useRef<HTMLDivElement>(null);
    const months = [
        { value: "01", label: "January" },
        { value: "02", label: "February" },
        { value: "03", label: "March" },
        { value: "04", label: "April" },
        { value: "05", label: "May" },
        { value: "06", label: "June" },
        { value: "07", label: "July" },
        { value: "08", label: "August" },
        { value: "09", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ];
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0');
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);
    const [search1, setSearch1] = useState('');
    const handleSearchChange1 = (event) => {
        setSearch1(event.target.value);

    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "year") {
            setYear(value);
        }
        else {
            setMonth(value);
        }

    };

    const handlePrint = useReactToPrint({
        content: () => tableRef.current,
    });

    useEffect(() => {
        apiService.getDropdown("hrms_ent_salaryconfig").then(response => {
            setSalary(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [])

    useEffect(() => {
        apiService.getDropdown("hrms_ent_salaryconfig")
            .then(response => {
                // Filter the data based on configid
                const filteredData = response.data.filter(item => item.id === configid);
                setData(filteredData.name);
            })
            .catch(err => {
                console.log("Error fetching salary config data: ", err);
            });
    }, [configid]);

    useEffect(() => {
        apiService.getsalarydetreport(configid, year, month, search1)
            .then((response) => {
                const data = response.data;
                console.log("Fetched data:", data);

                if (data.length > 0) {
                    // Extract headers, excluding those with only null or undefined values
                    const headers = Object.keys(data[0]).filter((key) =>
                        data.some((row) => row[key] !== null && row[key] !== undefined)
                    );
                    setTableHeaders(headers);
                }

                setFormData(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [configid, year, month, search1]);


    useEffect(() => {
        console.log("=>", formData);
        console.log("=>", data);
    }, [configid, formData, data]);

    const handleExportToExcel = () => {
        if (!tableHeaders.length || !formData.length) {
            console.error("No data available for export");
            return;
        }

        // Adding the table title
        const title = [["Salary Detail"]]; // Ensure `data` is wrapped in an array for a proper row
        const blankRow = [];

        // Map table headers for Excel
        const headers = ['S.No', ...tableHeaders];

        // Prepare rows for Excel, starting with S.No and the corresponding data
        const dataRows = formData.map((row, index) => {
            const rowData = { 'S.No': index + 1 };
            tableHeaders.forEach((header) => {
                rowData[header] = row[header] ?? "";
            });
            return rowData;
        });

        // Create a new worksheet and add the title, blank row, and headers
        const ws = XLSX.utils.json_to_sheet([]);

        // Adding title to the worksheet
        XLSX.utils.sheet_add_aoa(ws, title, { origin: 'A1' });

        // Adding a blank row
        XLSX.utils.sheet_add_aoa(ws, [blankRow], { origin: 'A2' });

        // Adding headers
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A3' });

        // Adding data rows starting from row 4
        XLSX.utils.sheet_add_json(ws, dataRows, { skipHeader: true, origin: 'A4' });

        // Create a new workbook and append the worksheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SalaryDetail"); // Set the worksheet name using `data`

        // Save the file with the worksheet name as the filename
        const fileName = "SalaryDetail.xlsx"; // Dynamically create the file name
        XLSX.writeFile(wb, fileName);
    };


    return (
        <>
            <PageTitleWrapper>
                <PageTitle
                    heading="Salary Detail Report"
                    subHeading=""
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center', // Align items vertically
                        justifyContent: 'space-between', // Add space between items
                        flexWrap: 'wrap', // Allow wrapping for responsiveness
                        gap: 1, // Add spacing between items
                        mt: -3.5, // Adjust margin-top as per requirement
                        marginTop: '10px'
                    }}
                >
                    <TextField
                        select
                        margin="dense"
                        label="Year"
                        name="year"
                        value={Number(year)}
                        onChange={handleChange}
                        sx={{ minWidth: 120 }} // Set minimum width for consistent sizing
                    >
                        <MenuItem value="2024">2024</MenuItem>
                        <MenuItem value="2025">2025</MenuItem>
                    </TextField>
                    <TextField
                        select
                        margin="dense"
                        label="Month"
                        name="month"
                        value={Number(month)}
                        onChange={handleChange}
                        sx={{ minWidth: 120 }}
                    >
                        {months.map((month) => (
                            <MenuItem key={month.value} value={month.value}>
                                {month.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={search1}
                        onChange={handleSearchChange1}
                        margin="normal"
                        sx={{ minWidth: 180 }}
                    />
                    <TextField
                        select
                        value={configid}
                        onChange={(e: any) => setConfigid(e.target.value)}
                        sx={{ minWidth: 150 }}
                    >
                        {salary
                            .filter((option) => option.isactive === 1)
                            .map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                        onClick={handleExportToExcel}
                        sx={{
                            m: 1,
                            minWidth: 120, // Ensure consistent button size
                        }}
                    >
                        Excel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrint}
                        sx={{
                            m: 1,
                            minWidth: 120,
                        }}
                    >
                        Print
                    </Button>
                </Box>

            </PageTitleWrapper>

            <Grid
                item
                xs={11}
                sx={{
                    mx: '10mm', // Margin left and right
                }}
            >
                <Paper>
                    <TableContainer style={{ height: "500px", overflowY: "auto" }}>
                        <div ref={tableRef}>
                            <Table stickyHeader>
                                <TableHead className="Heading">
                                    <TableRow className="headlogo">
                                        <TableCell colSpan={45} style={{ textAlign: 'center' }}><img
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '50px', height: '50px', marginRight: '20px', float: 'left' }}
                                        />
                                            <h4 style={{ margin: 0 }}><br></br>SAARC TOOL TECH PRIVATE LIMITED</h4>
                                        </TableCell>



                                    </TableRow>
                                    <TableRow className="headtitle">


                                        <TableCell colSpan={50} style={{ textAlign: 'center' }}>
                                            <h4 style={{ margin: 0, marginLeft: '70px' }} > SALARY DETAIL REPORT – {months.find((x) => x.value === month)?.label} {year}</h4>
                                        </TableCell>

                                    </TableRow>
                                    <TableRow>
                                        <TableCell>S.No</TableCell>
                                        {tableHeaders.map((header, index) => (
                                            <TableCell key={index}>{header}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                {/* <TableHead>
                                <TableRow>
                                    <TableCell>S.No</TableCell>
                                    {tableHeaders.map((header, index) => (
                                        <TableCell key={index}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead> */}
                                {/* <TableBody>
                                {formData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        {tableHeaders.map((header, i) => (
                                            <TableCell key={i}>{row[header]}</TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody> */}
                                <TableBody>
                                    {formData.map((row, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            {tableHeaders.map((header, i) => (
                                                <TableCell key={i}>{row[header]}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>

                </Paper>
            </Grid>
        </>
    );
};

export default SalarySheet;
