import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import { useLocation, useNavigate } from 'react-router-dom';


interface Data {
    id: number,
    enqid: number,
    qdate: string | Date,
    qno: number,
    Rno: number,
    qval: number,
    cusref: string,
    cuspocname: string,
    cuspocno: string,
    cusdesign: string,
    status: string,
    lastqid: number,
    subject: string,
    cby: string,
    con: string | Date,
    mby: null,
    mon: null,
    taxid: number,
    taxamt: number,
    total: number,
    appby: null,
    appdate: null,
    comments: null,
    enqno: string,
    qdates: string | Date,
    creatby: string
}

interface Data1 {
    id: number;
    Enqid: number;
    reqtypeid: number;
    enqno: string;
    enqdate: Date;
    type: string;
    sname: string;
    cusname: string;
    mktname?: string; // Make optional for cases where it's not needed
    req?: string; // Make optional for cases where it's not needed
    status: string;
    mpnno?: string; // New field to include for "NewEnq_Close"
    Detid: number;
}

interface LocationState {
    message?: string;
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

function ReqtoClarify() {

    // For Grid Table
    // const [data, setData] = useState<Data[]>([]);
    const [data, setData] = useState([]);

    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [activePartition, setActivePartition] = useState<string>('NewEnq_Clarify');
    const [tableData, setTableData] = useState<Data1[]>([]);
    const [status, setStatus] = useState<string>('');

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: 0,
        enqid: 0,
        qdate: "",
        qno: 0,
        Rno: 0,
        qval: 0,
        cusref: "",
        cuspocname: "",
        cuspocno: "",
        cusdesign: "",
        status: "",
        lastqid: 0,
        subject: "",
        cby: "",
        con: "",
        mby: null,
        mon: null,
        taxid: 0,
        taxamt: 0,
        total: 0,
        appby: null,
        appdate: null,
        comments: null,
        enqno: '',
        qdates: '',
        creatby: ''
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    const location = useLocation();

    useEffect(() => {
        apiService.getReqtoclarify({})
            .then(response => {
                setData(response.data);
                console.log('check id ', response.data);


            })

            .catch(err => console.error('Error fetching count data:', err));
    }, []);
    console.log();




    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            role: '',
            isactive: '',
        });
        setSelectedItem(null);
    };

    // Insert and Update 
    let submit = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior
        // Insert
        if (selectedItem == null) {

            let data = {
                "id": formData?.id,
                "role": formData?.role,
                "isactive": formData?.isactive,
                "cby": "1"
            };

            apiService.insertRole(data
            ).then(response => {
                console.log("response", response.data);
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message)
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        role: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
        // Update
        else {
            let data = {
                "id": formData?.id,
                "role": formData?.role,
                "isactive": formData?.isactive,
                "cby": "1"
            };
            apiService.updateRole(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        role: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    const navigate = useNavigate();

    const updateQuotation = (id: number, qno: string, enqid: number) => {
        console.log("id", id);
        console.log("qno", qno);


        navigate('/MarketingCRM/updatequotationpending', { state: { id, qno, enqid } });
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // 'en-GB' ensures DD-MM-YYYY format
    };

    useEffect(() => {
        if (activePartition) {
            apiService.getDesignListreq(rowsPerPage.toString(), activePartition, (page + 1).toString(), status)
                .then(response => {
                    console.log("Fetched list data:", response.data);
                    setTableData(response.data);
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });
        }
    }, [activePartition, rowsPerPage, page, status]);

    const handleUpdateClick = (id: number, detid: number, iid: number) => {
        console.log("id", id);
        console.log("id", detid);

        navigate('/marketingcrm/updaterequesttoclarify', { state: { id, detid, iid } });
    }

    useEffect(() => {
        const state = location.state as LocationState;

        if (state?.message) {
            toast.success(state.message);

            // Clear the message after displaying it
            navigate(location.pathname, { state: {} }); // Replace state with an empty object
        }
    }, [location.state, navigate, location.pathname]);

    return (
        <>
            <Helmet>
                <title>Request to Clarify</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Request to Clarify"

                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>

                                        <TableRow className="table-head">
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Enquiry No</TableCell>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Short name</TableCell>
                                            <TableCell>Customer</TableCell>
                                            {activePartition !== 'NewEnq_Close' && (
                                                <>
                                                    <TableCell>MKTG Officer</TableCell>
                                                    <TableCell>Requirement</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </>
                                            )}
                                            {activePartition === 'NewEnq_Close' && (
                                                <>
                                                    <TableCell>MPN No</TableCell>
                                                    <TableCell>Action</TableCell>
                                                </>
                                            )}
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {console.log("tableData =>", tableData)
                                        }
                                        {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index) => (
                                            <TableRow key={item.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item.enqno}</TableCell>
                                                <TableCell>{item.enqdate}</TableCell>
                                                <TableCell>{item.type}</TableCell>
                                                <TableCell>{item.sname}</TableCell>
                                                <TableCell>{item.cusname}</TableCell>
                                                {activePartition !== 'NewEnq_Close' && (
                                                    <>
                                                        <TableCell>{item.mktname}</TableCell>
                                                        <TableCell>{item.req}</TableCell>
                                                        <TableCell>{item.status}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleUpdateClick(item.Enqid, item.Detid, item.id)}
                                                            >
                                                                Update
                                                            </Button>
                                                        </TableCell>
                                                    </>
                                                )}
                                                {activePartition === 'NewEnq_Close' && (
                                                    <>
                                                        <TableCell>{item.mpnno}</TableCell>
                                                        <TableCell>{item.status}</TableCell>
                                                        <TableCell>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                onClick={() => handleUpdateClick(item.Enqid, item.Detid, item.id)}
                                                            >
                                                                View
                                                            </Button>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={tableData.length == 0 ? 0 : tableData[0]["count"]}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[5, 10, 25]}
                                />
                            </TableContainer>
                            {/* <TablePagination
                                component="div"
                                count={data.length == 0 ? 0 : data[0]["count"]}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            /> */}
                        </Paper>
                    </Grid>
                </Grid>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );

}


export default ReqtoClarify;