import { Box, Button, Card, CardContent, CardHeader, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import './designdev.css'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { log } from 'console';
import { id } from 'date-fns/locale';
import { NewReleasesRounded } from '@material-ui/icons';
import { isTemplateExpression } from 'typescript';

interface Data1 {
    id: number;
    enqno: string;
    enqdate: string;
    cusname: string;
    req: string;
    eqipposition: string;
    printcolor: string;
    isproceed: number;
    mpnno: string;
}
interface Data {
    id: number;
    name: string;
    compliance: string;
    attach: string;
    remarks: string;
}
interface Test {
    id: number;
    type: string;
    name: string;
}
interface Model {
    id: number;
    casemodel: string;
}
interface EssData {
    essdataid: string;
    remarks: string;
    cby: string;
    name: string;
    attach: string;
    compliance: string;
}
interface TestAccess {
    type: string;
    testaccessid: string;
    name: string;
    bit: string;
    handle?: string;  // Marking as optional
    status?: boolean | string;  // Marking as optional
    desp?: string;
}

interface FormData {
    ifinsert: boolean;
    informid: number;
    partno: string;
    drawid: string | number; // Assuming state.id is either a string or number, adjust as needed
    reqtypeid: number;
    ecnid: number;
    refno: string;
    issueno: string;
    pagerevno: string;
    intype: string;
    drawdate: Date;
    casetype: string;
    casemodelid: number;
    result: string;
    designby: string;
    authby: string;
    status: string;
    cby: string;
    listessdata: EssData[];
    listtest: TestAccess[];
    listaccess: TestAccess[];
    listitem: TestAccess[];
    listrequire: TestAccess[];
    attch: string;
    disablePartno?: boolean;
    testdesc: string;
    accdesc: string;
}
interface SizeWt {
    id: number;
    innerdimns: string;
    outerdimns: string;
    casenetwt2: number;
}
interface ReviewData {
    listreqitems: ReqItems[];
    ifinsert: boolean;
    drawid: string | number;
    enqdetid: number;
    rdate: Date;
    eqipsize: string;
    eqipwt: string;
    refdrgthere: number;
    referdrgtheretext: string;
    suplocspd: number;
    eqipposition: string;
    anysplmreq: number;
    eqliftbeltreq: number;
    nameplatereq: number;
    spltestreq: number;
    senspd: number;
    senspdtext: string;
    printcolor: string;
    prv: number;
    descnt: number;
    lhook: number;
    wheel: number;
    slings: number;
    flprov: number;
    lidhandle: number;
    locks: number;
    latchlookasst: number;
    huin: number;
    foam: string;
    casetype: string;
    caseresult: string;
    rofoam: string;
    roprintcolor: string;
    cby: "1";
    revno: string;
    revpoints: string;
    note: string;
}

interface EcnHistory {
    ecnno: string;
    date: Date;
    revpoints: string;
}
interface ReqItems {
    revid: number;
    accessid: number;
    status: number
}
interface VerifyData {
    ifinsert: boolean;
    drawid: string | number;
    enqdetid: number;
    vdate: Date;
    cieqdimns: string;
    caseexdimns: string;
    caseinnerdims: string;
    foamoutbottom: string;
    foamouttop: string;
    formcutbottom: string;
    formcuttop: string;
    handle: string;
    cby: "1"
    listrequirements: Requirements[];

}
interface Requirements {
    //drawid: number;
    verid: number;
    accessid: number;
    status: string
}

interface ValidData {
    id: number;
    detailstobechecked: string;
    chkwith: string;
}
interface ValidDetailsData {
    ifinsert: boolean;
    drawid: string | number;
    ecnid: number;
    drawvalid: number;
    con: Date;
    detailscheck: string[];
    casedwg: number;
    bom: number;
    foamdwg: number;
    printingdwg: number;
    remarkslistdate: number;
    eqpwt: string;
    casewt: string;
    accwt: string;
    foamwt: string;
    stiffwt: string;
    finalwt: string;
}

interface MarketDocs {
    drawid: number;
    docname: string;
    path: string;
}

interface LocationState1 {
    reqtypeid: number;
    id: number;
}

interface ModelData {
    id: number;
    casemodel: string;
    innerdimns: string;
    outerdimns: string;
    boxwt: number;
    casterwheel: number;
    acceswt: number;
    epe: number;
    casenetwt1: number;
    xlpe: number;
    casenetwt2: number;
    wropecode: number;
    latchtype: string;
    hingetype: string;
    stiffwt: number;
    indlen: number;
    indwidth: number;
    indbottom: number;
    indtop: number;
    isactive: number;
}

const CompletedReports: React.FC = () => {
    const location = useLocation();
    const state = location.state as LocationState1 | undefined;
    const [showImage, setShowImage] = useState(false);
    const [tableData, setTableData] = useState<Data1[]>([]);
    const [model, setModel] = useState<ModelData[]>([]);
    const [sizewtdata, setSizeWtData] = useState<SizeWt[]>([]);
    const [showTextBoxhandle, setShowTextBoxhandle] = useState(false);
    const [rows, setRows] = useState<EcnHistory[]>([]);// Initialize with one empty row

    const [formData, setFormData] = useState<FormData>({
        ifinsert: true,
        informid: 0,
        partno: "",
        drawid: state.id,
        reqtypeid: state.reqtypeid,
        ecnid: 0,
        refno: "",
        issueno: "",
        drawdate: new Date(),
        pagerevno: "",
        intype: "",
        casetype: "",
        casemodelid: 0,
        result: "",
        designby: "1",
        authby: "1",
        status: "Initiate",
        cby: "1",
        attch: "",
        testdesc: "",
        accdesc: "",
        listessdata: [
            {
                essdataid: "",
                remarks: "",
                cby: "1",
                name: "",
                attach: "",
                compliance: ""
            }
        ],
        listtest: [
            {
                type: "test",
                testaccessid: "",
                name: "",
                bit: ""
            }
        ],
        listaccess: [
            {
                type: "Accessories",
                testaccessid: "",
                name: "",
                bit: ""
            }
        ],
        listitem: [
            {
                type: "item",
                testaccessid: "",
                name: "",
                bit: ""
            }
        ],
        listrequire: [
            {
                type: "require",
                testaccessid: "",
                name: "",
                bit: "",
                status: "",
                desp: ""
            }
        ]
    })

    const [reviewData, setReviewData] = useState<ReviewData>({
        listreqitems: [],
        ifinsert: true,
        drawid: state.id,
        enqdetid: 0,
        eqipsize: "",
        eqipwt: "",
        rdate: new Date(),
        refdrgthere: null,
        referdrgtheretext: "",
        suplocspd: null,
        eqipposition: "",
        anysplmreq: null,
        eqliftbeltreq: null,
        nameplatereq: null,
        spltestreq: null,
        senspd: null,
        senspdtext: "",
        printcolor: "",
        prv: null,
        descnt: null,
        lhook: null,
        wheel: null,
        slings: null,
        flprov: null,
        lidhandle: null,
        locks: null,
        latchlookasst: null,
        huin: null,
        foam: "",
        casetype: "",
        caseresult: "",
        rofoam: "",
        roprintcolor: "",
        cby: "1",
        revno: "",
        revpoints: "",
        note: ""
    })

    const [ecnhistoryData, setecnhistoryData] = useState<EcnHistory>({
        ecnno: "",
        date: null,
        revpoints: ""
    })

    const [verifyData, setverifyData] = useState<VerifyData>({
        ifinsert: true,
        drawid: state.id,
        enqdetid: 0,
        vdate: new Date(),
        cieqdimns: "",
        caseexdimns: "",
        caseinnerdims: "",
        foamoutbottom: "",
        foamouttop: "",
        formcutbottom: "",
        formcuttop: "",
        handle: "",
        cby: "1",
        listrequirements: []
    })

    const [validData, setValidData] = useState<ValidData[]>([
        {
            id: 0,
            detailstobechecked: "",
            chkwith: "",
        },
    ]);

    const [validDetailsData, setvalidDetailsData] = useState<ValidDetailsData[]>([]);

    const [marketdocsData, setmarketdocsData] = useState<MarketDocs[]>([
        {
            drawid: state.id,
            docname: "",
            path: ""
        }
    ])

    useEffect(() => {
        if (state?.id) {
            apiService.getEssData(Number(state.id))
                .then(response => {
                    //setData(response.data);
                    console.log(response.data, "response.data12");
                    setFormData(prevState => ({
                        ...prevState,
                        listessdata: response.data
                    }));
                    console.log(formData, "formData12");
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });

            apiService.getTestData(Number(state.id))
                .then(response => {
                    // Ensure the response data includes table IDs mapped to testaccessid
                    const updatedData = response.data.map((item: any) => ({
                        ...item,
                        testaccessid: item.id, // Assign the table ID to testaccessid
                    }));

                    setFormData(prevState => ({
                        ...prevState,
                        listtest: updatedData
                    }));

                    console.log(formData, "Updated listtest with table IDs");
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });

            apiService.getAccessoriesData(Number(state.id))
                .then(response => {
                    //setData(response.data);
                    const updatedData = response.data.map((item: any) => ({
                        ...item,
                        testaccessid: item.id, // Assign the table ID to testaccessid
                    }));

                    setFormData(prevState => ({
                        ...prevState,
                        listaccess: updatedData
                    }));

                    console.log(formData, "Updated listaccess with table IDs");
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                });

            apiService.getSizeWt(Number(state.id))
                .then(response => {
                    console.log("Fetched list data:", response.data);
                    setSizeWtData(response.data);
                })
                .catch(err => {
                    console.log("Error fetching grid data:", err);
                });

            apiService.getDropdown("dsn_mas_model").then(response => {
                const activeModels = response.data.filter(item => item.isactive === 1);
                setModel(activeModels);
            }).catch((err) => {
                console.log("err", err);
            });
        };


        apiService.getDropdown("dsn_draw_validation").then(response => {
            setValidData(response.data);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getInputForm(state.id).then(response => {
            console.log("API Input Response:", response.data);
            const api = response.data
            setFormData(api as FormData);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getReviewForm(state.id).then(response => {
            console.log("API Response: Now Test", response.data);
            const api = response.data
            setReviewData(api as ReviewData);
            console.log("API reviewData:", reviewData);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getEcnHistory(state.id).then((response) => {
            console.log("API Response: ECN", response.data);

            // Ensure the response is mapped to the rows structure
            const transformedData = Array.isArray(response.data)
                ? response.data.map((item) => ({
                    ecnno: item.ecnno || "",
                    date: item.date ? new Date(item.date) : null,
                    revpoints: item.revpoints || "",
                }))
                : [
                    {
                        ecnno: response.data.ecnno || "",
                        date: response.data.date ? new Date(response.data.date) : null,
                        revpoints: response.data.revpoints || "",
                    },
                ];

            setRows(transformedData); // Update rows state
            console.log("Updated Rows:", transformedData);
        }).catch((err) => {
            console.log("Error fetching ECN history", err);
        });

        apiService.getVerificationForm(state.id).then(response => {
            console.log("API Response Verify:", response.data);
            const api = response.data
            setverifyData(api as VerifyData);
            if (response.data?.handle === "plastic" || response.data?.handle === "ss" || response.data?.handle === "tiny") {
                setShowTextBoxhandle(false);
            }
            else {
                setShowTextBoxhandle(true);
            }

        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getValidationForm(state.id).then(response => {
            console.log("=> test api data", response.data);
            const api = response.data
            setvalidDetailsData(api as ValidDetailsData[]);
        }).catch((err) => {
            console.log("err", err)
        });

    }, [state?.id]);
    console.log('api', formData);

    const printRef = useRef(null);
    const contentToPrint: any = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    //FOR drawdate
    let drawdate = new Date();
    let day1 = String(drawdate.getDate()).padStart(2, '0');
    let month1 = String(drawdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year1 = drawdate.getFullYear();
    let formattedDatedrawdate = `${day1}-${month1}-${year1}`;

    //FOR rdate
    let rdate = new Date();
    let day2 = String(rdate.getDate()).padStart(2, '0');
    let month2 = String(rdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year2 = rdate.getFullYear();
    let formattedDaterdate = `${day2}-${month2}-${year2}`;

    //FOR vdate
    let vdate = new Date();
    let day4 = String(vdate.getDate()).padStart(2, '0');
    let month4 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year4 = vdate.getFullYear();
    let formattedDatevdate = `${day4}-${month4}-${year4}`;

    //FOR con
    let con = new Date();
    let day5 = String(vdate.getDate()).padStart(2, '0');
    let month5 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year5 = con.getFullYear();
    let formattedDatecon = `${day5}-${month5}-${year5}`;

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>Design & Development - Design</title>
            </Helmet>
            <br></br>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '13px',
                            width: '100%',
                            color: 'white',
                        }}
                    >
                        <Tab
                            label="Back"
                            onClick={() => {
                                navigate('/Design/DesignFollowup');
                            }}
                            sx={{
                                backgroundColor: 'blue',
                                "&:hover": { color: 'white' },
                            }}
                        />
                    </div>



                    {/* Input Form */}
                    <div  ref={contentToPrint}>
                  
                    <div
                        className="panel"
                        style={{
                            marginTop: '10px',
                            marginLeft: '30px',
                        }}
                    >
                        <div className="display-area" id="printable-content" style={{ marginTop: '-10px' }}>
                            <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '5px' }}>
                                <Typography gutterBottom>
                                    <h1 style={{ marginTop: 10 }}><b>SAARC TOOL-TECH (P) LTD.</b></h1>
                                </Typography>
                            </div>
                            {/* Table-like Structure for Row Content */}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr auto',
                                alignItems: 'center',
                                //gap: '5px',
                                border: '1px solid black',
                                padding: '5px',
                                marginBottom: '10px'
                            }}>
                                {/* Left Side: Logo */}
                                <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '60px', height: '60%' }}
                                    />
                                </div>

                                {/* Center: Input Form Heading */}
                                <div style={{ textAlign: 'center' }}>
                                    <Typography gutterBottom>
                                        <h2 style={{ margin: 0, fontSize: '18px' }}><b>DESIGN AND DEVELOPMENT <br />INPUT FORM</b></h2>
                                    </Typography>
                                </div>
                                {/* Right Side: Reference Details in a Table */}
                                <div style={{ marginBottom: '-18px' }}>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>REFERENCE NO :</td>
                                                <td style={{ padding: '5px' }}>QMP 7.3.2 R/A</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>ISSUE NO. :</td>
                                                <td style={{ padding: '5px' }}>06/DATE: 02/06/17</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>PAGE REV. NO :</td>
                                                <td style={{ padding: '5px' }}>05/DATE: 02/06/17</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tableData.map((item, index) => (
                                <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry No :</strong>
                                        <span className="enquiry-data">{item.enqno}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry Date :</strong>
                                        <span className="enquiry-data">{item.enqdate}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Customer :</strong>
                                        <span className="enquiry-data">{item.cusname}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Requirement :</strong>
                                        <span className="enquiry-data">{item.req}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Date :</strong>
                                        <span className="enquiry-data">{formattedDatedrawdate}</span>
                                    </div>
                                </div>
                            ))}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

                            </div>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="h6" gutterBottom>
                                        <b><u>EQUIPMENT DETAILS</u></b>
                                    </Typography>
                                    {tableData.map((item, index) => (
                                        <div
                                            key={item.id}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                marginBottom: '20px',
                                            }}
                                        >
                                            <div>
                                                <strong style={{ color: "#000" }}>MPN No :</strong>{" "}
                                                <b style={{ color: "#a50101" }}>{item.mpnno}</b>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Table className="table-body">
                                    <TableBody>
                                        <TableRow className="table-header">
                                            <TableCell><b>SL. NO</b></TableCell>
                                            <TableCell><b>DESIGN DATA DETAILS</b></TableCell>
                                            <TableCell><b>GIVEN DETAILS & FILES</b></TableCell>
                                            <TableCell><b>REMARKS</b></TableCell>
                                        </TableRow>
                                        {formData?.listessdata?.map((row, index) => (
                                            <TableRow key={row.essdataid}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.compliance}</TableCell>
                                                <TableCell>{row.remarks}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>
                            <div>
                                <br></br>
                                <div className='test-print'>
                                    <Typography variant="h6" gutterBottom >
                                        <b style={{ color: "#a50101" }}><u>TEST DETAILS (AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <TableContainer>
                                                <Table className="table-body">
                                                    <TableHead>
                                                        <TableRow className="table-header">
                                                            <TableCell><b>SL. NO</b></TableCell>
                                                            <TableCell><b>NAME OF THE TEST</b></TableCell>
                                                            <TableCell><b>Y/N</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>

                                                        {formData?.listtest?.map((row, index) => (
                                                            <TableRow key={row.testaccessid}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{row.name}</TableCell>
                                                                {/* Display Yes/No based on the stored 0 or 1 */}
                                                                <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow>
                                                            <TableCell></TableCell>
                                                            <TableCell>Any other special requirements</TableCell>
                                                            <TableCell>{formData.testdesc}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Table className="table-body">
                                                <TableBody>
                                                    <TableRow className="table-header">
                                                        <TableCell><b>SL. NO</b></TableCell>
                                                        <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                                        <TableCell><b>APPLICABLE Y/N</b></TableCell>
                                                    </TableRow>
                                                    {formData?.listaccess?.map((row, index) => (
                                                        <TableRow key={row.testaccessid}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{row.name}</TableCell>
                                                            {/* Display Yes/No based on the stored 0 or 1 */}
                                                            <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        <TableCell>Others</TableCell>
                                                        <TableCell>{formData?.accdesc}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            <br></br>
                            <div>
                                <Typography variant="h6" gutterBottom>
                                    <b><u>CASE SELECTION</u></b>
                                </Typography>
                                <Table className="table-body">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><b>TYPE</b></TableCell>
                                            <TableCell><b>MODEL</b></TableCell>
                                            <TableCell><b>RESULT</b></TableCell>
                                            <TableCell><b>PART NO</b></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{formData?.casetype}</TableCell>
                                            <TableCell>{model.find(m => m.id === formData?.casemodelid)?.casemodel}</TableCell>
                                            <TableCell>{formData?.result}</TableCell>
                                            <TableCell>{formData?.partno}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                        <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                            <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                            <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                        </div>
                    </div>



                    {/* Review Form */}
                    <div
                        className="panel"
                        style={{
                            marginLeft: '30px',
                        }}
                    >
                        <div className="reviewprint">
                            <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '5px' }}>
                                <Typography gutterBottom>
                                    <h1 style={{ marginTop: 10 }}><b>SAARC TOOL-TECH (P) LTD.</b></h1>
                                </Typography>
                            </div>
                            {/* Table-like Structure for Row Content */}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr auto',
                                alignItems: 'center',
                                //gap: '5px',
                                border: '1px solid black',
                                padding: '5px',
                                marginBottom: '10px'
                            }}>
                                {/* Left Side: Logo */}
                                <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '60px', height: '60%' }}
                                    />
                                </div>
                                {/* Center: Input Form Heading */}
                                <div style={{ textAlign: 'center' }}>
                                    <Typography gutterBottom>
                                        <h2 style={{ margin: 0, fontSize: '18px' }}><b>DESIGN AND DEVELOPMENT <br />REVIEW FORM</b></h2>
                                    </Typography>
                                </div>
                                {/* Right Side: Reference Details in a Table */}
                                <div style={{ marginBottom: '-18px' }}>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>REFERENCE NO :</td>
                                                <td style={{ padding: '5px' }}>QMP 7.3.4 R/A</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>ISSUE NO. :</td>
                                                <td style={{ padding: '5px' }}>01/DATE: 01/08/12</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>PAGE REV. NO :</td>
                                                <td style={{ padding: '5px' }}>01/DATE: 01/06/13</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tableData.map((item, index) => (
                                <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry No :</strong>
                                        <span className="enquiry-data">{item.enqno}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry Date :</strong>
                                        <span className="enquiry-data">{item.enqdate}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Customer :</strong>
                                        <span className="enquiry-data">{item.cusname}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Requirement :</strong>
                                        <span className="enquiry-data">{item.req}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Date :</strong>
                                        <span className="enquiry-data">{formattedDaterdate}</span>
                                    </div>
                                </div>
                            ))}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} className="main-heading">
                                <div>INPUT DETAILS</div>
                                {tableData.map((item, index) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        <div>
                                            <strong style={{ color: "#000" }}>MPN No :</strong>{" "}
                                            <b style={{ color: "#a50101" }}>{item.mpnno}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="one-by-one">
                                <div className="field">
                                    <label><strong>I/P NO :</strong></label>
                                    {tableData.map((item, index) => (
                                        <div key={item.id}>
                                            <span className="value">{item.enqno}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="field">
                                    <label><strong>EQUIPMENT SIZE:</strong></label>
                                    {sizewtdata.map((item, index) => (
                                        <div key={item.id} style={{ marginBottom: "20px" }}>
                                            <span className="value">{item.outerdimns}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="field">
                                    <label><strong>EQUIPMENT WEIGHT:</strong></label>
                                    {sizewtdata.map((item, index) => (
                                        <div key={item.id} style={{ marginBottom: "20px" }}>
                                            <span className="value">{item.casenetwt2}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Start of two columns layout */}
                            <div className="two-in-row">
                                <div className="field">
                                    <strong>REFER DRG THERE:</strong>
                                    <span className="value">
                                        {reviewData?.refdrgthere != null
                                            ? reviewData.refdrgthere.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>
                                <div className="field">
                                    <strong>REFER DRG THERE TEXT:</strong>
                                    <span className="value"> {reviewData?.referdrgtheretext}</span>
                                </div>

                                <div className="field">
                                    <strong>SUPPORT LOCATION SPECIFIED:</strong>
                                    <span className="value">
                                        {reviewData?.suplocspd != null
                                            ? reviewData.suplocspd.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>
                                <div className="field">
                                    <strong>EQUIPMENT POSITION:</strong>
                                    <span className="value">{reviewData.eqipposition}</span>
                                </div>

                                <div className="field">
                                    <strong>ANY SPL MARKING REQUIRED:</strong>
                                    <span className="value">
                                        {reviewData?.anysplmreq != null
                                            ? reviewData.anysplmreq.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>
                                <div className="field">
                                    <strong>EQPT LIFTING BELT REQUIRED:</strong>
                                    <span className="value">
                                        {reviewData?.eqliftbeltreq != null
                                            ? reviewData.eqliftbeltreq.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>

                                <div className="field">
                                    <strong>NAME PLATE REQUIRED:</strong>
                                    <span className="value">
                                        {reviewData?.nameplatereq != null
                                            ? reviewData.nameplatereq.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>
                                <div className="field">
                                    <strong>SPECIAL TEST REQUIRED:</strong>
                                    <span className="value">
                                        {reviewData?.spltestreq != null
                                            ? reviewData.spltestreq.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>

                                <div className="field">
                                    <strong>SENSITIVITY SPECIFIED:</strong>
                                    <span className="value">
                                        {reviewData?.senspd != null
                                            ? reviewData.senspd.toString() === "1"
                                                ? "Yes"
                                                : "No"
                                            : "No"}
                                    </span>
                                </div>
                                <div className="field">
                                    <strong>SENSITIVITY SPECIFIED TEXT:</strong>
                                    <span className="value">{reviewData?.senspdtext}</span>
                                </div>

                                <div className="field">
                                    <strong>PRINTING COLOUR:</strong>
                                    <span className="value">{reviewData.printcolor}</span>
                                </div>
                                <div className="field">
                                    <strong>FOAM:</strong>
                                    <span className="value">{reviewData.foam}</span>
                                </div>

                                {/* Display radio options in two columns */}
                                <div className="field">
                                    <Grid container spacing={2}>
                                        {formData?.listaccess?.map((row: any, index: number) => (
                                            <Grid item xs={6} key={row.testaccessid}>
                                                <div className="radioWithMargin">
                                                    <strong>{row.name.toUpperCase()}</strong>
                                                    <span className="value">{row.bit === '1' ? 'Yes' : 'No'}</span>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </div>
                            </div>
                            <div className="review-outputs">
                                <div className="main-heading-break">
                                    <div className="main-heading">REVIEW OUTPUTS</div>
                                    <div className="field-row">
                                        <div className="field">
                                            <strong>CASE TYPE:</strong>
                                            <span className="value">{formData?.casetype}</span>
                                        </div>
                                        <div className="field">
                                            <strong>RESULT:</strong>
                                            <span className="value">{formData?.result}</span>
                                        </div>
                                    </div>
                                    <div className="field-row">
                                        <div className="field">
                                            <strong>FOAM:</strong>
                                            <span className="value">{reviewData?.rofoam}</span>
                                        </div>
                                        <div className="field">
                                            <strong>PRINTING COLOUR:</strong>
                                            <span className="value">{reviewData?.roprintcolor}</span>
                                        </div>
                                    </div>
                                </div>
                                <br></br>
                                <div className="section">
                                    <div className="main-heading">REVIEW ACCESSORIES</div>
                                    <div>
                                        <ul>
                                            {formData?.listaccess?.filter((row: any) =>
                                                reviewData.listreqitems?.some(
                                                    item => item.accessid === row.id && item.status === 1
                                                )
                                            ).map((selectedRow: any) => (
                                                <li key={selectedRow.id}>{selectedRow.name}</li>
                                            ))}
                                            {/* Display message if no items were selected */}
                                            {formData?.listaccess?.filter((row: any) =>
                                                reviewData.listreqitems?.some(
                                                    item => item.accessid === row.id && item.status === 1
                                                )
                                            ).length === 0 && <p>No items selected</p>}
                                        </ul>
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="main-heading">REVIEW DETAILS</div>
                                    <div className="field-row">
                                        <div className="field">
                                            <strong>REVIEW POINTS:</strong>
                                            <span className="value">{reviewData?.revpoints}</span>
                                        </div>
                                    </div>
                                    <div className="field-row">
                                        <div className="field">
                                            <strong>NOTE:</strong>
                                            <span className="value">{reviewData?.note}</span>
                                        </div>
                                    </div>
                                    {rows.length > 0 && ( // Check if there are rows before rendering the entire section
                                        <>
                                            <div className="review-history">
                                                <strong>ECN HISTORY</strong>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>ECN. No</TableCell>
                                                            <TableCell>ECN. Date</TableCell>
                                                            <TableCell>Review Points</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                                <TableCell></TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                            <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                            <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                        </div>
                    </div>


                    {/* Verification Form */}
                    <div
                        className="panel"
                        style={{
                            marginLeft: '30px',
                            width: '97%'
                        }}
                    >
                        <div  className="reviewprint">
                            <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '5px' }}>
                                <Typography gutterBottom>
                                    <h1 style={{ marginTop: 10 }}><b>SAARC TOOL-TECH (P) LTD.</b></h1>
                                </Typography>
                            </div>
                            {/* Table-like Structure for Row Content */}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr auto',
                                alignItems: 'center',
                                //gap: '5px',
                                border: '1px solid black',
                                padding: '5px',
                                marginBottom: '10px'
                            }}>
                                {/* Left Side: Logo */}
                                <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '60px', height: '60%' }}
                                    />
                                </div>
                                {/* Center: Input Form Heading */}
                                <div style={{ textAlign: 'center' }}>
                                    <Typography gutterBottom>
                                        <h2 style={{ margin: 0, fontSize: '18px' }}><b>DESIGN AND DEVELOPMENT <br />VERIFICATION FORM</b></h2>
                                    </Typography>
                                </div>
                                {/* Right Side: Reference Details in a Table */}
                                <div style={{ marginBottom: '-18px' }}>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>REFERENCE NO :</td>
                                                <td style={{ padding: '5px' }}>QMP 7.3.5 R/A</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>ISSUE NO. :</td>
                                                <td style={{ padding: '5px' }}>01/DATE: 01/08/12</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>PAGE REV. NO :</td>
                                                <td style={{ padding: '5px' }}>01/DATE: 01/06/13</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tableData.map((item, index) => (
                                <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry No :</strong>
                                        <span className="enquiry-data">{item.enqno}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry Date :</strong>
                                        <span className="enquiry-data">{item.enqdate}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Customer :</strong>
                                        <span className="enquiry-data">{item.cusname}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Requirement :</strong>
                                        <span className="enquiry-data">{item.req}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Date :</strong>
                                        <span className="enquiry-data">{formattedDatevdate}</span>
                                    </div>
                                </div>
                            ))}
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {tableData.map((item, index) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                            <strong style={{ color: "#000" }}>MPN No :</strong>{" "}
                                            <b style={{ color: "#a50101" }}>{item.mpnno}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan={3} className="no-border"></th>
                                        <th rowSpan={3} className='designop'>EQPT. DIMNS.</th> {/* Equipment Dimensions on the left */}
                                        <th colSpan={6} className='designop'>OUTPUT</th> {/* Output heading spanning all columns */}
                                    </tr>
                                    <tr>
                                        <th className='designop' rowSpan={2}>CASE EXTERIOR DIMNS.</th>
                                        <th className='designop' rowSpan={2}>CASE INNER DIMNS.</th>
                                        <th colSpan={2} className='designop'>FOAM OUTER DIMNS.</th> {/* Foam Outer Dimensions with partitions */}
                                        <th colSpan={2} className='designop'>FOAM CUT DIMNS.</th>   {/* Foam Cut Dimensions with partitions */}
                                    </tr>
                                    <tr>
                                        {/* Sub-headings under Foam Dimensions */}
                                        <th className='designop'>BOTTOM</th>
                                        <th className='designop'>TOP</th>
                                        <th className='designop'>BOTTOM</th>
                                        <th className='designop'>TOP</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='verticaltext'>DIMENSIONS</td> {/* Equipment dimensions cell */}
                                        <td>{verifyData.cieqdimns}</td>
                                        {sizewtdata.map((item, index) => (
                                            <React.Fragment key={index}>
                                                <td>{item.outerdimns}</td> {/* CASE EXTERIOR DIMNS. */}
                                                <td>{item.innerdimns}</td> {/* CASE INNER DIMNS. */}
                                            </React.Fragment>
                                        ))}
                                        <td>{verifyData.foamoutbottom}</td> {/* FOAM OUTER DIMNS. BOTTOM */}
                                        <td>{verifyData.foamouttop}</td> {/* FOAM OUTER DIMNS. TOP */}
                                        <td>{verifyData.formcutbottom}</td> {/* FOAM CUT DIMNS. BOTTOM */}
                                        <td>{verifyData.formcuttop}</td> {/* FOAM CUT DIMNS. TOP */}
                                    </tr>
                                    <td rowSpan={17} className='verticaltext'>REQUIREMENTS</td>
                                    {formData.listrequire.map((row, index) => (
                                        <tr key={row.testaccessid}>
                                            <td className="designop1" colSpan={4}>
                                                {row.name}
                                                {index === 0 && verifyData.handle && (
                                                    <span style={{ marginLeft: '8px', marginTop: '1px' }}>
                                                        {verifyData.handle === 'tiny' ? 'TINY' :
                                                            verifyData.handle === 'ss' ? 'SS' :
                                                                verifyData.handle === 'plastic' ? 'PLASTIC' :
                                                                    verifyData.handle}
                                                    </span>
                                                )}
                                            </td>
                                            <td colSpan={3}>
                                                <span>
                                                    {(verifyData?.listrequirements?.[index]?.status === '1') // Use == to compare both types
                                                        ? "Yes"
                                                        : "No"}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                                <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                            </div>
                        </div>
                    </div>


                    {/* Validation Form */}
                    <div
                        className="panel"
                        style={{
                            marginLeft: '30px',
                            width: '97%'
                        }}
                    >
                        <div className="reviewprint">
                            <div style={{ textAlign: 'center', marginBottom: '10px', marginTop: '5px' }}>
                                <Typography gutterBottom>
                                    <h1 style={{ marginTop: 10 }}><b>SAARC TOOL-TECH (P) LTD.</b></h1>
                                </Typography>
                            </div>
                            {/* Table-like Structure for Row Content */}
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto 1fr auto',
                                alignItems: 'center',
                                //gap: '5px',
                                border: '1px solid black',
                                padding: '5px',
                                marginBottom: '10px'
                            }}>
                                {/* Left Side: Logo */}
                                <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '60px', height: '60%' }}
                                    />
                                </div>
                                {/* Center: Input Form Heading */}
                                <div style={{ textAlign: 'center' }}>
                                    <Typography gutterBottom>
                                        <h2 style={{ margin: 0, fontSize: '18px' }}><b>DESIGN AND DEVELOPMENT <br />VALIDATION FORM</b></h2>
                                    </Typography>
                                </div>
                                {/* Right Side: Reference Details in a Table */}
                                <div style={{ marginBottom: '-18px' }}>
                                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>REFERENCE NO :</td>
                                                <td style={{ padding: '5px' }}>QMP 6.5 R/A</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>ISSUE NO. :</td>
                                                <td style={{ padding: '5px' }}>00/DATE: 03/05/16</td>
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', padding: '5px', fontWeight: 'bold' }}>PAGE REV. NO :</td>
                                                <td style={{ padding: '5px' }}>00/DATE: 03/05/16</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tableData.map((item, index) => (
                                <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry No :</strong>
                                        <span className="enquiry-data">{item.enqno}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Enquiry Date :</strong>
                                        <span className="enquiry-data">{item.enqdate}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Customer :</strong>
                                        <span className="enquiry-data">{item.cusname}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Requirement :</strong>
                                        <span className="enquiry-data">{item.req}</span>
                                    </div>
                                    <div className="enquiry-item">
                                        <strong className="enquiry-heading">Date :</strong>
                                        <span className="enquiry-data">{formattedDatecon}</span>
                                    </div>
                                </div>
                            ))}
                            <br></br>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                {tableData.map((item, index) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                                            <strong style={{ color: "#000", marginRight: '5px' }}>MPN No :</strong>
                                            <b style={{ color: "#a50101" }}>{item.mpnno}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className='designop'>DETAILS TO BE CHECKED</th>
                                        <th className='designop'>CHK WITH</th>
                                        <th className='designop'>CASE DWG</th>
                                        <th className='designop'>BOM</th>
                                        <th className='designop'>FOAM DWG</th>
                                        <th className='designop'>PRINTING DWG</th>
                                        <th className='designop'>REMARKS/LIST DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validData?.map((row, index) => (
                                        <tr key={row.id}>
                                            <td className="valid" style={{ width: '150px' }}>
                                                {row.detailstobechecked}
                                                {index === 3 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['req', 'not req'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 4 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['latch', 'hinge', 'handle'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 5 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['rivet', 'bolt', 'big'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                        {['2240', '2230', '2220'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 7 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['2150', '4350', '4760'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>SS CA      {value}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 10 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['epe', 'xlpe', 'pu'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 13 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['xlpe', 'silica gel'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 15 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['textstyle', 'hteveness'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 17 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['yellow', 'black', 'white'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 19 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['green', 'grey', 'black'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 20 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['plastic', 'ss', 'tiny'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                        {['2000', '2050', '2110'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 21 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['prv', 'humidity', 'doc hold'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                        {['2320', '4000', '4050'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 22 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['screen printing', 'name plate'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 23 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['1.5', '3', '2.5'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                                {index === 24 && (
                                                    <div style={{ marginTop: '10px' }}>
                                                        {['req', 'not req'].map((value) => (
                                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                                                <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                                            ) : null
                                                        ))}
                                                    </div>
                                                )}
                                            </td>
                                            {index === 11 ? (
                                                <td className="verticaltext" rowSpan={5}>
                                                    {row.chkwith}
                                                </td>
                                            ) : index > 11 && index < 16 ? null : (
                                                <td className="designop">
                                                    {row.chkwith}
                                                </td>
                                            )}
                                            {index === 9 ? (
                                                <>
                                                    <td className="designop">CASE WT {validDetailsData[index]?.casewt || ''}</td>
                                                    <td className="designop">ACC. WT {validDetailsData[index]?.accwt || ''}</td>
                                                    <td className="designop">FOAM WT {validDetailsData[index]?.foamwt || ''}</td>
                                                    <td className="designop">STIFF. WT {validDetailsData[index]?.stiffwt || ''}</td>
                                                    <td className="designop">FINAL WT {validDetailsData[index]?.finalwt || ''}</td>
                                                </>
                                            ) : index === 8 ? (
                                                <>
                                                    <td className="designop" colSpan={4}>{validDetailsData[index]?.eqpwt || ''}</td>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.remarkslistdate === 1}
                                                            name="remarkslistdate"
                                                            disabled
                                                        />
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.casedwg === 1}
                                                            name="casedwg"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.bom === 1}
                                                            name="bom"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.foamdwg === 1}
                                                            name="foamdwg"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.printingdwg === 1}
                                                            name="printingdwg"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td className="tick">
                                                        <input
                                                            type="checkbox"
                                                            className="styled-checkbox"
                                                            checked={validDetailsData[index]?.remarkslistdate === 1}
                                                            name="remarkslistdate"
                                                            disabled
                                                        />
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                                <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                                <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                            </div>
                        </div>
                    </div>
                    </div>
                    <Button onClick={() => {
                        handlePrint(null, () => contentToPrint.current);
                    }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                        variant="contained"
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faPrint} />}>
                        Print
                    </Button>
                  
                </Grid>
            </Container >
            <Footer />
        </>
    )
};

export default CompletedReports;