import React, { useEffect, useRef } from 'react';
import * as cocoSsd from '@tensorflow-models/coco-ssd';
import '@tensorflow/tfjs';

const ObjectDetection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const setupCameraAndModel = async () => {
      if (!navigator.mediaDevices) return;

      // Start the camera
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        await videoRef.current.play();
      }

      const model = await cocoSsd.load();
      detectObjects(model);
    };

    const detectObjects = async (model: cocoSsd.ObjectDetection) => {
      if (!videoRef.current || !canvasRef.current) return;

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (!context) return;

      const detectLoop = async () => {
        if (videoRef.current) {
          // Resize canvas to match video dimensions
          canvas.width = videoRef.current.videoWidth;
          canvas.height = videoRef.current.videoHeight;

          context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

          // Run detection
          const predictions = await model.detect(videoRef.current);

          // Draw predictions
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

          predictions.forEach(prediction => {
            context.strokeStyle = '#00FFFF';
            context.lineWidth = 2;
            context.strokeRect(
              prediction.bbox[0],
              prediction.bbox[1],
              prediction.bbox[2],
              prediction.bbox[3]
            );

            context.fillStyle = '#00FFFF';
            context.font = '18px Arial';
            if(`${prediction.class}` == "Person")
            alert(`${prediction.class}`)
            context.fillText(
              `${prediction.class} (${Math.round(prediction.score * 100)}%)`,
              prediction.bbox[0],
              prediction.bbox[1] > 10 ? prediction.bbox[1] - 5 : 10
            );
          });
        }

        requestAnimationFrame(detectLoop);
      };

      detectLoop();
    };

    setupCameraAndModel();
  }, []);

  return (
    <div>
      <video
        ref={videoRef}
        style={{ display: 'none' }}
      />
      <canvas
        ref={canvasRef}
        style={{ width: '640px', height: '480px', border: '1px solid black' }}
      />
    </div>
  );
};

export default ObjectDetection;
