
import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { ChangeEvent, SyntheticEvent, useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
    Button,
    Container,
    Grid,
    Card,
    CardHeader,
    CardContent,
    Divider,
    Select,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    Tabs,
    Tab,
    Typography
} from '@mui/material';
import Footer from 'src/components/Footer';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';

import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import axios from 'axios';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPencil } from "react-icons/fa6";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
// For Grid List
interface Data {
    empno: string;
    firstname: string;
    deptname: string;
    designname: string;
    id: number;
    workcatg: string;
    salarydays: string;
    paiddays: string;
    lop: string;
    total: string;
    paidamount: string;
    // Add more fields as necessary
}
interface Attndet {
    id: string;
    empid: string;
    year: string;
    month: string;
    dates: string;
    intimes: string;
    outtimes: string;
    status: string;

}
interface Salarydet {
    id: string;
    empid: string;
    year: string;
    month: string;
    dates: string;
    type: string;
    name: string;
    eamount: string;

}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];
const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];
interface District {
    id: number;
    name: string;
}

function SalaryProcess() {

    // For Grid Table
    const [data, setData] = useState<Data[]>([]);
    const [attndet, setAttndet] = useState<Attndet[]>([]);
    const [salarydet, setSalarydet] = useState<Salarydet[]>([]);
    const [district, setDistrict] = useState<District[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(150);
    const [search1, setSearch1] = useState('');
    const [empcode, setEmpcode] = useState('');
    const [empname, setEmpname] = useState('');
    const [open, setOpen] = useState(false);

    //Form data 
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0'); // Adds leading zero if needed

    const [formData, setFormData] = useState<any>({
        id: '',
        sname: '',
        name: '',
        isactive: '',
        districtid: '',
        year: currentYear,
        month: currentMonth
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [value, setValue] = useState(0);

    useEffect(() => {
        // Fetch data from an GET API
        console.log(rowsPerPage.toString(), "size");
        console.log((page + 1).toString(), "page");

        apiService.get_salarysum(rowsPerPage.toString(), (page + 1).toString(), search1, formData.year, formData.month).then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err)
        })

        apiService.getDropdown("mas_district").then(response => {
            setDistrict(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [rowsPerPage, page, formData, search1]);

    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangetap = (event: SyntheticEvent, newValue: number) => {

        setValue(newValue);
    }
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    // attn Process
    let submit = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior

        apiService.slary_process(formData.year, formData.month, "1"
        ).then(response => {
            console.log("response", response.data);
            if (response?.data.key == "400") {
                errorToast(response?.data?.message)
            }
            else {
                successToast(response.data);
                let fd = {
                    year: formData.year,
                    month: formData.month,
                }
                setFormData(fd);
            }
        }).catch((err) => {
            console.log("err", err)
        })
    }
    //attn det update
    let attndetupdate = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior
        console.log(attndet, "attndet");
        setOpen(false);
        apiService.attndet_update(attndet).then(response => {
            console.log("response", response.data);
            if (response?.data.key == "400") {
                errorToast(response?.data?.message)
            }
            else {
                successToast(response.data);
                setSearch1("");
                let fd = {
                    year: formData.year,
                    month: formData.month,
                }
                setFormData(fd);
            }
        }).catch((err) => {
            console.log("err", err)
        })
    }
    // Handler for Edit Button click
    const handleEditClick = (item: Data) => {
        setEmpcode(item.empno);
        setEmpname(item.firstname);

        apiService.get_attndet(rowsPerPage.toString(), (page + 1).toString(), "",
            formData.year, formData.month, item.id
        ).then(response => {
            setAttndet(response.data);

        }).catch((err) => {
            console.log("err", err)
        });

        apiService.get_salarydet(rowsPerPage.toString(), (page + 1).toString(), "",
            formData.year, formData.month, item.id
        ).then(response => {
            setSalarydet(response.data);

        }).catch((err) => {
            console.log("err", err)
        });
        //  setSelectedItem(item);
        setOpen(true);

    };
    const handleSearchChange1 = (event) => {
        setSearch1(event.target.value);

    };
    const handleClose = () => {
        setOpen(false);
        // setFormData({
        //   id: '',
        //   sname: '',
        //   name: '',
        //   isactive: '',
        // });
        // setSelectedItem(null);
        setErrors(null);
    };
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }
    const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const updatedFormData = { ...attndet };

        // Copy the tableRows from formData
        //const newTableRows = [...updatedFormData.enquiry[0]?.tableRows];

        // Update the specific row with new value
        // attndet[index].status = value;
        // setAttndet(attndet);
        // setAttndet(updatedFormData);

        // Create a copy of the attndet array
        const updatedRows = attndet.map((row, i) =>
            i === index ? { ...row, [name]: value } : row
        );

        // Update the state
        setAttndet(updatedRows);

        console.log(attndet[index], "Updated tableRows");
        console.log(attndet, "Updated => formData tableRows");
    };


    return (
        <>
            <Helmet>
                <title>Salary Process</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Salary Process"
                    subHeading=""
                />
            </PageTitleWrapper>

            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <form action="#">
                            <Card>
                                {/* Form Input Fields */}
                                <CardContent>
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, width: '25ch' }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >
                                        <div>
                                            <TextField
                                                select
                                                margin="dense"
                                                label="Year"
                                                name='year'
                                                fullWidth
                                                value={formData?.year === '' ? '' : Number(formData?.year)}
                                                onChange={handleChange}
                                                error={!!errors?.year} // Highlight the field if there's an error
                                                helperText={errors?.year ? "Status is required" : ""}
                                            >
                                                <MenuItem value="2024">2024</MenuItem>
                                                <MenuItem value="2025">2025</MenuItem>
                                            </TextField>
                                            <TextField
                                                select
                                                margin="dense"
                                                label="Month"
                                                name='month'
                                                fullWidth
                                                value={formData?.month === '' ? '' : Number(formData?.month)}
                                                onChange={handleChange}
                                                error={!!errors?.month} // Highlight the field if there's an error
                                                helperText={errors?.month ? "Status is required" : ""}
                                            >
                                                {months.map((month) => (
                                                    <MenuItem key={month.value} value={month.value}>
                                                        {month.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>


                                            <Button sx={{ margin: 1 }} onClick={submit} variant='contained' color="success">
                                                Process
                                            </Button>
                                            <TextField
                                                label="Search"
                                                variant="outlined"
                                                value={search1}
                                                onChange={handleSearchChange1}

                                                margin="normal"
                                            // sx={{ flexGrow: 1}}
                                            />
                                            <Button sx={{ margin: 1 }} variant='contained' color="primary">
                                                View
                                            </Button>

                                        </div>
                                    </Box>
                                </CardContent>
                            </Card>
                        </form>

                    </Grid>

                </Grid>
                <br></br>
                <Paper>

                    <TableContainer style={{ height: "500px", overflowY: "auto" }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>S.No</TableCell>
                                    <TableCell>Employee Code</TableCell>
                                    <TableCell>Employee Name</TableCell>
                                    <TableCell>Department</TableCell>
                                    <TableCell>Designation</TableCell>
                                    <TableCell>Work Category</TableCell>
                                    <TableCell>Total Days</TableCell>
                                    <TableCell>Paid Days</TableCell>
                                    <TableCell>LOP</TableCell>
                                    <TableCell>ACTUALS</TableCell>
                                    <TableCell>EARNINGS</TableCell>
                                    {/* Add more headers as necessary */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* page * rowsPerPage, page * rowsPerPage + rowsPerPage */}
                                {data.slice().map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell onClick={() => handleEditClick(row)}><b style={{ color: "#1700d5", cursor: "pointer", textDecoration: "underline" }}>{row.empno}</b></TableCell>
                                        <TableCell>{row.firstname}</TableCell>
                                        <TableCell>{row.deptname}</TableCell>
                                        <TableCell>{row.designname}</TableCell>
                                        <TableCell>{row.workcatg}</TableCell>
                                        <TableCell><b>{row.salarydays}</b></TableCell>
                                        <TableCell><b>{row.paiddays}</b></TableCell>
                                        <TableCell><b>{row.lop}</b></TableCell>
                                        {/* <TableCell><b>{row.PAA}</b></TableCell>
                    <TableCell><b>{row.FAP}</b></TableCell> */}
                                        <TableCell><b>{row.total}</b></TableCell>
                                        <TableCell><b>{row.paidamount}</b></TableCell>

                                        {/* Add more cells as necessary */}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        component="div"
                        count={data.length == 0 ? 0 : data[0]["count"]}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200, 300]}
                    />
                </Paper>
                <Dialog maxWidth="xl" open={open} onClose={handleClose}>
                    <form style={{ width: "800px" }}>
                        <DialogTitle><b>Manual Attendance</b> </DialogTitle>
                        <DialogContent>
                            <div>
                                <h3>Employee : <b style={{ color: "#1700d5" }}> {empcode}  - {empname} </b> </h3>
                            </div>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                textColor="primary"
                                indicatorColor="primary"
                                // value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                sx={{
                                    '& .MuiTab-root': {
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'blue', // Changes background color on hover
                                            color: '#fff',
                                        },
                                    },
                                    '& .Mui-selected': {
                                        backgroundColor: 'Blue', // Background color for selected tab
                                        color: '#fff', // Keep the font color unchanged
                                    },
                                }}
                            >
                                <Tab label="Salary Details"  {...a11yProps(0)} />
                                <Tab label="Attendance Details"  {...a11yProps(1)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <TableContainer style={{ height: "300px", overflowY: "auto" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Salary Head</TableCell>
                                                <TableCell>EARNINGS</TableCell>
                                                {/* <TableCell>Is Lock</TableCell> */}
                                                {/* Add more headers as necessary */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* page * rowsPerPage, page * rowsPerPage + rowsPerPage */}
                                            {salarydet.slice().map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>{row.type}</TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{row.eamount}</TableCell>
                                                   
                                                    {/* <TableCell></TableCell> */}

                                                    {/* Add more cells as necessary */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <TableContainer style={{ height: "300px", overflowY: "auto" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Date</TableCell>
                                                <TableCell>In Time</TableCell>
                                                <TableCell>Out Time</TableCell>
                                                <TableCell>Status</TableCell>
                                                {/* <TableCell>Is Lock</TableCell> */}
                                                {/* Add more headers as necessary */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {/* page * rowsPerPage, page * rowsPerPage + rowsPerPage */}
                                            {attndet.slice().map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>{row.dates}</TableCell>
                                                    <TableCell>{row.intimes}</TableCell>
                                                    <TableCell>{row.outtimes}</TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            style={{ padding: "8.5px 9px;" }}
                                                            select
                                                            margin="dense"
                                                            label="Status"
                                                            name='status'
                                                            fullWidth
                                                            value={attndet[index].status}
                                                            onChange={(e) => handleTableRowsChange(index, e)}
                                                            // onChange={handleChange}
                                                            error={!!errors?.status} // Highlight the field if there's an error
                                                            helperText={errors?.status ? "Status is required" : ""}
                                                        >
                                                            <MenuItem value="P">P</MenuItem>
                                                            <MenuItem value="AB">AB</MenuItem>
                                                            <MenuItem value="P/AA">P/AA</MenuItem>
                                                            <MenuItem value="FA/P">FA/P</MenuItem>
                                                            <MenuItem value="OD">OD</MenuItem>
                                                            <MenuItem value="CL">CL</MenuItem>
                                                            <MenuItem value="FH">FH</MenuItem>
                                                            <MenuItem value="WO">WO</MenuItem>

                                                        </TextField>
                                                    </TableCell>
                                                    {/* <TableCell></TableCell> */}

                                                    {/* Add more cells as necessary */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </TabPanel>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={attndetupdate} variant='contained' color="primary">
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>

                        </DialogActions>

                    </form>
                </Dialog>

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );
}

export default SalaryProcess;