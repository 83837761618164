import { DatePicker, LocalizationProvider } from "@mui/lab"
import { Box, Card, CardContent, DialogActions, Grid, MenuItem, TextField } from "@mui/material"
import { log } from "mathjs"
import React, { useEffect } from "react"
import { useState } from "react"
import { Button, Container } from "react-bootstrap"
import Year from "react-datepicker/dist/year"
import { Helmet } from "react-helmet-async"
import { toast, ToastContainer } from "react-toastify"
import Footer from "src/components/Footer"
import PageTitle from "src/components/PageTitle"
import PageTitleWrapper from "src/components/PageTitleWrapper"
import apiService from "src/services/api-service"

interface EmpCat {
    id: number;
    name: string;
}

interface LeaveType {
    id: number;
    name: string;
}

interface Data {
    id: number;
    year: number;
    empcatgid: number | "all";
    desp: string;
    attach: string;
    listleave: LeaveDays[];
}

interface LeaveDays {
    ltypeid: number;
    days: number;
    maxleavereq: number;
}

const LeaveOpening: React.FC = () => {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [empcat, setEmpCat] = useState<EmpCat[]>([]);
    const [leavetype, setLeaveType] = useState<LeaveType[]>([]);
    const [formData, setFormData] = useState<Data>({
        id: 0,
        year: 0,
        empcatgid: 0,
        desp: '',
        attach: '',
        listleave: [{
            ltypeid: 0,
            days: 0,
            maxleavereq: 0
        }]
    })

    useEffect(() => {
        apiService.getDropdown("hrms_mas_workcatg").then(response => {
            const activeEmpCat = response.data.filter(item => item.isactive === 1);
            setEmpCat(activeEmpCat);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getDropdown("hrms_mas_leavetype").then(response => {
            const activeLeaveType = response.data.filter(item => item.isactive === 1);
            setLeaveType(activeLeaveType);
            console.log("leavetype>>>>>>>>>>>", activeLeaveType)
        }).catch((err) => {
            console.log("err", err)
        });
    }, [])

    useEffect(() => {
        if (formData.year && formData.empcatgid) {
            fetchLeaveOpeningData();
        }
    }, [formData.year, formData.empcatgid]);


    const fetchLeaveOpeningData = () => {
        apiService.getLeaveOpening(formData.year, formData.empcatgid)
          .then(response => {
            const fetchedData = response.data;
      console.log("fetched leave data", fetchedData )
            if (fetchedData.length > 0) {
              // Extract desp and attach from the first row
              const { id, desp, attach } = fetchedData[0];
      
              // Process leave details (rest of the rows)
              const leaveDetails = fetchedData.map(row => ({
                ltypeid: row.ltypeid, // Leave type ID
                days: row.days, // Leave days
                maxleavereq: row.maxleavereq
              }));
      
              // Update formData
              const updatedFormData = {
                ...formData, // Preserve existing values
                id: id || "",
                desp: desp || "", // Set remarks from the first row
                attach: attach || "", // Set attachment from the first row
                listleave: leaveDetails, // Set leave details
              };
      
              setFormData(updatedFormData);
            }
          })
          .catch(err => {
            console.log("Error fetching leave opening data:", err);
          });
      };
      


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Save button clicked"); // Log to verify button click

        try {
            // Check if "All" is selected for empcatgid
            if (formData.empcatgid === "all") {
                // Create data for each empcatgid
                const dataToSubmitList = empcat.map((category) => ({
                    ...formData,
                    empcatgid: category.id, // Replace "All" with specific category id
                }));

                // Use Promise.all to send requests for all categories
                await Promise.all(
                    dataToSubmitList.map(async (dataToSubmit) => {
                        console.log("dataToSubmit", dataToSubmit);
                        await apiService.postleaveopendata(dataToSubmit);
                    })
                );
            } else {
                // Submit data for a single selected empcatgid
                const dataToSubmit = formData;
                console.log("dataToSubmit", dataToSubmit);
                await apiService.postleaveopendata(dataToSubmit);
            }

            toast.success("Leave opening registered successfully");
            window.scrollTo(0, 0);
        } catch (error: any) {
            // Log different types of errors
            if (error.response) {
                console.error("Error response:", error.response);
                console.error("Error data:", error.response.data);
                console.error("Error status:", error.response.status);
                console.error("Error headers:", error.response.headers);
            } else if (error.request) {
                console.error("Error request:", error.request);
            } else {
                console.error("Error message:", error.message);
            }
            console.error("Error config:", error.config);
        }
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    }

    const handleLeaveChange = (event: any, index: number) => {
        const { name, value } = event.target;

        setFormData((prevFormData) => {
            // Create a shallow copy of listleave
            const updatedLeaveDays = [...(prevFormData.listleave || [])];

            // Ensure the item at the current index exists with required properties
            updatedLeaveDays[index] = {
                ltypeid: leavetype[index]?.id, // Ensure ltypeid is set
                days: name === "days" ? parseFloat(value) || 0 : updatedLeaveDays[index]?.days || 0,
                maxleavereq: name === "maxleavereq" ? parseFloat(value) || 0 : updatedLeaveDays[index]?.maxleavereq || 0
            };

            return {
                ...prevFormData,
                listleave: updatedLeaveDays
            };
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;

        if (name === "attach" && event.target.files) {
            const file = event.target.files[0];
            const formdata = new FormData();
            formdata.append('file', file);

            apiService.uploadFiles(formdata)
                .then(response => {
                    const url = response.data; // Assuming response.data contains the file URL

                    // Update the formData with the file URL
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        attach: url,
                    }));
                })
                .catch(err => {
                    console.log("err", err);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Leave Opening Register - HRMS</title>
            </Helmet>

            <PageTitleWrapper>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <PageTitle
                        heading="Leave Opening Register"
                        subHeading=""
                    />
                </Box>
            </PageTitleWrapper>
            <Card>
                <CardContent>
                    <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', marginTop: '20px' }}>
                        {/* Left Section */}
                        <Box style={{ flex: 1, minWidth: '300px' }}>
                            <Box style={{ marginTop: '20px' }}>
                                <DatePicker
                                    label="Select Year"
                                    views={['year']}
                                    value={formData.year ? new Date(formData.year, 0) : null}
                                    onChange={(newValue) => {
                                        const year = newValue.getFullYear();
                                        setFormData((prevData) => ({ ...prevData, year }));
                                    }}
                                    minDate={new Date('2025-01-01')}
                                    maxDate={new Date('2027-12-31')}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                />

                            </Box>
                            <Box style={{ marginTop: '20px' }}>
                                <TextField
                                    select
                                    label="Employee Category"
                                    name="empcatgid"
                                    value={formData?.empcatgid}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        setFormData((prevData) => ({ ...prevData, [name]: value }));
                                    }}
                                    fullWidth
                                >
                                    {empcat.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="all">All</MenuItem>
                                </TextField>

                            </Box>
                            <Box style={{ marginTop: '20px' }}>
                                <TextField
                                    label="Remarks"
                                    name="desp"
                                    value={formData?.desp}
                                    onChange={handleChange}
                                    multiline
                                    fullWidth
                                />
                            </Box>
                            <Box style={{ marginTop: '20px' }}>
                                <input
                                    type="file"
                                    name="attach"
                                    id="attach"
                                    onChange={handleFileChange}
                                    className="fileInput"
                                />
                                {formData.attach && (
                                    <a href={formData.attach} target="_blank" rel="noopener noreferrer">
                                        View File
                                    </a>
                                )}
                            </Box>
                        </Box>
                        {/* Right Section */}
                        <Box style={{ flex: 1.5, minWidth: '300px', paddingLeft: '20px' }}>
                            <table style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th style={{ width: '180px' }}>Leave Days</th>
                                        <th style={{ width: '180px' }}>Max days per request</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leavetype.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td>
                                                <TextField
                                                    id={`days-${index}`}
                                                    name="days"
                                                    value={formData?.listleave?.[index]?.days || ""}
                                                    onChange={(e) => handleLeaveChange(e, index)}
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                    onInput={(e) => {
                                                        const input = e.target as HTMLInputElement;
                                                        input.value = input.value.replace(/[^0-9.]/g, '');
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={`maxleavereq-${index}`}
                                                    name="maxleavereq"
                                                    value={formData?.listleave?.[index]?.maxleavereq || ""}
                                                    onChange={(e) => handleLeaveChange(e, index)}
                                                    fullWidth
                                                    sx={{
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            border: 'none',
                                                        },
                                                    }}
                                                    onInput={(e) => {
                                                        const input = e.target as HTMLInputElement;
                                                        input.value = input.value.replace(/[^0-9.]/g, '');
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Box>
                    </Box>
                    <Grid container item xs={12} justifyContent="flex-end" style={{ marginTop: 16 }}>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            style={{
                                backgroundColor: '#5569ff', // Darker blue
                                color: '#fff',
                                borderRadius: '12px', // Curved borders
                                padding: '15px 28px', // Bigger size
                                fontSize: '14px', // Slightly larger text
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />
        </>
    )
}

export default LeaveOpening;