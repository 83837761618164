import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import SearchIcon from '@mui/icons-material/Search';


interface Data {
    id: number;
    type: string;
    name: string;
    isactive: number;
    reqtypeid: number;
    SerialNumber: number;
}

interface Requirement {
    id: number;
    name: string;
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];
function TestAccessories() {

    // For Grid Table
    const [data, setData] = useState<Data[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [requirement, setRequirement] = useState<Requirement[]>([]);
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState('');
    //Form data 
    const [formData, setFormData] = useState<any>({
        id: '',
        type: '',
        name: '',
        isactive: '',
        reqtypeid: ''
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        console.log(rowsPerPage.toString(), "size");
        console.log((page + 1).toString(), "page");

        apiService.getTestAccessories(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err)
        })

        apiService.getDropdown("crm_mas_reqtype").then(response => {
            const reqtype = response.data; // Display all roles
            setRequirement(reqtype);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [rowsPerPage, page, formData, search]);

    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        console.log(formData, "formDatatest");
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
            });
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    // Trigger search action manually
    const handleSearch = () => {
        apiService.getTestAccessories(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err);
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            type: '',
            name: '',
            isactive: '',
            reqtypeid: ''
        });
        setSelectedItem(null);
        setErrors(null);
    };

    const isInsert = !formData?.id;
    const validateForm = (isInsert: boolean) => {
        const requiredFields = [
            'type', 'name', 'reqtypeid', 'isactive'
        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};

        requiredFields.forEach((field) => {
            // Only validate all required fields during insert, or validate only if the field is being updated
            if (isInsert || formData[field] !== undefined) {
                console.log(field, formData[field], "validate");
                if (field === "isactive") {
                    if (formData[field] === '') {
                        newErrors[field] = `${field} is isactive`;
                        valid = false;
                    }
                }
                else {
                    if (!formData[field]) {
                        newErrors[field] = `${field} is required`;
                        valid = false;
                    }
                }

            }
        });
        setErrors(newErrors);
        return valid;
    }


    // Insert and Update 
    let submit = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior
        if (!validateForm(isInsert)) {
            console.log('Form is invalid. Please fill all required fields.');
            return;
        }


        // Insert
        if (selectedItem == null) {

            let data = {
                "name": formData?.name,
                "type": formData?.type,
                "isactive": formData?.isactive,
                "reqtypeid": formData?.reqtypeid,
                "cby": "1"
            };

            apiService.insertTestAccessories(data
            ).then(response => {
                console.log("response", response.data);
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message)
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        type: '',
                        name: '',
                        isactive: '',
                        reqtypeid: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
        // Update
        else {
            let data = {
                "id": formData?.id,
                "type": formData?.type,
                "name": formData?.name,
                "reqtypeid": formData?.reqtypeid,
                "isactive": formData?.isactive,
                "cby": "1"
            };
            apiService.updateTestAccessories(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        type: '',
                        name: '',
                        isactive: '',
                        reqtypeid: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    // Handler for Edit Button click
    const handleEditClick = (item: Data) => {

        setFormData({
            id: item?.id.toString(),
            type: item?.type,
            name: item?.name,
            reqtypeid: item?.reqtypeid,
            isactive: item?.isactive,
        });
        setSelectedItem(item);
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>Test & Accessories - Admin</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Test & Accessories"
                    subHeading=""
                />
            </PageTitleWrapper>
            <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center', // Align vertically in case of height differences
                        gap: 2, // Add space between TextField and Button
                        marginTop: '-15px', // Retain your margin adjustments
                        marginBottom: '5px',
                        marginRight: '30px'
                    }}
                >
                    <TextField
                        id="search"
                        name="search"
                        value={search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
                        margin="dense"
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: 'auto' }} // Adjust width to avoid unnecessary stretching
                    />
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Add New
                    </Button>
                </Box>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Requirement Type</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.map((row, index) => {
                                            const reqItem = requirement.find(option => option.id === row.reqtypeid);
                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>{row.SerialNumber}</TableCell>
                                                    <TableCell>{row.type}</TableCell>
                                                    <TableCell>{row.name}</TableCell>
                                                    <TableCell>{reqItem ? reqItem.name : ' '}</TableCell>
                                                    <TableCell style={{ color: row.isactive == 1 ? "green" : 'red' }}>
                                                        {row.isactive == 1 ? "Active" : "In-Active"}
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
    component="div"
    count={data.length === 0 ? 0 : data[0]["count"]} // Total rows count
    page={page}
    onPageChange={handleChangePage}
    rowsPerPage={rowsPerPage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    rowsPerPageOptions={[5, 10, 25]}
/>

                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <form>
                        <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                required
                                select
                                label='Type'
                                id="type"
                                name="type"
                                fullWidth
                                value={formData?.type}
                                onChange={handleChange}
                                error={!!errors?.type} // Show error if empty
                                helperText={errors?.type ? "Type is required" : ""}
                                sx={{ marginTop: '12px' }}
                            >
                                <MenuItem value="test">Test</MenuItem>
                                <MenuItem value="Accessories">Accessories</MenuItem>
                            </TextField>
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name='name'
                                fullWidth
                                value={formData?.name}
                                onChange={handleChange}
                                error={!!errors?.name} // Highlight the field if there's an error
                                helperText={errors?.name ? "Name is required" : ""} // Display error message if present
                                sx={{ marginTop: '15px' }}
                            ></TextField>
                            <TextField
                                required
                                select
                                label="Requirement Type"
                                name='reqtypeid'
                                value={formData?.reqtypeid}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={!!errors?.reqtypeid} // Highlight the field if there's an error
                                helperText={errors?.reqtypeid ? "Requirement Type is required" : ""} // Display error message if present
                                sx={{ marginTop: '12px' }}
                            >
                                {requirement.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                margin="dense"
                                label="Status"
                                name='isactive'
                                fullWidth
                                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                                onChange={handleChange}
                                sx={{ marginTop: '12px' }}
                                error={!!errors?.isactive} // Show error if empty
                                helperText={errors?.isactive ? "Status is required" : ""}
                            >
                                {status.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} variant='contained' color="primary">
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>

                        </DialogActions>

                    </form>
                </Dialog>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );


}
export default TestAccessories;
