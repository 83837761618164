import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import SearchIcon from '@mui/icons-material/Search';


interface Data {
  id: number;
  casemodel: string;
  innerdimns: string;
  outerdimns: string;
  boxwt: string;
  casterwheel: number;
  acceswt: number;
  epe: number;
  casenetwt1: number;
  xlpe: number;
  casenetwt2: number;
  wropecode: number;
  latchtype: string;
  hingetype: string;
  stiffwt: number;
  indlen: number;
  indwidth: number;
  indbottom: number;
  indtop: number;
  isactive: number;
  SerialNumber: number;
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];
function DesignModel() {

  // For Grid Table
  const [data, setData] = useState<Data[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [search, setSearch] = useState('');
  // For Grid Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Form data 
  const [formData, setFormData] = useState<any>({
    id: '',
    casemodel: '',
    innerdimns: '',
    outerdimns: '',
    boxwt: '',
    //casterwheel: 0,
    //acceswt: 0,
    //epe: 0,
    //casenetwt1: 0,
    //xlpe: 0,
    //casenetwt2: 0,
    //wropecode: 0,
    latchtype: '',
    hingetype: '',
    //stiffwt: 0,
    // indlen: 0,
    // indwidth: 0,
    // indbottom: 0,
    // indtop: 0,
    isactive: ''
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {

    console.log(rowsPerPage.toString(), "size");
    console.log((page + 1).toString(), "page");

    apiService.getDesignModel(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    })
  }, [rowsPerPage, page, formData, search]);

  //For Pagination 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Textbox and Select box onchage
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
      });
    }
  };

  const handleFieldChange = (e, field) => {
    const value = e.target.value;
    setFormData(prevState => {
      const newData = { ...prevState, [field]: value };
      return {
        ...newData,
        innerdimns: `${newData.indlen || ''}*${newData.indwidth || ''}*${newData.indbottom || ''}+${newData.indtop || ''}`
      };
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearch = () => {
    apiService.getDesignModel(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    })
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      casemodel: '',
      innerdimns: '',
      outerdimns: '',
      boxwt: '',
      casterwheel: '',
      acceswt: '',
      epe: '',
      casenetwt1: '',
      xlpe: '',
      casenetwt2: '',
      wropecode: '',
      latchtype: '',
      hingetype: '',
      stiffwt: '',
      indlen: '',
      indwidth: '',
      indbottom: '',
      indtop: '',
      isactive: ''
    });
    setSelectedItem(null);
    setErrors(null);
  };

  const isInsert = !formData?.id;
  const validateForm = (isInsert: boolean) => {
    const requiredFields = [
      'casemodel', 'innerdimns', 'outerdimns', 'isactive'
    ];
    let valid = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      // Only validate all required fields during insert, or validate only if the field is being updated
      if (isInsert || formData[field] !== undefined) {
        console.log(field, formData[field], "validate");
        if (field === "isactive") {
          if (formData[field] === '') {
            newErrors[field] = `${field} is isactive`;
            valid = false;
          }
        }
        else {
          if (!formData[field]) {
            newErrors[field] = `${field} is required`;
            valid = false;
          }
        }

      }
    });
    setErrors(newErrors);
    return valid;
  }

  // Insert and Update 
  let submit = (event: any) => {
    event.preventDefault(); // Prevents default form submission behavior
    if (!validateForm(isInsert)) {
      console.log('Form is invalid. Please fill all required fields.');
      return;
    }
    // Insert
    if (selectedItem == null) {

      let data = {
        "casemodel": formData?.casemodel,
        "innerdimns": formData?.innerdimns,
        "outerdimns": formData?.outerdimns,
        "boxwt": formData?.boxwt,
        "casterwheel": formData?.casterwheel,
        "acceswt": formData?.acceswt,
        "epe": formData?.epe,
        "casenetwt1": formData?.casenetwt1,
        "xlpe": formData?.xlpe,
        "casenetwt2": formData?.casenetwt2,
        "wropecode": formData?.wropecode,
        "latchtype": formData?.latchtype,
        "hingetype": formData?.hingetype,
        "stiffwt": formData?.stiffwt,
        "indlen": formData?.indlen,
        "indwidth": formData?.indwidth,
        "indbottom": formData?.indbottom,
        "indtop": formData?.indtop,
        "isactive": formData?.isactive,
        "cby": "1"
      };

      apiService.insertDesignModel(data
      ).then(response => {
        console.log("response", response.data);
        if (response?.data.key == "400") {
          errorToast(response?.data?.message)
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            casemodel: '',
            innerdimns: '',
            outerdimns: '',
            boxwt: '',
            casterwheel: '',
            acceswt: '',
            epe: '',
            casenetwt1: '',
            xlpe: '',
            casenetwt2: '',
            wropecode: '',
            latchtype: '',
            hingetype: '',
            stiffwt: '',
            indlen: '',
            indwidth: '',
            indbottom: '',
            indtop: '',
            isactive: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }
    // Update
    else {
      let data = {
        "id": formData?.id,
        "casemodel": formData?.casemodel,
        "innerdimns": formData?.innerdimns,
        "outerdimns": formData?.outerdimns,
        "boxwt": formData?.boxwt,
        "casterwheel": formData?.casterwheel,
        "acceswt": formData?.acceswt,
        "epe": formData?.epe,
        "casenetwt1": formData?.casenetwt1,
        "xlpe": formData?.xlpe,
        "casenetwt2": formData?.casenetwt2,
        "wropecode": formData?.wropecode,
        "latchtype": formData?.latchtype,
        "hingetype": formData?.hingetype,
        "stiffwt": formData?.stiffwt,
        "indlen": formData?.indlen,
        "indwidth": formData?.indwidth,
        "indbottom": formData?.indbottom,
        "indtop": formData?.indtop,
        "isactive": formData?.isactive,
        "cby": "1"
      };
      apiService.updateDesignModel(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            casemodel: '',
            innerdimns: '',
            outerdimns: '',
            boxwt: '',
            casterwheel: '',
            acceswt: '',
            epe: '',
            casenetwt1: '',
            xlpe: '',
            casenetwt2: '',
            wropecode: '',
            latchtype: '',
            hingetype: '',
            stiffwt: '',
            indlen: '',
            indwidth: '',
            indbottom: '',
            indtop: '',
            isactive: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }
  };
  // Handler for Edit Button click
  const handleEditClick = (item: Data) => {

    setFormData({
      id: item?.id.toString(),
      casemodel: item?.casemodel,
      innerdimns: item?.innerdimns,
      outerdimns: item?.outerdimns,
      boxwt: item?.boxwt,
      casterwheel: item?.casterwheel,
      acceswt: item?.acceswt,
      epe: item?.epe,
      casenetwt1: item?.casenetwt1,
      xlpe: item?.xlpe,
      casenetwt2: item?.casenetwt2,
      wropecode: item?.wropecode,
      latchtype: item?.latchtype,
      hingetype: item?.hingetype,
      stiffwt: item?.stiffwt,
      indlen: item?.indlen,
      indwidth: item?.indwidth,
      indbottom: item?.indbottom,
      indtop: item?.indtop,
      isactive: item?.isactive
    });
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Design Model - Design</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="Design Model"
          subHeading=""
        />
      </PageTitleWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center', // Align vertically in case of height differences
          gap: 2, // Add space between TextField and Button
          marginTop: '-15px', // Retain your margin adjustments
          marginBottom: '5px',
          marginRight: '30px'
        }}
      >
        <TextField
          id="search"
          name="search"
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          margin="dense"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
          sx={{ width: 'auto' }} // Adjust width to avoid unnecessary stretching
        />
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Add New
        </Button>
      </Box>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Case Model</TableCell>
                      <TableCell>Case Inner Dimns</TableCell>
                      <TableCell>Case Outer Dimns</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>{row.SerialNumber}</TableCell>
                        <TableCell>{row.casemodel}</TableCell>
                        <TableCell>{row.innerdimns}</TableCell>
                        <TableCell>{row.outerdimns}</TableCell>
                        <TableCell style={{ color: row.isactive == 1 ? "green" : 'red' }}>
                          {row.isactive == 1 ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={data.length == 0 ? 0 : data[0]["count"]}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="dense"
                id="casemodel"
                label="Case Model"
                name='casemodel'
                fullWidth
                value={formData?.casemodel}
                onChange={handleChange}
                error={!!errors?.casemodel} // Highlight the field if there's an error
                helperText={errors?.casemodel ? "Case Model is required" : ""} // Display error message if present
              />
              <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                <label style={{ display: 'block', marginBottom: '8px' }}>Case Inner Dimns. in mm</label>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <TextField
                    required
                    label="Length"
                    value={formData.indlen || ''}
                    onChange={(e) => handleFieldChange(e, 'indlen')}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                    }}
                  />
                  <TextField
                    required
                    label="Width"
                    value={formData.indwidth || ''}
                    onChange={(e) => handleFieldChange(e, 'indwidth')}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                    }}
                  />
                  <TextField
                    required
                    label="Bottom"
                    value={formData.indbottom || ''}
                    onChange={(e) => handleFieldChange(e, 'indbottom')}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                    }}
                  />
                  <TextField
                    required
                    label="Top"
                    value={formData.indtop || ''}
                    onChange={(e) => handleFieldChange(e, 'indtop')}
                    onInput={(e) => {
                      const input = e.target as HTMLInputElement;
                      input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                    }}
                  />
                </div>
              </div>
              <TextField
                required
                margin="dense"
                id="outerdimns"
                label="Case Outer Dimns. in mm"
                name='outerdimns'
                fullWidth
                value={formData?.outerdimns}
                onChange={handleChange}
                error={!!errors?.outerdimns} // Highlight the field if there's an error
                helperText={errors?.outerdimns ? "Case Outer Dimns. is required" : ""} // Display error message if present
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="boxwt"
                label="Box Wt."
                name='boxwt'
                fullWidth
                value={formData?.boxwt}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                id="casterwheel"
                label="Caster Wheel"
                name='casterwheel'
                fullWidth
                value={formData?.casterwheel}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="acceswt"
                label="Access Wt."
                name='acceswt'
                fullWidth
                value={formData?.acceswt}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="epe"
                label="EPE"
                name='epe'
                fullWidth
                value={formData?.epe}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="casenetwt1"
                label="Case Netwt.1"
                name='casenetwt1'
                fullWidth
                value={formData?.casenetwt1}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="xlpe"
                label="XLPE"
                name='xlpe'
                fullWidth
                value={formData?.xlpe}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="casenetwt2"
                label="Case Netwt.2"
                name='casenetwt2'
                fullWidth
                value={formData?.casenetwt2}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="wropecode"
                label="W rope Code"
                name='wropecode'
                fullWidth
                value={formData?.wropecode}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="latchtype"
                label="Latch Type"
                name='latchtype'
                fullWidth
                value={formData?.latchtype}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="hingetype"
                label="Hinge Type"
                name='hingetype'
                fullWidth
                value={formData?.hingetype}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                margin="dense"
                id="stiffwt"
                label="Stiff Wt."
                name='stiffwt'
                fullWidth
                value={formData?.stiffwt}
                onChange={handleChange}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                }}
              />
              <TextField
                select
                margin="dense"
                label="Status"
                name='isactive'
                fullWidth
                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                onChange={handleChange}
                error={!!errors?.isactive} // Show error if empty
                helperText={errors?.isactive ? "Status is required" : ""}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary">
                {selectedItem ? 'Update' : 'Save'}
              </Button>

            </DialogActions>

          </form>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />

    </>
  );


}
export default DesignModel;
