import { Box, Card, Typography, Container, Divider, Grid, Button } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { vpass } from 'src/api-login';
import './vmscss.css';
import { useReactToPrint } from 'react-to-print';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';

const MainContent = styled(Box)(`
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  @media print {
    justify-content: flex-start;
    min-height: auto;
    margin: 0;
  }
`);

const VisitorImage = styled('img')({
  width: '120px',
  height: '120px',
  borderRadius: '50%'
});

interface Visitor {
  name: string;
  mobile: string;
  image: string;
  remarks: string;
  empname: string;
  fdate: Date;
  checkin: string;
}

function VPass() {
  const { id } = useParams<{ id: string }>();
  const [visitorData, setVisitorData] = useState<Visitor[] | null>(null);
  const [showImage, setShowImage] = useState(false);

  const currentURL = window.location.href;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await vpass(id);
        console.log(data, 'visitordata');
        setVisitorData(data);
      } catch (error) {
        console.error('Error fetching visitor data:', error);
      }
    };
    fetchData();
  }, [id]);

  const formatDate = (date: Date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB').format(new Date(date));
  };

  const formatCheckinTime = (date: string) => {
    const time = new Date(date);
    const hours = time.getHours();
    const minutes = time.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const printRef = useRef(null);
  const contentToPrint: any = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => setShowImage(true),
    onBeforePrint: () => setShowImage(true),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
  });

  return (
    <>
      <Helmet>
        <title>Visitor Pass</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="sm">
          <Card sx={{ textAlign: 'center', mt: 1, p: 3 }}>
            {visitorData ? (
              <div className="impression-paysage" ref={contentToPrint}>
                <Divider sx={{ my: 1 }} />
                <Typography sx={{ my: 1, fontSize: '20px', fontWeight: 'bold' }}>
                  <img
                    src={require('src/assets/logosaarc.png')}
                    alt="Company Logo"
                    style={{ width: 60, height: 60, marginLeft: 10, marginRight: 10, float: 'left' }} />
                     VISITOR E-PASS
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Box textAlign="center" sx={{ my: 1 }}>
                  <VisitorImage src={visitorData[0].image || ''} alt="Visitor" />
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Name:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {visitorData[0].name || ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Mobile No:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {visitorData[0].mobile || ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Purpose:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {visitorData[0].remarks || ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Person to Meet:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {visitorData[0].empname || ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Check-in Time:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {formatCheckinTime(visitorData[0].checkin)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Date of Visit:
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body1" sx={{ textAlign: 'left' }}>
                        {formatDate(visitorData[0].fdate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider sx={{ my: 2 }}>QR CODE</Divider>
                <Box textAlign="center" sx={{ my: 1 }}>
                  <QRCode value={currentURL} size={100} />
                </Box>
              </div>
            ) : (
              <Typography variant="body1">Loading visitor data...</Typography>
            )}
          </Card>
        </Container>
        <Box
          bottom="20px"
          left="50%"
          sx={{ marginTop: '20px', justifyContent: 'center', marginBottom: '10px' }}
          className="no-print"
        >
          <Button
            onClick={() => {
              handlePrint(null, () => contentToPrint.current);
            }}
            variant="contained"
            color="primary"
            startIcon={<FontAwesomeIcon icon={faPrint} />}
          >
            Print
          </Button>
        </Box>
      </MainContent>
    </>
  );
}

export default VPass;
