import axios from "axios";
import api from "../http-common";
import http from './../api-jwt'; 
import User from "src/admin/user";

export default {
  login(data: any) {
    return http.post("/Login", data);
  },

  //city
  getCity(size: any, page: any, search: any) {
    return http.get(`/city?size=${size}&page=${page}&search=${search}`);
  },
  insertCity(data1: any) {
    return http.post("/city/insert", data1);
  },
  updateCity(id: any, data1: any) {
    return http.post(`/city/update?id=${id}`, data1);
  },

  //country
  getCountry(size: any, page: any, search: any) {
    return http.get(`/country?size=${size}&page=${page}&search=${search}`);
  },
  insertCountry(data1: any) {
    return http.post("/country/insert", data1);
  },
  updateCountry(id: any, data1: any) {
    return http.post(`/country/update?id=${id}`, data1);
  },

  //state
  getState(size: any, page: any, search: any) {
    return http.get(`/state?size=${size}&page=${page}&search=${search}`);
  },
  insertState(data1: any) {
    return http.post("/state/insert", data1);
  },
  updateState(id: any, data1: any) {
    return http.post(`/state/update?id=${id}`, data1);
  },
  getState_id(id: any) {
    return http.get(`/state/${id}`);
  },

  //district
  getDistrict(size: any, page: any, search: any) {
    return http.get(`/district?size=${size}&page=${page}&search=${search}`);
  },
  insertDistrict(data1: any) {
    return http.post("/district/insert", data1);
  },
  updateDistrict(id: any, data1: any) {
    return http.post(`/district/update?id=${id}`, data1);
  },
  getDistrict_id(id: any) {
    return http.get(`/district/${id}`);
  },

  //Designation
  getDesignation(size: any, page: any, search: any) {
    return http.get(`/designation?size=${size}&page=${page}&search=${search}`);
  },
  insertDesignation(data1: any) {
    return http.post("/designation/insert", data1);
  },
  updateDesignation(id: any, data1: any) {
    return http.post(`/designation/update?id=${id}`, data1);
  },

  //user
  getUser(size: any, page: any, search: any) {
    return http.get(`/user?size=${size}&page=${page}&search=${search}`);
  },
  insertUser(data1: any) {
    return http.post("/user/insert", data1);
  },
  updateUser(id: any, data1: any) {
    return http.post(`/user/update?id=${id}`, data1);
  },
  getUser_id(id: any) {
    return http.get(`/user/${id}`);
  },

  // Dropdown
  getDropdown(tablename: any) {
    return http.get(`/dropdown?tablename=${tablename}`);
  },


  //priority
  getPriority(size: any, page: any, search: any) {
    return http.get(`/priority?size=${size}&page=${page}&search=${search}`);
  },
  insertPriority(data1: any) {
    return http.post("/priority/insert", data1);
  },
  updatePriority(id: any, data1: any) {
    return http.post(`/priority/update?id=${id}`, data1);

  },
  //Status
  getStatus(size: any, page: any, search: any) {
    return http.get(`/status?size=${size}&page=${page}&search=${search}`);
  },
  insertStatus(data1: any) {
    return http.post("/status/insert", data1);
  },
  updateStatus(id: any, data1: any) {
    return http.post(`/status/update?id=${id}`, data1);

  },
  getCompany(size: any, page: any, search: any) {
    return http.get(`/company?size=${size}&page=${page}&search=${search}`);
  },
  insertCompany(data1: object) {
    return http.post("/company/insert", data1);
  },
  updateCompany(id: any, data1: any) {
    return http.post(`/company/update?id=${id}`, data1);

  },
  getPlant(size: any, page: any, search: any) {
    return http.get(`/plant?size=${size}&page=${page}&search=${search}`);
  },
  insertPlant(data1: object) {
    return http.post("/plant/insert", data1);
  },
  updatePlant(id: any, data1: any) {
    return http.post(`/plant/update?id=${id}`, data1);

  },
  getTeam(size: any, page: any, search: any) {
    return http.get(`/team?size=${size}&page=${page}&search=${search}`);
  },
  getEmployee1(size: any, page: any, search: any) {
    return http.get(`/team/GetEmployee?size=${size}&page=${page}&search=${search}`);
  },
  insertTeam(data1: object) {
    return http.post("/team/insert", data1);
  },
  updateTeam(id: any, data1: any) {
    return http.post(`/team/update?id=${id}`, data1);

  },

  //followtype
  getFollowtype(size: any, page: any, search: any) {
    return http.get(`/followtype?size=${size}&page=${page}&search=${search}`);
  },
  insertFollowtype(data1: any) {
    return http.post("/followtype/insert", data1);
  },
  updateFollowtype(id: any, data1: any) {
    return http.post(`/followtype/update?id=${id}`, data1);
  },

  postExcustomer(size: number, page: number, search: any, typeid: "") {
    return http.post(`/CRMindexcustomer`, {
      size: size.toString(),
      page: page.toString(),
      search,
      typeid
    })
  },
  getlist(size, click, page) {
    return http.get('/crm/getlist', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },
  getstatuslist(size, click, page, status) {
    return http.get('/crm/getstatuslist', {
      params: {
        size: size,
        click: click,
        page: page,
        status: status
      }
    });
  },
  getcount(data1: any) {
    return http.get(`/crm/getcount?search=${data1}`);
  },

  // getcount({
  //   search = '',
  //   typesearch = '',
  //   prioritysearch = '',
  //   modesearch = '',
  //   empsearch = '',
  //   statussearch = '',
  //   finsearch = '',
  //   enqnosearch = ''
  // }) {
  //   return http.get(
  //     `/crm/getcount?search=${search}&typesearch=${typesearch}&prioritysearch=${prioritysearch}&modesearch=${modesearch}&empsearch=${empsearch}&statussearch=${statussearch}&finsearch=${finsearch}&enqnosearch=${enqnosearch}`
  //   );
  // }

  // ,
  getId(id: number) {
    return http.get('/crm/getrow', {
      params: { id: id },
    });
  },


  //Role
  getRole(size: any, page: any, search: any) {
    return http.get(`/role?size=${size}&page=${page}&search=${search}`);
  },
  insertRole(data1: any) {
    return http.post("/role/insert", data1);
  },
  updateRole(id: any, data1: any) {
    return http.post(`/role/update?id=${id}`, data1);
  },

  //Mode
  getMode(size: any, page: any, search: any) {
    return http.get(`/mode?size=${size}&page=${page}&search=${search}`);
  },
  insertMode(data1: any) {
    return http.post("/mode/insert", data1);
  },
  updateMode(id: any, data1: any) {
    return http.post(`/mode/update?id=${id}`, data1);
  },

  //Type
  getType(size: any, page: any, search: any) {
    return http.get(`/type?size=${size}&page=${page}&search=${search}`);
  },
  insertType(data1: any) {
    return http.post("/type/insert", data1);
  },
  updateType(id: any, data1: any) {
    return http.post(`/type/update?id=${id}`, data1);
  },

  //Department
  getDepartment(size: any, page: any, search: any) {
    return http.get(`/department?size=${size}&page=${page}&search=${search}`);
  },
  getEmployee(size: any, page: any, search: any) {
    return http.get(`/department/GetEmployee?size=${size}&page=${page}&search=${search}`);
  },
  insertDepartment(data1: any) {
    return http.post("/department/insert", data1);
  },
  updateDepartment(id: any, data1: any) {
    return http.post(`/department/update?id=${id}`, data1);
  },


  // // Enquiry
  //   postExcustomer(size: number, page: number, search: "", typeid: "" ) 
  //   {
  //     return http.post(`/CRMindexcustomer`, {
  //       size: size.toString(),
  //       page: page.toString(),
  //       search,
  //       typeid
  //     })
  //   },
  //   postFormdata( data1: any) 
  //   {
  //     return http.post(`/crm`, { data1 })
  //   },
  //QuoteTerms
  getQuoteTerms(size: any, page: any, search: any) {
    return http.get(`/quoteterms?size=${size}&page=${page}&search=${search}`);
  },
  insertQuoteTerms(data1: any) {
    return http.post("/quoteterms/insert", data1);
  },
  updateQuoteTerms(id: any, data1: any) {
    return http.post(`/quoteterms/update?id=${id}`, data1);
  },
  //Employee
  getEmp(size: any, page: any, search: any) {
    return http.get(`/employee?size=${size}&page=${page}&search=${search}`);
  },
  insertEmp(data1: any) {
    return http.post("/employee/insert", data1);
  },
  updateEmp(id: any, data1: any) {
    return http.post(`/employee/update?id=${id}`, data1);
  },

  //Division
  getDivision(size: any, page: any, search: any) {
    return http.get(`division/?size=${size}&page=${page}&search=${search}`);
  },
  insertDivision(data1: any) {
    return http.post("/division/insert", data1);
  },
  updateDivision(id: any, data1: any) {
    return http.post(`/division/update?id=${id}`, data1);
  },

  getLeadReference(size: any, page: any, search: any) {
    return http.get(`/leadreference?size=${size}&page=${page}&search=${search}`);
  },
  insertLeadReference(data1: any) {
    return http.post("/leadreference/insert", data1);
  },
  updateLeadReference(id: any, data1: any) {
    return http.post(`/leadreference/update?id=${id}`, data1);
  },

  // Enquiry
  postFormdata(data1: any) {
    return http.post(`/crm`, data1)
  },
  // Upload Files
  uploadFiles(data1: any) {
    console.log(data1, "formdata");

    return http.post("/FileUpload/upload", data1, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
  },

  //Customer
  getCustomer(size: any, page: any, search: any) {
    return http.get(`/customer/?size=${size}&page=${page}&search=${search}`);
  },
  getCustomer_id(id: number) {
    return http.get(`/customer/${id}`);
  },
  insertCustomer(data1: any) {
    return http.post("/customer/insert", data1);
  },
  updateCustomer(id: any, data1: any) {
    return http.post(`/customer/update?id=${id}`, data1);
  },

  // Target
  getTarget(size: any, page: any, search: any) {
    return http.get(`/target?size=${size}&page=${page}&search=${search}`);
  },
  insertTarget(data1: any) {
    return http.post("/target/insert", data1);
  },
  updateTarget(id: any, data1: any) {
    return http.post(`/target/update?id=${id}`, data1);
  },

  //Requirement Type
  getRequirementType(size: any, page: any, search: any) {
    return http.get(`/requirementtype?size=${size}&page=${page}&search=${search}`);
  },
  insertRequirementType(data1: any) {
    return http.post("/requirementtype/insert", data1);
  },
  updateRequirementType(id: any, data1: any) {
    return http.post(`/requirementtype/update?id=${id}`, data1);
  },

  //Test and Accessories
  // getTestAccessories(size: any, page: any, search: string) {
  //   return http.get(`/testaccessories?size=${size}&page=${page}&search=${search}`);
  // },
  getTestAccessories(size: any, page: any, search: string) {
    return http.get('/testaccessories', {
      params: {
        size: size,
        //click: click,
        page: page,
        search: search
      }
    });
  },
  insertTestAccessories(data1: any) {
    return http.post("/testaccessories/insert", data1);
  },
  updateTestAccessories(id: any, data1: any) {
    return http.post(`/testaccessories/update?id=${id}`, data1);
  },

  //DesignModel
  getDesignModel(size: any, page: any, search: any) {
    return http.get(`/designmodel?size=${size}&page=${page}&search=${search}`);
  },
  insertDesignModel(data1: any) {
    return http.post("/designmodel/insert", data1);
  },
  updateDesignModel(id: any, data1: any) {
    return http.post(`/designmodel/update?id=${id}`, data1);
  },

  getDesignModelid(id: number) {
    return http.get('/design/getmodel', {
      params: { id: id },
    });
  },

  //Design and Development
  getDesignCount(search: any,userempid : any) {
    return http.get(`/design/getcount?search=${search}&userempid=${userempid}`);
  },
  getDesignList(size: any, click: any, page: any, search: any, userempid : any) {
    return http.get('/design/getlist', {
      params: {
        size: size,
        click: click,
        page: page,
        search: search,
        userempid: userempid
      }
    });
  },
  getDesignListreq(size: any, click: any, page: any, search: any) {
    return http.get('/design/getlistreq', {
      params: {
        size: size,
        click: click,
        page: page,
        search: search
      }
    });
  },

  getDesignerTable(data1: any) {
    return http.get("/design/designer_count", data1);
  },
  getEssData (id: number) {
    return http.get('/design/getdraw_Essdata', {
      params: { id: id },
    });
  },
  getTestData(id: number) {
    return http.get('/design/get_testdata', {
      params: { id: id },
    });
  },
  getAccessoriesData(id: number) {
    return http.get('/design/get_accessoriesdata', {
      params: { id: id },
    });
  },
  getSizeWt(id: number) {
    return http.get('/design/get_sizewt', {
      params: { id: id },
    });
  },
  getEnq_Date(id: number) {
    return http.get('/design/getEnq_Date', {
      params: { id: id },
    });
  },
  get_assign (id: number) {
    return http.get('/design/get_assign', {
      params: { id: id },
    });
  },


  getInputForm(drawid: any){ 
    return http.get(`/design/get_drawreg?drawid=${drawid}`);
  },

  getInputForm1(drawid: any){ 
    return http.get(`/design/get_drawreg1/${drawid}`);
  },

  get_drawdoc(drawid: any){ 
    return http.get(`/design/get_drawdoc/${drawid}`);
  },

  postdesigndata(data1: any) {
    return http.post(`/design/submit_drawreg`, data1)
  },

  getReviewForm(drawid: any) {
    return http.get(`/design/get_reviewdata/${drawid}`);
  },
  postreviewdata(data1: any) {
    return http.post(`/design/submit_review`, data1)
  },
  getEcnHistory(drawid: any) {
    return http.get(`/design/get_ecnhistory/${drawid}`);
  },

  getOutputForm(drawid: any) {
    return http.get(`/design/get_outputdata/${drawid}`);
  },
  postoutputdata(data1: any) {
    return http.post(`/design/submit_output`, data1)
  },

  getVerificationForm(drawid: any) {
    return http.get(`/design/get_verificationdata/${drawid}`);
  },
  postverifydata(data1: any) {
    return http.post(`/design/submit_verification`, data1)
  },

  getValidationForm(drawid: any) {
    return http.get(`/design/get_validationdata/${drawid}`);
  },
  postdrawvaliddata(data1: any) {
    return http.post(`/design/submit_drawing_validation`, data1)
  },
  submitmarket(data1: any) {
    return http.post(`/design/submit_market`, data1);
  },
  req_clarify(data1: any) {
    return http.post(`/design/req_clarify`, data1);
  },
  req_clarifyfirst(id: any){
    return http.post(`/design/req_clarifyfirst/${id}`);
  },
  postassigndata( data1: any) 
  {
    return http.post(`/design/submit_assigndata`,  data1 )
  },
  isproceed(data1: any){
    return http.post(`/design/isproceed`,data1);
  },

  //Sale Oreder Currency
  getCurrency(size: any, page: any, search: any) {
    return http.get(`/currency?size=${size}&page=${page}&search=${search}`);
  },
  insertCurrency(data1: any) {
    return http.post("/currency/insert", data1);
  },
  updateCurrency(id: any, data1: any) {
    return http.post(`/currency/update?id=${id}`, data1);
  },

  //Sale Order Payment Mode
  getPayMode(size: any, page: any, search: any) {
    return http.get(`/paymode?size=${size}&page=${page}&search=${search}`);
  },
  insertPayMode(data1: any) {
    return http.post("/paymode/insert", data1);
  },
  updatePayMode(id: any, data1: any) {
    return http.post(`/paymode/update?id=${id}`, data1);
  },

  //Sale Order Payment Terms
  getPayTerms(size: any, page: any, search: any) {
    return http.get(`/payterms?size=${size}&page=${page}&search=${search}`);
  },
  insertPayTerms(data1: any) {
    return http.post("/payterms/insert", data1);
  },
  updatePayTerms(id: any, data1: any) {
    return http.post(`/payterms/update?id=${id}`, data1);
  },

  //Sale Order Delivery Terms
  getDeliveryTerms(size: any, page: any, search: any) {
    return http.get(`/deliveryterms?size=${size}&page=${page}&search=${search}`);
  },
  insertDeliveryTerms(data1: any) {
    return http.post("/deliveryterms/insert", data1);
  },
  updateDeliveryTerms(id: any, data1: any) {
    return http.post(`/deliveryterms/update?id=${id}`, data1);
  },

  //Sale Order Warranty
  getWarranty(size: any, page: any, search: any) {
    return http.get(`/warranty?size=${size}&page=${page}&search=${search}`);
  },
  insertWarranty(data1: any) {
    return http.post("/warranty/insert", data1);
  },
  updateWarranty(id: any, data1: any) {
    return http.post(`/warranty/update?id=${id}`, data1);
  },

  //Sale Order Shipment Type
  getShipmentType(size: any, page: any, search: any) {
    return http.get(`/shiptype?size=${size}&page=${page}&search=${search}`);
  },
  insertShipmentType(data1: any) {
    return http.post("/shiptype/insert", data1);
  },
  updateShipmentType(id: any, data1: any) {
    return http.post(`/shiptype/update?id=${id}`, data1);
  },

  //HRMS PAYROLL Qualification
  getQualification(size: any, page: any, search: any) {
    return http.get(`/qualification?size=${size}&page=${page}&search=${search}`);
  },
  insertQualification(data1: any) {
    return http.post("/qualification/insert", data1);
  },
  updateQualification(id: any, data1: any) {
    return http.post(`/qualification/update?id=${id}`, data1);
  },

  //HRMS PAYROLL Religion
  getReligion(size: any, page: any, search: any) {
    return http.get(`/religion?size=${size}&page=${page}&search=${search}`);
  },
  insertReligion(data1: any) {
    return http.post("/religion/insert", data1);
  },
  updateReligion(id: any, data1: any) {
    return http.post(`/religion/update?id=${id}`, data1);
  },

  //HRMS PAYROLL Caste
  getCaste(size: any, page: any, search: any) {
    return http.get(`/caste?size=${size}&page=${page}&search=${search}`);
  },
  insertCaste(data1: any) {
    return http.post("/caste/insert", data1);
  },
  updateCaste(id: any, data1: any) {
    return http.post(`/caste/update?id=${id}`, data1);
  },

  //HRMS PAYROLL Shift
  getShift(size: any, page: any, search: any) {
    return http.get(`/shift?size=${size}&page=${page}&search=${search}`);
  },
  insertShift(data1: any) {
    return http.post("/shift/insert", data1);
  },
  updateShift(id: any, data1: any) {
    return http.post(`/shift/update?id=${id}`, data1);
  },

     //HRMS PAYROLL workcategory
    getWorkCategory(size: any, page: any, search: any) {
      return http.get(`/workcategory?size=${size}&page=${page}&search=${search}`);
    },
    insertWorkCategory(data1: any) {
      return http.post("/workcategory/insert", data1);
    },
    updateWorkCategory(id: any, data1: any) {
      return http.post(`/workcategory/update?id=${id}`, data1);
    },

    //HRMS PAYROLL Leave Type
    getLeaveType(size: any, page: any, search: any) {
      return http.get(`/leavetype?size=${size}&page=${page}&search=${search}`);
    },
    insertLeaveType(data1: any) {
      return http.post("/leavetype/insert", data1);
    },
    updateLeaveType(id: any, data1: any) {
      return http.post(`/leavetype/update?id=${id}`, data1);
    },

  //HRMS PAYROLL Legal Type
  getLegalType(size: any, page: any, search: any) {
    return http.get(`/legaltype?size=${size}&page=${page}&search=${search}`);
  },
  insertLegalType(data1: any) {
    return http.post("/legaltype/insert", data1);
  },
  updateLegalType(id: any, data1: any) {
    return http.post(`/legaltype/update?id=${id}`, data1);
  },

  //HRMS PAYROLL Language
  getLanguage(size: any, page: any, search: any) {
    return http.get(`/language?size=${size}&page=${page}&search=${search}`);
  },
  insertLanguage(data1: any) {
    return http.post("/language/insert", data1);
  },
  updateLanguage(id: any, data1: any) {
    return http.post(`/language/update?id=${id}`, data1);
  },

    postemployeeregisterdata( data1: any) 
    {
      return http.post("/employeeregister/submit_empregister",  data1 )
    },

  getSalaryDet(salconfigid: any) {
    return http.get(`/employeeregister/get_salary/${salconfigid}`);
  },

    getEducation(id: number) {
      return http.get('/employeeregister/get_education', {
        params: { id: id },
      });
    },
    postleaveopendata( data1: any) 
    {
      return http.post(`/leave/leave_opening`,  data1 )
    },

    getLeaveOpening(year: any, empcatgid: any) {
      return http.get('/leave/getleaveopening', {
        params: { year: year, empcatgid: empcatgid },
      });
    }, 

    getBalDays(ltypeid: any, empid: any, year: any) {
      return http.get('/leaverequest/getbaldays', {
        params: { ltypeid: ltypeid, empid: empid, year: year },
      });
    }, 

    getftodate(empid: any, year: any, fdate: any, todate: any) {
      return http.get('/leaverequest/getftodate', {
        params: { empid: empid, year: year, fdate: fdate, todate: todate},
      });
    }, 

    postleaverequest(data: any) {
      return http.post(`/leaverequest/submit_leaverequest`, data)
    },

    posthodapprove(data: any) {
      return http.post(`/leaverequest/submit_hodapprove`, data)
    },

    posthodreject(data: any) {
      return http.post(`/leaverequest/submit_hodreject`, data)
    },

    posthrapprove(data: any) {
      return http.post(`/leaverequest/submit_hrapprove`, data)
    },

    posthrreject(data: any) {
      return http.post(`/leaverequest/submit_hrreject`, data)
    },


    getleavereqlist(size: any, page: any, search: any,login_empid : any) {
      return http.get(`/leaverequest/getleavereqlist?size=${size}&page=${page}&search=${search}&login_empid=${login_empid}`);
    },

    getleavereqlistHOD(size: any, page: any, search: any,login_empid : any) {
      return http.get(`/leaverequest/getleavereqlistHOD?size=${size}&page=${page}&search=${search}&login_empid=${login_empid}`);
    },

    getleavereqlisthr(size: any, page: any, search: any,login_empid : any) {
      return http.get(`/leaverequest/getleavereqlisthr?size=${size}&page=${page}&search=${search}&login_empid=${login_empid}`);
    },

    getExperience(id: number) {
      return http.get('/employeeregister/get_experience', {
        params: { id: id },
      });
    },

    getLanguageProf(id: number) {
      return http.get('/employeeregister/get_languageprof', {
        params: { id: id },
      });
    },

    getFamily(id: number) {
      return http.get('/employeeregister/get_family', {
        params: { id: id },
      });
    },

    getsalaryamount(id: number) {
      return http.get(`/employeeregister/get_salaryamount/${id}`, {
        params: { id: id },
      });
    },

  //for financial year
  getFinancialYear(size: any, page: any, search: any) {
    return http.get(`/financialyear?size=${size}&page=${page}&search=${search}`);
  },
  insertFinancialYear(data1: any) {
    return http.post("/financialyear/insert", data1);
  },
  updateFinancialYear(id: any, data1: any) {
    return http.post(`/financialyear/update?id=${id}`, data1);
  },

  // Report
  getdailyfollowupreport(size: any, page: any, search: any, fdate: any, todate: any, status: any) {
    return http.get(`/report/dailyfollowupreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}&status=${status}`);
  },
  getdailyreport(size: any, page: any, search: any, fdate: any, todate: any) {
    return http.get(`/report/dailyreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}`);
  },
  getQuote(data: any) {
    return http.post("/crm/Quote", data);
  },
  updateForm(data1: any) {
    return http.post("/crm/enqupdate", data1);
  },
  getNotify(data1: any) {
    return http.get("/notification/count", data1);
  },
  getBudgetQuotationpending(data: any) {
    return http.get("/notification/quotepending", data);
  },
  postQuote(data: any) {
    return http.post("/crm/UpdateQuote", data);
  },
  EditQuote(data: any) {
    return http.post("/crm/EditQuote", data);
  },
  getUpdateQuote(id: number) {
    console.log('log -->.', id);

    return http.get('/crm/getrowQuote', {
      params: { id: id },
    });
  },
  quotelist(id: number) {
    return http.get('/crm/GetQuote', {
      params: { id: id },
    });
  },
  getstatus(size, click, page) {
    return http.get('/crm/GetStatusCount', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },

  getEssentialData(size: any, page: any, search: any) {
    return http.get(`/essentialdata?size=${size}&page=${page}&search=${search}`);
  },
  insertEssentialData(data1: any) {
    return http.post("/essentialdata/insert", data1);
  },
  updateEssentialData(id: any, data1: any) {
    return http.post(`/essentialdata/update?id=${id}`, data1);
  },
  getTax(size: any, page: any, search: any) {
    return http.get(`/tax?size=${size}&page=${page}&search=${search}`);
  },
  insertTax(data1: any) {
    return http.post("/tax/insert", data1);
  },
  updateTax(id: any, data1: any) {
    return http.post(`/tax/update?id=${id}`, data1);
  },

  getDropdown_indame(tablename: any, idname: any) {
    return http.get(`/dropdown/gettable_idname?tablename=${tablename}&idname=${idname}`);
  },
  getMonthlyVsActual(fiscalYear: string) {
    // return http.get(`crm/getquarterlydata=${fiscalYear}`);
    return http.get('/crm/getquarterlydata', {
      params: { fiscalYear: fiscalYear },
    });
  },
  getMonthlyQuator(fiscalYear: string) {
    // return http.get(`crm/getquarterlydata=${fiscalYear}`);
    return http.get('/crm/getmonthlyquoter', {
      params: { fiscalYear: fiscalYear },
    });
  },

  getEnquiry(search: string) {
    return http.get('/crm/getEnquiryData', {
      params: { search: search },
    });
  },
  getstatuswisereport(size: any, page: any, search: any, click: any, fdate: any, todate: any) {
    return http.get(`/report/statuswisereport/?size=${size}&page=${page}&search=${search}&click=${click}&fdate=${fdate}&todate=${todate}`);
  },
  getCustomerDetail(search: string) {
    return http.get('/crm/getCustomerData', {
      params: { search: search },
    });
  },
  getCusDetail(id: number) {
    return http.get('/crm/getEnquiryCustomerDetails', {
      params: { id: id },
    });
  },

  getstatuscount(startdate, enddate) {
    return http.get("/crm/GetSCount", {
      params: { startdate, enddate },
    });
  },
  getmodecount(startdate, enddate) {
    return http.get("/crm/GetModeCount", {
      params: { startdate, enddate },
    });
  },
  gettypecount(startdate, enddate) {
    return http.get("/crm/GetTypeCount", {
      params: { startdate, enddate },
    });
  },
  getprioritycount(startdate, enddate) {
    return http.get("/crm/GetPriorityCount", {
      params: { startdate, enddate },
    });
  },
  getleadcount(data1: any) {
    return http.get("/lead/getcount", data1);
  },
  getleadlist(size, click, page) {
    return http.get('/lead/getlist', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },
  leadinsert(data1: any) {
    return http.post(`/lead/insertlead`, data1)
  },
  leadgetId(id: number) {
    return http.get('/lead/getrow', {
      params: { id: id },
    });
  },
  leadupdate(data1: any) {
    return http.post("/lead/leadupdate", data1);
  },
  //   checkUniqueField(mobile: any, email: any) {
  //     console.log("Mobile:", mobile);  // Log the mobile number
  //     console.log("Email:", email);  // Log the email

  //     return http.get('/user/mobemail_val', {
  //       params: { mobile: mobile, email: email }
  //     });
  // }

  getquoteId(id: number) {
    return http.get('/crm/getquoterow', {
      params: { id: id },
    });
  },
  getquoterow(data: any) {
    return http.get('/crm/Qid', data);
  },
  leadfollowgetcount(data1: any) {
    return http.get("/lead/leadfollowgetcount", data1);
  },
  Prioritycount(fromDate: any, toDate: any, search = '') {
    return http.get(`/lead/Prioritycount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  PrioritycountEnq(fromDate: any, toDate: any, search = '') {
    return http.get(`/crm/Prioritycount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  ModeCount(fromDate: any, toDate: any, search = '') {
    return http.get(`/lead/ModeCount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  ModeCountEnq(fromDate: any, toDate: any, search = '') {
    return http.get(`/crm/ModeCount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  StatusCount(fromDate: any, toDate: any, search = '') {
    return http.get(`/lead/StatusCount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  StatusCountEnq(fromDate: any, toDate: any, search = '') {
    return http.get(`/crm/StatusCount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  getleadfollowlist(size, click, page) {
    return http.get('/lead/getleadlist', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },
  getenqfollowlist(size, click, page) {
    return http.get('/crm/getenqlist', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },

  leadnewexcount(fromDate: any, toDate: any, search = '') {
    return http.get(`/lead/leadnewexcount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  leadnewexcountEnq(fromDate: any, toDate: any, search = '') {
    return http.get(`/crm/leadnewexcount`, {
      params: {

        fromdate: fromDate,
        todate: toDate,
        search
      }
    });
  },
  CustomerOpportunity(data1: any) {
    return http.get("/lead/CustomerOpportunity", data1);
  },
  CustomerOpportunityEnq(data1: any) {
    return http.get("/crm/CustomerOpportunity", data1);
  },
  Enqfollowgetcount(data1: any) {
    return http.get("/crm/Enqfollowgetcount", data1);
  },
  getenqreport(size: any, page: any, search: any, fdate: any, todate: any, status: any, date: any) {
    return http.get(`/report/enquiryreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}&date=${date}`);
  },
  getleadreport(size: any, page: any, search: any, fdate: any, todate: any, status: any, date: any) {
    return http.get(`/report/leadreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}&date=${date}`);
  },
  leadMonthlyQuator(fiscalYear: string) {
    // return http.get(`crm/getquarterlydata=${fiscalYear}`);
    return http.get('/crm/leadmonthlyreport', {
      params: { fiscalYear: fiscalYear },
    });
  },
  leadstatuswisereport(size: any, page: any, search: any, fdate: any, todate: any, status: any) {
    return http.get(`/report/leadstatuswisereport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}&status=${status}`);
  },
  LeadStatusCount(size, click, page) {
    return http.get('/report/LeadStatusCount', {
      params: {
        size: size,
        click: click,
        page: page
      }
    });
  },
  leadstatuslist(size, click, page, status) {
    return http.get('/report/leadstatuslist', {
      params: {
        size: size,
        click: click,
        page: page,
        status: status
      }
    });
  },
  leaddailyreport(size: any, page: any, search: any, fdate: any, todate: any) {
    return http.get(`/report/leaddailyreport/?size=${size}&page=${page}&search=${search}&fdate=${fdate}&todate=${todate}`);
  },
  // hrms blood group
  getbgrp(size: any, page: any, search: any) {
    return http.get(`/bloodgroup?size=${size}&page=${page}&search=${search}`);
  },
  insertbgrp(data1: any) {
    return http.post("/bloodgroup/insert", data1);
  },
  updatebgrp(id: any, data1: any) {
    return http.post(`/bloodgroup/update?id=${id}`, data1);
  },

  //Vms things
  getthings(size: any, page: any, search: any) {
    return http.get(`/things?size=${size}&page=${page}&search=${search}`);
  },
  insertthings(data1: any) {
    return http.post("/things/insert", data1);
  },
  updatethings(id: any, data1: any) {
    return http.post(`/things/update?id=${id}`, data1);
  },
  getReqtoclarify(data: any) {
    return http.get("/crm/reqtoclarify", data);
  },
  updateReqclarify(data1: any) {
    return http.post("/crm/reqclarifyupdate", data1);
  },
  getreqId(id: number, detid: number, iid: number) {
    return http.get('/crm/reqgetrow', {
      params: {
        id: id,
        detid: detid,
        iid: iid
      },
    });
  },
  //Hrms Attendance Process
  attn_process(year: any, month: any, date: any) {
    return http.post(`/attendance/attn_process?year=${year}&month=${month}&date=${date}`);
  },
  get_attnsum(size: any, page: any, search: any, year: any, month: any) {
    return http.get(`/attendance/get_attnsum?size=${size}&page=${page}&search=${search}&year=${year}&month=${month}`);
  },
  get_attndet(size: any, page: any, search: any, year: any, month: any, empid: any) {
    return http.get(`/attendance/get_attndet?size=${size}&page=${page}&search=${search}&year=${year}&month=${month}&empid=${empid}`);
  },
  attndet_update(data: any) {
    return http.post(`/attendance/attndet_update`, data);
  },
  sogetrow(id: number) {
    return http.get('/SaleOrder/sogetrow', {
      params: {
        id: id
      },
    });
  },
  soinsert(data1: any) {
    return http.post("/SaleOrder/soinsert", data1);
  },
  sogetbilladdress(id: number) {
    return http.get('/SaleOrder/sobilladress', {
      params: {
        id: id
      },
    });
  },
  soproformainvoice(id: number) {
    return http.get('/SaleOrder/soproformainvoice', {
      params: { id: id },
    });
  },
  getsolist(size, page) {
    return http.get('/SaleOrder/solist', {
      params: {
        size: size,
        page: page
      }
    });
  },

  getsalarydetreport(id: number, year: any, month: any,search1: any) {
    return http.get('/Salarydetail', {
      params: {
        id: id,
        year: year,
        month: month,
        search : search1
      }
    });
  },
  getsalaryslip(id: number, year: any, month: any,search1: any) {
    return http.get('/Salarydetail/salaryslip', {
      params: {
        id: id,
        year: year,
        month: month,
        search : search1
      }
    });
  },
  getProof(size: any, page: any, search: any) {
    return http.get(`/proof?size=${size}&page=${page}&search=${search}`);
  },
  insertProof(data1: any) {
    return http.post("/proof/insert", data1);
  },
  updateProof(id: any, data1: any) {
    return http.post(`/proof/update?id=${id}`, data1);
  },
  //Hrms Salary Process
  slary_process(year: any, month: any, date: any) {
    return http.post(`/attendance/salary_process?year=${year}&month=${month}`);
  },
  get_salarysum(size: any, page: any, search: any, year: any, month: any) {
    return http.get(`/attendance/get_salarysum?size=${size}&page=${page}&search=${search}&year=${year}&month=${month}`);
  },
  get_salarydet(size: any, page: any, search: any, year: any, month: any, empid: any) {
    return http.get(`/attendance/get_salarydet?size=${size}&page=${page}&search=${search}&year=${year}&month=${month}&empid=${empid}`);
  },
  musterreport(size: any, page: any, search: any, year: any, month: any) {
    return http.get(`/attendance/musterreport?size=${size}&page=${page}&search=${search}&year=${year}&month=${month}`);
  },
  getcusname(search: any) {
    return http.get('/crm/customername', {
      params: {
        search : search
      }
    });
  },
  //Mode
  getPurpose(size: any, page: any, search: any) {
    return http.get(`/purpose?size=${size}&page=${page}&search=${search}`);
  },
  insertPurpose(data1: any) {
    return http.post("/purpose/insert", data1);
  },
  updatePurpose(id: any, data1: any) {
    return http.post(`/purpose/update?id=${id}`, data1);
  },
  getDrawreq(id: number) {
    return http.get('/design/getdraw_Drawreq', {
      params: { id: id },
    });
  },
  Designinit(data1: any) {
    return http.post("/design/drawreq", data1);
  },
  Designinput(data1: any) {
    return http.post("/design/drawinputform", data1);
  },
  getDrawatt(id: number) {
    return http.get('/design/getdraw_multiatt', {
      params: { id: id },
    });
  },
  Designcaseselect(data1: any) {
    return http.post("/design/drawcaseselect", data1);
  },
  getdrawreq(id: number) {
    return http.get('/design/getdrawreq', {
      params: { id: id },
    });
  },

  getlistpending() {
    return http.get('/design/getpending');
  },

  getlistrequirement(id: number) {
    return http.get('/design/getenqpending', {
      params: { id: id },
    });
  },

  submitRequest(data : any) {
    return http.post('/design/market_ipreq',data);
  },
  designPending(size, page ,search) {
    return http.get("/design/design_app_pending", {
      params: {
        size: size,
        page: page,
        search:search
      }
    });
  },
  get_alldesigners () {
    return http.get('/design/get_alldesigners');
  },
  approve_draw(id: number) {
    return http.post(`/design/approve_draw?id=${id}`);
  },
  SubmitReAssignData( data1: any) 
  {
    return http.post(`/design/SubmitReAssignData`,  data1 )
  },
  draw_status(id: number) {
    return http.get('/crm/draw_status', {
      params: { id: id },
    });
  },
  vlog(fdate: string , tdate:string , search:string) {
    return http.get('/visitorReports/visitorlogreport', {
      params: { fdate: fdate,
        tdate:tdate,
        search:search
       },
    });
  },
  get_lastenqno() {
    return http.get('/crm/get_lastenqno');
  },
}
