import React, { useState } from "react";
import * as XLSX from "xlsx";
import * as tf from "@tensorflow/tfjs";

interface Employee {
  name: string;
  Age: number;
  position: string;
}

const ExcelProcessor: React.FC = () => {
  const [processedData, setProcessedData] = useState<Employee[]>([]);

  // Handle file upload and parse Excel
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData: Employee[] = XLSX.utils.sheet_to_json(sheet);
      console.log(jsonData,"jsonData")
      preprocessData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  // Preprocess data using TensorFlow
  const preprocessData = (data: Employee[]) => {
    const processed = data.map((item) => {
      // Normalize the age using TensorFlow
      const tensor = tf.tensor1d([item.Age]);
      console.log(tensor,"tensor");
      
      
      const normalizedAge = tensor.div(tf.max(tensor)).arraySync()[0];
      console.log(normalizedAge,"console.log(normalizedAge);");
      return { ...item, normalizedAge };
    });

    setProcessedData(processed);
  };


//   const preprocessData = (data: Employee[]) => {
//     console.log(data,"data")
//     return data.map((item) => {
//       const age = Number(item.Age); // Convert age to a valid number
//       if (isNaN(age)) {
//         console.warn(`Invalid age for ${item.name}:`, item.Age);
//         return { ...item, normalizedAge: 0 }; // Default to 0 for invalid ages
//       }
    
//       // Normalize the age (example: divide by 100)
//       const normalizedAge = age > 0 ? age / 100 : 0;
//       console.log(normalizedAge,"normalizedAge")
//       return { ...item, normalizedAge };

//     });
//   };
  
  // Download processed data as Excel
  const downloadExcel = () => {
    console.log(processedData.length,"processedData.length")
    if (!processedData.length) return;

    const worksheet = XLSX.utils.json_to_sheet(processedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "ProcessedData");
    XLSX.writeFile(workbook, "Processed_Employee_List.xlsx");
  };

  return (
    <div>
      <h1>Excel Processor</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      <button onClick={downloadExcel} >
        Download Processed Excel
      </button>
    </div>
  );
};

export default ExcelProcessor;
