import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
import { useNavigate } from 'react-router-dom';


interface Data {
    id: number,
    enqid: number,
    qdate: string | Date,
    qno: number,
    Rno: number,
    qval: number,
    cusref: string,
    cuspocname: string,
    cuspocno: string,
    cusdesign: string,
    status: string,
    lastqid: number,
    subject: string,
    cby: string,
    con: string | Date,
    mby: null,
    mon: null,
    taxid: number,
    taxamt: number,
    total: number,
    appby: null,
    appdate: null,
    comments: null,
    enqno: string,
    qdates: string | Date,
    creatby: string
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

function DrawApproveList() {

    // For Grid Table
    // const [data, setData] = useState<Data[]>([]);
    const [data, setData] = useState([]);

    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: 0,
        enqno: "",
        name: "",
        desp: "",
        mpnno: "",
        Design_Status: "",
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    // useEffect(() => {

    //     console.log(rowsPerPage.toString(), "size");
    //     console.log((page + 1).toString(), "page");

    //     apiService.getRole(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
    //         setData(response.data);
    //     }).catch((err) => {
    //         console.log("err", err)
    //     })
    // }, [rowsPerPage, page, formData]);
    useEffect(() => {
        apiService.designPending(rowsPerPage, (page + 1), "")
            .then(response => {
                setData(response.data);
                console.log('check id ', response.data);
            })

            .catch(err => console.error('Error fetching count data:', err));
    }, [rowsPerPage,page]);






    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            role: '',
            isactive: '',
        });
        setSelectedItem(null);
    };





    const navigate = useNavigate();

    const updateQuotation = (id: number, qno: string, enqid: number) => {
        console.log("id", id);
        console.log("qno", qno);


        navigate('/MarketingCRM/updatequotationpending', { state: { id, qno, enqid } });
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); // 'en-GB' ensures DD-MM-YYYY format
    };

    const handleViewClick = (id: number, reqtypeid: number) => {
        navigate('/Design/Drawingapprove', { state: { id, reqtypeid } });
      };

    return (
        <>
            <Helmet>
                <title>Design Approve Pending</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Design Approve Pending"
                   
                />
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Enquiry No</TableCell>
                                            <TableCell>Customer</TableCell>
                                            <TableCell>Requirment</TableCell>

                                            <TableCell>MPN No</TableCell>
                                            <TableCell>Design Status</TableCell>
                                            <TableCell>Approved or Reject</TableCell>

                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow key={row.id} >
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.enqno}</TableCell>
                                                <TableCell >{row.name}</TableCell>
                                                <TableCell>{row.desp}</TableCell>
                                                <TableCell>{row.mpnno}</TableCell>
                                                <TableCell>{row.Design_Status}</TableCell>

                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleViewClick(row.id, row.reqtypeid)}
                                                    >
                                                        Update
                                                    </Button>
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[50, 100, 250]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );

}


export default DrawApproveList;