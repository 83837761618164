import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { it } from "date-fns/locale";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";

import { format } from "date-fns";
import { useReactToPrint } from 'react-to-print';// import { Type } from "typescript";
import * as XLSX from 'xlsx';
import React from "react";
import { faPrint, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { log } from "console";


interface Data {

    id: number;
    EnquiryNo: number;
    EnquiryDate: Date;
    Customer: string;
    Division: string;
    Status: string;
    Priority: string;
    FollowBy: string;
    FollowDate: Date;
    FollowDescription: string;
    NextFollowDate: Date;


}
const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

const getFirstDayOfMonth = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
};


function VisitorHistoryReport() {
    const [data, setData] = useState<any[]>([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const [status, setStatus] = useState<string>('');
    const [search, setSearch] = useState('');
    const [date, setDate] = useState<string>('enqdate');
    const [issaved, setisSaved] = useState(false);


    const [showImage, setShowImage] = useState(false)


    useEffect(() => {
        apiService.vlog(fdate, tdate, search)
            .then(response => {
                console.log(response.data);

                setData(response.data);
                console.log("====> ",response.data);
                
            })
            .catch(err => console.error('Error fetching list data:', err));
    }, [search]);


    const handleSearch = () => {
        apiService.vlog(fdate, tdate, search).then((response) => {
            setData(response.data);

        }).catch((err) => {
            console.log("err", err);

        })

    }




    const printRef = useRef(null);
    const contentToPrint: any = useRef(null);
    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Reort",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,

    });
    const PrintComponent = React.forwardRef((props, ref) => (
        <div style={{ padding: '20px' }}>
            <header style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={require('../assets/logosaarc.png')} alt="Logo" style={{ width: '100px', display: 'block', margin: '0 auto', height: '100%' }} />
                <h1>Daily Followup Report</h1>
            </header>

        </div>
    ));

    const handleConvert = () => {
        const data1 = [];

        data1.push([
            'Visitor History Report',

        ]);
        data1.push([
            ' ',

        ]);

        data1.push([
            "S.No",
            "Date",
            "Checkin",
            "Checkout",
            "Stay Hours",
            "No of Person",
            "Employee Name",
            "Permitted Things",
            "Remarks"
        ]);

        Object.keys(groupedDataByFdate).forEach(con => {
            data1.push([`${con}`]); // Adding status as a header row
            groupedDataByFdate[con].forEach((row, index) => {
                data1.push([
                    index + 1,
                    row.con,
                    row.checkin,
                    row.checkout,
                    row.checkout,
                    row.noofper,
                    row.empname,
                    row.permitted_things,
                    row.remarks
                ]);
            });
        });
        const worksheet = XLSX.utils.aoa_to_sheet(data1);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Visitor Log History");

        XLSX.writeFile(workbook, "Visitor History Report.xlsx");
    };

    const [stat, setStat] = useState([]);

    useEffect(() => {
        apiService.getDropdown("crm_mas_status")
            .then(response => {
                setStat(response.data);
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, []);




    const formatToInput = (date: string) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };

    const formatToISO = (date: string) => {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
    };

    const [fdate, setFromDate] = useState(formatToISO(getFirstDayOfMonth()));
    const [tdate, setToDate] = useState(formatToISO(getCurrentDate()));

    const handleDateChange = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        setFromDate(formattedDate);
    };
    const handleDateChange1 = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        setToDate(formattedDate);
    };

    const groupedData = data.reduce((acc, row) => {
        const status = row.Status;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(row);
        return acc;
    }, {});


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const groupedDataByFdate = data.reduce((acc, row) => {
        const key = `${row.visitorname} - ${row.mobile} - ${row.vtype}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(row);
        return acc;
    }, {});
    
    console.log(groupedDataByFdate);

    return (
        <>
            <Helmet>
                <title>Visitor Log Report - CRM</title>
            </Helmet>
            <PageTitleWrapper>
                <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <PageTitle
                            heading=" Visitor History Log Report"
                            subHeading=""
                        />
                    </Grid>
                    <Grid item xs={12} sm={9} container spacing={1} justifyContent="flex-end">

                        <Grid item>
                            <TextField
                                id="fdate"
                                label="From Date"
                                name='fdate'
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={handleDateChange}
                                value={formatToInput(fdate)} 
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="tdate"
                                label="To Date"
                                name="tdate"
                                type="date"
                                value={formatToInput(tdate)}  // Display initial value
                                onChange={handleDateChange1}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                focused
                                id="search"
                                label="Search"
                                name='search'
                                onChange={(e: any) => {
                                    setSearch(e.target.value);
                                }}
                                sx={{
                                    m: 1,
                                    width: '20ch',
                                    '& .MuiInputBase-root': {
                                        height: '5%'  // Adjust this value as needed
                                    }
                                }}
                                margin="dense"
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: '8px' }}
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </PageTitleWrapper>



            <Container maxWidth="lg">
                <Grid
                    item
                    xs={12}

                    container
                    alignItems="center"
                    justifyContent="flex-end"  // Aligns items to the right side
                    style={{ display: 'flex', flexDirection: 'row' }}  // Reverses the row direction so buttons are on the right
                    gap={2}
                >
                    <Button
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                        variant="contained"
                        color="secondary"
                        startIcon={<FontAwesomeIcon icon={faPrint} />}  // Add the Print icon
                        onClick={() => {
                            handlePrint1(null, () => contentToPrint.current);
                        }}
                    >
                        Print
                    </Button>

                    <Button
                        style={{ marginTop: '10px' }}
                        variant="contained"
                        color="success"
                        startIcon={<FontAwesomeIcon icon={faFileExcel} />}  // Add the Excel icon
                        onClick={handleConvert}
                    >
                        Excel
                    </Button>
                </Grid>
                <br />

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                // spacing={3}
                >



                    <Grid item xs={12}>
                        <Paper
                            ref={contentToPrint}
                        >


                            <div className="printable-content" style={{ padding: '20px' }}>
                                <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={require('../assets/logosaarc.png')}
                                        alt="Logo"
                                        style={{ width: '50px', height: '50px', marginRight: '20px' }}
                                    />
                                    <h1 style={{ margin: 0 }}> Visitor History Log Report</h1>
                                </header>
                            </div>


                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            {/* <TableCell>Visitor Type</TableCell>
                                            <TableCell>Visitor</TableCell>*/}
                                            <TableCell>Date</TableCell> 
                                            <TableCell>Checkin</TableCell>
                                            <TableCell>Checkout</TableCell>
                                            <TableCell>Stay Hours</TableCell>
                                            <TableCell>No of Person</TableCell>
                                            <TableCell>Employee Name</TableCell>
                                            <TableCell>Permitted Things </TableCell>
                                            <TableCell>Remarks</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(groupedDataByFdate).length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={11} style={{ textAlign: 'center', color: '#888' }}>
                                                    No records or Provide Valid Filters
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            Object.keys(groupedDataByFdate).map(con => (
                                                <React.Fragment key={con}>
                                                    {console.log("con con con ===>>>", con)}
                                                    <TableRow>
                                                        <TableCell colSpan={11} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0' ,justifyContent:'left'}}>
                                                            {con}
                                                        </TableCell>
                                                    </TableRow>

                                                    {/* Grouped Rows */}
                                                    {groupedDataByFdate[con].map((row, index) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            {/* <TableCell>{row.vtype}</TableCell>
                                                            <TableCell>{row.visitorname}</TableCell>*/}
                                                            <TableCell>{row.con}</TableCell> 
                                                            <TableCell>{row.checkin}</TableCell>
                                                            <TableCell>{row.checkout}</TableCell>
                                                            <TableCell>{Number(row.stay_hrs).toFixed(2)} Hrs</TableCell>
                                                            <TableCell>{row.noofper}</TableCell>
                                                            <TableCell>{row.empname}</TableCell>
                                                            <TableCell>{row.permitted_things}</TableCell>
                                                            <TableCell>{row.remarks}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </React.Fragment>
                                            ))
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                rowsPerPageOptions={[50, 100, 250, 500]}
                                component="div"
                                count={data.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}


                     

                        {/* <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Visitor Type</th>
                                    <th>Visitor</th>
                                    <th>Mobile</th>
                                    <th>Checkin</th>
                                    <th>Checkout</th>
                                    <th>Stay Hours</th>
                                    <th>No of Person</th>
                                    <th>Employee Name</th>
                                    <th>Permitted Things</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(groupedDataByFdate).length === 0 ? (
                                    <tr>
                                        <td colSpan={11} style={{ textAlign: 'center', color: '#888' }}>
                                            No records or Provide Valid Filters
                                        </td>
                                    </tr>
                                ) : (
                                    Object.keys(groupedDataByFdate).map(con => (
                                        <React.Fragment key={con}>
                                            {console.log("con con con ===>>>", con)}
                                            <tr>
                                                <td colSpan={11} style={{ fontWeight: 'bold', backgroundColor: '#f0f0f0',textAlign: 'left' }}>
                                                    {con}
                                                </td>
                                            </tr>

                                         
                                            {groupedDataByFdate[con].map((row, index) => (
                                                <tr key={row.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{row.vtype}</td>
                                                    <td>{row.visitorname}</td>
                                                    <td>{row.mobile}</td>
                                                    <td>{row.checkin}</td>
                                                    <td>{row.checkout}</td>
                                                    <td>{row.stayHours}</td>
                                                    <td>{row.noofper}</td>
                                                    <td>{row.empname}</td>
                                                    <td>{row.permitted_things}</td>
                                                    <td>{row.remarks}</td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table> */}
                        </Paper>

                    </Grid>

                </Grid>


            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />



            <style>
                {`
                    @media print {
                    .table-cell-header {
                          text-align: left !important; 
                      }
                    @page {
                size: A4 portrait; /* Set the page size to A4 and orientation to portrait */
                margin: 10mm; /* Adjust margins as needed */
            }
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default VisitorHistoryReport;