import { useNavigate, useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import BaseLayout from './layouts/BaseLayout';
import Overview from './content/overview';
import Status404 from './content/pages/Status/Status404';
import Status500 from './content/pages/Status/Status500';
import StatusMaintenance from './content/pages/Status/Maintenance';
import StatusComingSoon from './content/pages/Status/ComingSoon';
import { useEffect } from 'react';


function App() {
  const content = useRoutes(router);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token'); // Get token from local storage
    if (!token) {
      // If token is null or not found, redirect to login
     // navigate('/');
    }
  }, [navigate]);
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />       
        {content}
      </LocalizationProvider>
    </ThemeProvider>
    
  );
}
export default App;
