import React, { useState } from 'react';
import axios from 'axios';

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

const Chatbot: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    // Add user message to the chat
    const userMessage: Message = { text: input, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);

    // Clear input field
    setInput('');

    // Fetch bot response
    setLoading(true);
    const botMessage = await getBotResponse(input);
    setMessages((prev) => [...prev, botMessage]);
    setLoading(false);
  };

  const getBotResponse = async (query: string): Promise<Message> => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/completions',
        {
          model: 'text-davinci-003', // Or use GPT-4 if available
          prompt: query,
          max_tokens: 100,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer sk-abcdefg1234567890`, // Replace with your API key
          },
        }
      );

      return {
        text: response.data.choices[0].text.trim(),
        sender: 'bot',
      };
    } catch (error) {
      console.error('Error fetching bot response:', error);
      return {
        text: 'Sorry, I encountered an error. Please try again later.',
        sender: 'bot',
      };
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '10px' }}>
      <div style={{ flex: 1, overflowY: 'auto', marginBottom: '10px' }}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              alignSelf: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              backgroundColor: msg.sender === 'user' ? '#d1e7dd' : '#f8d7da',
              padding: '10px',
              borderRadius: '10px',
              margin: '5px 0',
              maxWidth: '60%',
            }}
          >
            {msg.text}
          </div>
        ))}
        {loading && <div style={{ textAlign: 'center' }}>Typing...</div>}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          style={{ flex: 1, padding: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
          placeholder="Ask me anything..."
        />
        <button
          onClick={handleSendMessage}
          style={{
            marginLeft: '10px',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
          }}
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
