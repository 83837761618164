import React, { useState } from 'react';
import * as tf from '@tensorflow/tfjs';

const QuestionGenerator: React.FC = () => {
  const [essay, setEssay] = useState<string>('');
  const [questions, setQuestions] = useState<string[]>([]);

  // Load the TensorFlow model
//   const loadModel = async () => {
//     const model = await tf.loadGraphModel('/path-to-your-model/model.json');
//     return model;
//   };
  const loadModel = async () => {
    try {
      const model = await tf.loadGraphModel('/model/model.json');
      return model;
    } catch (error) {
      console.error('Error loading model:', error);
      alert('Failed to load the model. Please check the console for details.');
    }
  };
  // Generate questions from the essay
  const generateQuestions = async () => {
    if (!essay.trim()) return alert('Please enter an essay.');
    const model = await loadModel();

    // Preprocess input for the model
    const inputTensor = tf.tensor([essay]); // Adjust this based on your model's requirements

    // Generate questions
    const output = model.predict(inputTensor) as tf.Tensor;
    // const generatedQuestions = output.arraySync() as string[];

    // setQuestions(generatedQuestions);


  // Convert tensor output to a string array
  const outputArray = output.arraySync() as unknown; // Use 'unknown' to handle type mismatch
  let generatedQuestions: string[] = [];

  // Process the outputArray to extract string questions
  if (Array.isArray(outputArray)) {
    generatedQuestions = outputArray.flat().map((item) => String(item));
  }

  setQuestions(generatedQuestions);
  };

  return (
    <div>
      <h1>Essay to Question Generator</h1>
      <textarea
        value={essay}
        onChange={(e) => setEssay(e.target.value)}
        placeholder="Enter your essay here..."
        rows={10}
        cols={50}
      />
      <button onClick={generateQuestions}>Generate Questions</button>
      <div>
        <h2>Generated Questions:</h2>
        <ul>
          {questions.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default QuestionGenerator;
