import React, { useEffect, useRef, useState } from "react";
import "./SalarySlip.css";
// import { Box, Button, Card, CardContent, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { Box, Button, Card, CardContent, Grid, MenuItem, TextField } from "@mui/material";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import PageTitle from "src/components/PageTitle";
import apiService from "src/services/api-service";
// import { Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import { size } from "mathjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";

interface Salary {
    id: number;
    name: string;
    isactive: number;
}
const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
];
const currentYear = new Date().getFullYear();
const currentMonth = String(new Date().getMonth() + 1).padStart(2, '0'); // Adds leading zero if needed

const SalarySlip = () => {

    const [formData, setFormData] = useState([]);
    const [configid, setConfigid] = useState(1);
    const [year, setYear] = useState(currentYear);
    const [month, setMonth] = useState(currentMonth);
    const [salary, setSalary] = useState<Salary[]>([]);
    const [data, setData] = useState<string>('');
    const [search1, setSearch1] = useState('');
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name === "year") {
            setYear(value);
        }
        else {
            setMonth(value);
        }

    };
    const handleSearchChange1 = (event) => {
        setSearch1(event.target.value);

    };

    useEffect(() => {
        apiService.getDropdown("hrms_ent_salaryconfig").then(response => {
            setSalary(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [])

    useEffect(() => {
        apiService.getDropdown("hrms_ent_salaryconfig")
            .then(response => {
                // Filter the data based on configid
                const filteredData = response.data.filter(item => item.id === configid);
                setData(filteredData.name);
            })
            .catch(err => {
                console.log("Error fetching salary config data: ", err);
            });
    }, [configid]);

    useEffect(() => {
        apiService
            .getsalaryslip(configid, year, month, search1)
            .then((response) => {
                const data = response.data;
                console.log("Fetched data:", data);
                setFormData(data);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [configid, year, month, search1]);

    useEffect(() => {
        console.log("=> ", formData);

    }, [configid]);



    const componentRef = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Employee Salary Slip",
        pageStyle: `
         @media print {
    @page {
        margin: 10mm;
        size: landscape; /* Ensure the page is in landscape mode */
    }

    body {
        font-family: Arial, sans-serif;
        margin: 0;
        padding: 10px;
    }

    .salary-slip-container {
        margin: 0 auto;
        width: 100%;
    }

    .header-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    header {
        display: flex;
        flex-direction: column;
    }

    .net-pay {
        font-weight: bold;
        margin-top: 10px;
    }

    footer {
        text-align: center;
        margin-top: 10px;
    }
            .page-break {
                page-break-before: always; /* Start new page before the element */
                page-break-after: always;  /* Start a new page after the element */
            }

            .no-page-break {
                page-break-inside: avoid; /* Prevent page break inside the element */
            }
}
        `,
    });

    function numberToWords(num) {
        if (num === 0) return "Zero";

        const ones = [
            '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
            'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen',
            'Seventeen', 'Eighteen', 'Nineteen'
        ];

        const tens = [
            '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
        ];

        const thousands = [
            '', 'Thousand', 'Million', 'Billion'
        ];

        function convertHundreds(num) {
            if (num === 0) return '';
            if (num < 20) return ones[num];
            if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + ones[num % 10] : '');
            return ones[Math.floor(num / 100)] + ' Hundred' + (num % 100 !== 0 ? ' ' + convertHundreds(num % 100) : '');
        }

        let result = '';
        let thousandIndex = 0;

        while (num > 0) {
            if (num % 1000 !== 0) {
                result = convertHundreds(num % 1000) + ' ' + thousands[thousandIndex] + (result ? ' ' + result : '');
            }
            num = Math.floor(num / 1000);
            thousandIndex++;
        }

        return result.trim();
    }

    return (
        <>
            <Box
                sx={{
                    position: 'sticky',
                    top: 40,
                }}
            >
                {/* <PageTitleWrapper>
                    <PageTitle heading="Salary Slip" subHeading="" />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end', // Align items to the right
                            alignItems: 'center', // Vertically align items in the center
                            mt: -3.5,
                            gap: 2, // Add space between TextField and Button
                        }}
                    >

                    </Box>
                </PageTitleWrapper> */}
                <PageTitleWrapper>
                    <PageTitle
                        heading="Pay Slip"
                        subHeading=""
                    />
                 

                </PageTitleWrapper>
                
            </Box>
            <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <form action="#">
                                <Card>
                                    {/* Form Input Fields */}
                                    <CardContent>
                                        <Box
                                            component="form"
                                            sx={{
                                                '& .MuiTextField-root': { m: 1, width: '25ch' }
                                            }}
                                            noValidate
                                            autoComplete="off"
                                        >
                                            <div>
                                                <TextField
                                                    select
                                                    margin="dense"
                                                    label="Year"
                                                    name='year'
                                                    fullWidth
                                                    value={Number(year)}
                                                    onChange={handleChange}

                                                >
                                                    <MenuItem value="2024">2024</MenuItem>
                                                    <MenuItem value="2025">2025</MenuItem>
                                                </TextField>
                                                <TextField
                                                    select
                                                    margin="dense"
                                                    label="Month"
                                                    name='month'
                                                    fullWidth
                                                    value={Number(month)}
                                                    onChange={handleChange}

                                                >
                                                    {months.map((month) => (
                                                        <MenuItem key={month.value} value={month.value}>
                                                            {month.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                                <TextField
                                                    label="Search"
                                                    variant="outlined"
                                                    value={search1}
                                                    onChange={handleSearchChange1}

                                                    margin="normal"
                                                // sx={{ flexGrow: 1}}
                                                />
                                                <TextField
                                                    select
                                                    value={configid}
                                                    onChange={(e: any) => setConfigid(e.target.value)}
                                                    sx={{
                                                        minWidth: 200, // Ensure the select box has a minimum width
                                                    }}
                                                >
                                                    {salary
                                                        .filter((option) => option.isactive === 1)
                                                        .map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.name}
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                                <Button style={{marginTop:'12px' }} onClick={handlePrint} variant="contained"
                                                    color="secondary">
                                                    Print
                                                </Button>
                                                {/* <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{
                                                        m: 1,
                                                        width: '10ch',
                                                        '& .MuiInputBase-root': {
                                                            height: '5%'  // Adjust this value as needed
                                                        }
                                                    }}
                                                    className=""
                                                    onClick={handlePrint}
                                                >
                                                    Print
                                                </Button> */}

                                            </div>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </form>

                        </Grid>

                    </Grid>
            <div ref={componentRef}>
                <Grid
                    item
                    xs={8}
                    sx={{
                        mx: '10mm', // Margin left and right
                    }}
                >
                    {formData.map((row: any, index: number) => (
                        <div key={row.id || index} >
                            <Card><CardContent>
                                <div className="salary-slip-container">

                                    <header className="header-container">
                                        <table>
                                            <tr style={{}}>
                                                <td className="tdimg" style={{ border: '0px', width: '10%' }}> <img
                                                    src={require('src/assets/logosaarc.png')}
                                                    alt="Company Logo"
                                                    style={{ width: 60, height: 60, marginLeft: 10, marginRight: 10, float: 'left' }} // Space between the image and the text
                                                /></td>
                                                <td>
                                                    <div className="header-text" style={{textAlign: "center"}}>
                                                        <h2>SAARC TOOL TECH PVT LTD</h2>
                                                        <p>Plot No:85,86,87, Sidco Industrial Estate, Malumichampatti Coimbatore - 641050</p>
                                                        <p style={{ fontWeight: '600', color: '#000',textTransform : 'uppercase' }}>FORM-T SALARY SLIP FOR MONTH OF <b style={{textTransform : 'uppercase'}}>{months.find((x) => x.value === month)?.label}</b> {year}</p>
                                                        <small>
                                                     see sub-rule(6) of rule 11 of the tamil nadu shops and establishments rules,1948
                                                            {/* see 25 b under section 103b and Form-15 under Factory Act */}
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>

                                    </header>


                                    <div className="employee-details">
                                        <section className="details-block">
                                            <h3>EMPLOYEE DETAILS</h3>
                                            <div className="details-row">
                                                <p>EMPLOYEE No:</p>
                                                <p>{row.ID}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>NAME:</p>
                                                <p>{row.Names}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>FATHER NAME:</p>
                                                <p>{row.Father_name}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>DESIGNATION:</p>
                                                <p>{row.Design}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>DEPARTMENT:</p>
                                                <p>{row.Deptname}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>DOJ:</p>
                                                <p>{new Date(row.DOJ).toLocaleDateString('en-GB')}</p>
                                            </div>
                                        </section>
                                        <section className="details-block">
                                            <h3>STATUTORY DETAILS</h3>
                                            <div className="details-row">
                                                <p>PF NO:</p>
                                                <p></p>
                                            </div>
                                            <div className="details-row">
                                                <p>UAN NO:</p>
                                                <p></p>
                                            </div>
                                            <div className="details-row">
                                                <p>ESI NO:</p>
                                                <p></p>
                                            </div>
                                            <div className="details-row">
                                                <p>BANK NAME:</p>
                                                <p></p>
                                            </div>
                                            <div className="details-row">
                                                <p>ACC NO:</p>
                                                <p></p>
                                            </div>
                                            <div className="details-row">
                                                <p>IFSC CODE:</p>
                                                <p></p>
                                            </div>

                                        </section>
                                        <section className="details-block">
                                            <h3>ATTENDANCE DETAILS</h3>
                                            <div className="details-row">
                                                <p>SALARY DAYS:</p>
                                                <p>{row.Total_Days}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>WORKING DAYS:</p>
                                                <p>{row.No_of_Working_Days}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>WEEK OFF:</p>
                                                <p>{row.Weekoff}</p>
                                            </div>
                                            <div className="details-row">
                                                <p>FH / NH:</p>
                                                <p>{row.FH} </p>
                                            </div>
                                            <div className="details-row">
                                                <p>LOP:</p>
                                                <p>{row.LOP} </p>
                                            </div>
                                            <div className="details-row">
                                                <p>PAID DAYS:</p>
                                                <p>{row.No_of_Working_Days}</p>
                                            </div>
                                        </section>
                                    </div>

                                    <div className="employee-details">
                                        <section className="details-block">
                                            <h3>PAY ELEMENTS</h3>
                                            {Object.entries(row).map(([key, value]) => {
                                                // Check if the value contains ",e"
                                                if (typeof value === "string" && value.includes(",e")) {
                                                    return (
                                                        <div key={key} className="details-row">
                                                            <p>
                                                                {key.replace(/\r\n/g, "").replace(/_/g, " ").toUpperCase()}
                                                            </p>

                                                        </div>
                                                    );
                                                }
                                                return null; // Skip if it doesn't match the condition
                                            })}
                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>
                                            <h3>ACTUAL</h3>

                                            {Object.entries(row).map(([key, value]) => {
                                                // Check if the value contains ",e"
                                                if (typeof value === "string" && value.includes(",e")) {
                                                    return (
                                                        <div key={key} className="details-row" style={{ textAlign: 'right' }}>


                                                            <p style={{ width: '100%' }}>{value.split(",")[2]?.trim() || ""}</p> {/* Extract the third part, if it exists */}
                                                        </div>
                                                    );
                                                }
                                                return null; // Skip if it doesn't match the condition
                                            })}



                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>
                                            <h3>EARNINGS</h3>

                                            {Object.entries(row).map(([key, value]) => {
                                                // Check if the value contains ",e"
                                                if (typeof value === "string" && value.includes(",e")) {
                                                    return (
                                                        <div key={key} className="details-row" style={{ textAlign: 'right' }}>

                                                            <p style={{ width: '100%' }}>{value.split(",")[0].trim()}</p> {/* Extract the first part */}

                                                        </div>
                                                    );
                                                }
                                                return null; // Skip if it doesn't match the condition
                                            })}



                                        </section>
                                        <section className="details-block">
                                            <h3>DEDUCTIONS</h3>
                                            {Object.entries(row).map(([key, value]) => {
                                                // Check if the value contains ",e"
                                                if (typeof value === "string" && value.includes(",d")) {
                                                    return (
                                                        <div key={key} className="details-row" >
                                                            <p>
                                                                {key.replace(/\r\n/g, "").replace(/_/g, " ").toUpperCase()}
                                                            </p>
                                                            <p>{value.split(",")[0].trim()}</p> {/* Extract the first part */}

                                                        </div>
                                                    );
                                                }
                                                return null; // Skip if it doesn't match the condition
                                            })}
                                        </section>
                                    </div>
                                    <div className="employee-details" style={{ height: '30px' }}>
                                        <section className="details-block">
                                            <p style={{ color: '#000', paddingLeft: '18px' }}><strong>Total</strong></p>

                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>
                                            <p style={{ color: '#000', paddingRight: '8px' }}><strong>
                                                {Object.entries(row).reduce((sum, [key, value]) => {
                                                    if (typeof value === "string" && value.includes(",e")) {
                                                        const thirdPart = parseFloat(value.split(",")[2]?.trim());
                                                        if (!isNaN(thirdPart)) {
                                                            sum += thirdPart;
                                                        }
                                                    }
                                                    return sum;
                                                }, 0)}
                                            </strong></p>




                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>

                                            <p style={{ color: '#000', paddingRight: '8px' }}><strong>
                                                {Object.entries(row).reduce((sum, [key, value]) => {
                                                    if (typeof value === "string" && value.includes(",e")) {
                                                        const thirdPart = parseFloat(value.split(",")[0]?.trim());
                                                        if (!isNaN(thirdPart)) {
                                                            sum += thirdPart;
                                                        }
                                                    }
                                                    return sum;
                                                }, 0)}
                                            </strong>
                                            </p>


                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>

                                            <p style={{ color: '#000', paddingRight: '8px' }}><strong>
                                                {Object.entries(row).reduce((sum, [key, value]) => {
                                                    if (typeof value === "string" && value.includes(",d")) {
                                                        const thirdPart = parseFloat(value.split(",")[0]?.trim());
                                                        if (!isNaN(thirdPart)) {
                                                            sum += thirdPart;
                                                        }
                                                    }
                                                    return sum;
                                                }, 0)}
                                            </strong>
                                            </p>

                                        </section>
                                    </div>
                                    <div className="employee-details" style={{ height: '30px' }}>
                                        <section className="details-block">
                                           
                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>
                                           

                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>
                                        <p style={{ color: '#000', paddingLeft: '18px',fontSize: '13px' }}><strong>Net Pay </strong></p>

                                        </section>
                                        <section className="details-block" style={{ textAlign: 'right' }}>

                                       
                                        <p style={{ fontSize: '13px',  color: '#000',paddingRight: '8px' }}><b>{row.Net_Pay}</b></p>

                                        </section>
                                    </div>
                                    <div className="employee-details" style={{
                                        height: '50px', textAlign: 'left', paddingTop: '10px', justifyContent: 'left',
                                        paddingLeft: '10px'
                                    }}>
                                       
                                        <p style={{ fontSize: '13px', width: '350px', color: '#000' }}><b>({numberToWords(row.Net_Pay)})</b></p>

                                        <p style={{ textAlign: 'right' }}><br /><strong style={{ marginLeft: '0px' }}> This is computer generated slip does not require signature </strong></p>


                                    </div>


                                </div>
                            </CardContent></Card>
                            <br />
                        </div>))}

                </Grid></div>

        </>
    );
};

export default SalarySlip;
